import { createReducer, on } from '@ngrx/store';
import {
  lastTermsAccepted,
  saveTermsToValidate,
} from './terms-conditions.actions';
import {
  initialAuthState,
  TermsConditionsState,
} from './terms-conditions.state';

/**
 * Reducer function for the terms and conditions state.
 * @param {TermsConditionsState} state - The current state of the terms and conditions.
 * @param {Action} action - The action to be performed on the state.
 * @returns The updated state after applying the action.
 */
export const termsConditionsReducer = createReducer<TermsConditionsState>(
  initialAuthState,
  on(lastTermsAccepted, (state) => ({
    ...state,
    termsAccepted: true,
  })),
  on(saveTermsToValidate, (state, { options }) => ({
    ...state,
    termsToValidate: options,
  }))
);
