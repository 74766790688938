import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { terminateOfflineMode } from '@tc/store';
import { ConfigService } from '../../../../../../shared/services/config.service';
import { ConfigKeys } from '../../../../../../shared/interfaces/config.interface';
import {
  TcComponentLookup,
  TcFormComponent,
  TcTranslateService,
} from '@tc/core';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfflineModeSyncResponse, SyncResponsePopupPayload } from '@tc/breeze';

@Component({
  selector: 'app-sync-response-popup',
  templateUrl: './sync-response-popup.component.html',
  styleUrls: ['./sync-response-popup.component.scss'],
})
@TcComponentLookup('SyncResponsePopupComponent')
export class SyncResponsePopupComponent
  extends TcFormComponent<any>
  implements OnInit
{
  public DATE_FORMAT = 'dd/MM/YYYY hh:mm:ss';

  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `prompt.close-button`,
  };

  confirmButtonConfig: TcSmartButton = {
    label: 'confirm',
    class: 'btn-secondary',
  };

  constructor(
    private dialogRef: MatDialogRef<SyncResponsePopupComponent>,
    translate: TcTranslateService,
    elem: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: SyncResponsePopupPayload
  ) {
    super(translate, elem);
  }

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
