import { Injectable } from '@angular/core';
import { IConfigService } from '@tc/config';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
/**
 * A service class that provides access to configuration settings.
 */
export class ConfigService implements IConfigService {
  private readonly jsonConfig = require('../../custom/assets/config.json');

  /**
   * Retrieves the value associated with the given key from the JSON configuration object.
   * @param {string} key - The key to retrieve the value for.
   * @returns The value associated with the given key, or undefined if the key does not exist.
   * @template T - The type of the value to retrieve.
   */
  get<T>(key: string): T {
    return get(this.jsonConfig, key, undefined);
  }
}
