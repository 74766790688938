import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

/**
 * Retrieves the authentication state from the store using the createFeatureSelector function.
 * @returns The authentication state object from the store.
 */
export const getAuthenticationState = createFeatureSelector<AuthState>('auth');

/**
 * Returns the authenticated user from the authentication state.
 * @param {AuthState} state - The authentication state object.
 * @returns The authenticated user.
 */
export const getAuthenticatedUser = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.user
);

/**
 * Retrieves the authentication token from the authentication state.
 * @param {AuthState} state - The authentication state object.
 * @returns The authentication token.
 */
export const getAuthenticationToken = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.token
);

/**
 * Returns the MFA (Multi-Factor Authentication) options from the authentication state.
 * @param {AuthState} state - The authentication state object.
 * @returns The MFA options from the authentication state.
 */
export const getAuthenticationMfaOptions = createSelector(
  getAuthenticationState,
  (state: AuthState) => state.mfaOptions
);
