import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PermissionsService } from '../../../services/permissions.service';
import { initMenu } from '../../menu/store/menu.actions';
import {
  loadTcPermissions,
  loadTcPermissionsSuccess,
  refreshTcPermissionsSuccess,
} from '@tc/permissions';

@Injectable()
/**
 * Class representing the effects for managing permissions.
 */
export class PermissionsEffects {
  /**
   * Constructs a new instance of the class.
   * @param {Actions} actions$ - The actions observable.
   * @param {Store<any>} store$ - The store observable.
   * @param {PermissionsService} permissionsService - The permissions service.
   * @returns None
   */
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly permissionsService: PermissionsService
  ) {}

  /**
   * Creates an effect that loads the permissions for the given user roles.
   * @returns None
   */
  loadPermissions = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcPermissions),
        tap(async ({ userRoles, refresh }) => {
          const rightsConfig = await this.permissionsService.getPermissions();

          const permissionsForRoles = [];
          for (const configuration of rightsConfig.configuration) {
            // Add the permissions associated with the roles to the total permissions of the logged user.
            if (
              userRoles.some((userRole) =>
                configuration.roles.includes(userRole)
              )
            ) {
              permissionsForRoles.push(...configuration.permissions);
            }
          }

          if (refresh === false) {
            this.store$.dispatch(
              loadTcPermissionsSuccess({
                rights: rightsConfig,
                authenticatedUserPermissions: permissionsForRoles,
              })
            );
          } else {
            this.store$.dispatch(
              refreshTcPermissionsSuccess({
                rights: rightsConfig,
                authenticatedUserPermissions: permissionsForRoles,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * after the permissions are set into the store, call the action to initialize the menu
   */
  loadTcPermissionsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcPermissionsSuccess),
        tap(() => {
          this.store$.dispatch(initMenu());
        })
      ),
    { dispatch: false }
  );

  /**
   * after the permissions are set into the store, call the action to initialize the menu
   */
  refreshTcPermissionsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTcPermissionsSuccess),
        tap(() => {
          this.store$.dispatch(initMenu());
        })
      ),
    { dispatch: false }
  );
}
