import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
  getTcWriteDataProvider,
  initTcListDataStore,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { hasValue } from '@tc/utils';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
} from '@tc/smart-form';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  saveCurrentEncaissement,
  setCurrentEncaissement,
  setCurrentEncaissementFromPopup,
} from './encaissement.actions';
import { getCurrentEncaissement } from './encaissement.selectors';
import { selectValueByKey } from '@tc/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  TcSmartGridConfig,
} from '@tc/abstract';
import { TcNotificationService, TcTranslateService } from '@tc/core';

@Injectable()
export class EncaissementEffects {
  dataStore$: Observable<NgRxTcDataState>;
  formStore$: Observable<NgRxTcSmartFormState>;
  subscription = new Subscription();
  storeKey = 'encaissement-grid';

  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly tcNotificationService: TcNotificationService,
    private readonly translateService: TcTranslateService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  setCurrentEncaissementFromPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setCurrentEncaissementFromPopup),
        tap(async (payload) => {
          this.store$.dispatch(
            setCurrentEncaissement({ encaissement: payload.encaissement })
          );

          if (payload.save) {
            // Save the encaissement
            this.store$.dispatch(
              saveCurrentEncaissement({ notification: true })
            );
          }
        })
      ),
    { dispatch: false }
  );

  saveCurrentEncaissement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveCurrentEncaissement),
        tap(async (payload) => {
          // Init the grid if needed
          await this.initGridWithMinimalSetup();

          // Get the encaissement from the store
          const encaissement = await this.store$
            .select(getCurrentEncaissement)
            .pipe(take(1))
            .toPromise();

          if (!encaissement) {
            throw new Error('No encaissement found in the store');
          }

          this.store$.dispatch(
            updateItem({
              storeKey: this.storeKey,
              item: encaissement,
              displaySuccessNotification: false,
            })
          );

          this.actions$
            .pipe(ofType(updateItemSuccess), take(1))
            .subscribe(() => {
              // Clear the document in the store
              this.store$.dispatch(
                setCurrentEncaissement({ encaissement: null })
              );
              if (payload.notification) {
                this.tcNotificationService.success(
                  this.translateService.instant('success-save-encaissement'),
                  null,
                  { timeOut: 5000 }
                );
              }
            });
        })
      ),
    { dispatch: false }
  );

  /**
   * Initializes the grid with minimal setup.
   * If a write data provider is not already defined for encaissement, it instantiates the grid with minimal setup to be able to launch an updateItem event.
   */
  public async initGridWithMinimalSetup() {
    // Check if a write data provider is already defined for encaissement-grid
    const dataProvider = await selectValueByKey(
      getTcWriteDataProvider,
      this.dataStore$,
      this.storeKey
    );

    if (!dataProvider) {
      // No data provider, elevage-grid was never launched. Instanciate it with minimal setup to be able to launch a updateItem event.
      const config: TcSmartGridConfig = {
        configType: TcConfigTypes.TcGrid,
        storeKey: this.storeKey,
        gridOptions: {},
        dataProvider: {
          configType: TcConfigTypes.TcDataProvider,
          providerType: TcDataProviderType.BreezeJs,
          dataSet: 'Encaissements',
        },
        columns: [],
      };

      this.store$.dispatch(
        initTcListDataStore({ storeKey: this.storeKey, listConfig: config })
      );
    }
  }
}
