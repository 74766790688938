import {
  InitTcFilterPayload,
  NgRxTcDataState,
  UpdateTcFilterPayload,
} from '@tc/data-store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { TcFilterService } from '../../services/tc-filter-store.service';
import {
  initTcFilter,
  initTcFilterSuccess,
  updateTcFilter,
  updateTcFilterSuccess,
} from '../actions/tc-filter-actions';
import { refreshTcData } from '@tc/data-store';
import { getTcFilters } from '../selectors/tc-filter.selectors';
import { DEFAULT_TC_FILTER_STATE_KEY } from '../state/tc-filter-state';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import { selectValueByKey } from '@tc/store';
import * as R from 'ramda';
import { InputType, TcConfigTypes } from '@tc/abstract';
import { ActivatedRoute } from '@angular/router';
import { isValidDate } from '../../../shared/utils/date-utils';
import moment from 'moment';
import { initTcSmartForm } from '@tc/smart-form';
import { TcFilterCONSTANTS } from '@tc/core';

@Injectable()
export class TcFilterEffects {
  filterStore$: Observable<NgRxTcDataState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly tcFilterService: TcFilterService,
    private readonly route: ActivatedRoute
  ) {
    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   *
   */
  initTcFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initTcFilter),
        tap(async (payload: InitTcFilterPayload) => {
          let { storeKey, filter, isPersistant, config } = payload;

          // Initialize default filters from config fields
          if (!filter) {
            filter = {
              filters: this.tcFilterService.getDefaultFilterItems(
                config.fields
              ),
              anyFieldContainsFields: config.anyFieldContainsFields,
              anyFieldStartsWithFields: config.anyFieldStartsWithFields,
              anyFieldEqualsFields: config.anyFieldEqualsFields,
            };
          }

          const queryParams = this.route.snapshot.queryParams;
          // TODO Task 67049 : from what i could tell the form works with {a: {b: {c: 'value}}}
          const model = {};
          // TODO Task 67049 : from what i could tell the filter works with 'a.b.c': 'value'
          const filterModel = {};

          // Adds a nested key as an object: 'a.b.c': 'value' => {a: {b: {c: 'value'}}}
          const addNestedKeyToModel = (nestedKey, value, object) => {
            nestedKey.split('.').map((k, i, values) => {
              object = object[k] = i == values.length - 1 ? value : {};
            });
          };

          let autoOpenParam = null;

          if (Object.keys(queryParams).length > 0) {
            let queryParamsEntries = Object.entries(queryParams);

            // check if autoOpen parameter is in the url
            autoOpenParam = Object.entries(queryParams).find(
              (e) => e[0] === TcFilterCONSTANTS.AUTO_OPEN
            );

            // if autoOpen param is in the url, take it out..it will be added again later, after set default filter values
            if (autoOpenParam) {
              queryParamsEntries = Object.entries(queryParams).filter(
                (e) => e[0] !== TcFilterCONSTANTS.AUTO_OPEN
              );
            }

            const fieldDefs = this.tcFilterService.getFormlyFieldDefinitions(
              config.fields
            );
            const fieldDefsKeys = fieldDefs?.map((fieldDef) => fieldDef.key);

            for (const [key, value] of queryParamsEntries) {
              if (!fieldDefsKeys.includes(key)) continue;

              const inputType = this.tcFilterService.getInputType(
                key,
                config.fields
              );

              let typedValue;

              switch (inputType) {
                case InputType.checkbox:
                  typedValue = value === 'true' ? true : false;
                  break;
                case InputType.number:
                  typedValue = Number(value);
                  break;
                case InputType.multipleSelect:
                  if (Array.isArray(value)) {
                    typedValue = value;
                  } else {
                    if (value === 'null') {
                      typedValue = [null];
                    } else {
                      typedValue = [value];
                    }
                  }
                  break;
                case InputType.text:
                default:
                  typedValue = value;
              }

              // Addind key to form Model
              addNestedKeyToModel(key, typedValue, model);

              // Adding key to filter Model
              filterModel[key] = typedValue;

              // Creating filter model based on filter model
              const index = filter.filters.findIndex(
                (elem) => elem.key === key
              );
              if (index >= 0) {
                filter.filters[index].value = filterModel[key];
              } else {
                filter.filters.push(
                  this.tcFilterService.getFilterItemFromUrl(
                    config.fields,
                    key,
                    filterModel[key]
                  )
                );
              }
            }
          }

          const filterSmartFormStoreKey =
            storeKey + TcFilterCONSTANTS.SMART_FORM_STORE_KEY_ENDING;
          this.store$.dispatch(
            initTcSmartForm({
              storeKey: filterSmartFormStoreKey,
              config: {
                autoSubmit: true,
                configType: TcConfigTypes.TcForm,
                storeKey: filterSmartFormStoreKey,
                fieldConfigs: config.fields,
              },
              model: model, //TODO Task 67049 doensn't work with nested keys properly
            })
          );

          //TODO Task 67049 uncomment when URL feature will be implemented
          //this.tcFilterService.updateUrl(filter.filters);

          this.store$.dispatch(
            initTcFilterSuccess({
              storeKey,
              filter,
              isPersistant,
              config,
            })
          );

          // TODO: Date-Range Picker currently throws errors when built from url - re-enable together with the parsing fix
          // Update the url with the default filter and model values
          // add back the autoOpen parameter if was in the initial url
          // this.tcFilterService.updateUrl([
          //   ...filter.filters,
          //   ...(autoOpenParam
          //     ? [{ key: autoOpenParam[0], value: autoOpenParam[1] }]
          //     : []),
          // ]);

          // If the filters are empty on init there is no need to
          // refresh data as it generates an useless extra call.
          if (filter.filters?.length > 0) {
            this.store$.dispatch(
              refreshTcData({
                storeKey,
                filter,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  /**
   *  effect to update and refresh tc-data
   */
  updateTcFilters$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTcFilter),
        tap(async (payload: UpdateTcFilterPayload) => {
          const { storeKey, filter, alwaysRefresh } = payload;

          // alwaysRefresh is by default null.
          // Thus this block of code is always executed unless the developer specifies not to.
          if (!alwaysRefresh) {
            // Get previous filter
            const storeFilter = await selectValueByKey(
              getTcFilters,
              this.filterStore$,
              storeKey
            );

            // If current filter equals previous filter don't do anything
            if (R.equals(storeFilter.filters, filter.filters)) return;
          }

          // update the url to show filters
          // TODO: Date-Range Picker currently throws errors when built from url - re-enable together with the parsing fix
          // this.tcFilterService.updateUrl(filter.filters);

          this.store$.dispatch(
            refreshTcData({
              storeKey,
              filter,
            })
          );

          this.store$.dispatch(
            updateTcFilterSuccess({
              storeKey,
              filter,
            })
          );
        })
      ),
    { dispatch: false }
  );
}
