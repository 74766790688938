import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { TcTranslateService } from '@tc/core';

export interface CanDeactivateComponent {
  shouldConfirmDeactivation(): Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
/**
 * A guard that implements the CanDeactivate interface to handle deactivation confirmation.
 * @implements CanDeactivate<CanDeactivateComponent>
 */
export class DeactivationConfirmationGuard
  implements CanDeactivate<CanDeactivateComponent>
{
  constructor(private readonly translate: TcTranslateService) {}

  /**
   * Checks if a component can be deactivated before navigating to a new route.
   * @param {CanDeactivateComponent} component - The component to check for deactivation.
   * @param {ActivatedRouteSnapshot} currentRoute - The current route being navigated from.
   * @param {RouterStateSnapshot} currentState - The current router state.
   * @param {RouterStateSnapshot} [nextState] - The next router state being navigated to.
   * @returns A promise that resolves to a boolean or UrlTree indicating if deactivation is allowed.
   */
  async canDeactivate(
    component: CanDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (await component?.shouldConfirmDeactivation?.()) {
      return confirm(this.translate.instant('leave-page-message'));
    } else {
      return true;
    }
  }
}
