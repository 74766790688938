import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TcSmartButton } from '@tc/buttons';
import { TcNavMenuNodes, TcPage } from '@tc/core';
import { getAbilities } from '@tc/permissions';
import { IPageState, SetPageInfo } from '@tc/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigKeys } from '../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../shared/services/config.service';
import { initHome } from '../../modules/home/store/home.actions';
import {
  getElevageButtonsList,
  getStockButtonsList,
  getXpertButtonsList,
} from '../../modules/home/store/home.selectors';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent extends TcPage implements OnInit, OnDestroy {
  constructor(
    private readonly store$: Store<any>,
    private readonly config: ConfigService,
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(store$);
  }

  /**
   * MediaQueryList that is used in order to know when the resolution reaches the mobile breakpoint
   */
  mobileQuery: MediaQueryList;

  /**
   * Listener that is used by mobileQuery for when the resolution reaches the mobile breakpoint.
   * Only declared so that it can be removed in the ngOnDestroy method
   */
  private _mobileQueryListener: () => void;

  isMobile: boolean;

  menuItems: TcNavMenuNodes[];

  responsiveOptions: any;

  elevageButtonsList$: Observable<TcSmartButton[]>;
  stockButtonsList$: Observable<TcSmartButton[]>;
  xpertButtonsList$: Observable<TcSmartButton[]>;

  async ngOnInit() {
    const pageInfo: IPageState = {
      title: 'home-page.title',
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

    this.responsiveOptions = this.config.get(
      ConfigKeys.layoutConfig.responsiveOptions
    );

    if (this.responsiveOptions?.responsive) {
      const setMobileResolution = () => {
        this.isMobile = this.mobileQuery.matches;

        // Update page's name
        if (this.store) {
          this.store.dispatch(new SetPageInfo(this.pageInfo));
        }
      };

      this.mobileQuery = this.media.matchMedia(
        `(max-width: ${this.responsiveOptions.breakPoint})`
      );

      this._mobileQueryListener = () => {
        setMobileResolution();

        this.changeDetectorRef.detectChanges();
      };
      setMobileResolution();

      this.mobileQuery.addEventListener('change', this._mobileQueryListener);

      this.store$.dispatch(initHome());
      this.initButtonsLists();
    }

    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    /**
     * NOTE: If this array gets changed probably the array form apps\frontend\src\app\custom\modules\menu\store\menu.effects.ts:42:7
     *       needs to be updated
     */
    this.menuItems = [];
  }

  ngOnDestroy(): void {
    if (this.responsiveOptions?.responsive) {
      this.mobileQuery?.removeEventListener(
        'change',
        this._mobileQueryListener
      );
    }
  }

  initButtonsLists(): void {
    this.elevageButtonsList$ = this.store$.pipe(select(getElevageButtonsList));
    this.stockButtonsList$ = this.store$.pipe(select(getStockButtonsList));
    this.xpertButtonsList$ = this.store$.pipe(select(getXpertButtonsList));
  }
}
