import { createAction, props } from '@ngrx/store';
import { TcDataProviderType } from '../../tc-abstract/component-configs';

/**
 * this action is triggered to check if offline mode is activated
 */
export const hasOfflineMode = createAction(
  '[Offline Mode] Check if offline mode is activated',
  props<{
    providerType: TcDataProviderType;
    relaunch: boolean;
    popupComponentName?: string;
  }>()
);

/**
 * this action is triggered to activate offline mode
 */
export const activateOfflineMode = createAction(
  '[Offline Mode] Activate offline mode',
  props<{ providerType: TcDataProviderType; popupComponentName?: string }>()
);

export const offlineModeActivated = createAction(
  '[Offline Mode] Offline mode activated'
);

/**
 * this action is triggered to terminate offline mode
 */
export const terminateOfflineMode = createAction(
  '[Offline Mode] Terminate offline mode',
  props<{
    providerType: TcDataProviderType;
    relaunch: boolean;
    popupComponentName?: string;
  }>()
);

export const offlineModeDeactivated = createAction(
  '[Offline Mode] Offline mode deactivated'
);

export const updateSyncPercentage = createAction(
  '[Offline Mode] Update synchronisation percentage',
  props<{ percentage: number }>()
);
