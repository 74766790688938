import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TcCoreModule } from '@tc/core';
import { SharedModule } from '../../../shared/shared.module';
import { DevisGridComponent } from './components/smart/devis-grid/devis-grid.component';
import { DevisDetailGridComponent } from './components/smart/devis-detail-grid/devis-detail-grid.component';
import { TcSmartFormModule } from '@tc/smart-form';
import { TcButtonsModule } from '@tc/buttons';
import { EffectsModule } from '@ngrx/effects';
import { DevisEffects } from './store/devis.effects';

const components = [DevisGridComponent, DevisDetailGridComponent];

const services = [];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    SharedModule,
    TcSmartFormModule,
    TcButtonsModule,
    EffectsModule.forFeature([DevisEffects]),
  ],
  providers: [...services],
  exports: [...components],
})
export class DevisModule {}
