import { Component, OnInit, Inject } from '@angular/core';
import { TcComponentLookup, TcTranslateService } from '@tc/core';
import { TcSmartDetailPopupComponent } from '@tc/advanced-components';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { PriceService } from '../../../../../services/price.service';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { take } from 'rxjs/operators';
import { TcDataService } from '@tc/data-store';
import { Article } from '../../../interfaces/article.interface';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import { Promotion } from '../../../interfaces/promotion.interface';
import { DiscountType } from '../../../../../typings/discount-type.enum';
@TcComponentLookup('PromotionPopupComponent')
@Component({
  selector: 'app-promotion-popup',
  templateUrl: './promotion-popup.component.html',
  styleUrls: ['./promotion-popup.component.scss'],
})
export class PromotionPopupComponent
  extends TcSmartDetailPopupComponent
  implements OnInit
{
  formStoreKey = 'promotions-popup';

  popupData: any;
  article: Article;
  elevage: Elevage;
  promotion: Promotion | null = null;
  tarifDegressif:
    | {
        borneSuperieure: number;
        remise: number;
        type: DiscountType;
        prixNet: number;
        categorieTarifaire: {
          _id: string;
          intitule: string;
        };
      }[]
    | null = null;
  initDone = false;

  private readonly articleDataService: ITcDataService<any> =
    this.dataService.getService('Article', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'reference',
    });

  constructor(
    store$: Store<any>,
    public readonly translate: TcTranslateService,
    @Inject(MAT_DIALOG_DATA) data,
    private readonly priceService: PriceService,
    private readonly dataService: TcDataService
  ) {
    super(store$, data);
    this.popupData = data;
    this.formStoreKey = data.formStoreKey;
    // tc-smart-form needs tyo be in the HTML, even if we don't use it and needs the store key at the beginning, if not, the headers will not display the title.
    this.dialogConfig.dialogStoreKey = this.formStoreKey;
    this.dialogConfig.hasFooter = false;
  }

  async ngOnInit() {
    const articleReference = this.popupData?.entityData?.reference;
    // Load the article from the DB to have all the data
    const articles = await this.articleDataService.getData(0, null, {
      filters: [
        {
          key: 'reference',
          value: articleReference,
          filterType: FilterTypesEnum.Equal,
        },
      ],
    });
    const articleFound = articles?.data[0];
    if (articleFound) {
      this.article = articleFound;
      const elevage = await this.store$
        .select(getCurrentElevage)
        .pipe(take(1))
        .toPromise();
      const promotion = await this.priceService.getCurrentPromotion(
        this.article,
        elevage
      );
      if (promotion) {
        this.promotion = promotion;
      } else {
        const tarifDegressif = await this.priceService.getTarifDegressif(
          this.article,
          elevage
        );
        if (tarifDegressif) {
          this.tarifDegressif = tarifDegressif;
        }
      }
    }

    await super.ngOnInit();
    this.initDone = true;
  }

  async setFormConfig() {
    this.formConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.formStoreKey,
      autoSubmit: false,
      fieldConfigs: [],
      titleConfig: {
        storeKey: this.formStoreKey,
        configType: TcConfigTypes.TcDetailTitle,
        titlePrefix:
          this.promotion !== null
            ? 'promotion-popup.title'
            : 'promotion-popup.title-remise',
        hasGenericSaveButton: false,
      },
      headerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
      },
      footerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
      },
    };
  }
}
