import { DEFAULT_TC_DATA_STATE_KEY, NgRxTcDataState } from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { getPasswordChange } from './xpert.selectors';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  DEFAULT_TC_GRID_STATE_KEY,
  NgRxTcGridState,
} from '@tc/core';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import { saveNewPassword } from './xpert.actions';
import { updateItem } from '@tc/data-store';
import { MD5 } from 'crypto-js';

@Injectable()
export class XpertEffects {
  gridStore$: Observable<NgRxTcGridState>;
  filterStore$: Observable<NgRxTcDataState>;
  dataStore$: Observable<NgRxTcDataState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions
  ) {
    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  saveNewPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveNewPassword),
        tap(async () => {
          const passwordChange = await this.store$
            .select(getPasswordChange)
            .pipe(take(1))
            .toPromise();

          // For now, password is in MD5 without salt in the ETL. If we need to change this, we need to change the ETL too for all encoding to be at the same page.
          const xpert = {
            ...passwordChange.xpert,
            password: MD5(passwordChange.password).toString(),
          };

          // Save the movement
          this.store$.dispatch(
            updateItem({
              storeKey: passwordChange.storeKey,
              item: xpert,
              displaySuccessNotification: true,
            })
          );
        })
      ),
    { dispatch: false }
  );
}
