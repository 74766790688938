import { Injectable, OnDestroy } from '@angular/core';
import { TcAppState } from '@tc/store';
import { TcTranslateService } from '@tc/core';
import { TcService } from '@tc/abstract';
import { Idle, EventTargetInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ConfigService } from '../shared/services/config.service';
import { ConfigKeys } from '../shared/interfaces/config.interface';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { logout, refresh } from '../modules/auth/store/auth.actions';
import * as moment from 'moment';
import { TcPromptDialogComponent } from '@tc/dialog';

@Injectable({
  providedIn: 'root',
})
/**
 * Service class for managing session timeouts.
 * @extends TcService
 * @implements OnDestroy
 */
export class SessionTimeoutService extends TcService implements OnDestroy {
  /**
   * Constructs a new instance of the class.
   * @param {ConfigService} config - The configuration service.
   * @param {Idle} idle - The idle service.
   * @param {Keepalive} keepalive - The keepalive service.
   * @param {Store<TcAppState>} store - The store service.
   * @param {MatDialog} dialog - The dialog service.
   * @param {TcTranslateService} translateService - The translation service.
   * @returns None
   */
  constructor(
    private readonly config: ConfigService,
    private readonly idle: Idle,
    private readonly keepalive: Keepalive,
    private readonly store: Store<TcAppState>,
    private readonly dialog: MatDialog,
    private readonly translateService: TcTranslateService
  ) {
    super();
  }

  /**
   * Starts the service to watch if user is idle or not and terminate session if this is the case.
   * @param interrupts EventTargetInterruptSource object with native element and events to watch. Exemple : [new EventTargetInterruptSource(this.element.nativeElement, "mousedown keydown")]
   */
  public start(interrupts: EventTargetInterruptSource[]) {
    // Setup the time the user has been idle (for X seconds)
    this.idle.setIdle(parseInt(this.config.get(ConfigKeys.idle_timeout), 10));
    // Setup the time before he gets kicked out once the user is idle (for X seconds)
    this.idle.setTimeout(
      parseInt(this.config.get(ConfigKeys.logout_timeout), 10)
    );
    // Sets the default interrupts
    this.idle.setInterrupts(interrupts);

    this.idle.onTimeout.subscribe(() => {
      // Time out. We close all dialogs, we logout.
      this.dialog.closeAll();
      this.store.dispatch(logout());
    });

    this.idle.onIdleStart.subscribe(() => {
      const secondsBeforeTimeout = parseInt(
        this.config.get(ConfigKeys.logout_timeout),
        10
      );
      const now = moment(new Date());
      now.add(secondsBeforeTimeout, 'seconds');

      // Idle, show the deconnexion warning
      const dialogRef = this.dialog.open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: 'warning',
          disableTextTranslation: true,
          text: this.translateService.instant('globalMessages.logoutWarning', {
            timeout: now.format('HH:mm'),
          }),
          confirm: 'yes',
        },
      });
      dialogRef.afterClosed().subscribe((value) => {        
        // When closing the popup, consider the user is not idle anymore
        this.idle.interrupt();
        if(value === false) {
          // user choosed to not stay connected, do disconnect
          this.dialog.closeAll();
          this.store.dispatch(logout());
        }
      });
    });

    // Refresh the token on server side every X seconds to ensure that the user will not be disconnected while using the app.
    // This renewal will not be triggered when the tiemout countdown has started.
    this.keepalive.interval(
      parseInt(this.config.get(ConfigKeys.jwt_renewal), 10)
    );
    this.keepalive.onPing.subscribe(() => {
      // Call store to refresh JWT token
      this.store.dispatch(refresh());
    });
  }

  /**
   * Unsubscribes from the timeout, idle start, and ping events.
   * @returns None
   */
  ngOnDestroy() {
    this.idle.onTimeout.unsubscribe();
    this.idle.onIdleStart.unsubscribe();
    this.keepalive.onPing.unsubscribe();
  }
}
