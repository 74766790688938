import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormConfig,
  getTcSmartFormInvalidity,
  getTcSmartFormModel,
  markTcSmartFormStateAsChanged,
  NgRxTcSmartFormState,
  submitTcSmartFormCurrentModel,
  updateTcSmartFormCurrentModel,
} from '../../store/index';

import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import * as R from 'ramda';
import {
  MaterialColor,
  TcDetailHeaderConfig,
  TcDetailTitleConfig,
  TcSmartFormConfig,
} from '@tc/abstract';
import { TcFormComponent, TcTranslateService } from '@tc/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { TcDetailTitleComponent } from '../tc-detail-title/tc-detail-title.component';
import { TcButtonsListComponent } from '@tc/buttons';

@Component({
  selector: 'tc-smart-form',
  templateUrl: './tc-smart-form.component.html',
  styleUrls: ['./tc-smart-form.component.scss'],
})
export class TcSmartFormComponent
  extends TcFormComponent<any>
  implements OnInit, OnDestroy
{
  @Input() storeKey: string;
  /**
   * Used in order to know if this is a filter form or not.
   */
  @Input() filterForm: boolean = false;

  config: TcSmartFormConfig;

  titleConfig: TcDetailTitleConfig;
  headerConfig: TcDetailHeaderConfig;
  footerConfig: TcDetailHeaderConfig;

  formStore$: Observable<NgRxTcSmartFormState>;

  private formSubscription: Subscription;
  private configSubscription: Subscription;
  private fieldsSubscription: Subscription;
  private modelSubscription: Subscription;
  private initialModel: any;

  /**
   * Component Portal for the form title
   */
  titleComponentPortal: ComponentPortal<TcDetailTitleComponent>;

  /**
   * Portal host that selects the title slot from the dialog
   */
  titlePortalHost: DomPortalOutlet;

  /**
   * Component Portal for the form actions buttons
   */
  buttonsListCoponentPortal: ComponentPortal<TcButtonsListComponent>;

  /**
   * Portal host that selects the action buttons slot from the dialog
   */
  buttonsListPortalHost: DomPortalOutlet;

  constructor(
    private readonly store$: Store<any>,
    translate: TcTranslateService,
    elem: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _appRef: ApplicationRef,
    private _injector: Injector
  ) {
    super(translate, elem);
    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  ngOnInit() {
    this.configSubscription = selectByKey(
      getTcSmartFormConfig,
      this.formStore$,
      this.storeKey
    )
      .pipe(filter((v) => !!v))
      .subscribe((config) => {
        this.config = R.clone(config);

        this.fields = this.config.fieldConfigs;
        this.titleConfig = this.config.titleConfig;
        this.headerConfig = this.config.headerConfig;
        this.footerConfig = this.config.footerConfig;
        // If we try to set the id of the form dinamically in the html the id will
        // appear on the form as an attribute only after the AfterViewInit is passed
        // which is too late and so we have to send it like this
        super.ngOnInit(this.storeKey);

        this.setFormListener();

        // If this is not a filter form we are in a details form
        if (!this.filterForm) {
          // Add generic save button
          this.createGenericSaveButton();

          // Add other action buttons via portal next to the save button
          this.createActionsPortal();

          // Add the pop-up title via portal
          this.createTitlePortal();
        }
      });

    this.modelSubscription = selectByKey(
      getTcSmartFormModel,
      this.formStore$,
      this.storeKey
    )
      // TODO: I noticed that when using the smart form as a filter if we work with nested keys and selects / multiple-selects
      //       , which emit object values because the store, there is endless loop of updateTcSmartFormCurrentModel and
      //       submitTcSmartFormCurrentModel actions being dispatched making the app unusable.
      //       The reason behind the endless loop is that the smart form sees a new model when the submit happens and since it's a
      //       filter a autosubmit happens every time the form sees a new model.
      //       As a fix I've found that ignorig the models that are the same with the current model stops the endless loop but
      //       this might need some more investigation as behaviour
      .pipe(filter((v) => !!v && !R.equals(this.model, v)))
      .subscribe((model) => {
        this.model = R.clone(model);
        this.initialModel = R.clone(model);
      });
  }

  /**
   * Update the form model into the currentModel of the store
   */
  setFormListener() {
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.store$.dispatch(
        updateTcSmartFormCurrentModel({
          storeKey: this.storeKey,
          invalid: this.form.invalid,
          currentModel: R.clone(this.model),
        })
      );

      if (this.config.autoSubmit) {
        this.store$.dispatch(
          submitTcSmartFormCurrentModel({
            storeKey: this.storeKey,
          })
        );
      }
    });
  }

  /**
   * Add a generic save button to the tittle buttonsList
   */
  createGenericSaveButton() {
    if (this.titleConfig?.hasGenericSaveButton) {
      if (!this.titleConfig.buttonsList) this.titleConfig.buttonsList = [];
      this.titleConfig.buttonsList.push({
        label: 'enregister',
        color: MaterialColor.Primary,
        matIcon: 'save',
        action: this.titleConfig.genericSaveButtonAction,
        actionPayload: { storeKey: this.storeKey },
        disableStoreKey: this.storeKey,
        disableSelector: getTcSmartFormInvalidity,
        smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
        permissionAction: this.config?.permissionAction,
        permissionSubject: this.config?.permissionSubject,
        permissionCustom: this.config?.permissionCustom,
        name: `${this.storeKey}.detail.save-button`,
      });
    }
  }

  /**
   * Inject into the dialog header the action buttons via portal
   */
  createActionsPortal() {
    const dialogActionsSlotId = `tc-dialog-actions-slot-1-${this.storeKey}`;
    this.buttonsListPortalHost = new DomPortalOutlet(
      this.document.getElementById(dialogActionsSlotId),
      this._componentFactoryResolver,
      this._appRef,
      this._injector
    );

    this.buttonsListCoponentPortal = new ComponentPortal(
      TcButtonsListComponent
    );

    if (this.buttonsListPortalHost.outletElement) {
      if (this.buttonsListPortalHost.hasAttached())
        this.buttonsListPortalHost.detach();

      const componentRef = this.buttonsListPortalHost.attach(
        this.buttonsListCoponentPortal
      );
      componentRef.instance.buttonsList = this.titleConfig.buttonsList;
    }

    // Wait for the html to get initialized
    setTimeout(() => {
      if (!this.buttonsListPortalHost.outletElement) {
        console.warn(`Could not find element with id: ${dialogActionsSlotId}`);
      }
    }, 1000);
  }

  /**
   * Inject into the dialog header the form title via portal
   */
  createTitlePortal() {
    const dialogTitleSlotId = `tc-dialog-title-slot-${this.storeKey}`;
    this.titlePortalHost = new DomPortalOutlet(
      this.document.getElementById(dialogTitleSlotId),
      this._componentFactoryResolver,
      this._appRef,
      this._injector
    );

    this.titleComponentPortal = new ComponentPortal(TcDetailTitleComponent);

    if (this.titlePortalHost.outletElement) {
      if (this.titlePortalHost.hasAttached()) this.titlePortalHost.detach();

      const componentRef = this.titlePortalHost.attach(
        this.titleComponentPortal
      );
      componentRef.instance.config = this.titleConfig;
    }

    // Wait for the html to get initialized
    setTimeout(() => {
      if (!this.titlePortalHost.outletElement) {
        console.warn(`Could not find element with id: ${dialogTitleSlotId}`);
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.configSubscription?.unsubscribe();
    this.fieldsSubscription?.unsubscribe();
    this.modelSubscription?.unsubscribe();
    this.formSubscription?.unsubscribe();

    if (this.titlePortalHost && this.titlePortalHost.outletElement)
      this.titlePortalHost.dispose();

    if (this.buttonsListPortalHost && this.buttonsListPortalHost.outletElement)
      this.buttonsListPortalHost.dispose();
  }

  onModelChange(model): void {
    if (!R.equals(model, this.initialModel)) {
      this.markFormAsChanged();
    }
  }

  /**
   * Used to notify system once form changed by user
   */
  markFormAsChanged = R.once(() => {
    this.store$.dispatch(
      markTcSmartFormStateAsChanged({
        storeKey: this.storeKey,
      })
    );
  });
}
