import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {
  TcTranslateService,
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridReadyEvent,
} from '@tc/core';
import { TcSmartGridComponent } from '@tc/advanced-components';
import {
  ColDefExtended,
  ColGroupDefExtended,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  TcSmartFormConfig,
} from '@tc/abstract';
import { initTcSmartForm, setTcSmartFormModel } from '@tc/smart-form';
import { Store, select } from '@ngrx/store';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
  loadTcDataSuccess,
} from '@tc/data-store';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, Subscription } from 'rxjs';
import { DataType } from 'breeze-client';
import { Actions, ofType } from '@ngrx/effects';
import { Article } from '../../../interfaces/article.interface';
import { Promotion } from '../../../interfaces/promotion.interface';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import { DiscountType } from '../../../../../typings/discount-type.enum';

@Component({
  selector: 'app-promotion-grid',
  templateUrl: './promotion-grid.component.html',
  styleUrls: ['./promotion-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PromotionGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'promotions-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  @Input() article: Article;
  @Input() promotion: Promotion | null;
  @Input() tarifDegressif:
    | {
        borneSuperieure: number;
        remise: number;
        type: DiscountType;
        prixNet: number;
        categorieTarifaire: {
          _id: string;
          intitule: string;
        };
      }[]
    | null;
  @Input() elevage: Elevage | null;

  private refresh: () => void;
  private refreshLaunched = false;
  dataStore$: Observable<NgRxTcDataState>;
  subscription = new Subscription();

  constructor(
    store$: Store<any>,
    public readonly translate: TcTranslateService,
    private readonly actions$: Actions
  ) {
    super(store$);

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Function to sync form and grid data with the loaded document
   */
  public refreshFormAndGrid() {
    if (this.refresh) {
      if (this.refreshLaunched === false) {
        this.refreshLaunched = true;
        this.setFormValues();
        this.refresh();
      }
    }
  }

  public async onReady(event: TcGridReadyEvent) {
    this.refresh = event.refresh;
  }

  async ngOnInit() {
    this.initHeader();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'promotion-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      columnNumberPerDevice: {
        extraSmallDevice: 9,
        smallDevice: 9,
        mediumDevice: 9,
        largeDevice: 9,
        extraLargeDevice: 9,
        extraExtraLargeDevice: 9,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'PromotionGrid',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Promotions',
          data: this.getLines.bind(this),
          breezeStructuralTypeExtension: {
            counter: DataType.Int32,
            from: DataType.Decimal,
            to: DataType.Decimal,
            discountPromotion: DataType.Decimal,
            discountTarifDegressif: DataType.Decimal,
          },
        },
      },
      columns: this.promotion
        ? this.getPromotionColumns()
        : this.getTarifDegressifColumns(),
    };

    super.ngOnInit();

    this.subscription.add(
      this.actions$.pipe(ofType(loadTcDataSuccess)).subscribe(() => {
        this.refreshLaunched = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  initHeader() {
    const formConfig: TcSmartFormConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.storeKey,
      autoSubmit: false,
      fieldConfigs: this.promotion
        ? this.getPromotionFields()
        : this.getRemiseFields(),
    };

    this.store$.dispatch(
      initTcSmartForm({
        storeKey: formConfig.storeKey,
        config: formConfig,
      })
    );

    this.setFormValues();
  }

  public setFormValues() {
    const formDefaultValue = {};
    if (this.promotion) {
      formDefaultValue['intitule'] = this.promotion.intitule;
      formDefaultValue['debut'] = this.promotion.debut;
      formDefaultValue['fin'] = this.promotion.fin;
    }

    this.store$.dispatch(
      setTcSmartFormModel({
        storeKey: this.storeKey,
        model: formDefaultValue,
      })
    );
  }

  private getPromotionFields(): FormlyFieldConfig[] {
    const fields = [
      formlyRow({
        fields: [
          formlyControl({
            key: 'intitule',
            type: TcFormlyComponent.FormlyInput,
            templateOptions: {
              appearance: 'outline',
              disabled: true,
            },
            smColSpan: 12,
            lgColSpan: 12,
            xlColSpan: 12,
            xxlColSpan: 12,
          }),
        ],
      }),
    ];

    // If the promotion is permanent, we don't display the dates. If the dates are equal to 1753-01-01, it means the promotion is permanent.
    if (
      (this.promotion.debut.startsWith('1753-01-01') &&
        this.promotion.fin.startsWith('1753-01-01')) === false
    ) {
      fields.push(
        formlyRow({
          fields: [
            formlyControl({
              key: 'debut',
              className: 'date-picker',
              type: TcFormlyComponent.TcDatePicker,
              templateOptions: {
                appearance: 'outline',
                disabled: true,
                matIcon: 'calendar_today',
              },
              smColSpan: 6,
              lgColSpan: 6,
              xlColSpan: 6,
              xxlColSpan: 6,
            }),
            formlyControl({
              key: 'fin',
              className: 'date-picker',
              type: TcFormlyComponent.TcDatePicker,
              templateOptions: {
                appearance: 'outline',
                disabled: true,
                matIcon: 'calendar_today',
              },
              smColSpan: 6,
              lgColSpan: 6,
              xlColSpan: 6,
              xxlColSpan: 6,
            }),
          ],
        })
      );
    }

    const formFieldConfigs: FormlyFieldConfig[] = [
      formlyColumn({
        fields: fields,
      }),
    ];

    return formFieldConfigs;
  }

  private getRemiseFields(): FormlyFieldConfig[] {
    const formFieldConfigs: FormlyFieldConfig[] = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'typeRemise',
                colSpan: 6,
                defaultValue: DiscountType.Montant,
                type: TcFormlyComponent.FormlySelect,
                templateOptions: {
                  disabled: true,
                  appearance: 'outline',
                  options: [
                    {
                      value: DiscountType.Montant,
                      label: this.translate.instant('discountType.montant'),
                    },
                    {
                      value: DiscountType.Pourcentage,
                      label: this.translate.instant('discountType.pourcentage'),
                    },
                    {
                      value: DiscountType.Quantite,
                      label: this.translate.instant('discountType.quantite'),
                    },
                  ],
                },
                modelOptions: {
                  updateOn: 'blur',
                },
              }),
            ],
          }),
        ],
      }),
    ];

    return formFieldConfigs;
  }

  private getPromotionColumns(): (ColDefExtended | ColGroupDefExtended)[] {
    return [
      {
        field: 'counter',
        sort: 'asc',
        sortable: false,
        valueFormatter: (params) => {
          return `Tranche ${params.value}`;
        },
      },
      {
        field: 'from',
        sortable: false,
        cellClass: 'text-align-right',
        headerClass: 'text-align-right',
      },
      {
        field: 'to',
        sortable: false,
        cellClass: 'text-align-right',
        headerClass: 'text-align-right',
      },
      {
        field: 'discountPromotion',
        sortable: false,
        hide: !this.promotion,
        cellClass: 'text-align-right',
        headerClass: 'text-align-right',
        valueFormatter: (params) => {
          return `${params.value} %`;
        },
      },
    ];
  }

  private getTarifDegressifColumns(): (ColDefExtended | ColGroupDefExtended)[] {
    return [
      {
        field: 'counter',
        sort: 'asc',
        sortable: false,
        minWidth: 100,
        valueFormatter: (params) => {
          return `Tranche ${params.value}`;
        },
      },
      {
        field: 'from',
        sortable: false,
        cellClass: 'text-align-right',
        headerClass: 'text-align-right',
      },
      {
        field: 'to',
        sortable: false,
        cellClass: 'text-align-right',
        headerClass: 'text-align-right',
      },
      {
        field: 'discountTarifDegressif',
        sortable: false,
        cellClass: 'text-align-right',
        headerClass: 'text-align-right',
        minWidth: 180,
        valueFormatter: (params) => {
          return `${params.value} €`;
        },
      },
    ];
  }

  public async getLines(): Promise<any[]> {
    let toDisplay: any[] = [];

    // Promotion
    if (this.promotion) {
      let counter = 1;
      let lastPalier = 0;
      // Sort promotion paliers by borneSuperieure
      const promotion = this.promotion.paliers.sort(
        (a, b) => a.borneSuperieure - b.borneSuperieure
      );

      toDisplay = promotion.map((palier) => {
        const palierToDisplay = {
          counter: counter,
          from: lastPalier !== 0 ? lastPalier : 0,
          to: palier.borneSuperieure,
          discountPromotion: palier.remise,
        };
        lastPalier = palier.borneSuperieure;
        counter++;

        return palierToDisplay;
      });
    } else {
      // Tarif degressif
      let counter = 1;
      let lastPalier = 0;

      // Sort tarif degressif paliers by borneSuperieure
      const tarifs = this.tarifDegressif.sort(
        (a, b) => a.borneSuperieure - b.borneSuperieure
      );

      toDisplay = tarifs.map((palier) => {
        const palierToDisplay = {
          counter: counter,
          from: lastPalier !== 0 ? lastPalier : 0,
          to: palier.borneSuperieure,
          discountTarifDegressif: palier.prixNet,
        };
        lastPalier = palier.borneSuperieure;
        counter++;

        return palierToDisplay;
      });
    }

    // Only declared as a promise to comply to the interface. Returns the data directly.
    return toDisplay;
  }
}
