import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TcCoreModule } from '@tc/core';
import { SharedModule } from '../../../shared/shared.module';
import { TcDataService, TcDataServiceFactory } from '@tc/data-store';
import { TcBreezeService } from '@tc/breeze';
import { XrOptimaPurchaseHistoryGridComponent } from './components/smart/xroptima-purchase-history-grid/xroptima-purchase-history-grid.component';
import { TcButtonsModule } from '@tc/buttons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { XrOptimaEffects } from './store/xroptima.effects';
import { XrOptimaReducer } from './store/xroptima.reducer';

const components = [XrOptimaPurchaseHistoryGridComponent];

const services = [TcDataServiceFactory, TcDataService, TcBreezeService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    SharedModule,
    TcButtonsModule,
    EffectsModule.forFeature([XrOptimaEffects]),
    StoreModule.forFeature('xrOptima', XrOptimaReducer),
  ],
  providers: [...services],
  exports: [...components],
})
export class XrOptimaPurchaseModule {}
