import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { XrOptimaDocButtonState, XrOptimaState } from './xroptima.state';

export const getXrOptimaState =
  createFeatureSelector<XrOptimaState>('xrOptima');

export const getXrOptimaStateByStoreKey = (
  state: XrOptimaState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

export const getXrOptimaOrderBtnDisabledState = createSelector(
  getXrOptimaStateByStoreKey,
  (state: XrOptimaDocButtonState) => {
    return !R.propOr(false, 'canMakeOrder')(state);
  }
);
