import * as R from 'ramda';

/**
 * Does a brute force check to see if 2 arrays have the same elements regardless
 * of the order of elements
 * @param a - first array
 * @param b - second array
 * @returns `true` if the arrays contain the same elements
 * @returns `false` if the arrays don't contain the same elements
 */
export function arraysEqual(a: Array<any>, b: Array<any>) {
  // Make sure to not mutate the arguments
  a = R.clone(a);
  b = R.clone(b);

  // Safety check
  if (a === null) {
    if (b === null) {
      return true;
    } else {
      return false;
    }
  } else {
    if (b === null) {
      return false;
    }
  }

  // Safety check
  if (a === undefined) {
    if (b === undefined) {
      return true;
    } else {
      return false;
    }
  } else {
    if (b === undefined) {
      return false;
    }
  }

  // Short circuit check
  if (a.length !== b.length) {
    return false;
  }

  let i;
  // loop through all the elements of the first array
  for (i = 0; i < a.length; i++) {
    // try to find the exact same object in the second array
    let j;
    for (j = 0; j < b.length; j++) {
      // if exact same object in the second array was found stop looking for it
      // and remove it from the second array
      if (R.equals(a[i], b[j])) {
        b.splice(j, 1);
        j--;
        break;
      }
    }

    // if the index is above the length of array it means the exact same object
    // was not found, thus the arrays are not the same
    if (j >= b.length) {
      return false;
    }
  }

  // if the second array still has elements it means that the first array has less
  // elements than the second array, thus they are not the same
  if (b.length > 0) {
    return false;
  }

  return true;
}
