import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  MaterialColor,
  ListOrder,
  FilterTypesEnum,
  TcFilterItem,
} from '@tc/abstract';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridCellComponent,
} from '@tc/core';
import { TcSmartGridComponent } from '@tc/advanced-components';
import {
  elevageGridOpenDetail,
  setLastFilteredSecteur,
} from '../../../store/elevage.actions';
import { Xpert } from '../../../../xpert/interfaces/xpert.interface';
import { XpertService } from '../../../../../services/xpert.service';
import { DualChoice } from '../../../../../typings/dual-choice.enum';
import { ElevageGridFilterFields } from './elevage-grid.types';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { Subscription } from 'rxjs';
import { updateTcSmartFormCurrentModel } from '@tc/smart-form';
import { Actions, ofType } from '@ngrx/effects';
import { TcLocalStorageService } from '@tc/local-storage';
import { LocalStorageKeys } from '../../../../../typings/local-storage-keys.enum';
import { getLastFilteredSecteur } from '../../../store/elevage.selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-elevage-grid',
  templateUrl: './elevage-grid.component.html',
  styleUrls: ['./elevage-grid.component.scss'],
})
export class ElevageGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'elevage-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];
  subscription: Subscription = new Subscription();

  private xpert: Xpert;
  private lastFilteredSecteur: string;

  constructor(
    protected store$: Store<any>,
    private readonly xpertService: XpertService,
    private readonly actions$: Actions,
    private readonly localStorage: TcLocalStorageService
  ) {
    super(store$);
  }

  async ngOnInit() {
    this.xpert = await this.xpertService.getConnectedXpert();
    const xpertSecteur = this.xpert?.territoire?.groupe?.secteur?.libelle;

    // XPR-51 : The secteur filter in the elevage grid should be set to the xpert's secteur by default but if touched, should keep the value and not be reset to the xpert's secteur.
    // We use the local storage to know if he logged in recently or not and if the filter needs to be reset.
    const elevageGridResetSecteur = await this.localStorage.get(
      LocalStorageKeys.ElevageGridResetSecteur
    );
    if (elevageGridResetSecteur) {
      // New login. Set the default secteur filter to the xpert's secteur.
      this.store$.dispatch(
        setLastFilteredSecteur({
          secteur: xpertSecteur,
        })
      );
      this.lastFilteredSecteur = xpertSecteur;

      // Reset the local storage value : this if code block will not be executed until next login.
      await this.localStorage.remove(LocalStorageKeys.ElevageGridResetSecteur);
    } else {
      // Not a new login. Get the latest secteur filter value from the store.
      this.lastFilteredSecteur = await this.store$
        .select(getLastFilteredSecteur)
        .pipe(take(1))
        .toPromise();
    }

    this.subscription.add(
      this.actions$
        .pipe(ofType(updateTcSmartFormCurrentModel))
        .subscribe(async (data) => {
          // Update the last secteur filter value when the user changes the filter secteur.
          const secteur =
            data.currentModel?.territoire?.groupe?.secteur?.libelle;
          this.store$.dispatch(setLastFilteredSecteur({ secteur: secteur }));
        })
    );

    const elevageFilters: TcFilterItem[] = [];
    if (
      this.xpert.venteHorsZone?.toLowerCase() !== DualChoice.Yes.toLowerCase()
    ) {
      elevageFilters.push({
        key: 'adherent',
        value: DualChoice.Yes.toUpperCase(),
        filterType: FilterTypesEnum.Equal,
      });
    }

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'elevage-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'Elevage',
        fields: 'numero,nom,contactPrincipal,telephone,ville,adherent',
        filter: {
          filters: elevageFilters,
        },
      },
      columns: [
        {
          field: 'numero',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          minWidth: 150,
          comparator: gridComparatorForNumber,
          cellRenderer: (params) =>
            `<span title="${params?.data?.numero}">${params?.data?.numero}</span>`,
        },
        {
          field: 'nom',
          sort: ListOrder.Asc,
          minWidth: 250,
          width: 250,
          cellRenderer: (params) =>
            `<span title="${params?.data?.nom}">${params?.data?.nom}</span>`,
          comparator: gridComparatorForString,
        },
        {
          field: 'contactPrincipal',
          minWidth: 190,
          width: 190,
          comparator: gridComparatorForString,
        },
        {
          field: 'territoire.groupe.secteur.libelle',
          cellRenderer: (params) =>
            `<span title="${
              params?.data?.territoire?.groupe?.secteur?.libelle ?? ''
            }">${
              params?.data?.territoire?.groupe?.secteur?.libelle ?? ''
            }</span>`,
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.territoire?.groupe?.secteur?.libelle
              ? valueA?.territoire?.groupe?.secteur?.libelle
              : '';
            const valueBCleaned = valueB?.territoire?.groupe?.secteur?.libelle
              ? valueB?.territoire?.groupe?.secteur?.libelle
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'telephone',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) => this.phoneFormatter(params.value),
          comparator: gridComparatorForString,
        },
        {
          field: 'ville',
          cellRenderer: (params) =>
            `<span title="${params?.data?.ville}">${params?.data?.ville}</span>`,
          comparator: gridComparatorForString,
        },
        {
          field: 'actions',
          sortable: false,
          maxWidth: 160,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'create-outline',
                tooltip: 'eye-tooltip',
                action: elevageGridOpenDetail,
              },
            ],
          },
        },
      ],
      filterConfig: this.getFilterConfig(),
      columnNumberPerDevice: {
        extraSmallDevice: 7,
        smallDevice: 7,
        mediumDevice: 7,
        largeDevice: 7,
        extraLargeDevice: 7,
        extraExtraLargeDevice: 7,
      },
    };

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  phoneFormatter(phoneNumber: string): string {
    return phoneNumber.replace(/[-.]/g, ' ');
  }

  getFilterConfig(): TcSmartFilterConfig {
    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: '_id',
                  type: TcFormlyComponent.FormlyInput,
                  hide: true,
                  templateOptions: {
                    filterType: FilterTypesEnum.Equal,
                  },
                }),
                formlyControl({
                  key: 'main-filter',
                  templateOptions: {
                    filterOn: ElevageGridFilterFields,
                    filterMultiWord: true,
                    appearance: 'outline',
                  },
                  smColSpan: 5,
                  lgColSpan: 3,
                  xlColSpan: 3,
                  xxlColSpan: 3,
                  className: 'main-filter',
                }),
                formlyControl({
                  key: 'nom',
                  templateOptions: {
                    appearance: 'outline',
                  },
                  smColSpan: 2,
                  lgColSpan: 2,
                  xlColSpan: 2,
                  xxlColSpan: 2,
                }),
                formlyControl({
                  key: 'territoire.groupe.secteur.libelle',
                  templateOptions: {
                    appearance: 'outline',
                  },
                  defaultValue: this.lastFilteredSecteur,
                  smColSpan: 2,
                  lgColSpan: 2,
                  xlColSpan: 2,
                  xxlColSpan: 2,
                }),
                formlyControl({
                  key: 'territoire.groupe.libelle',
                  templateOptions: {
                    appearance: 'outline',
                  },
                  smColSpan: 2,
                  lgColSpan: 2,
                  xlColSpan: 2,
                  xxlColSpan: 2,
                }),
                formlyControl({
                  key: 'territoire.libelle',
                  templateOptions: {
                    appearance: 'outline',
                  },
                  smColSpan: 2,
                  lgColSpan: 2,
                  xlColSpan: 2,
                  xxlColSpan: 2,
                }),
              ],
              colSpan: 12,
            }),
          ],
        }),
      ],
    };
  }
}
