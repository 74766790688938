import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderConfig,
  TcDataProviderType,
} from '@tc/abstract';
import { initTcListDataStore, TcDataService, updateItem } from '@tc/data-store';
import { ModeSuiviStock } from '../../../typings/mode-suivi-stock.enum';
import { Movement } from '../../article/interfaces/movement.interface';
import { MovementType } from '../../../typings/movement-type.enum';
import { ArticleLotMovement } from '../../../typings/article-lot-movement.interface';
import { DepotStockType } from '../../../typings/depot.enum';
import { Article } from '../../article/interfaces/article.interface';
import { ArticlesMovement, QrCodeData } from '../store/stock.state';
import { XpertMobileDocument } from '../../elevage/interfaces/elevage-document.interface';
import { DocumentDomaine, DocumentType } from '../../../typings/document.enum';
import { XpertService } from '../../../services/xpert.service';
import { DocumentService } from '../../../services/document.service';
import { DocumentLigne } from '../../elevage/interfaces/document-ligne.interface';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  // Stand alone store keys to be able to update without any external disturbance
  private storeKeyArticleLotMovement = 'article-lot-movement';
  private storeKeyStockMovement = 'stock-movement';

  // Data provider for saving articles
  private articleDataProvider: TcDataProviderConfig = {
    configType: TcConfigTypes.TcDataProvider,
    providerType: TcDataProviderType.BreezeJs,
    dataSet: 'Article',
  };

  // Data provider for saving stock movements
  private stockMovementDataProvider: TcDataProviderConfig = {
    configType: TcConfigTypes.TcDataProvider,
    providerType: TcDataProviderType.BreezeJs,
    dataSet: 'MouvementStock',
  };

  // Service for getting article data from DB
  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'DAS,reference,intitule,stock,sousFamille',
    }
  );

  // Service for getting collaborator data from DB
  private collaboratorDataService: ITcDataService<any> =
    this.dataService.getService('Collaborateur', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Collaborateur',
      fields: 'idXpert, nom, prenom',
    });

  // Service for getting refDepot from DB
  private refDepotDataService: ITcDataService<any> =
    this.dataService.getService('refDepot', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,nom,code,typeDeStock,emplacement',
    });

  private stockMovementDataService: ITcDataService<any> =
    this.dataService.getService('MouvementStock', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'MouvementStock',
      fields: 'document',
    });

  constructor(
    private readonly store$: Store<any>,
    private readonly dataService: TcDataService,
    private readonly xpertService: XpertService,
    private readonly docService: DocumentService
  ) {}

  /**
   * Give the remaining quantity in the emplacement / depot of one article. If lot number is provided, will return the lot remaining quantity for the article.
   * @param article Article reference (mandatory)
   * @param lotNumber Lot number of the article (optionnal)
   * @param numeroDepot Reference of the depot where the article was stored (warehouse). numeroDepot or codeEmplacement must be defined.
   * @param codeEmplacement Reference of the emplacement within the depot (place inside the warehouse) numeroDepot or codeEmplacement must be defined.
   */
  public getArticleRemainingQuantity(
    article: Article,
    lotNumber: string | null = null,
    numeroDepot: number | null = null,
    codeEmplacement: string | null = null
  ): number {
    if (!codeEmplacement && !numeroDepot) {
      throw new Error(
        'getArticleRemainingQuantity : codeEmplacement or numeroDepot : one of them is mandatory.'
      );
    }
    if (codeEmplacement && numeroDepot) {
      throw new Error(
        'getArticleRemainingQuantity : codeEmplacement or numeroDepot : cannot be both defined.'
      );
    }

    if (article) {
      let depot = null;
      let emplacement = null;

      // Try to find the depot / emplacement in the existing article object based on code emplacement or numero depot
      if (codeEmplacement) {
        depot = article.stock.find((stock) =>
          stock.depot.emplacement.some((empl) => empl.code === codeEmplacement)
        )?.depot;
        emplacement = depot?.emplacement.find(
          (empl) => empl.code === codeEmplacement
        );
      } else if (numeroDepot) {
        depot = article.stock.find(
          (stock) => stock.depot.numero === numeroDepot
        )?.depot;
      }

      // If you found the depot, that mean stocks exists for this article else, you can return zero. No depot, no emplacement, no stocks.
      if (depot) {
        switch (article.modeSuiviStock) {
          case ModeSuiviStock.SuiviCMUP:
            // If you wanted the remaining stock in the emplacement
            if (codeEmplacement) {
              return emplacement?.quantiteStockEmplacement ?? 0;
            }
            // If you wanted the remaining stock in the depot
            if (numeroDepot) {
              return depot?.quantiteStockTotale ?? 0;
            }
            break;
          case ModeSuiviStock.SuiviLotsDLUO:
            let availableQuantity = this.getLotRemainingQuantity(
              depot,
              emplacement,
              lotNumber
            );

            if (availableQuantity < 0) {
              availableQuantity = 0;
            }

            return availableQuantity;
          default:
            throw new Error(
              'Unable to calculate remaining stock for article ' +
                article.reference
            );
        }
      }
    }

    // Default value if you didn't find the depot.
    return 0;
  }

  /**
   * Calculate stocks in the article collections after a movement (delivery, stock movement...) to keep the stocks up to date as much as we can, even if the software was not synced.
   * You need to provide at least a numeroDepot or a codeEmplacement to know where to impact the stocks.
   * @param articles List of articles that where impacted by a stock movement or a delivery document with their quantities added/removed and the lot number if needed
   * @param movementType Define if it's an entry (added quantities) or a release (removed quantities)
   * @param numXpertMobile Document number generated by XpertMobile for the movement
   * @param numeroDepot Reference of the depot where the article was stored (warehouse). numeroDepot or codeEmplacement must be defined.
   * @param codeEmplacement Reference of the emplacement within the depot (place inside the warehouse) numeroDepot or codeEmplacement must be defined.
   */
  public async setArticleMovement(
    articles: ArticleLotMovement[],
    movementType: MovementType,
    numXpertMobile: string,
    numeroDepot: number | null = null,
    codeEmplacement: string | null = null
  ): Promise<boolean> {
    if (!codeEmplacement && !numeroDepot) {
      throw new Error(
        'setArticleLotMovement : codeEmplacement or numeroDepot : one of them is mandatory.'
      );
    }
    if (codeEmplacement && numeroDepot) {
      throw new Error(
        'setArticleLotMovement : codeEmplacement or numeroDepot : cannot be both defined.'
      );
    }

    // Set the movement as a release or entry depending on how the method was called
    let ligneEntree = null;
    let ligneSortie = null;
    switch (movementType) {
      case MovementType.Entry:
        ligneEntree = {
          numeroInterne: null,
          numXpertMobile,
        };
        break;
      case MovementType.Release:
        ligneSortie = {
          numeroInterne: null,
          numXpertMobile,
        };
        break;
      default:
        // Stop the process right here if the movement type is not handled.
        throw new Error('Unsupported movementType : ' + movementType);
    }

    // Extract all articles references and query latest article data from DB
    let articleRefs = articles.map((row) => row.reference);
    articleRefs = articleRefs.filter(
      (ref, index, array) => array.indexOf(ref) === index
    );
    const articleFilter = articleRefs.join(',');
    const { data: articlesToUpdate } = await this.articleDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'reference',
            value: articleFilter,
            filterType: FilterTypesEnum.In,
          },
        ],
      }
    );

    // Iterate on all articles and change the stocks on the found depots
    for (const article of articlesToUpdate) {
      let depot = null;
      let emplacement = null;

      // Try to find the depot / emplacement in the existing article object based on code emplacement or numero depot
      if (codeEmplacement) {
        depot = article.stock.find((stock) =>
          stock.depot.emplacement.some((empl) => empl.code === codeEmplacement)
        )?.depot;
        emplacement = depot?.emplacement.find(
          (empl) => empl.code === codeEmplacement
        );
      } else if (numeroDepot) {
        depot = article.stock.find(
          (stock) => stock.depot.numero === numeroDepot
        )?.depot;
      }

      // If depot was not found, add it (may not exists in a case of a stock movement of an article in a new depot).
      if (!depot) {
        const depotFilter = [];
        let refDepotResult = null;
        let refDepot = null;
        if (codeEmplacement) {
          depotFilter.push({
            key: 'typeDeStock',
            value: DepotStockType.StockSecteur,
            filterType: FilterTypesEnum.Equal,
          });
          // Get refDepot from database with all stock sectors
          refDepotResult = await this.refDepotDataService.getData(0, null, {
            filters: depotFilter,
          });
          // Search inside stock sectors for the emplacement
          refDepot = refDepotResult.data.find((item) =>
            item.emplacement.some((empl) => empl.code === codeEmplacement)
          );
        } else {
          depotFilter.push({
            key: 'numero',
            value: numeroDepot,
            filterType: FilterTypesEnum.Equal,
          });
          // Get refDepot from database
          refDepotResult = await this.refDepotDataService.getData(0, null, {
            filters: depotFilter,
          });
          refDepot = refDepotResult.data[0];
        }

        // Beware, the depot can already be in the article if the codeEmplacement was used. It's just that the emplacement may be missing.
        if (
          article.stock.find(
            (stock) => stock.depot.numero === refDepot.numero
          ) === undefined
        ) {
          // Create emplacement in the new depot with the code if needed
          const emplacementForNewDepot = [];
          if (codeEmplacement) {
            const emplacementFromRefDepot = refDepot?.emplacement.find(
              (empl) => empl.code === codeEmplacement
            );
            emplacementForNewDepot.push({
              code: codeEmplacement,
              intitule: emplacementFromRefDepot.intitule,
              quantiteStockEmplacement: 0,
            });
          }

          // Create depot with minimal setup and stocks to zero. We can't invent something we don't have.
          article.stock.push({
            depot: {
              _id: refDepot._id,
              numero: refDepot.numero,
              nom: refDepot.nom,
              estDepotPrincipal: false,
              quantiteStockTotale: 0,
              quantiteCommandeFournisseur: 0,
              quantiteCommandeClient: 0,
              emplacement: emplacementForNewDepot,
              movement: [],
            },
          });
        } else {
          // The depot seems to exists already, but maybe the emplacement is not present. Add it to the existing article if needed.
          if (
            codeEmplacement &&
            article.stock
              .find((stock) => stock.depot.numero === refDepot.numero)
              .depot.emplacement.find(
                (empl) => empl.code === codeEmplacement
              ) === undefined
          ) {
            const emplacementFromRefDepot = refDepot?.emplacement.find(
              (empl) => empl.code === codeEmplacement
            );

            article.stock
              .find((stock) => stock.depot.numero === refDepot.numero)
              .depot.emplacement.push({
                code: codeEmplacement,
                intitule: emplacementFromRefDepot.intitule,
                quantiteStockEmplacement: 0,
              });
          }
        }

        // At this point, you should have the depot and the emplacement initiated in the article with default values.
        // Recreate depot variable reference with new added data.
        if (codeEmplacement) {
          depot = article.stock.find((stock) =>
            stock.depot.emplacement.some(
              (empl) => empl.code === codeEmplacement
            )
          )?.depot;

          // Recreate emplacement variable reference with new added data.
          emplacement = depot?.emplacement.find(
            (empl) => empl.code === codeEmplacement
          );
        } else {
          // Recreate depot variable reference with new added data.
          depot = article.stock.find(
            (stock) => stock.depot.numero === numeroDepot
          )?.depot;
        }
      }

      // At this point, depot always exists. Emplacement may be not defined if just a depot number was provided.
      // Adjust the quantities in the depot / emplacement.
      if (article.modeSuiviStock === ModeSuiviStock.SuiviCMUP) {
        // STOCKS
        const quantityToMove = articles.find(
          (row) => row.reference === article.reference
        )?.quantite;

        switch (movementType) {
          case MovementType.Entry:
            // Add quantity
            if (emplacement) {
              emplacement.quantiteStockEmplacement += quantityToMove;
            }
            depot.quantiteStockTotale += quantityToMove;
            break;
          case MovementType.Release:
            // Remove quantity
            if (emplacement) {
              emplacement.quantiteStockEmplacement -= quantityToMove;
            }
            depot.quantiteStockTotale -= quantityToMove;
            break;
        }
      } else if (article.modeSuiviStock === ModeSuiviStock.SuiviLotsDLUO) {
        // LOT
        // Group articles by lots for iterating on all lots
        const articleLots = articles.filter(
          (row) => row.reference === article.reference && row.numeroLot
        );

        for (const articleLot of articleLots) {
          const quantiteLot = this.getLotRemainingQuantity(
            depot,
            emplacement,
            articleLot.numeroLot
          );
          const existingLotMovement = depot.movement.find(
            (movemnt) => movemnt.numeroLot === articleLot.numeroLot
          );

          let remainingQuantity = 0;
          switch (movementType) {
            case MovementType.Entry:
              // Add quantity (+ before variable is for casting to number)
              remainingQuantity = quantiteLot + +articleLot.quantite;
              break;
            case MovementType.Release:
              // Remove quantity (+ before variable is for casting to number)
              remainingQuantity = quantiteLot - +articleLot.quantite;
              break;
          }

          // Create the movement for the lot
          const movement: Movement = {
            numeroLot: articleLot.numeroLot,
            createdOn: new Date().toISOString(),
            quantite: articleLot.quantite,
            quantiteRestante: remainingQuantity,
            lotEstEpuise: remainingQuantity <= 0,
            datePeremention: existingLotMovement?.datePeremention,
            type: movementType,
            dateFabrication: existingLotMovement?.dateFabrication,
            ligneEntree: ligneEntree,
            ligneSortie: ligneSortie,
          };

          depot.movement.push(movement);

          // After movement creation and calculations on lot, decrease or increase main depot / emplacement
          switch (movementType) {
            case MovementType.Entry:
              // Add quantity
              if (emplacement) {
                emplacement.quantiteStockEmplacement += articleLot.quantite;
              }
              depot.quantiteStockTotale += articleLot.quantite;
              break;
            case MovementType.Release:
              // Remove quantity
              if (emplacement) {
                emplacement.quantiteStockEmplacement -= articleLot.quantite;
              }
              depot.quantiteStockTotale -= articleLot.quantite;
              break;
          }
        }
      }

      // Create list data store
      this.store$.dispatch(
        initTcListDataStore({
          storeKey: this.storeKeyArticleLotMovement,
          listConfig: {
            storeKey: this.storeKeyArticleLotMovement,
            dataProvider: this.articleDataProvider,
            configType: TcConfigTypes.TcDataProvider,
          },
        })
      );

      // Update articles with new depot / emplacement / movements / quantities
      this.store$.dispatch(
        updateItem({
          storeKey: this.storeKeyArticleLotMovement,
          item: article,
          displaySuccessNotification: false,
        })
      );
    }

    // Return promise to not break await flow.
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  /**
   * Calculate the remaining amount on a lot number within the depot
   * @param depot Depot
   * @param emplacement Emplacement
   * @param numeroLot string Lot number
   * @returns number
   */
  private getLotRemainingQuantity(
    depot: any,
    emplacement: any | null,
    numeroLot: string
  ): number {
    // This can be simplified by using createdOn new property in movement.
    // Sadly, I was asked to keep the existing code from VB, as lot calculation is unclear.
    const sortieQuantite = depot.movement
      .filter(
        (movmnt) =>
          movmnt.numeroLot === numeroLot &&
          (!movmnt.ligneEntree || !movmnt.ligneEntree?.numeroInterne)
      )
      .reduce((prev, movemnt) => prev + movemnt.quantite, 0);
    const entreeQuantite = depot.movement
      .filter(
        (movemnt) =>
          movemnt.numeroLot === numeroLot &&
          (!movemnt.ligneSortie || !movemnt.ligneSortie?.numeroInterne)
      )
      .reduce((prev, movemnt) => prev + movemnt.quantite, 0);
    // TODO : quantiteStockEmplacement is messing up the calculations... Something fishy here.
    // This code was herited from VB but it seems to not follow explanations in #XPR-EX-176
    // MTU said to ignore the problem for now.
    const quantiteLot =
      (emplacement?.quantiteStockEmplacement ?? 0) -
      sortieQuantite +
      entreeQuantite;

    return quantiteLot;
  }

  /**
   * Create stock movement in the database based on ArticlesMovement data
   * @param movement ArticlesMovement
   */
  public async createStockMovement(
    movement: ArticlesMovement
  ): Promise<QrCodeData> {
    const hasQrCodeData = movement.qrCodeData.length > 0;

    // Smallest object possible because QrCode has limited space. See interface for more details about how the data is dispatched.
    const qrCodeData: QrCodeData = {
      a: {
        n: movement.departure.numeroDepot,
        c: movement.departure.codeEmplacement,
      },
      b: {
        n: movement.destination.numeroDepot,
        c: movement.destination.codeEmplacement,
      },
      c: [],
      d: [],
    };

    // Create list data store
    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.storeKeyStockMovement,
        listConfig: {
          storeKey: this.storeKeyStockMovement,
          dataProvider: this.stockMovementDataProvider,
          configType: TcConfigTypes.TcDataProvider,
        },
      })
    );

    const xpert = await this.xpertService.getConnectedXpert();

    // Get refDepot / emplacements from database
    const refDepotDeparture = (
      await this.refDepotDataService.getData(0, null, {
        filters: [
          {
            key: 'numero',
            value: movement.departure.numeroDepot.toString(),
            filterType: FilterTypesEnum.Equal,
          },
        ],
      })
    ).data[0];

    const refEmplacementDeparture = movement.departure?.codeEmplacement
      ? refDepotDeparture.emplacement.find(
          (item) => item.code === movement.departure.codeEmplacement
        )
      : null;

    const refDepotDestination = (
      await this.refDepotDataService.getData(0, null, {
        filters: [
          {
            key: 'numero',
            value: movement.destination.numeroDepot.toString(),
            filterType: FilterTypesEnum.Equal,
          },
        ],
      })
    ).data[0];

    const refEmplacementDestination = movement.destination?.codeEmplacement
      ? refDepotDestination.emplacement.find(
          (item) => item.code === movement.destination.codeEmplacement
        )
      : null;

    const documentTypesToGenerate = [];
    // #XPR-EX-152 : Start by checking witch operations you should do based on depots
    if (movement.departure.numeroDepot !== movement.destination.numeroDepot) {
      // If depot is different, only one document (doType : 23)
      documentTypesToGenerate.push(DocumentType.VirementDepotADepot);
    } else {
      // If depot is the same, one removal document (doType : 21) and one placement document (doType : 20)
      documentTypesToGenerate.push(DocumentType.MouvementSortie);
      documentTypesToGenerate.push(DocumentType.MouvementEntree);
    }

    let numeroPiece = await this.getLastTempNumeroPiece();

    for (const doType of documentTypesToGenerate) {
      let numXperMobile = null;
      let date = new Date().toISOString();
      const prescripteur = {
        _id: xpert.idXpert,
        nom: xpert.nom,
        prenom: xpert.prenom,
      };

      numeroPiece++;

      // Check if the document was already created and if we can reuse the data from the qrCodeData
      if (hasQrCodeData) {
        // Search for the documentType in the qrCodeData array. If you found it, that means numXpertMobile is already generated and should be reused.
        const qrCodeDataFound = movement.qrCodeData.find(
          (item) => item.documentType === doType
        );
        if (qrCodeDataFound) {
          // Reuse the QrCode data to recreate the same document
          numXperMobile = qrCodeDataFound.numXpertMobile;
          date = qrCodeDataFound.date;
          numeroPiece = qrCodeDataFound.numPiece;

          // Get the prescripteur from the qrCodeData if it exists
          if (qrCodeDataFound.idXpert) {
            const collaborator = (
              await this.collaboratorDataService.getData(0, null, {
                filters: [
                  {
                    key: 'idXpert',
                    value: qrCodeDataFound.idXpert,
                    filterType: FilterTypesEnum.Equal,
                  },
                ],
              })
            ).data[0];
            if (collaborator) {
              prescripteur._id = collaborator.idXpert;
              prescripteur.nom = collaborator.nom;
              prescripteur.prenom = collaborator.prenom;
            }
          }
        } else {
          throw new Error(
            'QR Code data not found for documentType : ' + doType
          );
        }
      }

      // If numXpertMobile is not found in the qrCodeData array, generate a new one.
      if (numXperMobile === null) {
        numXperMobile = await this.docService.generateNewNumXpertMobile(xpert);
      }

      // Push document info in QRCodeData
      qrCodeData.d.push({
        t: doType,
        n: numXperMobile,
        p: numeroPiece,
        x: xpert.idXpert,
        d: date,
      });

      const document: XpertMobileDocument = {
        numXpertMobile: numXperMobile,
        type: doType,
        domaine: DocumentDomaine.Stock,
        prescripteur: prescripteur,
        createdOn: date,
        date: date.split('T')[0],
        dateLivraison: null,
        numeroPiece: numeroPiece.toString(),
        totalTTC: null,
        ligne: [],
      };

      for (const articlesToAdd of movement.articles) {
        // Push articles info in QRCodeData
        qrCodeData.c.push({
          r: articlesToAdd.reference,
          l: articlesToAdd.numeroLot,
          q: articlesToAdd.quantite,
        });

        const refArticle = (
          await this.articleDataService.getData(0, null, {
            filters: [
              {
                key: 'reference',
                value: articlesToAdd.reference,
                filterType: FilterTypesEnum.Equal,
              },
            ],
          })
        ).data[0];

        const ligne: DocumentLigne = {
          numXpertMobile: numXperMobile,
          domaine: DocumentDomaine.Stock,
          numeroPiece: numeroPiece.toString(),
          type: doType,
          quantite: articlesToAdd.quantite,
          article: { reference: articlesToAdd.reference },
          libelle: refArticle.intitule,
        };

        switch (doType) {
          // In case of the depot a depot type, we need to add one line in positive (destination) and one in negative (departure)
          case DocumentType.VirementDepotADepot:
            const negativeligne = R.clone(ligne);

            // Set depot for both lines
            ligne.depot = {
              _id: refDepotDestination._id,
              numero: refDepotDestination.numero,
              nom: refDepotDestination.nom,
              code: refDepotDestination.code,
            };
            if (refEmplacementDestination !== null) {
              ligne.depot.emplacement = [
                {
                  code: refEmplacementDestination.code,
                  intitule: refEmplacementDestination.code,
                  quantite: this.getArticleRemainingQuantity(
                    refArticle,
                    articlesToAdd.numeroLot,
                    null,
                    refEmplacementDestination.code
                  ),
                },
              ];
            }

            // Set depot for both lines
            negativeligne.quantite = negativeligne.quantite * -1;
            negativeligne.depot = {
              _id: refDepotDeparture._id,
              numero: refDepotDeparture.numero,
              nom: refDepotDeparture.nom,
              code: refDepotDeparture.code,
            };
            if (refEmplacementDeparture !== null) {
              negativeligne.depot.emplacement = [
                {
                  code: refEmplacementDeparture.code,
                  intitule: refEmplacementDeparture.code,
                  quantite: this.getArticleRemainingQuantity(
                    refArticle,
                    articlesToAdd.numeroLot,
                    null,
                    refEmplacementDeparture.code
                  ),
                },
              ];
            }

            document.ligne.push(ligne);
            document.ligne.push(negativeligne);
            break;
          case DocumentType.MouvementSortie:
          case DocumentType.MouvementEntree:
            // Set the right depot / emplacement based on document type
            let refDepot = refDepotDeparture;
            let refEmplacement = refEmplacementDeparture;
            if (doType === DocumentType.MouvementEntree) {
              refDepot = refDepotDestination;
              refEmplacement = refEmplacementDestination;
            }

            ligne.depot = {
              _id: refDepot._id,
              numero: refDepot.numero,
              nom: refDepot.nom,
              code: refDepot.code,
            };
            if (refEmplacement !== null) {
              ligne.depot.emplacement = [
                {
                  code: refEmplacement.code,
                  intitule: refEmplacement.code,
                  quantite: this.getArticleRemainingQuantity(
                    refArticle,
                    articlesToAdd.numeroLot,
                    null,
                    refEmplacement.code
                  ),
                },
              ];
            }
            document.ligne.push(ligne);
            break;
        }
      }

      // Save the movement
      this.store$.dispatch(
        updateItem({
          storeKey: this.storeKeyStockMovement,
          item: { document: document },
          displaySuccessNotification: false,
        })
      );

      // Map articles to expected configuration for setArticleMovement
      const articlesLotMovement: ArticleLotMovement[] = [];
      for (const articlesToMap of movement.articles) {
        const articleLotMovement: ArticleLotMovement = {
          reference: articlesToMap.reference,
          quantite: articlesToMap.quantite,
        };
        if (articlesToMap.numeroLot !== null) {
          articleLotMovement['numeroLot'] = articlesToMap.numeroLot;
        }
        articlesLotMovement.push(articleLotMovement);
      }

      // Set a release on the departure to update article stocks
      if (
        [
          DocumentType.VirementDepotADepot,
          DocumentType.MouvementSortie,
        ].includes(doType)
      ) {
        if (refEmplacementDeparture !== null) {
          await this.setArticleMovement(
            articlesLotMovement,
            MovementType.Release,
            document.numXpertMobile,
            null,
            refEmplacementDeparture.code
          );
        } else {
          await this.setArticleMovement(
            articlesLotMovement,
            MovementType.Release,
            document.numXpertMobile,
            refDepotDeparture.numero,
            null
          );
        }
      }

      // Set a entry on the destination to update article stocks
      if (
        [
          DocumentType.VirementDepotADepot,
          DocumentType.MouvementEntree,
        ].includes(doType)
      ) {
        if (refEmplacementDestination !== null) {
          await this.setArticleMovement(
            articlesLotMovement,
            MovementType.Entry,
            document.numXpertMobile,
            null,
            refEmplacementDestination.code
          );
        } else {
          await this.setArticleMovement(
            articlesLotMovement,
            MovementType.Entry,
            document.numXpertMobile,
            refDepotDestination.numero,
            null
          );
        }
      }
    }

    // Return promise to not break await flow.
    return new Promise((resolve) => {
      resolve(qrCodeData);
    });
  }

  private async getLastTempNumeroPiece() {
    const { data: stockMovement } = await this.stockMovementDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'document.numeroPiece',
            value: 'null',
            filterType: FilterTypesEnum.IsNotNullOrEmptyString,
          },
        ],
      }
    );

    let docs = (stockMovement as any[]).reduce(
      (prev, stockMovementObject) => [...prev, stockMovementObject.document],
      []
    ) as XpertMobileDocument[];

    docs = docs.filter((doc) => !isNaN(+doc.numeroPiece));

    const numPieces = docs
      .map((doc) => doc.numeroPiece)
      .sort((num1, num2) =>
        num1?.localeCompare(num2, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );

    return numPieces.length ? Number(numPieces[numPieces.length - 1]) : 0;
  }
}
