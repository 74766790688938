import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { TcService } from '@tc/abstract';
import { ConfigKeys } from '../../shared/interfaces/config.interface';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { GenericRoutes } from '../../shared/typings/generic-routes';
import { CustomRequestOptions } from './crud.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * A generic CRUD service class that provides methods for performing CRUD operations
 * on a specified entity.
 * @template TResult - The type of the result object, which must have an 'id' property of type number.
 */
export class CrudService<
  TResult extends { id: number } = any
> extends TcService {
  protected basePath = 'http://localhost:3333/api';
  protected defaultHeaders = new HttpHeaders();

  /**
   * Constructs a new instance of the class.
   * @param {CustomEntitiesEnum} entityName - The name of the custom entity.
   * @param {HttpClient} httpClient - The HTTP client used for making requests.
   * @param {ConfigService} config - The configuration service used for retrieving API base path.
   * @returns None
   */
  constructor(
    private entityName: CustomEntitiesEnum,
    public httpClient: HttpClient,
    public config: ConfigService
  ) {
    super();

    this.basePath = config.get(ConfigKeys.API_BASE_PATH) || this.basePath;
  }

  /**
   * Deletes an entity with the specified ID.
   * @param {number} id - The ID of the entity to delete.
   * @param {string} [observe='body'] - The type of response to observe. Defaults to 'body'.
   * @param {boolean} [reportProgress=false] - Whether to report progress during the request. Defaults to false.
   * @returns {Promise<boolean>} - A promise that resolves to true if the entity was successfully deleted, or false otherwise.
   */
  public async delete(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<boolean> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .delete<any>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<boolean>();
  }

  /**
   * Creates a new item by making a POST request to the entity service endpoint.
   * @param {TResult} item - The item to create.
   * @param {string} [observe='body'] - The type of response to observe.
   * @param {boolean} [reportProgress=false] - Whether to report progress of the request.
   * @returns {Promise<TResult>} - A promise that resolves to the created item.
   */
  public async create(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .post<TResult>(`${url}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  /**
   * Updates an item by making a PUT request to the entity service endpoint.
   * @param {TResult} item - The item to be updated.
   * @param {string} [observe='body'] - The type of response to observe.
   * @param {boolean} [reportProgress=false] - Whether to report progress during the request.
   * @returns {Promise<TResult>} - A promise that resolves to the updated item.
   */
  public async update(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .put<TResult>(`${url}/${item.id}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  /**
   * Retrieves an entity by its ID from the server.
   * @param {number} id - The ID of the entity to retrieve.
   * @param {string} [observe='body'] - The type of response to observe. Defaults to 'body'.
   * @param {boolean} [reportProgress=false] - Whether to report progress of the request. Defaults to false.
   * @returns {Promise<TResult>} - A promise that resolves to the retrieved entity.
   */
  public async getById(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  /**
   * Sends an HTTP GET request to the specified endpoint with the given parameters.
   * @param {Params} params - The parameters to include in the request.
   * @param {string} [observe='body'] - The type of response to observe. Defaults to 'body'.
   * @param {boolean} [reportProgress=false] - Whether to report progress of the request. Defaults to false.
   * @returns {Promise<TResult>} - A promise that resolves to the response data.
   */
  public async get(
    params: Params,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(url, {
        params,
        withCredentials: true,
        headers,
        observe,
        reportProgress,
      })
      .toPromise<TResult>();
  }

  /**
   * Sends a custom HTTP request using the provided options.
   * @param {CustomRequestOptions} request - The options for the custom request.
   * @returns {Promise<any>} - A promise that resolves with the response from the request.
   */
  public async custom(request: CustomRequestOptions) {
    return this.httpClient
      .request(request.method, request.url, {
        withCredentials: true,
        observe: 'body',
        ...request.options,
      })
      .toPromise();
  }

  /**
   * Returns the endpoint URL for the current entity based on the entity name.
   * @returns {string} The endpoint URL for the entity.
   */
  public getEntityServiceEndpoint() {
    switch (this.entityName) {
      case CustomEntitiesEnum.Auth:
        return `${this.basePath}/${GenericRoutes.Auth}`;
      case CustomEntitiesEnum.Users:
        return `${this.basePath}/${GenericRoutes.Users}`;
      case CustomEntitiesEnum.Terms:
        return `${this.basePath}/${GenericRoutes.TermsOfUse}`;
      default:
        return '';
    }
  }
}
