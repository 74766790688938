import { createReducer, on } from '@ngrx/store';
import { setMenuItems } from './menu.actions';
import { initialMenuState, MenuState } from './menu.state';

/**
 * Reducer function for the menu state.
 * @param {MenuState} state - The current state of the menu.
 * @param {Action} action - The action to be performed on the state.
 * @returns The updated state after applying the action.
 */
export const menuReducer = createReducer<MenuState>(
  initialMenuState,
  on(setMenuItems, (state, { items }) => ({
    ...state,
    items,
  }))
);
