import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';
import { CustomRoutes } from '../../typings/custom-routes.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-catalog-articles-page',
  templateUrl: './catalog-articles.component.html',
  styleUrls: ['./catalog-articles.component.scss'],
})
export class CatalogArticlesComponent extends TcPage implements OnInit {
  constructor(store: Store<any>, private router: Router) {
    super(store);
  }

  ngOnInit() {
    let title;
    switch (this.router.url) {
      case `/${CustomRoutes.MakeEstimateOrTakeOrder}`:
        title = 'catalog-articles.optima.title';
        break;
      default:
        title = 'catalog-articles.title';
    }

    const pageInfo: IPageState = {
      title,
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
