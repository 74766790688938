import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import {
  TcTranslateService,
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormComponent,
} from '@tc/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { MovementDepot } from '../../../store/stock.state';
import {
  FilterTypesEnum,
  ITcDataService,
  MaterialColor,
  TcConfigTypes,
  TcDataProviderConfig,
  TcDataProviderType,
} from '@tc/abstract';
import { TcDataService, initTcListDataStore } from '@tc/data-store';
import { DepotStockType } from '../../../../../typings/depot.enum';
import {
  swapDepartureWithDestination,
  setMovementDeparture,
  setMovementDestination,
} from '../../../store/stock.actions';
import { TcLocalStorageService } from '@tc/local-storage';
import { LocalStorageKeys } from '../../../../../typings/local-storage-keys.enum';
import {
  getFormatedDepotName,
  getFormatedVehicleName,
} from '../../../../../../custom/shared/util';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { Depot } from '../../../../depot/interfaces/depot.interface';
import { getMovement } from '../../../store/stock.selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-movement-depot-selection',
  templateUrl: './movement-depot-selection.component.html',
  styleUrls: ['./movement-depot-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MovementDepotSelectionComponent
  extends TcFormComponent<any>
  implements OnInit
{
  @Input() deposit: boolean; // True if the form is displayed on Depot -> Xpert screen, false if it is displayed on Transfert Xpert screen
  storeKeyDeparture = 'movement-depot-departure';
  storeKeyDestination = 'movement-depot-destination';
  form: FormGroup = new FormGroup({});
  model: { departure: string; destination: string };
  formFields: FormlyFieldConfig[];
  departureList: { comboValue: string; movementDepot: MovementDepot }[] = [];
  destinationList: { comboValue: string; movementDepot: MovementDepot }[] = [];
  depotToXpert = true; // Used to know if the departure should be switched with the destination
  selectedDepartureIsVehicle = false;
  currentVehicleCode: string;
  currentContainerKey: string;
  loginContainer: Depot;
  loginVehicleDepot: Depot;

  private depotDataService: ITcDataService<any> = this.dataService.getService(
    'refDepot',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,emplacement,nom',
    }
  );

  constructor(
    public readonly translate: TcTranslateService,
    private readonly localStorage: TcLocalStorageService,
    private readonly store$: Store<any>,
    private readonly dataService: TcDataService,
    public elem: ElementRef
  ) {
    super(translate, elem);
  }

  async ngOnInit() {
    super.ngOnInit();

    this.currentVehicleCode = await this.localStorage.get(
      LocalStorageKeys.LastLoginVehicleKey
    );

    this.currentContainerKey = await this.localStorage.get(
      LocalStorageKeys.LastLoginContainerKey
    );

    const { data: loginContainers } = await this.depotDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'numero',
            value: this.currentContainerKey,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      }
    );
    const loginContainer = loginContainers[0];
    if (loginContainer) {
      this.loginContainer = loginContainer;
    }

    const { data: depots } = await this.depotDataService.getData(0, null, {
      filters: [
        {
          key: 'typeDeStock',
          value: DepotStockType.StockSecteur,
          filterType: FilterTypesEnum.Equal,
        },
      ],
    });

    const relatedDepot = depots.find((depot) =>
      depot.emplacement.some((empl) => empl.code === this.currentVehicleCode)
    );

    if (relatedDepot) {
      this.loginVehicleDepot = relatedDepot;
      const relatedEmplacement = relatedDepot.emplacement.find(
        (empl) => empl.code === this.currentVehicleCode
      );

      // Set the depot in the model
      const departure = this.deposit
        ? {
            numeroDepot: relatedDepot.numero,
            intitule: getFormatedDepotName(relatedDepot),
          }
        : {
            numeroDepot: relatedDepot.numero,
            codeEmplacement: relatedEmplacement.code,
            intitule: relatedEmplacement.intitule,
          };
      // TcSmartSelect is not able to pre-select an object stored in the model. We work with string to overcome that.
      const departureComboValue = this.deposit
        ? getFormatedDepotName(relatedDepot)
        : getFormatedVehicleName(relatedEmplacement);
      this.store$.dispatch(setMovementDeparture({ data: departure }));
      this.model.departure = departureComboValue;
      if (
        departure?.codeEmplacement !== undefined &&
        departure?.codeEmplacement !== null
      ) {
        this.selectedDepartureIsVehicle = true;
      }

      // XPR-58 - Autoselect the connected car in destination combo on deposit screen
      if (this.deposit && relatedEmplacement) {
        const destination: MovementDepot = {
          numeroDepot: relatedDepot.numero,
          codeEmplacement: relatedEmplacement.code,
          intitule: relatedEmplacement.intitule,
        };

        const destinationComboValue =
          getFormatedVehicleName(relatedEmplacement);
        this.store$.dispatch(setMovementDestination({ data: destination }));
        this.model.destination = destinationComboValue;
      }
    }

    // Init store for departure
    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.storeKeyDeparture,
        listConfig: {
          storeKey: this.storeKeyDeparture,
          dataProvider: this.getDepartureDataProvider(),
          configType: TcConfigTypes.TcFormField,
        },
      })
    );

    // Init store for destination
    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.storeKeyDestination,
        listConfig: {
          storeKey: this.storeKeyDestination,
          dataProvider: this.getDestinationDataProvider(),
          configType: TcConfigTypes.TcFormField,
        },
      })
    );

    this.initFields();
  }

  initFields() {
    const departureFromlyControl = formlyControl({
      key: 'departure',
      type: TcFormlyComponent.TcSmartSelect,
      templateOptions: {
        change: (field, event) => {
          const movementDepot = this.departureList.find(
            (item) => item.comboValue === event.value
          ).movementDepot;

          const action = this.depotToXpert
            ? setMovementDeparture
            : setMovementDestination;

          this.store$.dispatch(action({ data: movementDepot }));

          this.selectedDepartureIsVehicle =
            movementDepot?.codeEmplacement !== undefined &&
            movementDepot?.codeEmplacement !== null;
          this.initFields();
        },
        label: this.depotToXpert
          ? this.deposit
            ? this.translate.instant('stock-departure') // Depot -> Xpert screen
            : this.translate.instant('xpert-departure') // Transfert Xpert screen
          : this.deposit
          ? this.translate.instant('stock-destination') // Depot -> Xpert screen
          : this.translate.instant('xpert-destination'), // Transfert Xpert screen

        required: true,
        appearance: 'outline',
        storeKey: this.storeKeyDeparture,
        dataProvider: this.getDepartureDataProvider.bind(this),
        valueFieldName: 'intitule',
        labelFn: (item: any) => {
          return item;
        },
        filter: true,
        focusOnFilter: true,
        filterPlaceholder: 'search',
      },
    });

    const destinationFromlyControl = formlyControl({
      key: 'destination',
      type: TcFormlyComponent.TcSmartSelect,
      templateOptions: {
        change: (field, event) => {
          const movementDepot = this.destinationList.find(
            (item) => item.comboValue === event.value
          ).movementDepot;

          const action = this.depotToXpert
            ? setMovementDestination
            : setMovementDeparture;

          this.store$.dispatch(action({ data: movementDepot }));
        },
        label: this.depotToXpert
          ? this.translate.instant('xpert-destination')
          : this.translate.instant('xpert-departure'),
        required: true,
        appearance: 'outline',
        storeKey: this.storeKeyDestination,
        dataProvider: this.getDestinationDataProvider.bind(this),
        valueFieldName: 'intitule',
        labelFn: (item: any) => {
          return item;
        },
        filter: true,
        focusOnFilter: true,
        filterPlaceholder: 'search',
      },
    });

    const swapButtonFromlyControl = formlyControl({
      type: TcFormlyComponent.TcButton,
      className: 'swap-button field-fit-content',
      templateOptions: {
        config: {
          matIcon: 'swap_horiz',
          type: MaterialButtonType.Icon,
          color: MaterialColor.Primary,
          action: () => {
            this.depotToXpert = !this.depotToXpert;
            this.initFields();
            this.store$.dispatch(swapDepartureWithDestination());
          },
        } as TcSmartButton,
      },
    });

    this.formFields = [
      formlyColumn({
        fields: [
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              this.depotToXpert
                ? departureFromlyControl
                : destinationFromlyControl,

              ...(this.deposit ? [swapButtonFromlyControl] : []),

              this.depotToXpert
                ? destinationFromlyControl
                : departureFromlyControl,
            ],
          }),
        ],
      }),
    ];
  }

  /**
   * Returns a data provider configuration for departure combo
   * @returns TcDataProviderConfig
   */
  getDepartureDataProvider(): TcDataProviderConfig {
    return {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,nom,emplacement',
      filter: {
        filters: [
          {
            key: 'typeDeStock',
            value: DepotStockType.StockSecteur,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      },
      transformFn: (result) => {
        let depots = [];
        this.departureList = [];
        if (this.deposit) {
          // Depot -> Xpert screen
          // Expected to display stock secteur list
          depots = this.mapDepot(result);
        } else {
          // Transfert Xpert screen
          // XPR-113 : Only display vehicle and container from login
          const loginContainers = [this.loginContainer];
          const loginContainer = this.mapDepot(loginContainers);
          // Remove all other emplacements from the depot, we only want the one from the login
          const depotVehicle = {
            ...this.loginVehicleDepot,
            emplacement: [
              this.loginVehicleDepot.emplacement.find(
                (empl) => empl.code === this.currentVehicleCode
              ),
            ],
          };
          const loginVehicles = [depotVehicle];
          const resultVehicle = this.mapEmplacement(loginVehicles);

          // Fuse the two arrays. Should have two items : the container and the vehicle
          depots = loginContainer.concat(resultVehicle);
        }

        this.clearDepartureStoreIfNeeded();

        return depots;
      },
    };
  }

  /**
   * Returns a data provider configuration for destination combo
   * @returns TcDataProviderConfig
   */
  getDestinationDataProvider(): TcDataProviderConfig {
    return {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,nom,emplacement',
      filter: {
        filters: [
          {
            key: 'typeDeStock',
            value: [DepotStockType.StockSecteur, DepotStockType.CuveXpert].join(
              ','
            ),
            filterType: FilterTypesEnum.In,
          },
        ],
      },
      transformFn: (result) => {
        let depots = [];
        this.destinationList = [];
        if (this.deposit) {
          // Depot -> Xpert screen
          // We display the vehicles from the stock secteur
          result = result.filter(
            (item) => item.typeDeStock === DepotStockType.StockSecteur
          );
          depots = this.mapEmplacement(result, false);
        } else {
          // Transfert Xpert screen
          // Depending if the departure selected is a depot or a vehicle, we display the corresponding destination
          if (this.selectedDepartureIsVehicle) {
            result = result.filter(
              (item) => item.typeDeStock === DepotStockType.StockSecteur
            );

            // Remove from the result set the vehicle from the login
            const vehicleDepots = result.filter((item) =>
              item.emplacement.some(
                (empl) => empl.code === this.currentVehicleCode
              )
            );
            for (const depot of vehicleDepots) {
              depot.emplacement = depot.emplacement.filter(
                (empl) => empl.code !== this.currentVehicleCode
              );
            }

            // If the departure selected is a vehicle, we display the vehicles from the stock secteur
            depots = this.mapEmplacement(result, false);
          } else {
            // If the departure selected is a cuve, we display the cuves from the cuve xpert
            result = result.filter(
              (item) => item.typeDeStock === DepotStockType.CuveXpert
            );

            // Remove from the result set the container from the login
            result = result.filter(
              (item) => item.numero !== this.currentContainerKey
            );

            depots = this.mapDepot(result, false);
          }
        }

        this.clearDestinationStoreIfNeeded();

        return depots;
      },
    };
  }

  /**
   * Clears the departure store if needed.
   * This method checks if there is a departure in the movements and if it exists in the departure list.
   * If the departure does not exist in the departure list, it clears the departure store and sets the model's departure to null.
   */
  private async clearDepartureStoreIfNeeded() {
    const movements = await this.store$
      .select(getMovement)
      .pipe(take(1))
      .toPromise();

    const list = this.depotToXpert ? this.departureList : this.destinationList;

    if (movements.departure) {
      const departure = list.find(
        (item) =>
          item.movementDepot.numeroDepot === movements.departure.numeroDepot &&
          (item.movementDepot?.codeEmplacement ===
            movements.departure?.codeEmplacement ||
            (!movements.departure?.codeEmplacement &&
              !item.movementDepot?.codeEmplacement))
      );
      if (!departure) {
        this.store$.dispatch(setMovementDeparture({ data: null }));
        this.model.departure = null;
      }
    }
  }

  /**
   * Clears the destination store if needed.
   * This method checks if there is a destination store selected in the movements,
   * and if the selected destination store is still available in the destination list.
   * If the selected destination store is not available, it clears the destination store
   * in the store and updates the model.
   */
  private async clearDestinationStoreIfNeeded() {
    const movements = await this.store$
      .select(getMovement)
      .pipe(take(1))
      .toPromise();

    const list = this.depotToXpert ? this.destinationList : this.departureList;

    if (movements.destination) {
      const destination = list.find(
        (item) =>
          item.movementDepot.numeroDepot ===
            movements.destination.numeroDepot &&
          (item.movementDepot?.codeEmplacement ===
            movements.destination?.codeEmplacement ||
            (!movements.destination?.codeEmplacement &&
              !item.movementDepot?.codeEmplacement))
      );
      if (!destination) {
        this.store$.dispatch(setMovementDestination({ data: null }));
        this.model.destination = null;
      }
    }
  }

  /**
   * Transform a list of depot from refDepot to a usable string in the combo
   * @param data any[] Data from refdepot
   * @param departure boolean True for departure, false for destination
   * @returns string[]
   */
  public mapDepot(data: Array<any>, departure: boolean = true): string[] {
    const transformed: string[] = [];

    for (const depot of data) {
      const label = getFormatedDepotName(depot);

      transformed.push(label);

      const object = {
        comboValue: label,
        movementDepot: {
          numeroDepot: depot.numero,
          intitule: label,
        },
      };

      if (departure) {
        this.departureList.push(object);
      } else {
        this.destinationList.push(object);
      }
    }

    return transformed;
  }

  /**
   * Transform a list of emplacement from refDepot to a usable string in the combo
   * @param data any[] Data from refdepot
   * @param departure boolean True for departure, false for destination
   * @returns string[]
   */
  public mapEmplacement(data: Array<any>, departure: boolean = true): string[] {
    const transformed: string[] = [];
    const emplacements = data
      .reduce((acc, item) => acc.concat(item.emplacement), [])
      .filter((item) => item.code !== 'DEFAUT');

    for (const emplacement of emplacements) {
      const depot = data.find((item) =>
        item.emplacement.find((empl) => empl.code === emplacement.code)
      );
      const label = getFormatedVehicleName(emplacement);
      transformed.push(label);
      const object = {
        comboValue: label,
        movementDepot: {
          numeroDepot: depot.numero,
          codeEmplacement: emplacement.code,
          intitule: emplacement.intitule,
        },
      };

      if (departure) {
        this.departureList.push(object);
      } else {
        this.destinationList.push(object);
      }
    }

    // Case insensitive sorting
    transformed.sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: 'base' })
    );

    return transformed;
  }
}
