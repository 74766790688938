import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcButtonsListComponent } from './components/tc-buttons-list/tc-buttons-list.component';
import { TcSmartButtonComponent } from './components/tc-smart-button/tc-smart-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

const components = [TcButtonsListComponent, TcSmartButtonComponent];

@NgModule({
  declarations: [components],
  providers: [],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
  ],
  exports: [components],
})
export class TcButtonsModule {}
