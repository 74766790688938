import { createReducer, on } from '@ngrx/store';
import { setLayoutHiddenSidenavRoutes } from './layout.actions';
import { setVisibleXrOptimaLogoRoutes } from './layout.actions';
import { initialLayoutState, LayoutState } from './layout.state';

export const layoutReducer = createReducer<LayoutState>(
  initialLayoutState,
  on(setLayoutHiddenSidenavRoutes, (state, { routes }) => ({
    ...state,
    hiddenSidenavRoutes: routes,
  })),
  on(setVisibleXrOptimaLogoRoutes, (state, { routes }) => ({
    ...state,
    visibleXrOptimaLogoRoutes: routes,
  }))
);
