import { createAction, props } from '@ngrx/store';
import { Encaissement } from './encaissement.state';

export const setCurrentEncaissementFromPopup = createAction(
  '[Encaissement] Set encaissement in store from encaissement popup',
  props<{ encaissement: Encaissement; save?: boolean }>()
);

export const saveCurrentEncaissement = createAction(
  '[Encaissement] Save current encaissement in store to database',
  props<{ notification: boolean }>()
);

export const setCurrentEncaissement = createAction(
  '[Encaissement] Set encaissement in store',
  props<{ encaissement: Encaissement }>()
);

export const setBankDepositEncaissementButtonState = createAction(
  '[Encaissement] Set bank deposit encaissement button state in store',
  props<{ active: boolean }>()
);

export const setBankDepositSaveButtonState = createAction(
  '[Encaissement] Set bank deposit save button state in store',
  props<{ active: boolean }>()
);
