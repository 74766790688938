import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TcButtonsModule } from '@tc/buttons';
import { TcDialogContentComponent } from './components/tc-dialog-content/tc-dialog-content.component';
import { TcDialogHeaderComponent } from './components/tc-dialog-header/tc-dialog-header.component';
import { TcDialogFooterComponent } from './components/tc-dialog-footer/tc-dialog-footer.component';
import { TcPromptDialogComponent } from './components/tc-prompt-dialog/tc-prompt-dialog.component';
import { TcHelpDialogComponent } from './components/tc-help-dialog/tc-help-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const components = [
  TcDialogContentComponent,
  TcDialogHeaderComponent,
  TcDialogFooterComponent,
  TcPromptDialogComponent,
  TcHelpDialogComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    TcButtonsModule,
    MatProgressBarModule,
  ],
  exports: [...components],
})
export class TcDialogModule {}
