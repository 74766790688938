import {
  selectByKey,
  selectValueByKey,
  TcSpinnerService,
  terminateOfflineMode,
} from '@tc/store';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  deleteItemSuccess,
  NgRxTcDataState,
  refreshTcData,
  updateTcListDataStore,
} from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  DEFAULT_TC_GRID_STATE_KEY,
  getTcFilterConfig,
  getTcFilters,
  getTcGridColumns,
  NgRxTcGridState,
  setTcGridColumns,
  TcTranslateService,
} from '@tc/core';
import {
  deleteArticleMovement,
  removeArticleMovementLine,
  saveMovement,
  saveTransfertAndDisplayQrCode,
  saveTransfertAndSync,
  setMovementArticles,
  updateStockGridColumns,
  upsertArticleMovement,
} from './stock.actions';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import * as R from 'ramda';
import { DepotStockType } from '../../../typings/depot.enum';
import { TcConfigTypes, TcDataProviderType } from '@tc/abstract';
import { ArticleService } from '../../../services/article.service';
import { DataType } from 'breeze-client';
import { getMovement } from './stock.selectors';
import {
  ArticleMovementLine,
  ArticleMovementSaveOption,
  QrCodeData,
} from './stock.state';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { StockService } from '../services/stock.services';
import { navigate } from '@tc/advanced-components';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { ConfigKeys } from '../../../../shared/interfaces/config.interface';
import { QrCodeScanPopupComponent } from '../components/smart/qr-code-scan-popup/qr-code-scan-popup.component';
import { encodeQRCode, numericComparator } from '../../../shared/util';

@Injectable()
export class StockEffects {
  gridStore$: Observable<NgRxTcGridState>;
  filterStore$: Observable<NgRxTcDataState>;
  dataStore$: Observable<NgRxTcDataState>;

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly translate: TcTranslateService,
    private readonly articleService: ArticleService,
    private readonly stockService: StockService,
    private readonly spinner: TcSpinnerService,
    private dialog: MatDialog,
    private readonly authenticationService: AuthenticationService,
    private readonly config: ConfigService
  ) {
    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  updateStockGridColumns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateStockGridColumns),
        tap(async (payload) => {
          const { storeKey, filtering } = payload;
          let filterFieldValue = R.clone(payload.filterFieldValue);

          const action = `${storeKey} - spinner`;
          this.spinner.showSpinner(action);

          const filterConfig = await selectByKey(
            getTcFilterConfig,
            this.filterStore$,
            storeKey
          )
            .pipe(take(1))
            .toPromise();

          let columns = R.clone(
            await selectByKey(getTcGridColumns, this.gridStore$, storeKey)
              .pipe(take(1))
              .toPromise()
          ) as any[];

          columns = columns.filter(
            (col) => !(col.field as string)?.startsWith('stock_')
          );

          filterFieldValue?.forEach((val) => {
            const newColumn = {
              field: `stock_${val.numero}`,
              headerValueGetter: () =>
                (val.typeDeStock !== '' &&
                val.typeDeStock !== null &&
                val.typeDeStock !== undefined &&
                val.typeDeStock.toLowerCase() !==
                  DepotStockType.CuveXpert.toLowerCase()
                  ? `${this.translate.instant(
                      this.getTypeDeStockEnumKeyFromValue(val.typeDeStock)
                    )} `
                  : '') +
                val.nom +
                ` (${this.translate.instant('quantity')})`,
              headerClass: 'text-align-right',
              cellClass: 'text-align-right',
              comparator: numericComparator,
            };

            columns.push(newColumn);
          });

          const baseDataSet = 'ArticleStock';

          filterFieldValue = filterFieldValue.sort((value1, value2) => {
            if (value1.numero < value2.numero) return -1;
            else if (value1.numero > value2.numero) return 1;
            else return 0;
          });

          const dataSet = filterFieldValue.reduce(
            (prev, filterField) => prev + `_${filterField.numero}`,
            baseDataSet
          );

          const breezeStructuralTypeExtension = filterFieldValue.reduce(
            (prev, depot) => ({
              ...prev,
              [`stock_${depot.numero}`]: DataType.Int32,
            }),
            {}
          );

          const stock = async () => {
            let articles = await this.articleService.getArticleStockData(
              ...filterFieldValue
            );

            // Display only the articles that have stock on at least one stock column
            articles = articles.filter((article) => {
              const stockColumns = [
                article.myVehicleStock,
                article.mySecteurStock,
              ];

              const additionalStockColumns = filterFieldValue.reduce(
                (prev, depot) => [...prev, article[`stock_${depot.numero}`]],
                []
              );

              return [...stockColumns, ...additionalStockColumns].some(
                (articleStock) => articleStock > 0
              );
            });

            return articles;
          };

          const listConfig = {
            configType: TcConfigTypes.TcGrid,
            storeKey,
            gridOptions: {},
            emptyDataOnDestroy: true,
            dataProvider: {
              configType: TcConfigTypes.TcDataProvider,
              providerType: TcDataProviderType.BreezeJs,
              dataSet,
              dynamicCollectionFrom: {
                breezeStructuralType: 'Article',
                data: stock,
                breezeStructuralTypeExtension: {
                  myVehicleStock: DataType.Int32,
                  myContainerStock: DataType.Int32,
                  mySecteurStock: DataType.Int32,
                  ...breezeStructuralTypeExtension,
                },
              },
            },
            columns,
            filterConfig,
          };

          this.store$.dispatch(setTcGridColumns({ storeKey, columns }));
          this.store$.dispatch(updateTcListDataStore({ storeKey, listConfig }));

          this.spinner.hideSpinner(action);

          const storeFilter = await selectValueByKey(
            getTcFilters,
            this.filterStore$,
            storeKey
          );

          // If the current filter behavior equals the previous one, refresh the data since the filter store component won't do it
          if (R.equals(storeFilter.filters, filtering.filters)) {
            this.store$.dispatch(
              refreshTcData({
                storeKey,
                filter: storeFilter,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  upsertArticleMovement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(upsertArticleMovement),
        tap(async (payload) => {
          if (!payload.data._id) {
            throw new Error(
              'upsertArticleMovement : the grid must provide a unique id.'
            );
          }

          // Start by getting all articles in the store
          const movement = await this.store$
            .select(getMovement)
            .pipe(take(1))
            .toPromise();

          const articles = R.clone(movement.articles);
          // If you have at least one article, check if she is already present by local id
          const index = articles.findIndex(
            (item) => item._id === payload.data._id
          );
          if (index >= 0) {
            // Found, update it
            articles[index] = payload.data;
          } else {
            // Not found, insert it
            articles.push(payload.data);
          }

          this.store$.dispatch(setMovementArticles({ data: articles }));
        })
      ),
    { dispatch: false }
  );

  deleteArticleMovement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteArticleMovement),
        tap(async (payload) => {
          if (!payload.data._id) {
            throw new Error(
              'upsertArticleMovement : the grid must provide a unique id.'
            );
          }
          // Start by getting all articles in the store
          const movement = await this.store$
            .select(getMovement)
            .pipe(take(1))
            .toPromise();

          const articles = R.clone(movement.articles);
          // Check if the article is already present by local id
          const index = articles.findIndex(
            (item) => item._id === payload.data._id
          );
          if (index >= 0) {
            // Found, remove it
            const newArticles = articles.filter(
              (_, indexFromArray) => indexFromArray !== index
            );
            this.store$.dispatch(setMovementArticles({ data: newArticles }));
          }
        })
      ),
    { dispatch: false }
  );

  removeArticleMovementLine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeArticleMovementLine),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;
          // Map Article to article movement line
          const articleMovementLine: ArticleMovementLine = {
            _id: rowData._id,
            reference: rowData.reference,
            numeroLot: rowData.numeroLot ?? null,
            description: rowData.description,
            quantiteEnStock: rowData.quantiteEnStock,
            quantite: rowData.quantite,
          };
          this.store$.dispatch(
            deleteArticleMovement({ data: articleMovementLine })
          );
          this.store$.dispatch(deleteItemSuccess({ storeKey, item: rowData }));
        })
      ),
    { dispatch: false }
  );

  saveMovement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveMovement),
        tap((payload) => {
          this.processMovement(ArticleMovementSaveOption.none);
        })
      ),
    { dispatch: false }
  );

  saveTransfertAndSync$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveTransfertAndSync),
        tap((payload) => {
          this.processMovement(ArticleMovementSaveOption.sync);
        })
      ),
    { dispatch: false }
  );

  saveTransfertAndDisplayQrCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveTransfertAndDisplayQrCode),
        tap((payload) => {
          this.processMovement(ArticleMovementSaveOption.qrcode);
        })
      ),
    { dispatch: false }
  );

  private getTypeDeStockEnumKeyFromValue(value: string) {
    for (const key of Object.keys(DepotStockType)) {
      const enumValue = DepotStockType[key] as string;
      if (enumValue.toLowerCase() === value.toLowerCase()) {
        return key;
      }
    }

    return null;
  }

  /**
   * Do the checks on the movement data in the store. If everything is ok, run the createStockMovement method to save it.
   * @param option
   */
  private async processMovement(
    option: ArticleMovementSaveOption
  ): Promise<void> {
    const canBeSaved = await this.movementCanBeSaved();
    if (canBeSaved === false) {
      this.showMovementErrorDialog();
    } else {
      const movement = R.clone(
        await this.store$.select(getMovement).pipe(take(1)).toPromise()
      );
      const qrCodeData = await this.stockService.createStockMovement(movement);

      switch (option) {
        case ArticleMovementSaveOption.none:
          this.showMovementSaveConfirmationDialog(false);
          break;
        case ArticleMovementSaveOption.sync:
          this.showMovementSaveConfirmationDialog(true);
          break;
        case ArticleMovementSaveOption.qrcode:
          this.showQrCodeDialog(qrCodeData);
          break;
      }
    }
  }

  /**
   * Check if movement can be saved :
   * - has departure
   * - has destination
   * - has at least one article
   * - has quantities on the articles
   * - quantities does
   */
  private async movementCanBeSaved(): Promise<boolean> {
    const movement = await this.store$
      .select(getMovement)
      .pipe(take(1))
      .toPromise();

    return (
      movement.departure !== null &&
      movement.destination !== null &&
      movement.articles.length > 0 &&
      movement.articles.some((item) => item.quantite === 0) === false &&
      movement.articles.some((item) => item.quantite > item.quantiteEnStock) ===
        false
    );
  }

  /**
   * Display the error popup if the checks on the movement failed
   */
  private showMovementErrorDialog(): void {
    this.dialog.open(TcPromptDialogComponent, {
      width: '37.5em',
      data: {
        title: 'warning',
        disableTextTranslation: true,
        text: this.translate.instant('globalMessages.movement-is-invalid'),
        confirm: 'ok',
        displayCancelButton: false,
        disableClose: true,
      },
    });
  }

  /**
   * Displays a QR code dialog with the movement data to import it in another application.
   * @returns Void
   */
  private async showQrCodeDialog(qrCodeData: QrCodeData): Promise<void> {
    // Compression
    const qrCodeDataEncoded = encodeQRCode(qrCodeData);

    this.dialog
      .open(QrCodeScanPopupComponent, {
        width: '40em',
        data: {
          qrCodeContent: qrCodeDataEncoded,
          qrCodeMessage: this.translate.instant('stock-movement-saved-qrcode'),
        },
      })
      .afterClosed()
      .subscribe(async () => {
        // Redirect to the home page
        this.store$.dispatch(
          navigate({
            route: '/',
            queryParams: {},
            storeKey: null,
          })
        );
      });
  }

  /**
   * Display prompt popup after movement was saved
   * @param sync If true, will force the sync to trigger
   */
  private showMovementSaveConfirmationDialog(sync = false): void {
    this.dialog
      .open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translate.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translate.instant('stock-movement-saved'),
          confirm: this.translate.instant('globalLabels.close'),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      })
      .afterClosed()
      .subscribe(async () => {
        if (sync === true) {
          await this.authenticationService.restoreSession();
          // Set timeout to activate the sync after the redirection
          setTimeout(async () => {
            this.store$.dispatch(
              terminateOfflineMode({
                providerType: this.config.get(
                  ConfigKeys.offlineModeDataProviderType
                ),
                relaunch: true,
                popupComponentName: 'SyncResponsePopupComponent',
              })
            );
          }, 200);
        }
        // Redirect to the home page
        this.store$.dispatch(
          navigate({
            route: '/',
            queryParams: {},
            storeKey: null,
          })
        );
      });
  }
}
