import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';
import { TcSmartButton } from '@tc/buttons';
import { Router } from '@angular/router';
import { saveMovement } from '../../modules/stock/store/stock.actions';

@Component({
  selector: 'app-xpert-deposit-page',
  templateUrl: './xpert-deposit-page.component.html',
  styleUrls: ['./xpert-deposit-page.component.scss'],
})
export class XpertDepositPageComponent extends TcPage implements OnInit {
  constructor(
    store: Store<any>,
    private dialog: MatDialog,
    private readonly router: Router
  ) {
    super(store);
  }

  buttonsList: TcSmartButton[] = [
    {
      label: 'validate-xpert-deposit',
      ionIcon: 'checkmark-outline',
      class: `mat-raised-button btn-primary`,
      smartStateKey: 'stock',
      action: saveMovement,
      actionPayload: {
        storeKey: 'xpert-deposit-grid',
      },
    },
  ];

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'xpert-deposit-transfert-page.title',
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
