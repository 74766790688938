import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TcCoreModule } from '@tc/core';
import { TcDialogModule } from '@tc/dialog';
import { TcSmartFormModule } from '@tc/smart-form';
import { SharedModule } from '../../../shared/shared.module';
import { XpertGridComponent } from './components/smart/xpert-grid/xpert-grid.component';
import { XpertPasswordPopupComponent } from './components/smart/xpert-password-popup-component/xpert-password-popup-component';
import { TcButtonsModule } from '@tc/buttons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { XpertEffects } from './store/xpert.effects';
import { XpertReducer } from './store/xpert.reducer';

const components = [XpertGridComponent, XpertPasswordPopupComponent];

const services = [];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    TcDialogModule,
    TcSmartFormModule,
    TcCoreModule,
    SharedModule,
    TcButtonsModule,
    EffectsModule.forFeature([XpertEffects]),
    StoreModule.forFeature('xpert', XpertReducer),
  ],
  providers: [...services],
  exports: [...components],
})
export class XpertModule {}
