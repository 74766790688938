import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  FilterTypesEnum,
  ITcDataService,
  TcSmartFormConfig,
  MaterialColor,
} from '@tc/abstract';

import { TcSmartGridComponent } from '@tc/advanced-components';
import { merge, Subscription } from 'rxjs';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { ActivatedRoute } from '@angular/router';
import {
  deleteItemSuccess,
  loadTcDataSuccess,
  TcDataService,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import { PinnedRowPosition } from '@tc/abstract';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridCellComponent,
  TcGridReadyEvent,
  TcTranslateService,
} from '@tc/core';
import { DataType } from 'breeze-client';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PaymentMode } from '../../../../../typings/payment-mode.enum';
import {
  getTcSmartFormIsChangedState,
  initTcSmartForm,
  markTcSmartFormStateAsChanged,
  setTcSmartFormModel,
  updateTcSmartFormCurrentModel,
} from '@tc/smart-form';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
} from '../../../../article/store/catalog-article.selectors';
import { first, take } from 'rxjs/operators';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { TcLocalStorageService } from '@tc/local-storage';
import {
  saveEstimate,
  editEstimateArticles,
  removeEstimateLine,
  updateEstimateLine,
  updateEstimateDocument,
} from '../../../store/devis.actions';
import { Actions, ofType } from '@ngrx/effects';
import { DOCUMENT } from '@angular/common';
import {
  redirectToArticleOrigin,
  setCurrentDocument,
} from '../../../../article/store/catalog-article.actions';
import * as R from 'ramda';
import { DocumentService } from '../../../../../services/document.service';
import {
  DocumentDomaine,
  DocumentType,
} from '../../../../../typings/document.enum';
import { DocumentLigne } from '../../../../elevage/interfaces/document-ligne.interface';
import {
  decimalsForXpertMobile,
  emptyDecimalFormatForXpertMobile,
} from '../../../../../shared/decimals';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { ArticleOrigin } from '../../../../commande/store/commande.payloads';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';

@Component({
  selector: 'app-devis-detail-grid',
  templateUrl: './devis-detail-grid.component.html',
  styleUrls: ['./devis-detail-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DevisDetailGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'devis-detail-grid';

  elevage: Elevage;
  devisDocumentView: ElevageDocument | null;
  subscription = new Subscription();
  readonly = false;
  buttonsList: TcSmartButton[] = [];
  addBtn: TcSmartButton;
  devisDocNum: string = null;
  private refresh: () => void;
  // Start the refreshLaunched to true to prevent unnecessary refresh on page loading witch in certain cases can cause a row duplication bug in the grid
  private refreshLaunched = true;

  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'reference,intitule',
    }
  );

  private isUpdateItemActionTriggered = false;
  private ignoreConfirmation = false;

  constructor(
    protected store$: Store<any>,
    private activatedRoute: ActivatedRoute,
    private readonly dataService: TcDataService,
    private readonly translate: TcTranslateService,
    private readonly localStorage: TcLocalStorageService,
    private readonly docService: DocumentService,
    private readonly actions$: Actions,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private documentDOM: Document
  ) {
    super(store$);
    this.initSubscriptions();
  }

  initSubscriptions() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);

    const documentSubscription = this.store$
      .pipe(select(getCurrentDocument))
      .subscribe((document) => {
        this.devisDocumentView = document;
        this.setReadonly();
        this.refreshFormAndGrid();
      });

    this.subscription.add(documentSubscription);
  }

  /**
   * Function to sync form and grid data with the loaded document
   */
  public refreshFormAndGrid() {
    if (this.refresh) {
      if (this.refreshLaunched === false) {
        this.refreshLaunched = true;
        this.setFormValues();
        this.refresh();
      }
    }
  }

  public async onReady(event: TcGridReadyEvent) {
    this.refresh = event.refresh;
  }

  private setPageButtons() {
    this.buttonsList = [
      {
        label: 'devis-detail-grid.button.cancel',
        ionIcon: 'close',
        color: MaterialColor.Warn,
        type: MaterialButtonType.Raised,
        action: () => {
          // Display prompt confirmation
          const dialog = this.dialog.open(TcPromptDialogComponent, {
            width: '37.5em',
            data: {
              title: 'prompt.title',
              text: 'devis-detail-grid.button.cancel.prompt-message',
              disableClose: true,
            },
          });

          dialog.afterClosed().subscribe((result) => {
            if (!result) return;

            // Reset the state and redirect to the appropriate page
            this.store$.dispatch(redirectToArticleOrigin({ reset: true }));
          });
        },
      },
      {
        label: 'devis-detail-grid.button.validate',
        ionIcon: 'checkmark-outline',
        class:
          'mat-raised-button btn-primary devis-validate-button btn-disabled',
        action: saveEstimate,
        actionPayload: {
          estimateDocNum: this.devisDocNum,
          storeKey: this.storeKey,
        },
      },
    ];

    this.addBtn = {
      ionIcon: 'add-outline',
      color: MaterialColor.Primary,
      type: MaterialButtonType.Fab,
      class: this.readonly ? 'btn-disabled' : '',
      action: editEstimateArticles,
      actionPayload: {
        estimateDocNum: this.devisDocNum,
        storeKey: this.storeKey,
      },
    };
  }

  private async initGridData() {
    this.devisDocNum = this.activatedRoute.snapshot.queryParams['devisDocNum'];

    // Get the origin of the article
    const origin =
      (await this.store$
        .select(getCurrentDocumentArticleOrigin)
        .pipe(take(1))
        .toPromise()) ?? ArticleOrigin.OrderEstimateOptima;

    // Load existing devis into the store if not set (case of viewing existing devis)
    if (
      this.devisDocNum &&
      this?.devisDocumentView?.numeroPiece !== this.devisDocNum
    ) {
      const estimate = this.elevage.documents.find(
        (doc) =>
          doc.numeroPiece === this.devisDocNum &&
          doc.domaine === DocumentDomaine.Vente &&
          doc.type === DocumentType.Devis
      );
      if (!estimate) throw new Error('Devis document not found');
      this.store$.dispatch(
        setCurrentDocument({
          document: estimate,
          articleOrigin: origin,
        })
      );
    }

    this.setReadonly();
    this.setPageButtons();
  }

  async ngOnInit() {
    await this.initGridData();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'devis-detail-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageDevisDetail',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document.ligne',
          data: this.getDevisLines.bind(this),
          breezeStructuralTypeExtension: {
            description: DataType.String,
            prixUnitaireRemise: DataType.Double,
            prixUnitaireTotal: DataType.Double,
          },
        },
      },
      columns: [
        {
          field: 'article.reference',
          minWidth: 150,
          maxWidth: 150,
          sort: 'asc',
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.article?.reference
              ? valueA?.article?.reference
              : '';
            const valueBCleaned = valueB?.article?.reference
              ? valueB?.article?.reference
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'description',
          flex: 1,
          comparator: gridComparatorForString,
        },
        {
          field: 'conditionnementXpert',
          width: 80,
          maxWidth: 80,
          comparator: gridComparatorForString,
        },
        {
          field: 'quantite',
          width: 120,
          maxWidth: 120,
          cellClass: `quantityCell text-align-right${
            this.readonly ? ' ag-cell-disabled' : ''
          }`,
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
          cellRenderer: TcGridCellComponent.MatInputEditor,
          cellRendererParams: {
            type: 'text',
            smartState: {
              storeKey: this.storeKey,
              field: 'quantite',
            },
            mask: '0*',
            selectTextOnClick: true,
            beforeChange: (value) => {
              // To avoid empty values and leading zeros
              if (value === '') {
                return 0;
              }
              const result = parseInt(value, 10);
              return isNaN(result) || result < 0 ? 0 : result;
            },
            onValueChange: (value, data) => {
              this.store$.dispatch(
                updateEstimateLine({ storeKey: this.storeKey, rowData: data })
              );
            },
            onValueChangeDelayInMillis: 1000,
          },
        },
        {
          field: 'prixUnitaire',
          width: 120,
          maxWidth: 120,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaire
                ? `${params.data.prixUnitaire.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'prixUnitaireRemise',
          width: 120,
          maxWidth: 120,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaireRemise
                ? `${params.data.prixUnitaireRemise.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'prixUnitaireTotal',
          width: 120,
          maxWidth: 120,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.prixUnitaireTotal
                ? `${params.data.prixUnitaireTotal.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })}`
                : emptyDecimalFormatForXpertMobile
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'actions',
          minWidth: 100,
          maxWidth: 100,
          sortable: false,
          headerClass: 'text-align-center',
          cellClass: `text-align-center${
            this.readonly ? ' ag-cell-disabled' : ''
          }`,
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'trash-outline',
                tooltip: 'trash-tooltip',
                action: removeEstimateLine,
                actionPayload: {
                  storeKey: this.storeKey,
                },
              },
            ],
          },
          pinnedRowCellRenderer: () => '',
        },
      ],
      pinnedRowsConfig: [
        {
          rowId: 'total-row-excl-discount',
          cssClass: 'row-total-excl-discount',
          position: PinnedRowPosition.Bottom,
          columnConfigs: [
            {
              columnField: 'article.reference',
              columnValue: this.translate.instant('amount-excl-tax'),
              colSpan: 5,
            },
            {
              columnField: 'prixUnitaireTotal',
              columnValue: () =>
                `${this.docService
                  .getTotalPriceExclTax(this.devisDocumentView)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`,
              cellClass: 'text-align-right',
              colSpan: 1,
            },
            {
              columnField: 'actions',
              columnValue: null,
            },
          ],
        },
        {
          rowId: 'discount-row',
          cssClass: 'pinned-row-gray',
          position: PinnedRowPosition.Bottom,
          columnConfigs: [
            {
              columnField: 'article.reference',
              columnValue: this.translate.instant('global-discount-amount'),
              colSpan: 5,
            },
            {
              columnField: 'prixUnitaireTotal',
              columnValue: () =>
                `${this.docService
                  .getTotalDiscount(this.devisDocumentView)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`,
              cellClass: 'text-align-right',
              colSpan: 1,
            },
            {
              columnField: 'actions',
              columnValue: null,
            },
          ],
        },
        {
          rowId: 'total-row',
          cssClass: 'row-total',
          position: PinnedRowPosition.Bottom,
          columnConfigs: [
            {
              columnField: 'article.reference',
              columnValue: this.translate.instant('total'),
              colSpan: 5,
            },
            {
              columnField: 'prixUnitaireTotal',
              columnValue: () =>
                `${this.docService
                  .getTotalPriceWithDiscount(this.devisDocumentView)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`,
              cellClass: 'text-align-right',
              colSpan: 1,
            },
            {
              columnField: 'actions',
              columnValue: null,
            },
          ],
        },
      ],
      columnNumberPerDevice: {
        extraSmallDevice: 8,
        smallDevice: 8,
        mediumDevice: 8,
        largeDevice: 8,
        extraLargeDevice: 8,
        extraExtraLargeDevice: 8,
      },
    };

    this.initEstimateHeading();

    super.ngOnInit();

    this.subscription.add(
      this.actions$.pipe(ofType(updateItemSuccess), take(1)).subscribe(() => {
        this.isUpdateItemActionTriggered = true;
      })
    );

    this.subscription.add(
      this.actions$.pipe(ofType(deleteItemSuccess), take(1)).subscribe(() => {
        this.isUpdateItemActionTriggered = true;
      })
    );

    this.subscription.add(
      this.actions$.pipe(ofType(loadTcDataSuccess)).subscribe(() => {
        this.refreshLaunched = false;
      })
    );

    this.subscription.add(
      this.actions$
        .pipe(ofType(markTcSmartFormStateAsChanged))
        .subscribe(() => {
          this.enableValidateButton();
        })
    );

    this.subscription.add(
      merge(
        this.actions$.pipe(ofType(updateItem)),
        this.actions$.pipe(ofType(editEstimateArticles))
      )
        .pipe(take(1))
        .subscribe(() => {
          this.ignoreConfirmation = true;
        })
    );

    this.subscription.add(
      this.actions$
        .pipe(ofType(updateTcSmartFormCurrentModel))
        .subscribe(() => {
          this.store$.dispatch(
            updateEstimateDocument({ storeKey: this.storeKey })
          );
        })
    );
  }

  /**
   * Convert the lines in devisDocumentView to the expected format of the grid
   * @returns any[]
   */
  public async getDevisLines(): Promise<any[]> {
    const toDisplay = [];

    if (
      this.devisDocumentView !== null &&
      this.devisDocumentView?.ligne?.length > 0
    ) {
      const articleFilter = this.devisDocumentView.ligne
        .map((ligne) => ligne.article.reference)
        .join(',');

      const { data: articles } = await this.articleDataService.getData(
        0,
        null,
        {
          filters: [
            {
              key: 'reference',
              value: articleFilter,
              filterType: FilterTypesEnum.In,
            },
          ],
        }
      );
      this.devisDocumentView.ligne.forEach((ligne: DocumentLigne) => {
        const updatedLigne = R.clone(ligne);
        this.docService.setLignePricesForGrid(updatedLigne);
        updatedLigne.description = articles.find(
          (article) => article.reference === updatedLigne.article.reference
        )?.intitule;
        toDisplay.push(updatedLigne);
      });
    }

    // Only declared as a promise to comply to the interface. Returns the data directly.
    return toDisplay;
  }

  initEstimateHeading() {
    const formConfig: TcSmartFormConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.storeKey,
      autoSubmit: false,
      fieldConfigs: this.getEstimateHeadingFields(),
    };

    this.store$.dispatch(
      initTcSmartForm({
        storeKey: formConfig.storeKey,
        config: formConfig,
      })
    );

    this.setFormValues();
  }

  public setFormValues() {
    let noteDeReglement;

    if (this.elevage.reglement.intitule !== 'Prélèvement') {
      noteDeReglement = PaymentMode.Paiement1X;
    }

    const formDefaultValue = {
      noteDeReglement:
        noteDeReglement ||
        this.devisDocumentView?.noteDeReglement ||
        PaymentMode.Paiement1X,
      numeroPiece: this.devisDocumentView?.numeroPiece,
      datePieceDevisOrigine:
        this.devisDocumentView?.ligne &&
        this.devisDocumentView?.ligne.length > 0
          ? this.devisDocumentView.ligne[0].datePieceDevisOrigine
          : undefined,
    };

    this.store$.dispatch(
      setTcSmartFormModel({
        storeKey: this.storeKey,
        model: formDefaultValue,
      })
    );
  }

  private getEstimateHeadingFields() {
    const formFieldConfigs: FormlyFieldConfig[] = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'datePieceDevisOrigine',
                className: 'date-picker semi-disabled',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  appearance: 'outline',
                  matIcon: 'calendar_today',
                  disabled: true,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'numeroPiece',
                className: 'semi-disabled',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  label: 'numeroPiece',
                  type: 'text',
                  appearance: 'outline',
                  disabled: true,
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'noteDeReglement',
                className: this.readonly ? 'semi-disabled' : '',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  appearance: 'outline',
                  options: Object.values(PaymentMode).map((paymentMode) => ({
                    value: paymentMode,
                    label: paymentMode,
                  })),
                  disabled:
                    this.readonly ||
                    this.elevage.reglement.intitule !== 'Prélèvement',
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
            ],
            colSpan: 12,
          }),
        ],
      }),
    ];

    return formFieldConfigs;
  }

  setReadonly() {
    return new Promise(async () => {
      const lastSyncDate = await this.localStorage.get('lastSyncDate');

      if (!this.devisDocumentView) {
        this.readonly = false;
        this.enableValidateButton();
        return false;
      }

      if (
        this.devisDocumentView.createdOn &&
        new Date(this.devisDocumentView.createdOn) > new Date(lastSyncDate)
      ) {
        this.readonly = false;
        this.enableValidateButton();
        return false;
      } else {
        this.readonly = true;
        this.enableValidateButton();
        return true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async isChanged(): Promise<boolean> {
    const isFormChanged = await this.store$
      .select(getTcSmartFormIsChangedState(this.storeKey))
      .pipe(first())
      .toPromise();

    return this.ignoreConfirmation || this.readonly
      ? false
      : this.isUpdateItemActionTriggered || isFormChanged;
  }

  enableValidateButton() {
    if (!this.readonly) {
      const elements = this.documentDOM.querySelectorAll(
        '.devis-validate-button'
      );
      if (elements?.length > 0) {
        elements.forEach((element) => {
          element?.classList.remove('btn-disabled');
        });
      }
    }
  }
}
