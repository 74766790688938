import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { mergeMap, tap } from 'rxjs/operators';
import { TermsService } from '../../../services/business-services/terms.service';
import { GenericRoutes } from '../../../shared/typings/generic-routes';
import { loginFailure, loginSuccess } from '../../auth/store/auth.actions';
import { TermsOfUse } from '../interfaces/terms-of-use.interface';
import {
  acceptTerms,
  lastTermsAccepted,
  openTermsOfUsePage,
  saveTermsToValidate,
  verifyTerms,
} from './terms-conditions.actions';

@Injectable()
/**
 * Class representing the effects related to terms and conditions.
 */
export class TermsConditionsEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly store$: Store<TcAppState>,
    private readonly termsService: TermsService
  ) {}

  /**
   * An effect that verifies the terms of use.
   * @returns An observable that emits actions based on the verification result.
   */
  verifyTerms = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyTerms),
      mergeMap(() =>
        this.termsService
          .checkLast()
          .then((options: TermsOfUse) => {
            if (options?.id) {
              this.store$.dispatch(saveTermsToValidate({ options }));
              return openTermsOfUsePage();
            }

            this.store$.dispatch(lastTermsAccepted());

            return loginSuccess();
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );

  /**
   * An effect that listens for the action to open the terms of use page and navigates to the
   * terms of use route.
   * @returns None
   */
  openTermsOfUsePage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openTermsOfUsePage),
        tap(() => {
          this.router.navigateByUrl(`/${GenericRoutes.TermsOfUse}`);
        })
      ),
    { dispatch: false }
  );

  /**
   * An effect that listens for the action of accepting terms and conditions.
   * When the action is triggered, it sends a request to the terms service to accept the terms.
   * If the request is successful, it dispatches the action to mark the last terms as accepted
   * and returns the action for successful login.
   * If there is an error, it dispatches the action for login failure with the error.
   * @returns None
   */
  acceptTerms = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptTerms),
      mergeMap(({ id }) =>
        this.termsService
          .accept(id)
          .then(() => {
            this.store$.dispatch(lastTermsAccepted());

            return loginSuccess();
          })
          .catch((error) => loginFailure({ error }))
      )
    )
  );
}
