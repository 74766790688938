import { createAction, props } from '@ngrx/store';
import {
  EditOrderArticlesPayload,
  SaveOrderPayload,
} from './commande.payloads';

export const editOrderArticles = createAction(
  '[Order] Edit Order Articles',
  props<EditOrderArticlesPayload>()
);

export const saveOrder = createAction(
  '[Order] Save Order',
  props<SaveOrderPayload>()
);

export const updateOrderDocument = createAction(
  '[Order] Update Order Document',
  props<{ storeKey: string }>()
);

export const updateOrderLine = createAction(
  '[Order] Update Order Line',
  props<{ storeKey: string; rowData: any }>()
);

export const removeOrderLine = createAction(
  '[Order] Remove Order Line',
  props<{ storeKey: string; rowData: any }>()
);

export const recalculatePrice = createAction('[Order] Recalculate Order Price');
