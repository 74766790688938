import { CustomRoutes } from '../../../typings/custom-routes.enum';

export interface LayoutState {
  hiddenSidenavRoutes: string[];
  visibleXrOptimaLogoRoutes: string[];
}

export const initialLayoutState: LayoutState = {
  hiddenSidenavRoutes: ['/'],
  visibleXrOptimaLogoRoutes: [`/${CustomRoutes.XrOptimaPurchaseHistory}`],
};
