import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterTypesEnum,
  ITcDataService,
  ListOrder,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
} from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import { TcButtonsListComponent, TcSmartButton } from '@tc/buttons';
import {
  DEFAULT_TC_GRID_STATE_KEY,
  formlyColumn,
  formlyComponent,
  formlyControl,
  formlyRow,
  getTcGridSelection,
  NgRxTcGridState,
  setTcGridSelection,
  TcFormlyComponent,
  TcGridCellComponent,
  TcGridReadyEvent,
  TcNotificationService,
  TcTranslateService,
} from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
  TcDataService,
  updateItem,
} from '@tc/data-store';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormCurrentModel,
  NgRxTcSmartFormState,
  setTcSmartFormModel,
} from '@tc/smart-form';
import { decimalsForXpertMobile } from '../../../../../shared/decimals';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { MatDialog } from '@angular/material/dialog';
import {
  getBankDepositEncaissementButtonState,
  getBankDepositSaveButtonState,
} from '../../../store/encaissement.selectors';
import {
  saveCurrentEncaissement,
  setBankDepositEncaissementButtonState,
  setBankDepositSaveButtonState,
  setCurrentEncaissement,
} from '../../../store/encaissement.actions';
import moment from 'moment';
import {
  ModeReglement,
  StatutRemisEnBanque,
} from '../../../store/encaissement.state';
import { EncaissementPopupComponent } from '../encaissement-popup/encaissement-popup.component';
import * as R from 'ramda';
import { selectByKey, selectValueByKey } from '@tc/store';
import { PdfGeneratorService } from '../../../../../services/pdf-generator.service';
import { TDocumentDefinitions } from 'pdfmake/build/pdfmake';
import { XpertService } from '../../../../../services/xpert.service';

@Component({
  selector: 'app-bank-deposit-grid',
  templateUrl: './bank-deposit-grid.component.html',
  styleUrls: ['./bank-deposit-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BankDepositGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  private refresh: () => void;

  storeKey = 'bank-deposit-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  dataStore$: Observable<NgRxTcDataState>;
  gridStore$: Observable<NgRxTcGridState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  elevage: Elevage;
  buttonsList: TcSmartButton[];
  subscription = new Subscription();

  private readonly encaissementDataService: ITcDataService<any> =
    this.dataService.getService('Encaissements', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Encaissements',
      fields: 'date',
    });

  private encaissementDefaultFilters = [];

  constructor(
    store$: Store<any>,
    private dataService: TcDataService,
    public readonly translate: TcTranslateService,
    private dialog: MatDialog,
    private readonly pdfGeneratorService: PdfGeneratorService,
    private readonly xpertService: XpertService,
    private readonly notification: TcNotificationService
  ) {
    super(store$);

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.loadCurrentElevage();
    this.enableEncaissementButton();
  }

  async loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe(async (elevage) => {
        this.elevage = elevage;
        this.enableEncaissementButton();
      });

    this.subscription.add(elevageSubscription);
  }

  private async enableEncaissementButton() {
    if (this.elevage) {
      this.store$.dispatch(
        setBankDepositEncaissementButtonState({ active: true })
      );
    } else {
      this.store$.dispatch(
        setBankDepositEncaissementButtonState({ active: false })
      );
    }
  }

  public async saveDeposit() {
    const selection: any[] = R.clone(
      await selectValueByKey(getTcGridSelection, this.gridStore$, this.storeKey)
    );

    const now = moment().toISOString().split('T')[0];

    // Update the status of the encaissements to "Remis en banque"
    selection.forEach((encaissement) => {
      encaissement.remisEnBanque = now;
      encaissement.statutRemisEnBanque = StatutRemisEnBanque.csvToGenerate;

      this.store$.dispatch(
        updateItem({
          storeKey: this.storeKey,
          item: encaissement,
          displaySuccessNotification: false,
        })
      );
    });

    this.notification.success(
      this.translate.instant('bank-deposit.success-message')
    );

    // Generate PDF for the bank deposit
    this.generatePdf(selection);
    this.refresh();
  }

  public async onReady(event: TcGridReadyEvent) {
    this.refresh = event.refresh;
    event.api.addEventListener('selectionChanged', function () {
      const selectedNodes = event.api.getSelectedNodes();

      // Determine if header checkbox was checked
      const headerCheckbox =
        selectedNodes.length === event.api.getDisplayedRowCount();

      if (headerCheckbox) {
        // Manually select only the rows that are selectable
        event.api.forEachNode(function (node) {
          if (!node.data.numeroCheque) {
            node.setSelected(false);
          } else {
            node.setSelected(true);
          }
        });
      }
    });
  }

  public async generatePdf(selection: any[]) {
    const xpert = await this.xpertService.getConnectedXpert();

    const now = moment().format('DD/MM/YYYY');

    const currentFilterModel = await selectValueByKey(
      getTcSmartFormCurrentModel,
      this.formStore$,
      `${this.storeKey}.filter`
    );

    let period = null;
    if (currentFilterModel?.period) {
      const split = currentFilterModel.period.split('|');
      const startDate = moment(split[0]).format('DD/MM/YYYY');
      const endDate = moment(split[1]).format('DD/MM/YYYY');
      period = `${startDate} - ${endDate}`;
    }

    const rows = [
      ...selection.map((row) => {
        const date = new Date(row.date);
        const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
        return [
          {
            text: row.elevage.numero,
            alignment: 'right',
          },
          {
            text: row.elevage.nom,
          },
          {
            text: formattedDate,
            alignment: 'right',
          },
          {
            text: row.modeReglement,
          },
          {
            text: row.numeroCheque,
            alignment: 'right',
          },
          {
            text: `${
              row.montant || row.montant === 0
                ? `${row.montant
                    .toLocaleString(undefined, {
                      minimumFractionDigits: decimalsForXpertMobile,
                    })
                    .replace(/\u202F/g, '\u00A0')} €` // Hack to replace the narrow no-break space with a regular no-break space
                : ''
            }`,
            alignment: 'right',
          },
        ];
      }),
    ];

    //Un fichier PDF est généré localement pour envoi par email/impression, il contient en plus de la liste des chèques, le code Xpert son nom et son prénom et le code IBAN associé à l’Xpert Collaborateur.IBAN (compte XR Repro)
    const document: TDocumentDefinitions = {
      content: [
        {
          alignment: 'center',
          text: this.translate.instant('bank-deposit.title'),
          style: 'header',
          fontSize: 23,
          bold: true,
          margin: [0, 10],
        },
        {
          margin: [0, 0, 0, 10],
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 ? '#ebebeb' : '#f5f5f5';
            },
          },
          table: {
            widths: ['100%'],
            heights: [10, 10, 10],
            body: [
              [
                {
                  text: `${this.translate.instant(
                    'bank-deposit-grid.code'
                  )} : ${xpert.idXpert}`,
                  bold: true,
                },
              ],
              [
                {
                  text: `${this.translate.instant(
                    'bank-deposit-grid.name'
                  )} : ${xpert.nom + ' ' + xpert.prenom.trim()}`,
                  bold: true,
                },
              ],
              [
                {
                  text: `${this.translate.instant('iban')} : ${
                    xpert.IBAN
                      ? xpert.IBAN
                      : this.translate.instant('not-filled')
                  }`.trim(),
                  bold: true,
                },
              ],
              [
                {
                  text: `${this.translate.instant(
                    'bank-deposit-grid.filter.labels.period'
                  )} : ${
                    period ? period : this.translate.instant('not-filled')
                  }`.trim(),
                  bold: true,
                },
              ],
              [
                {
                  text: `${this.translate.instant(
                    'bank-deposit-grid.dateBankDeposit'
                  )} : ${now}`,
                  bold: true,
                },
              ],
            ],
          },
        },
        {
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              if (rowIndex === 0) {
                return '#c2dec2';
              }

              return rowIndex % 2 === 0 ? '#ebebeb' : '#f5f5f5';
            },
          },
          table: {
            widths: ['20%', '20%', '15%', '15%', '15%', '15%'],
            heights: [10, 10, 10, 10, 10, 10],
            headerRows: 1,
            body: [
              [
                {
                  text: this.translate.instant(
                    'bank-deposit-grid.header.elevage.numero'
                  ),
                  bold: true,
                },
                {
                  text: this.translate.instant(
                    'bank-deposit-grid.header.elevage.nom'
                  ),
                  bold: true,
                },
                {
                  text: this.translate.instant('bank-deposit-grid.header.date'),
                  bold: true,
                },
                {
                  text: this.translate.instant(
                    'bank-deposit-grid.header.modeReglement'
                  ),
                  bold: true,
                },
                {
                  text: this.translate.instant(
                    'bank-deposit-grid.header.numeroCheque'
                  ),
                  bold: true,
                },
                {
                  text: this.translate.instant(
                    'bank-deposit-grid.header.montant'
                  ),
                  bold: true,
                },
              ],
              ...rows, // Actual grid data
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 8.25,
      },
    };

    this.pdfGeneratorService.download(
      document,
      `remise-en-banque-${moment().format('DD-MM-YYYY')}.pdf`
    );
  }

  /**
   * Cleans the selection by removing the item with the specified ID from the grid selection.
   *
   * @param id - The ID of the item to be removed from the selection.
   * @returns void
   */
  private async cleanSelection(id: string) {
    const selection: any[] = R.clone(
      await selectValueByKey(getTcGridSelection, this.gridStore$, this.storeKey)
    );

    if (selection.find((item) => item._id === id)) {
      const newSelection = selection.filter((item) => item._id !== id);

      this.store$.dispatch(
        setTcGridSelection({
          storeKey: this.storeKey,
          selectedRows: newSelection,
        })
      );
    }
  }

  /**
   * Updates the "numeroCheque" property of a row in the grid's selection.
   *
   * @param rowData - The data of the row to update.
   * @returns A Promise that resolves once the update is complete.
   */
  private async forceUpdateNumeroCheque(rowData: any) {
    const selection: any[] = R.clone(
      await selectValueByKey(getTcGridSelection, this.gridStore$, this.storeKey)
    );

    if (selection.find((item) => item._id === rowData?._id)) {
      const rowInStore = selection.find((item) => item._id === rowData?._id);
      rowInStore.numeroCheque = rowData.numeroCheque;

      this.store$.dispatch(
        setTcGridSelection({
          storeKey: this.storeKey,
          selectedRows: selection,
        })
      );
    }
  }

  async ngOnInit() {
    const xpert = await this.xpertService.getConnectedXpert();

    this.encaissementDefaultFilters = [
      {
        key: 'statutRemisEnBanque',
        value: StatutRemisEnBanque.no.toString(),
        filterType: FilterTypesEnum.Equal,
      },
      {
        key: 'collaborateur.idXpert',
        value: xpert.idXpert,
        filterType: FilterTypesEnum.Equal,
      },
    ];

    this.subscription.add(
      selectByKey(getTcGridSelection, this.gridStore$, this.storeKey).subscribe(
        async (selection) => {
          if (selection.length > 0) {
            this.store$.dispatch(
              setBankDepositSaveButtonState({ active: true })
            );
          } else {
            this.store$.dispatch(
              setBankDepositSaveButtonState({ active: false })
            );
          }
        }
      )
    );

    const encaissements = await this.encaissementDataService.getData(
      0,
      1,
      {
        filters: this.encaissementDefaultFilters,
      },
      {
        key: 'date',
        order: ListOrder.Asc,
      }
    );

    const encaissementFound = encaissements?.data[0];

    let periodDefaultValue = null;
    if (encaissementFound) {
      // Confirmed by MTU : Filtrer par date d'encaissement (qui sera visible dans le PDF), par défaut Debut=date min encaissement, Fin=Aujourd'hui
      periodDefaultValue = `${moment(
        encaissementFound.date,
        'YYYY-MM-DD'
      ).toISOString()}|${moment().toISOString()}`;
    }

    this.buttonsList = [
      {
        label: 'bank-deposit.button',
        ionIcon: 'checkmark-outline',
        class: 'bank-deposit-save-button btn-primary btn-text',
        action: this.saveDeposit.bind(this),
        smartStateKey: 'encaissement',
        disableSelector: getBankDepositSaveButtonState,
        disableStoreKey: 'encaissementButtonsState',
      },
    ];

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'bank-deposit-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      columnNumberPerDevice: {
        extraSmallDevice: 9,
        smallDevice: 9,
        mediumDevice: 9,
        largeDevice: 9,
        extraLargeDevice: 9,
        extraExtraLargeDevice: 9,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'Encaissements',
        filter: {
          filters: this.encaissementDefaultFilters,
        },
      },
      columns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: (params) => {
            const numeroChequeIsSet =
              params?.data?.numeroCheque !== '' &&
              params?.data?.numeroCheque !== null &&
              params?.data?.numeroCheque !== undefined;
            if (numeroChequeIsSet) {
              // Force the refresh of the selection in case the numero changed
              this.forceUpdateNumeroCheque(params.data);

              // If the numero was inputed, we allow to check the row
              return true;
            }

            // If the numero was inputed and then removed, we need to uncheck the row in the store
            this.cleanSelection(params?.data?._id);

            return false;
          },
          field: 'elevage.numero',
          sort: 'asc',
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.elevage?.numero
              ? valueA?.elevage?.numero
              : '';
            const valueBCleaned = valueB?.elevage?.numero
              ? valueB?.elevage?.numero
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'elevage.nom',
          cellRenderer: (params) =>
            `<span title="${params?.data?.elevage?.nom}">${params?.data?.elevage?.nom}</span>`,
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.elevage?.nom
              ? valueA?.elevage?.nom
              : '';
            const valueBCleaned = valueB?.elevage?.nom
              ? valueB?.elevage?.nom
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'date',
          comparator: gridComparatorForString,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
        },
        {
          field: 'modeReglement',
          comparator: gridComparatorForString,
        },
        {
          field: 'numeroCheque',
          comparator: gridComparatorForString,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          cellRenderer: TcGridCellComponent.MatInputEditor,
          cellRendererParams: {
            type: 'text',
            smartState: {
              storeKey: this.storeKey,
              field: 'numeroCheque',
            },
            selectTextOnClick: true,
            disableFocusAfterInit: true,
            onValueChangeDelayInMillis: 1000,
            disabled: (rowData) =>
              rowData.modeReglement === ModeReglement.check,
          },
        },
        {
          field: 'montant',
          valueFormatter: (params) =>
            `${
              params.data.montant || params.data.montant === 0
                ? `${params.data.montant.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`
                : ''
            }`,
          comparator: gridComparatorForNumber,
          width: 180,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
      ],
      filterConfig: this.getFilterConfig(periodDefaultValue),
    };

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.store$.dispatch(setCurrentEncaissement({ encaissement: null }));
  }

  private getFilterConfig(periodDefaultValue: string) {
    const formFieldConfigs: TcSmartFilterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'period',
                  className: 'date-picker',
                  type: TcFormlyComponent.TcDateRangePicker,
                  defaultValue: periodDefaultValue,
                  templateOptions: {
                    appearance: 'outline',
                    matIcon: 'calendar_today',
                    filterType: FilterTypesEnum.DateRangeFromString,
                    filterOn: ['date'],
                  },
                  colSpan: 2,
                }),
                formlyComponent({
                  component: TcButtonsListComponent,
                  className: 'encaissement-btn-list',
                  componentData: {
                    buttonsList: [
                      {
                        label: 'make-cash-collection',
                        class: 'encaissement-button btn-primary btn-text',
                        tooltipDisabled: 'encaissement-must-select-elevage',
                        action: () => {
                          const dialog = this.dialog.open(
                            EncaissementPopupComponent,
                            {
                              data: {
                                total: 0,
                              },
                              width: '30em',
                            }
                          );
                          dialog.afterClosed().subscribe((result) => {
                            if (result && result?.setInStore === true) {
                              // Launch the saving process of the encaissement
                              this.store$.dispatch(
                                saveCurrentEncaissement({ notification: true })
                              );
                              this.refresh();
                            }
                          });
                        },
                        smartStateKey: 'encaissement',
                        disableSelector: getBankDepositEncaissementButtonState,
                        disableStoreKey: 'encaissementButtonsState',
                      },
                    ],
                  },
                  colSpan: 10,
                }),
              ],
            }),
          ],
          colSpan: 12,
        }),
      ],
    };

    return formFieldConfigs;
  }
}
