import { NavigationStart, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { TcTranslateService } from '@tc/core';
import { take } from 'rxjs/operators';
import { clearUserData, loginSuccess } from '../modules/auth/store/auth.actions';
import { getAuthenticationToken } from '../modules/auth/store/auth.selectors';
import { verifyTerms } from '../modules/terms-conditions/store/terms-conditions.actions';
import { Subscription } from 'rxjs';
import { ConfigService } from '../shared/services/config.service';
import { Title } from '@angular/platform-browser';
import { ConfigKeys } from '../shared/interfaces/config.interface';
import { GenericRoutes } from '../shared/typings/generic-routes';
import { TcOfflineModeService } from '@tc/breeze';
import { TcDataServiceFactory } from '@tc/data-store';

@Injectable()
/**
 * Service class for initializing the application.
 */
export class AppInitService {
  private routerSubscription: Subscription;
  private loginEndpointRoute: string = null;
  private offlineModeService: TcOfflineModeService;

  /**
   * Constructs a new instance of the class.
   * @param {Store<TcAppState>} store$ - The store object for managing application state.
   * @param {Router} router - The router object for navigating between routes.
   * @param {TcTranslateService} translate - The translation service for translating text.
   * @param {ConfigService} config - The configuration service for accessing application configuration.
   * @param {Title} titleService - The title service for managing the title of the current page.
   * @param {TcDataServiceFactory} tcDataServiceFactory - The factory for creating data services.
   * @returns None
   */
  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly router: Router,
    private readonly translate: TcTranslateService,
    private config: ConfigService,
    private titleService: Title,
    private tcDataServiceFactory: TcDataServiceFactory,
  ) {
    if (this.config.get(ConfigKeys.supportsOfflineMode)) {
      this.offlineModeService = this.tcDataServiceFactory.getOfflineModeService(
        this.config.get(ConfigKeys.offlineModeDataProviderType)
      );
    }
  }

  /**
   * Initializes the application by setting the app title, subscribing to router events,
   * checking user authentication token, verifying terms and conditions, and dispatching
   * login success action if necessary.
   * @returns None
   */
  public async init() {
    this.setAppTitle();

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.setLoginEndpointRoute(event.url);
      }
    });

    const userAuthToken = await this.store$
      .select(getAuthenticationToken)
      .pipe(take(1))
      .toPromise();

    const hasOfflineMode = this.offlineModeService && await this.offlineModeService.hasOfflineMode();

    if (!userAuthToken) {
      return;
    } else if (this.config.get(ConfigKeys.verifyTermsConditions)) {
      await this.verifyTermsConditions();
    } else if (!hasOfflineMode) {
      this.store$.dispatch(loginSuccess());
    }
  }

  /**
   * Verifies the terms and conditions by dispatching actions to clear user data and
   * verify the terms.
   * @returns None
   */
  private async verifyTermsConditions() {
    this.store$.dispatch(clearUserData());
    this.store$.dispatch(verifyTerms());
  }

  /**
   * Sets the login endpoint route based on the given URL.
   * @param {string} url - The URL to set as the login endpoint route.
   * @returns None
   */
  private setLoginEndpointRoute(url: string) {
    const routesToIgnore = [
      `/${GenericRoutes.Login}`,
      `/${GenericRoutes.LoginMfa}`,
      `/${GenericRoutes.LoginOauth}`,
      `/${GenericRoutes.LoginOauthFailed}`,
      `/${GenericRoutes.TermsOfUse}`,
    ];

    if (routesToIgnore.includes(url)) {
      url = '/';
    }
    if (!this.loginEndpointRoute) {
      this.loginEndpointRoute = url;
      this.routerSubscription.unsubscribe();
    }
  }

  /**
   * Retrieves the login endpoint route.
   * If a redirect route after login is configured, it returns the redirect route.
   * Otherwise, it returns the default login endpoint route.
   * @returns {string} - The login endpoint route.
   */
  public getLoginEndPointRoute() {
    // If 'redirectAfterLogin' route is defined in config, redirect to it, otherwise follow the default redirect
    const redirectRouteAfterLogin = this.config.get(ConfigKeys.redirectRouteAfterLogin) as string;
    if (redirectRouteAfterLogin) {
      return redirectRouteAfterLogin;
    }
    return this.loginEndpointRoute;
  }

  /**
   * Dynamically sets app title based on config.json
   */
  private setAppTitle() {
    const appTitle = this.config.get(ConfigKeys.appTitle) as string;
    this.titleService.setTitle(appTitle || 'App title not set');
  }
}
