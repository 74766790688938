import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { TcTranslateService } from '@tc/core';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(
    private httpClient: HttpClient,
    private config: ConfigService,
    private dialog: MatDialog,
    private translateService: TcTranslateService
  ) {}

  async isSyncAvailable(showSyncUnavailableDialog = true): Promise<boolean> {
    return this.httpClient
      .get(`${this.config.get('API_BASE_PATH')}/custom/syncIsAvailable`)
      .toPromise()
      .then((res: { data: { isSyncAvailable: boolean } }) => {
        if (
          res &&
          !res.data.isSyncAvailable &&
          showSyncUnavailableDialog === true
        ) {
          this.showSyncUnavailableDialog();
        }

        return res?.data.isSyncAvailable ?? false;
      });
  }

  showSyncUnavailableDialog(): void {
    this.dialog.open(TcPromptDialogComponent, {
      width: '37.5em',
      data: {
        title: this.translateService.instant('prompt.title'),
        disableTextTranslation: true,
        text: this.translateService.instant('globalMessages.sync-unavailable'),
        displayCancelButton: false,
        displayConfirmButton: true,
        disableClose: true,
      },
    });
  }

  /**
   * Launch the ETL process on backend side
   */
  async launchEtlSage2Xpert() {
    // Security
    const syncIsavailable = await this.isSyncAvailable(false);
    if (syncIsavailable === false) {
      throw new Error('ETL is already running.');
    }
    this.httpClient
      .get(`${this.config.get('API_BASE_PATH')}/custom/etl-sage-xpert`)
      .toPromise()
      .catch((err) => {
        // Ignore any error. Http call may reach a timeout. What is important is to launch the process, backend will finish it.
        return;
      });
  }

  /**
   * Launch the ETL process on backend side
   */
  async launchEtlXpert2Sage() {
    // Security
    const syncIsavailable = await this.isSyncAvailable(false);
    if (syncIsavailable === false) {
      throw new Error('ETL is already running.');
    }
    this.httpClient
      .get(`${this.config.get('API_BASE_PATH')}/custom/etl-xpert-sage`)
      .toPromise()
      .catch((err) => {
        // Ignore any error. Http call may reach a timeout. What is important is to launch the process, backend will finish it.
        return;
      });
  }

  /**
   * Launch the ETL process on backend side
   */
  launchEtlXpertExtractOnly() {
    return this.httpClient
      .get(
        `${this.config.get('API_BASE_PATH')}/custom/etl-recette-extract-only`
      )
      .toPromise();
  }
}
