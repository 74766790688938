import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
/**
 * Represents the login page component.
 * @class LoginPageComponent
 * @extends TcPage
 * @param {Store<any>} [store] - The Redux store object.
 */
export class LoginPageComponent extends TcPage {
  constructor(store?: Store<any>) {
    super(store);
    this.pageInfo = {
      title: "login-title"
    };
  }
}
