import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { TcVersionModule } from '@tc/version';
import { TcButtonsModule } from '@tc/buttons';
import { StoreModule } from '@ngrx/store';
import { homeReducer } from './store/home.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HomeEffects } from './store/home.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TcCoreModule,
    TcVersionModule,
    TcButtonsModule,
    EffectsModule.forFeature([HomeEffects]),
    StoreModule.forFeature('home', homeReducer),
  ],
})
export class HomeModule {}
