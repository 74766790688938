import {
  Component,
  OnInit,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { EventTargetInterruptSource } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { TcAppComponent, TcConfigService, TcMediaService } from '@tc/core';
import { SetVersionInfo } from '@tc/store';
import { TcVersionService } from '@tc/version';
import moment from 'moment';
import { SessionTimeoutService } from './services/session-timeout.service';
import { ConfigKeys } from './shared/interfaces/config.interface';
import { ConfigService } from './shared/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
/**
 * The AppComponent class represents the root component of the application.
 * It extends the TcAppComponent and implements the OnInit interface.
 * @constructor
 * @param {TcConfigService} appConfigService - The service for application configuration.
 * @param {SessionTimeoutService} sessionTimeoutService - The service for managing session timeouts.
 * @param {ElementRef} element - The reference to the root element of the component.
 * @param {ChangeDetectorRef} changeDetectorRef - The reference to the change detector of the component.
 * @param {TcMediaService} mediaService - The service for managing media queries.
 * @param {Store<any>} store$ - The Redux store for managing application state.
 * @param {
 */
export class AppComponent extends TcAppComponent implements OnInit {
  /**
   * Constructs a new instance of the component.
   * @param {TcConfigService} appConfigService - The app configuration service.
   * @param {SessionTimeoutService} sessionTimeoutService - The session timeout service.
   * @param {ElementRef} element - The element reference.
   * @param {ChangeDetectorRef} changeDetectorRef - The change detector reference.
   * @param {TcMediaService} mediaService - The media service.
   * @param {Store<any>} store$ - The store.
   * @param {TcVersionService} tcVersionService - The version service.
   * @param {ConfigService} config - The configuration service.
   * @returns None
   */
  constructor(
    public readonly appConfigService: TcConfigService,
    private readonly sessionTimeoutService: SessionTimeoutService,
    private element: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly mediaService: TcMediaService,
    private readonly store$: Store<any>,
    private readonly tcVersionService: TcVersionService,
    private readonly config: ConfigService
  ) {
    super(appConfigService);

    // Display version info on console
    this.tcVersionService.displayVersionInConsole();

    this.store$.dispatch(
      new SetVersionInfo({
        version: this.tcVersionService.getVersionAsString(),
        currentDate: moment().toISOString(),
      })
    );

    if (!this.config.get(ConfigKeys.disableKeepAlive)) {
      this.sessionTimeoutService.start([
        new EventTargetInterruptSource(
          this.element.nativeElement,
          'mousedown keydown'
        ),
      ]);
    }

    this.mediaService.init(this.changeDetectorRef);
  }

  /**
   * Lifecycle hook that is called after the component has been initialized.
   * Calls the ngOnInit method of the parent class.
   * @returns None
   */
  async ngOnInit() {
    super.ngOnInit();
  }
}
