import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeState } from './home.state';

export const getHomeState = createFeatureSelector<HomeState>('home');

export const getElevageButtonsList = createSelector(
  getHomeState,
  (state: HomeState) => state.elevageButtonsList
);

export const getStockButtonsList = createSelector(
  getHomeState,
  (state: HomeState) => state.stockButtonsList
);

export const getXpertButtonsList = createSelector(
  getHomeState,
  (state: HomeState) => state.xpertButtonsList
);
