import { DocumentType } from '../../../typings/document.enum';

/**
 * Main stock state
 */
export interface StockState {
  movement: ArticlesMovement;
}

/**
 * Initial stock state
 */
export const initialStockState: StockState = {
  movement: {
    departure: null,
    destination: null,
    articles: [],
    qrCodeData: [],
  },
};

/**
 * Enum for the saving method of the movement
 */
export enum ArticleMovementSaveOption {
  none = 'none',
  sync = 'sync',
  qrcode = 'qrcode',
}

/**
 * Main interface for articles movement between two depots / emplacements. Has a departure, a destination and a list of moved articles.
 */
export interface ArticlesMovement {
  departure: MovementDepot | null;
  destination: MovementDepot | null;
  articles: ArticleMovementLine[];
  qrCodeData: QrCodeMovementData[]; // If provided, it will be used to put the data instead of letting the process create them.
}

/**
 * Interface for the data that will come from the qr code. It is a subset of the ArticlesMovement interface.
 */
export interface QrCodeMovementData {
  documentType: DocumentType;
  numXpertMobile: string;
  numPiece: number;
  idXpert: string;
  date: string;
}

/**
 * Interface for qr code creation with minimal use of the data. Length of the qr code is limited. That why the properties are only 1 characters. We try to gain space where we can...
 */
export interface QrCodeData {
  a: {
    // Departure
    n: number; // NumeroDepot
    c?: string; // CodeEmplacement
  };
  b: {
    // Destination
    n: number; // NumeroDepot
    c?: string; // CodeEmplacement
  };
  c: {
    // Articles
    r: string; // Reference
    l: string; // Lot number
    q: number; // Quantity
  }[];
  d: {
    // Document informations
    t: DocumentType; // DocumentType
    n: string; // NumXpertMobile
    p: number; // NumPiece
    x: string; // NumXpert
    d: string; // Date
  }[];
}

/**
 * Interface used to be able to identify a depot / emplacement that is the departure or the destination of the movement
 */
export interface MovementDepot {
  numeroDepot: number;
  codeEmplacement?: string;
  intitule?: string;
}

/**
 * Interface used by the grid for deposit / transfert display
 */
export interface ArticleMovementLine {
  _id: string; // Internal computed id from reference and lot number. Provided by the grid to have a unique reference. Mandatory.
  reference: string;
  numeroLot: string | null; // May be not defined.
  description: string;
  quantiteEnStock: number;
  quantite: number;
}
