import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';
import { CanDeactivateComponent } from '../../../modules/guards/deactivation-confirmation.guard';
import { DevisDetailGridComponent } from '../../modules/devis/components/smart/devis-detail-grid/devis-detail-grid.component';

@Component({
  selector: 'app-devis-detail',
  templateUrl: './devis-detail.component.html',
  styleUrls: ['./devis-detail.component.scss'],
})
export class DevisDetailPageComponent
  extends TcPage
  implements OnInit, CanDeactivateComponent
{
  @ViewChild(DevisDetailGridComponent)
  devisDetailGridComponent: DevisDetailGridComponent;

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'devis-detail-page.title',
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

  shouldConfirmDeactivation(): Promise<boolean> {
    return this.devisDetailGridComponent.isChanged();
  }
}
