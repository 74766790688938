import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  TcTranslateService,
  TcFormComponent,
  TcFormlyComponent,
  decimalMask,
} from '@tc/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { Subscription } from 'rxjs';
import {
  EncaissementDocument,
  ModeReglement,
} from '../../../store/encaissement.state';
import { FormControl, FormGroup } from '@angular/forms';
import { FactureGridDocument } from '../../../../facture/interfaces/facture-grid-document.interface';
import { EncaissementService } from '../../../../../services/encaissement.service';
import {
  setCurrentEncaissement,
  setCurrentEncaissementFromPopup,
} from '../../../store/encaissement.actions';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { getCurrentDocument } from '../../../../article/store/catalog-article.selectors';
import { take } from 'rxjs/operators';
import { getCurrentEncaissement } from '../../../store/encaissement.selectors';

@Component({
  selector: 'app-encaissement-popup',
  templateUrl: './encaissement-popup.component.html',
  styleUrls: ['./encaissement-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EncaissementPopupComponent
  extends TcFormComponent<any>
  implements OnInit, OnDestroy
{
  error = false;
  qrCodeContent: string = null;
  qrCodeMessage: string = null;
  buttonsList: TcSmartButton[];
  message: string;
  subscriptions = new Subscription();
  form: FormGroup = new FormGroup({});
  formModel: any;
  invoices: FactureGridDocument[] = [];
  total: number = 0;

  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `prompt.close-button`,
  };

  closeFullButtonConfig: TcSmartButton = {
    matIcon: 'close',
    name: `prompt.close-button`,
  };

  constructor(
    private dialogRef: MatDialogRef<EncaissementPopupComponent>,
    public readonly translate: TcTranslateService,
    private readonly store$: Store<any>,
    public elem: ElementRef,
    private readonly encaissementService: EncaissementService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(translate, elem);
    this.total = data?.total ? data?.total : 0;
    this.invoices = data?.invoices ? data.invoices : [];
  }

  async ngOnInit() {
    super.ngOnInit();
    this.initButtons();
    this.initFormFields();

    // If you have a current encaissement, set the form values
    const currentEncaissement = await this.store$
      .select(getCurrentEncaissement)
      .pipe(take(1))
      .toPromise();

    // If the numXpertMobile is not the same of the current document, clear the encaissement
    const currentDocument = await this.store$
      .select(getCurrentDocument)
      .pipe(take(1))
      .toPromise();

    if (currentEncaissement && currentDocument) {
      // Check if the encaissement is from the current document
      if (currentEncaissement.documents.length > 0) {
        const encaissementDocument = currentEncaissement.documents[0];
        if (
          encaissementDocument.numXpertMobile !== currentDocument.numXpertMobile
        ) {
          this.store$.dispatch(setCurrentEncaissement({ encaissement: null }));
        } else {
          // Restore the encaissement data in the form
          this.form.patchValue({
            montant: currentEncaissement.montant,
            date: currentEncaissement.date,
            modeReglement: currentEncaissement.modeReglement,
            numeroCheque: currentEncaissement.numeroCheque,
          });
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async onSubmit() {
    if (this.form.valid) {
      // We must save the encaissement from the list of invoices. Direct save is possible. In case of BL / order, the encaissement must be first set in the store and saved alonside the BL / order.
      if (this.invoices.length > 0) {
        // Retrieve an empty encaissement object with the session data.
        const invoicesEncaissement =
          await this.encaissementService.getEncaissementForInvoices(
            this.form.value.montant,
            this.form.value.date,
            this.form.value.modeReglement,
            this.form.value.numeroCheque,
            this.invoices
          );

        this.store$.dispatch(
          setCurrentEncaissementFromPopup({
            encaissement: invoicesEncaissement,
            save: true,
          })
        );
        this.onClose();
      } else {
        // From Order / BL or Bank Deposit screen.
        // Get the current document from the store to get the numXpertMobile
        const document: ElevageDocument = await this.store$
          .select(getCurrentDocument)
          .pipe(take(1))
          .toPromise();

        const orderEncaissement =
          await this.encaissementService.getNewEncaissementWithSessionData();
        orderEncaissement.montant = this.form.value.montant;
        orderEncaissement.date = this.form.value.date;
        orderEncaissement.modeReglement = this.form.value.modeReglement;
        orderEncaissement.numeroCheque = this.form.value.numeroCheque;

        // Build document from the current document
        if (document) {
          const encaissementDocument: EncaissementDocument = {
            numXpertMobile: document.numXpertMobile,
            domaine: document.domaine,
            type: document.type,
            numeroPiece: document.numeroPiece,
            echeances: [],
          };
          orderEncaissement.documents.push(encaissementDocument);
        }

        this.store$.dispatch(
          setCurrentEncaissement({ encaissement: orderEncaissement })
        );
        this.onClose({ setInStore: true });
      }
    }
  }

  onClose(response: any = null) {
    this.dialogRef.close(response);
  }

  /**
   * Initializes the form fields for the component.
   * @returns None
   */
  private initFormFields() {
    this.fields = [
      {
        key: 'montant',
        type: TcFormlyComponent.NgxInput,
        defaultValue: this.total > 0 ? this.total : null,
        templateOptions: {
          required: true,
          type: 'text',
          appearance: 'outline',
          label: this.translate.instant('amount'),
          mask: decimalMask(),
        },
        validators: [
          (formControl: FormControl) => {
            if (formControl.value) {
              if (formControl.value <= 0) {
                return false;
              }

              return true;
            }
          },
        ],
      },
      {
        key: 'date',
        className: 'date-picker',
        type: TcFormlyComponent.TcDatePicker,
        templateOptions: {
          label: this.translate.instant('date-encaissement'),
          required: true,
          appearance: 'outline',
          matIcon: 'calendar_today',
          datepickerOptions: {
            // 4 months from now
            max: new Date(new Date().setMonth(new Date().getMonth() + 4)),
          },
        },
        validators: [
          (formControl: FormControl) => {
            if (formControl.value) {
              const date = new Date(formControl.value);
              const maxDate = new Date(
                new Date().setMonth(new Date().getMonth() + 4)
              );
              if (date > maxDate) {
                return false;
              }
            }
            return true;
          },
        ],
      },
      {
        key: 'modeReglement',
        type: TcFormlyComponent.TcSmartSelect,
        templateOptions: {
          change: (field, $event) => {
            if ($event?.value !== ModeReglement.check) {
              const form = field.formControl.parent;
              const dependentFieldControl = form.get('numeroCheque');
              dependentFieldControl.setValue('');
            }
          },
          label: this.translate.instant('mode-reglement'),
          required: true,
          appearance: 'outline',
          options: [
            {
              label: this.translate.instant('cash'),
              value: ModeReglement.cash,
            },
            {
              label: this.translate.instant('check'),
              value: ModeReglement.check,
            },
          ],
        },
      },
      {
        key: 'numeroCheque',
        type: TcFormlyComponent.FormlyInput,
        templateOptions: {
          appearance: 'outline',
          disabled: true,
          type: 'text',
          label: this.translate.instant('check-number'),
        },
        expressionProperties: {
          'templateOptions.disabled': (model) =>
            model.modeReglement !== ModeReglement.check,
          'templateOptions.required': (model) =>
            model.modeReglement === ModeReglement.check,
        },
      },
    ];
  }

  initButtons() {
    const buttons: TcSmartButton[] = [];

    buttons.push({
      label: 'cancel',
      action: this.onClose.bind(this),
      class: 'btn-secondary dialog-button',
    });

    buttons.push({
      label: 'validate-encaissement',
      ionIcon: 'checkmark',
      action: this.onSubmit.bind(this),
      class: 'btn-primary dialog-button',
    });

    this.buttonsList = buttons;
  }
}
