import { Component } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-login-mfa-page',
  templateUrl: './login-mfa-page.component.html',
  styleUrls: ['./login-mfa-page.component.scss'],
})
/**
 * Represents the Login MFA page component.
 * Extends the TcPage class.
 */
export class LoginMfaPageComponent extends TcPage {
  constructor() {
    super();
  }
}
