import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FilterTypesEnum,
  ListOrder,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcTranslateService,
} from '@tc/core';
import { DataType } from 'breeze-client';
import { TcLocalStorageService } from '@tc/local-storage';
import { LocalStorageKeys } from '../../../../../typings/local-storage-keys.enum';
import { updateStockGridColumns } from '../../../store/stock.actions';
import { DepotStockType } from '../../../../../typings/depot.enum';
import { ArticleService } from '../../../../../services/article.service';
import { TcBreezeService } from '@tc/breeze';
import { getFormatedVehicleName } from '../../../../../../custom/shared/util';
import { setTcDataSort } from '@tc/data-store';
import { numericComparator } from '../../../../../shared/util';

@Component({
  selector: 'app-consultation-stock-grid',
  templateUrl: './consultation-stock-grid.component.html',
  styleUrls: ['./consultation-stock-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationStockGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'consultation-stock-grid';

  private currentVehicleCode: string;

  constructor(
    store$: Store<any>,
    private readonly localStorage: TcLocalStorageService,
    private readonly translate: TcTranslateService,
    private readonly articleService: ArticleService,
    private readonly breeze: TcBreezeService
  ) {
    super(store$);
  }

  async ngOnInit() {
    this.currentVehicleCode = await this.localStorage.get(
      LocalStorageKeys.LastLoginVehicleKey
    );

    const depots = await this.articleService.getStockConsultationDepots();

    const xpertSecteur = depots.find((depot) =>
      depot.emplacement.some((empl) => empl.code === this.currentVehicleCode)
    );

    const stock = async () => {
      let articles = await this.articleService.getArticleStockData();

      // Display only the articles that have stock on at least one stock column
      articles = articles.filter((article) => {
        const stockColumns = [article.myVehicleStock, article.mySecteurStock];

        return stockColumns.some((articleStock) => articleStock > 0);
      });

      return articles;
    };

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ArticleStock',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Article',
          data: stock,
          breezeStructuralTypeExtension: {
            myVehicleStock: DataType.Int32,
            myContainerStock: DataType.Int32,
            mySecteurStock: DataType.Int32,
          },
        },
      },
      columns: [
        {
          field: 'reference',
          maxWidth: 120,
        },
        {
          field: 'intitule',
          flex: 1,
        },
        {
          field: 'conditionnementXpert',
          width: 100,
          maxWidth: 100,
        },
        {
          field: 'myVehicleStock',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          headerValueGetter: () =>
            `${this.translate.instant(
              'consultation-stock-grid.header.myVehicleStock'
            )} (${this.translate.instant('quantity')})`,
          comparator: numericComparator,
        },
        {
          field: 'mySecteurStock',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          headerValueGetter: () =>
            `${this.translate.instant('StockSecteur')} ${
              xpertSecteur.code
                ? xpertSecteur.nom + ' - ' + xpertSecteur.code
                : xpertSecteur.nom
            } (${this.translate.instant('quantity')})`,
          comparator: numericComparator,
        },
      ],
      filterConfig: await this.getFilterConfig(),
    };

    super.ngOnInit();

    // Order by alphabetical order
    setTimeout(() => {
      this.store$.dispatch(
        setTcDataSort({
          storeKey: this.storeKey + '.filter-availableStocks',
          sort: { key: 'nom', order: ListOrder.Asc },
        })
      );
    });
  }

  async getFilterConfig() {
    let depots = await this.articleService.getStockConsultationDepots();

    const vehicles = depots
      .reduce((acc, item) => acc.concat(item.emplacement), [])
      .filter((item) => item.code !== 'DEFAUT')
      .map((item) => ({
        ...item,
        // In order to make blend depots & vehicles the vehicles need the following 2 properties to not affect the original depot flow
        nom: getFormatedVehicleName(item),
        numero: `vehicle-${item.code}`,
      }));

    depots = depots.map((depot) => ({
      ...depot,
      nom: `${
        depot.typeDeStock.toLowerCase() ===
        DepotStockType.CuveXpert.toLowerCase()
          ? `${this.translate.instant('xpert')} `
          : ''
      }${depot.code ? depot.nom + ' - ' + depot.code : depot.nom}`,
    }));

    // Remove current sector from options
    const xpertSecteurIndex = depots.findIndex((depot) =>
      depot.emplacement.some((empl) => empl.code === this.currentVehicleCode)
    );

    if (xpertSecteurIndex >= 0) depots.splice(xpertSecteurIndex, 1);

    // Remove current car from options
    const currentVehicleOptionIndex = vehicles.findIndex(
      (vehicle) => vehicle.code === this.currentVehicleCode
    );

    if (currentVehicleOptionIndex >= 0)
      vehicles.splice(currentVehicleOptionIndex, 1);

    const stockColumnsOptions = [...depots, ...vehicles];

    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'mainFilter',
                  className: 'search-icon-filter',
                  templateOptions: {
                    filterOn: ['reference', 'intitule'],
                    filterMultiWord: true,
                    appearance: 'outline',
                  },
                  colSpan: 4,
                }),
                formlyControl({
                  key: 'availableStocks',
                  type: TcFormlyComponent.TcMultiSelect,
                  templateOptions: {
                    appearance: 'outline',
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'StockChoices',
                      fields: 'nom',
                      dynamicCollectionFrom: {
                        breezeStructuralType: 'refDepot',
                        data: stockColumnsOptions,
                      },
                    },
                    // Start loading items even if the user didn't type anything
                    autocompleteMinLength: 0,
                    // In order to make sure we load all the options
                    maxOptionsNumber: 10000,
                    closeMatIcon: 'close',
                    action: updateStockGridColumns,
                    filterOptionsType: FilterTypesEnum.Contains,
                  },
                  colSpan: 8,
                }),
              ],
            }),
          ],
        }),
      ],
    };
  }

  async clearStockEntities() {
    const collections = await this.breeze.getCollectionsFromMetadata();
    const stockCollections = collections
      .filter((collection) => collection.startsWith('ArticleStock'))
      .map((collection) => collection.replace(':#dynamic', ''));
    stockCollections.forEach(
      async (collection) =>
        await this.breeze.clearOfflineCollectionData(collection)
    );
  }

  ngOnDestroy(): void {
    this.clearStockEntities();
  }
}
