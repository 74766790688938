import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Store, select } from '@ngrx/store';
import { FieldType } from '@ngx-formly/material/form-field';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  getTcData,
  loadTcData,
  NgRxTcDataState,
} from '@tc/data-store';
import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { isObservable } from 'rxjs/internal/util/isObservable';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as R from 'ramda';

/**
 * This component is a copy of the FormlyFieldSelect (V) compoennt form formly with all it's functionalities
 * + a dataSource functionality
 * + allow a empty value option ( {value: '', label: 'N/A'} ) functionality
 */
@Component({
  selector: 'tc-formly-smart-select',
  templateUrl: './tc-formly-smart-select.component.html',
  styleUrls: ['./tc-formly-smart-select.component.scss'],
})
export class TcFormlySmartSelectComponent extends FieldType implements OnInit {
  /**
   * @ignore
   * Base formly functionality from version v5.6.1 that got removed in later verions.
   * Needs to be declared in order to allow an empty value (option: {value: '', label: 'N/A'}).
   * Otherwise the input label collapses ofer the option label.
   */
  @ViewChild(MatSelect, { static: true }) formFieldControl!: MatSelect;
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;

  /**
   * @ignore
   * Base formly functionality
   * The default options of the component passed through FieldType
   */
  defaultOptions = {
    templateOptions: {
      options: [],
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
      /**
       * default value as the mat-select doesn't support lazy load and until it is developed
       * we must load everything. I chose 1000 because I have to specify it otherwise it will default to
       * the default page size (200). 1000 options in a list is already too much but for now it will have to do.
       * Also any number bigger than this will get reduced to 1000 as we have a limit set in the backend.
       */
      take: 1000,
    },
  };

  /**
   * @ignore
   * Base formly functionality
   */
  private selectAllValue!: { options: any; value: any[] };

  /**
   * @ignore
   * Base formly functionality
   */
  getSelectAllState(options: any[]) {
    if (this.empty || this.value.length === 0) {
      return null;
    }

    return this.value.length !== this.getSelectAllValue(options).length
      ? 'indeterminate'
      : 'checked';
  }

  /**
   * @ignore
   * Base formly functionality
   */
  toggleSelectAll(options: any[]) {
    const selectAllValue = this.getSelectAllValue(options);
    this.formControl.setValue(
      !this.value || this.value.length !== selectAllValue.length
        ? selectAllValue
        : []
    );
    this.formControl.markAsDirty();
  }

  /**
   * @ignore
   * Base formly functionality
   * Calls the change function specified by the user in case there is one.
   * @param $event
   */
  change($event: MatSelectChange) {
    this.to.change?.(this.field, $event);
  }

  /**
   * @ignore
   * Base formly functionality
   */
  _getAriaLabelledby() {
    if (this.to.attributes?.['aria-labelledby']) {
      return this.to.attributes['aria-labelledby'] as string;
    }

    return this.formField?._labelId;
  }

  /**
   * @ignore
   * Base formly functionality
   */
  _getAriaLabel() {
    return this.to.attributes?.['aria-label'] as string;
  }

  /**
   * @ignore
   * Base formly functionality
   */
  private getSelectAllValue(options: any[]) {
    if (!this.selectAllValue || options !== this.selectAllValue.options) {
      const flatOptions: any[] = [];
      options.forEach((o) =>
        o.group ? flatOptions.push(...o.group) : flatOptions.push(o)
      );

      this.selectAllValue = {
        options,
        value: flatOptions.filter((o) => !o.disabled).map((o) => o.value),
      };
    }

    return this.selectAllValue.value;
  }

  //  **** Custom login starts here ****

  /**
   * Observable of the data store
   */
  private dataStore$: Observable<NgRxTcDataState>;

  /**
   * Field used as option label
   */
  public labelFieldName: string;

  /**
   * The name of the field for which to consider the value (Optional)
   */
  public valueFieldName?: string;

  /**
   * The label to appear as selected when default value is set
   */
  public defaultValueLabel?: string;

  /**
   * The default value that the control should have
   */
  public defaultValue?: string;

  /**
   * The items from the data store / template options
   */
  public items: any[];

  /**
   * Current filter value for searching options
   */
  public filter: string | undefined = undefined;

  /**
   * Subscription
   */
  private subscription: Subscription = new Subscription();

  /**
   * If the value is missing in the list of items, reset the field
   */
  resetIfValueMissing: boolean = false;

  /**
   * Custom labels for the select options
   */
  public labelFn?: (item: any) => string;

  /**
   * @ignore
   */
  constructor(private readonly store$: Store<any>) {
    super();
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    super.ngOnInit();

    // If we have a data provider we need to load the data into the store and
    // subscribe to it. Otherwise this component acts like a regular FormlySelect component.
    if (this.to.dataProvider) {
      const {
        storeKey,
        dataProvider: { fields: defaultLabelFieldName },
        labelFieldName,
        valueFieldName,
        defaultValueLabel,
        defaultValue,
        labelFn,
        resetIfValueMissing,
      } = this.to;

      this.defaultValue = defaultValue;
      this.defaultValueLabel = defaultValueLabel;
      this.labelFieldName = labelFieldName || defaultLabelFieldName;
      this.valueFieldName = valueFieldName;
      this.resetIfValueMissing = resetIfValueMissing;
      this.labelFn = labelFn;

      this.loadData();

      if (this.isDefaultValueDefined() && !this.valueFieldName) {
        this.value = this.getDefaultValue();
      }

      this.subscription.add(
        selectByKey(getTcData, this.dataStore$, storeKey).subscribe(
          (options) => {
            const val = this.value; // Get current value before updating items since they could already be defined
            if (this.isDefaultValueDefined()) {
              this.items = [this.getDefaultValue(), ...options];
            } else {
              this.items = options;
            }
            if (!this.valueFieldName) {
              // Reset the current value to the previously selected item only if the valueFieldName is not defined -> setting object as value
              this.value = this.items.find(
                (i) => i?.[this.valueFieldName] === val?.[this.valueFieldName]
              );
            }
            // If the value is not in the list of items anymore, reset the field. That means the new list of options does not contain the previously selected value.
            // Added as a option because it could cause regressions in some cases : the value may be defined before the options are loaded or valueFieldName may be defined but the value is a plain array of strings, not an object. There were too many corner cases to make it the default behavior.
            // Even so, we need a way to reset the field if the value is not in the list of items anymore.
            if (
              this.resetIfValueMissing &&
              this.value &&
              this.items &&
              this.valueFieldName &&
              !this.items.find((i) => i[this.valueFieldName] === this.value)
            ) {
              this.formControl.reset();
            }
          }
        )
      );
    }

    // If the data comes from an observable or a hardcoded config, fill the items property with the data.
    if (this.to.options) {
      if (isObservable(this.to.options)) {
        this.subscription.add(
          this.to.options.subscribe((options) => {
            this.items = options;
          })
        );
      } else {
        // Hardcoded data, use it directly
        this.items = this.to.options;
      }
    }
  }

  private isDefaultValueDefined(): boolean {
    return (
      this.defaultValue !== undefined &&
      this.defaultValue !== null &&
      this.defaultValueLabel !== undefined &&
      this.defaultValueLabel !== null
    );
  }

  private getDefaultValue(): any {
    return {
      [this.labelFieldName]: this.defaultValueLabel,
      [this.valueFieldName]: this.defaultValue,
    };
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription?.unsubscribe();
  }

  /**
   * Dispatches the loadTcData action thus making a request
   * to the server for the options and loading the into the store.
   */
  loadData() {
    this.store$.dispatch(
      loadTcData({
        storeKey: this.to.storeKey,
        skip: 0,
        take: this.to.take,
      })
    );
  }

  /**
   * Returns what is emited by the select when the an option is selected
   * @param item i.e. selected option
   * @returns Default value is the whole option. But if the multiselect
   * the distinct property in the dataSource that it return just the value of the distinct option
   */
  itemValue(item) {
    if (this.to.dataProvider.distinct) {
      return item[this.valueFieldName || this.labelFieldName];
    }

    return item[this.valueFieldName] ?? item;
  }

  /**
   * Set an internal filter to filter options
   * @param value String to search
   */
  setFilter(event) {
    if (event.target.value && event.target.value.trim() !== '') {
      this.filter = event.target.value.toLowerCase();
    } else {
      this.filter = undefined;
    }

    // If enter key was pressed and you found only one result, autoselect it (MTU request for barcode scan)
    if (event.keyCode === ENTER) {
      const results = this.getFilteredItems();
      if (results.length === 1) {
        const item = R.clone(results[0]);
        let value;
        if (this.to.dataProvider) {
          value = this.itemValue(item);
        } else {
          value = item.value;
        }
        this.formControl.setValue(value);
        this.formControl.markAsTouched();

        // Manually trigger change event if set
        if (this.to.change) {
          // Mock a MatSelectChange event
          const event = { source: this, value: value };
          this.to.change(this.field, event);
        }
        this.formFieldControl.close();
      }
    }
    event.stopPropagation();
  }

  /**
   * Get the filtered items based on the filter in memory. If no filter, will return the full array.
   * @returns any[] items
   */
  getFilteredItems(): any[] {
    // If a filter is set, use it to filter the result list
    if (this.filter) {
      const results = this.items.filter((option) => {
        let label;

        if (this.labelFn) {
          label = this.labelFn(option);
        } else if (typeof option === 'object') {
          label = option?.label;
        } else {
          label = option;
        }

        return label?.toLowerCase().includes(this.filter);
      });
      return results;
    }

    // Default behavior, returns everything
    return this.items;
  }

  /**
   * Clear the input filter value in the component and in the input field
   * @param clickEvent
   */
  onClearClicked(clickEvent: Event): void {
    this.filter = undefined;
    this.filterInput.nativeElement.value = '';

    clickEvent.stopPropagation();
  }

  /**
   * Handle the open panel event when select is opened
   */
  opened() {
    if (this.to.filter && this.to.focusOnFilter) {
      this.filterInput.nativeElement.focus();
    }
  }
}
