import { Component } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-login-oauth-failed-page',
  templateUrl: './login-oauth-failed-page.component.html',
  styleUrls: ['./login-oauth-failed-page.component.scss'],
})
/**
 * Represents a component for the login OAuth failed page.
 * Extends the TcPage class.
 */
export class LoginOauthFailedPageComponent extends TcPage {
  constructor() {
    super();
  }
}
