import { createAction, props } from '@ngrx/store';
import { TcSmartButton } from '@tc/buttons';

export const initHome = createAction('[Home] Init Home');

export const setHomeButtonsLists = createAction(
  '[Home] Set Home Buttons Lists',
  props<{
    elevageButtonsList: TcSmartButton[];
    stockButtonsList: TcSmartButton[];
    xpertButtonsList: TcSmartButton[];
  }>()
);
