import { Component, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { TcMenuItem } from '@tc/core';
import { getAbilities } from '@tc/permissions';
import { TcPermissionAction } from '../../../../auth/enums/tc-permission-action.enum';
import { impersonate, logout } from '../../../../auth/store/auth.actions';
import { getAuthenticationState } from '../../../../auth/store/auth.selectors';

import { AbstractLayoutComponent } from '../abstract-layout/abstract-layout.component';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
/**
 * Represents a layout component that extends the AbstractLayoutComponent and implements the OnInit interface.
 */
export class LayoutComponent extends AbstractLayoutComponent implements OnInit {
  private readonly userMenuItemNames = {
    logout: 'logout',
    impersonate: 'impersonate'
  };
  private readonly logoutMenuItem: TcMenuItem = {
    name: this.userMenuItemNames.logout,
    icon: 'power_settings_new',
  };

  private readonly impersonateMenuItem: TcMenuItem = {
    name: this.userMenuItemNames.impersonate,
    icon: 'group',
  };
  private readonly userMenuItems = [
    {
      name: this.userMenuItemNames.logout,
      icon: 'power_settings_new',
    },
  ];

  /**
   * Initializes the component by subscribing to the authentication state and abilities
   * from the store. Sets the `isImpersonation` flag based on the `loginAs` property in the
   * authentication state. Calls the `initUserMenu` method to initialize the user menu.
   * @returns None
   */
  ngOnInit(): void {
    super.ngOnInit();

    this.store$
      .pipe(select(getAuthenticationState))
      .subscribe(async (authState) => {
        this.isImpersonation = !!authState.loginAs;
        this.initUserMenu();
      });

    this.store$.pipe(select(getAbilities)).subscribe(async (abilities) => {
      if (!abilities) {
        return;
      }
      this.hasImpersonateAbility = abilities.can(
        TcPermissionAction.Impersonate,
        null
      );
      this.initUserMenu();
    });

    this.initUserMenu();
  }

  /**
   * Initializes the user menu by setting up the menu items based on the current user's
   * permissions and settings.
   * @returns None
   */
  private initUserMenu() {
    this.currentUserMenu.items = [];

    // Display logOut menu if enabled in config or we have impersonation
    if (!this.isLogoutDisabled || this.isImpersonation) {
      this.currentUserMenu.items.push(this.logoutMenuItem);
    }
    
    if (this.hasImpersonateAbility) {
      this.currentUserMenu.items.push(this.impersonateMenuItem);
    }

    this.isUserMenuDisabled = this.currentUserMenu.items.length === 0;

    this.currentUserMenu.onMenuItemClick = (item: TcMenuItem) => {
      if (item.name === this.userMenuItemNames.logout) {
        this.store$.dispatch(logout());
      }
      if (item.name === this.userMenuItemNames.impersonate) {
        this.store$.dispatch(impersonate());
      }
    };
  }
}
