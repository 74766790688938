import { Component } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
/**
 * Represents the Users Page Component, which extends the TcPage class.
 * This component is responsible for rendering the users page.
 */
export class UsersPageComponent extends TcPage {
  constructor() {
    super();
  }
}
