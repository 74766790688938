import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { TcVersionModule } from '@tc/version';
import { TcButtonsModule } from '@tc/buttons';
import { LivraisonGridComponent } from './components/smart/livraison-grid.component.ts/livraison-grid.component';
import { LivraisonDetailGridComponent } from './components/smart/livraison-detail-grid/livraison-detail-grid.component';
import { EffectsModule } from '@ngrx/effects';
import { LivraisonEffects } from './store/livraison.effects';
import { StoreModule } from '@ngrx/store';
import { livraisonReducer } from './store/livraison.reducer';
import { TcSmartFormModule } from '@tc/smart-form';

const components = [LivraisonGridComponent, LivraisonDetailGridComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    TcSmartFormModule,
    TcVersionModule,
    TcButtonsModule,
    EffectsModule.forFeature([LivraisonEffects]),
    StoreModule.forFeature('livraison', livraisonReducer),
  ],
  exports: [...components],
})
export class LivraisonModule {}
