import {
  Injectable,
  ErrorHandler,
  Injector,
  Inject,
  isDevMode,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TcLoggerService } from '../services/tc-logger.service';
import { TcNotificationService } from '../services/tc-notification.service';
import { TcSpinnerService } from '../../tc-store/services/tc-spinner.service';
import { ConfigService } from '../../../../../../apps/frontend/src/app/shared/services/config.service';
import { ConfigKeys } from '../../../../../../apps/frontend/src/app/shared/interfaces/config.interface';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class TcErrorHandler implements ErrorHandler {
  constructor(
    @Inject(Injector) private injector: Injector,
    public configService: ConfigService
  ) {}

  private get logger(): TcLoggerService {
    return this.injector.get(TcLoggerService);
  }

  private get notification(): TcNotificationService {
    return this.injector.get(TcNotificationService);
  }

  private get spinner(): TcSpinnerService {
    return this.injector.get(TcSpinnerService);
  }

  handleError(error: Error | HttpErrorResponse) {
    // Get exclusion list from config
    const exclusionList =
      (this.configService.get(
        ConfigKeys.tcErrorHandlerExclusionList
      ) as string[]) || [];

    // Log the error before doing anything else. Because the spinner and the toaster message can also send a error.
    // We don't want to lose the initial breaking error.
    this.logger.error('An error occurred!', error);
    if (error) {
      if (error.message) {
        if (
          !error.message.includes('ServiceWorker') &&
          !error.message.includes(
            'ExpressionChangedAfterItHasBeenCheckedError'
          ) &&
          !exclusionList.some((excludedString) =>
            error.message.includes(excludedString)
          )
        ) {
          if (error instanceof Error) {
            // Reset spinner so that the user can continue navigating the app
            this.spinner.resetSpinner();

            // Show error message
            const errMessage = error.message;
            this.showError(errMessage);
          }
        }
      }
    }
  }

  showError(errMessage: string) {
    if (isDevMode()) {
      this.notification.error(errMessage, null, {
        closeButton: true,
        timeOut: 0,
      });
    } else {
      this.notification.error(errMessage);
    }
  }
}
