import { createAction, props } from '@ngrx/store';
import {
  UpdateGridColumnsPayload,
  CreateEstimateFromCatalogPayload,
  CreateOrderFromCatalogPayload,
} from './catalog-article.payloads';
import { ElevageDocument } from '../../elevage/interfaces/elevage-document.interface';
import { ArticleOrigin } from '../../commande/store/commande.payloads';

export const updateCatalogGridColumns = createAction(
  '[Catalog] Update Grid Columns',
  props<UpdateGridColumnsPayload>()
);

export const createEstimateFromCatalog = createAction(
  '[Catalog] Create Estimate From Catalog',
  props<CreateEstimateFromCatalogPayload>()
);

export const createOrderFromCatalog = createAction(
  '[Catalog] Create Order From Catalog',
  props<CreateOrderFromCatalogPayload>()
);

export const createEstimateFromDose = createAction(
  '[Catalog] Create Estimate From Dose',
  props<CreateEstimateFromCatalogPayload>()
);

export const createOrderFromDose = createAction(
  '[Catalog] Create Order From Dose',
  props<CreateOrderFromCatalogPayload>()
);

export const createOrderFromEstimate = createAction(
  '[Catalog] Create Order From Estimate',
  props<CreateEstimateFromCatalogPayload>()
);

export const setCurrentDocument = createAction(
  '[Catalog] Set Or Clear Document For Order/Estimate',
  props<{ document: ElevageDocument | null; articleOrigin: ArticleOrigin }>()
);

export const setCanMakeEstimate = createAction(
  '[Catalog] Set State of Estimate Button',
  props<{ canMakeEstimate: boolean }>()
);

export const setCanMakeOrder = createAction(
  '[Catalog] Set State of Order Button',
  props<{ canMakeOrder: boolean }>()
);

export const recalculatePrice = createAction('[Catalog] Recalculate Price');

export const resetCatalogArticleState = createAction(
  '[Catalog] Reset State',
  props<{ redirectTo?: string }>()
);

export const redirectToArticleOrigin = createAction(
  '[Catalog] Redirect To Article Origin',
  props<{
    reset?: boolean;
    devisDocNum?: string;
    orderDocNum?: string;
    storeKey?: string;
  }>()
);
