import { Component, OnInit } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
/**
 * Represents the home page component of the application.
 * Extends the TcPage class and implements the OnInit interface.
 */
export class HomePageComponent extends TcPage implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
