import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TermsOfUseValidation } from '../../modules/terms-conditions/interfaces/terms-of-use-validation.interface';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
/**
 * A service class for managing terms of service entities.
 */
export class TermsService extends CrudService {
  constructor(public config: ConfigService, public httpClient: HttpClient) {
    super(CustomEntitiesEnum.Terms, httpClient, config);
  }

  /**
   * Accepts the terms of use for a given terms ID.
   * @param {number} termsId - The ID of the terms to accept.
   * @returns {Promise<TermsOfUseValidation>} - A promise that resolves to the validation result of accepting the terms.
   */
  async accept(termsId: number): Promise<TermsOfUseValidation> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<TermsOfUseValidation>(`${url}/${termsId}/validation`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }

  /**
   * Checks the last entity using an HTTP GET request to the entity service endpoint.
   * @returns {Promise<any>} A promise that resolves with the response from the server.
   * @throws {Error} If the request fails or encounters an error.
   */
  async checkLast() {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .get(`${url}/last`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }
}
