import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-terms-submit-form',
  templateUrl: './terms-submit-form.component.html',
  styleUrls: ['./terms-submit-form.component.scss'],
})
/**
 * Represents a component for submitting terms.
 * @extends TcFormComponent<any>
 * @implements OnInit
 */
export class TermsSubmitFormComponent
  extends TcFormComponent<any>
  implements OnInit
{
  @Output() accept: EventEmitter<void> = new EventEmitter();

  public form: FormGroup;

  constructor(
    public readonly elem: ElementRef,
    public readonly translate: TcTranslateService
  ) {
    super(translate, elem);

    this.initFormFields();
  }

  ngOnInit() {
    this.model = { accept: false };
  }

  public submit() {
    if (this.form.valid) {
      this.accept.emit();
    }
  }

  private initFormFields() {
    this.fields = [
      {
        key: 'accept',
        type: 'checkbox',
        templateOptions: {
          type: 'checkbox',
          label: this.translate.instant('terms-submit-form.labels.accept'),
        },
      },
    ];
  }
}
