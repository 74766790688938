import { createAction, props } from '@ngrx/store';
import {
  EditEstimateArticlesPayload,
  SaveEstimatePayload,
} from './devis.payloads';

export const editEstimateArticles = createAction(
  '[Estimate] Edit Estimate Articles',
  props<EditEstimateArticlesPayload>()
);

export const saveEstimate = createAction(
  '[Estimate] Save Estimate',
  props<SaveEstimatePayload>()
);

export const updateEstimateDocument = createAction(
  '[Estimate] Update Estimate Document',
  props<{ storeKey: string }>()
);

export const updateEstimateLine = createAction(
  '[Estimate] Update Estimate Line',
  props<{ storeKey: string; rowData: any }>()
);

export const removeEstimateLine = createAction(
  '[Estimate] Remove Estimate Line',
  props<{ storeKey: string; rowData: any }>()
);

export const recalculatePrice = createAction(
  '[Estimate] Recalculate Estimate Price'
);
