import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TcSmartComponent } from '@tc/abstract';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Elevage } from '../../../interfaces/elevage.interface';
import {
  getCurrentElevage,
  getShowElevageContactRoutes,
} from '../../../store/elevage.selectors';

@Component({
  selector: 'app-elevage-contact',
  templateUrl: './elevage-contact.component.html',
  styleUrls: ['./elevage-contact.component.scss'],
})
export class ElevageContactComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  subscription = new Subscription();
  elevage: Elevage;

  showElevageContact: boolean;

  constructor(store$: Store<any>, private router: Router) {
    super(store$);
  }

  ngOnInit(): void {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);

    const routeWithoutParameters = this.getRouteWithoutParameters(
      this.router.url
    );
    this.setElevageContactDisplay(routeWithoutParameters);
    this.watchRouteChanges();
  }

  private async setElevageContactDisplay(route: string) {
    const routes = await this.store$
      .select(getShowElevageContactRoutes)
      .pipe(take(1))
      .toPromise();

    if (this.elevage && routes.includes(route)) this.showElevageContact = true;
    else this.showElevageContact = false;
  }

  private watchRouteChanges() {
    const routeSubscription = this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        const routeWithoutParameters = this.getRouteWithoutParameters(
          route.url
        );
        this.setElevageContactDisplay(routeWithoutParameters);
      }
    });

    this.subscription.add(routeSubscription);
  }

  private getRouteWithoutParameters(route: string) {
    const urlTree = this.router.parseUrl(route);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
