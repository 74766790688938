import { UnsavedChanges } from '../../article/interfaces/unsaved-changes.interface';

export interface XrOptimaState {
  docButtonsState: XrOptimaDocButtonState;
}

export interface XrOptimaDocButtonState {
  canMakeOrder: boolean;
}

export const initialXrOptimaState: XrOptimaState = {
  docButtonsState: {
    canMakeOrder: false,
  },
};
