import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  FilterTypesEnum,
  ITcDataService,
  ListOrder,
  TcConfigTypes,
  TcDataProviderType,
  TcFilterItem,
  TcSmartFilterConfig,
} from '@tc/abstract';

import { TcSmartGridComponent } from '@tc/advanced-components';
import { Subscription } from 'rxjs';
import {
  Elevage,
  HistoriqueAchatsAppros,
} from '../../../../elevage/interfaces/elevage.interface';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import * as R from 'ramda';
import {
  DEFAULT_TC_GRID_STATE_KEY,
  formlyColumn,
  formlyComponent,
  formlyControl,
  formlyRow,
  getTcGridSelection,
  TcFormlyComponent,
} from '@tc/core';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { selectByKey } from '@tc/store';
import { TcButtonsListComponent } from '@tc/buttons';
import { getXrOptimaOrderBtnDisabledState } from '../../../store/xroptima.selectors';
import {
  createOrderFromXrOptima,
  setXrOptimaCanMakeOrder,
} from '../../../store/xroptima.actions';
import { ActivatedRoute } from '@angular/router';
import { DEFAULT_TC_DATA_STATE_KEY, TcDataService } from '@tc/data-store';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { getCurrentDocument } from '../../../../article/store/catalog-article.selectors';
import { DAS } from '../../../../../typings/DAS.enum';
import { XpertService } from '../../../../../services/xpert.service';

@Component({
  selector: 'xroptima-purchase-history-grid',
  templateUrl: './xroptima-purchase-history-grid.component.html',
  styleUrls: ['./xroptima-purchase-history-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XrOptimaPurchaseHistoryGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  private readonly articleDataService: ITcDataService<any> =
    this.dataService.getService('Article', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
    });

  storeKey = 'xroptima-purchase-history-grid';

  subscription = new Subscription();
  elevage: Elevage;
  orderDocNum: string = null;
  xrOptimaDocument: ElevageDocument | null = null;

  gridStore$ = this.store$.pipe(
    select(DEFAULT_TC_GRID_STATE_KEY),
    filter(hasValue),
    distinctUntilChanged()
  );

  dataStore$ = this.store$.pipe(
    select(DEFAULT_TC_DATA_STATE_KEY),
    filter(hasValue),
    distinctUntilChanged()
  );

  isOrderButtonDisabled = false;

  constructor(
    protected store$: Store<any>,
    private readonly activatedRoute: ActivatedRoute,
    private dataService: TcDataService,
    private readonly xpertService: XpertService
  ) {
    super(store$);

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);

    const documentSubscription = this.store$
      .pipe(select(getCurrentDocument))
      .subscribe((document) => {
        this.xrOptimaDocument = document;
      });

    this.subscription.add(documentSubscription);
  }

  private async getXrOptimaAppros(): Promise<any[]> {
    const historiqueAchatsAppros =
      (R.clone(this.elevage.historiqueAchatsAppros) as any[]) || [];
    if (historiqueAchatsAppros && historiqueAchatsAppros.length > 0) {
      // Default filters
      const articleFilters: TcFilterItem[] = [
        {
          key: 'reference',
          value: historiqueAchatsAppros
            .map((obj) => obj.referenceArticle)
            .join(','),
          filterType: FilterTypesEnum.In,
        },
        {
          key: 'DAS.intitule',
          value: DAS.XROptima,
          filterType: FilterTypesEnum.Equal,
        },
      ];

      const collaboratorsFilters =
        await this.xpertService.getConnectedXpertArticlesFilters();
      for (const collaboratorFilter of collaboratorsFilters) {
        articleFilters.push(collaboratorFilter);
      }

      // Query to find all articles in the appro that have DAS XR OPTIMA.
      const articles = await this.articleDataService.getData(0, null, {
        filters: articleFilters,
      });
      const dasArticlesReferences = articles.data.map((item) => item.reference);

      // Filter back the historiqueAchatsAppros with only the references from DAS XR OPTIMA
      const xrOptimaAppros = historiqueAchatsAppros.filter((item) =>
        dasArticlesReferences.includes(item.referenceArticle)
      );
      return xrOptimaAppros;
    }

    return [];
  }

  async ngOnInit() {
    const purchases = await this.getXrOptimaAppros();
    this.orderDocNum = this.activatedRoute.snapshot.queryParams['orderDocNum'];

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'xroptima-purchase-history-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'XrOptimaPurchases',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Elevage.historiqueAchatsAppros',
          data: purchases,
        },
      },
      columns: [
        {
          field: 'numeroBL',
          minWidth: 180,
          maxWidth: 180,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          sortable: false,
          headerCheckboxSelection: this.elevage ? true : false,
          checkboxSelection: this.elevage ? true : false,
        },
        {
          field: 'dateBL',
          sort: ListOrder.Desc,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          maxWidth: 150,
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
        },
        {
          field: 'numeroFacture',
          maxWidth: 150,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          sortable: false,
        },
        {
          field: 'dateFacture',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          minWidth: 100,
          maxWidth: 150,
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
        },
        {
          field: 'gamme',
        },
        {
          field: 'referenceArticle',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
        {
          field: 'libelleArticle',
        },
        {
          field: 'quantiteLivreeFacturee',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          sortable: false,
        },
      ],
      filterConfig: this.getFilterConfig(),
      columnNumberPerDevice: {
        extraSmallDevice: 8,
        smallDevice: 8,
        mediumDevice: 8,
        largeDevice: 8,
        extraLargeDevice: 8,
        extraExtraLargeDevice: 8,
      },
    };

    super.ngOnInit();

    const selectedRowsSubscription = selectByKey(
      getTcGridSelection,
      this.gridStore$,
      this.storeKey
    ).subscribe(async (selectedRows: HistoriqueAchatsAppros[]) => {
      this.setOrderBtnState(selectedRows);
    });

    this.subscription.add(selectedRowsSubscription);
  }

  setOrderBtnState(selectedRows: any[]) {
    let canMakeOrder = false;

    if (this.xrOptimaDocument !== null) {
      canMakeOrder = true;
    } else {
      if (selectedRows.length > 0) {
        canMakeOrder = true;
      }
    }

    this.store$.dispatch(setXrOptimaCanMakeOrder({ canMakeOrder }));
  }

  getFilterConfig(): TcSmartFilterConfig {
    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: '_id',
                  type: TcFormlyComponent.FormlyInput,
                  hide: true,
                  templateOptions: {
                    filterType: FilterTypesEnum.Equal,
                  },
                }),
                formlyControl({
                  key: 'gamme',
                  templateOptions: {
                    appearance: 'outline',
                  },
                  colSpan: 3,
                }),
                formlyControl({
                  key: 'referenceArticle',
                  templateOptions: {
                    appearance: 'outline',
                  },
                  colSpan: 2,
                }),
                formlyControl({
                  key: 'libelleArticle',
                  templateOptions: {
                    appearance: 'outline',
                  },
                  colSpan: 2,
                }),
                formlyControl({
                  key: 'period',
                  type: TcFormlyComponent.TcDateRangePicker,
                  templateOptions: {
                    appearance: 'outline',
                    filterType: FilterTypesEnum.DateRangeFromString,
                    filterOn: ['dateBL', 'dateFacture'],
                  },
                  colSpan: 3,
                }),
                formlyComponent({
                  component: TcButtonsListComponent,
                  className: `sale-docs-btn-list ${
                    this.elevage ? '' : 'hidden'
                  }`,
                  componentData: {
                    buttonsList: [
                      {
                        label: 'make-order',
                        class: 'btn-primary btn-text',
                        action: createOrderFromXrOptima,
                        actionPayload: {
                          storeKey: this.storeKey,
                          orderDocNum: this.orderDocNum ?? undefined,
                        },
                        smartStateKey: 'xrOptima',
                        disableSelector: getXrOptimaOrderBtnDisabledState,
                        disableStoreKey: 'docButtonsState',
                      },
                    ],
                  },
                  colSpan: 2,
                }),
              ],
            }),
          ],
        }),
      ],
    };
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
