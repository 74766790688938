import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { TcMenuItem } from '@tc/core';
import { TcLayoutComponent } from '@tc/layout';
import { logout } from '../../../../../../modules/auth/store/auth.actions';
import { AbstractLayoutComponent } from '../../../../../../modules/layout/components/smart/abstract-layout/abstract-layout.component';
import {
  getLayoutHiddenSidenavRoutes,
  getVisibleXrOptimaLogoRoutes,
} from '../../../store/layout.selectors';
import { Subscription } from 'rxjs';
import { select } from '@ngrx/store';

@Component({
  selector: 'app-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss'],
})
export class CustomLayoutComponent
  extends AbstractLayoutComponent
  implements OnInit, OnDestroy
{
  private readonly userMenuItemNames = {
    logout: 'logout',
  };
  private readonly userMenuItems = [
    {
      name: this.userMenuItemNames.logout,
      icon: 'power_settings_new',
    },
  ];

  private hiddenSidenavRoutes: string[];
  private visibleXrOptimaRoutes: string[];

  private subscription = new Subscription();

  currentUserLayout: TcLayoutComponent;
  @ViewChild('currentUserLayout', { static: true }) set appCurrentUserLayout(
    value: TcLayoutComponent
  ) {
    this.currentUserLayout = value;
  }

  /**
   * Boolean used to toggle the display of the sidebar menu
   */
  enableSidenav = true;
  /**
   * Boolean used to toggle the display of XrOptima Logo
   */
  showXrOptimaLogo = false;

  ngOnInit(): void {
    super.ngOnInit();

    this.initUserMenu();

    this.initLayoutElementStates();
    this.watchRouteChanges();
  }

  private initUserMenu() {
    // Check in config if logout is disabled
    // to add or no logout option
    this.currentUserMenu.items = this.isLogoutDisabled
      ? []
      : this.userMenuItems;

    this.currentUserMenu.onMenuItemClick = (item: TcMenuItem) => {
      if (item.name === this.userMenuItemNames.logout) {
        this.store$.dispatch(logout());
      }
    };
  }

  private async initLayoutElementStates() {
    const visibleXrOptimaSub = this.store$
      .pipe(select(getVisibleXrOptimaLogoRoutes))
      .subscribe((routes) => {
        this.visibleXrOptimaRoutes = routes;
      });

    const hiddenSidenavSub = this.store$
      .pipe(select(getLayoutHiddenSidenavRoutes))
      .subscribe((routes) => {
        this.hiddenSidenavRoutes = routes;
      });

    this.subscription.add(visibleXrOptimaSub);
    this.subscription.add(hiddenSidenavSub);

    const currentRoute = this.router.url;
    this.setSidenavDisplay(currentRoute);
    this.setXrOptimaLogoDisplay(currentRoute);
  }

  private async setXrOptimaLogoDisplay(route: string) {
    if (this.visibleXrOptimaRoutes.includes(route))
      this.showXrOptimaLogo = true;
    else this.showXrOptimaLogo = false;
  }

  private async setSidenavDisplay(route: string) {
    if (this.hiddenSidenavRoutes.includes(route)) this.enableSidenav = false;
    else this.enableSidenav = true;
  }

  private watchRouteChanges() {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.setSidenavDisplay(route.url);
        this.setXrOptimaLogoDisplay(route.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
