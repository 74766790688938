import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ServiceWorkerService } from './app/services/service-worker';
import { environment } from './environments/environment';


/**
 * Initializes the application in production mode if the environment is set to production.
 * Then, bootstraps the AppModule and initializes the ServiceWorkerService.
 * @returns None
 */
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => ServiceWorkerService.init())
  .catch((err) => console.error(err));
