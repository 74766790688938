import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { CustomRoutingModule } from './custom-routing.module';
import { LayoutModule } from '../modules/layout/layout.module';
import { CustomLayoutModule } from './modules/layout/custom-layout.module';
import { MenuModule } from './modules/menu/menu.module';
import { HomeModule } from './modules/home/home.module';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { EtlPageComponent } from './pages/etl-page/etl-page.component';
import { EtlXpert2SagePageComponent } from './pages/etl-xpert2sage-page/etl-xpert2sage-page.component';
import { EtlXpertExtractOnlyPageComponent } from './pages/etl-xpert-extract-only-page/etl-xpert-extract-only-page.component';
import { HomeComponent } from './pages/home-page/home.component';
import { ElevageDetailComponent } from './pages/elevage-detail/elevage-detail.component';
import { CustomLoginComponent } from './pages/login-page/custom-login.component';
import { ElevageModule } from './modules/elevage/elevage.module';
import { OfflineModeResolver } from './resolvers/offline-mode.resolvers';
import { TcButtonsModule } from '@tc/buttons';
import { ElevagePageComponent } from './pages/elevage-page/elevage-page.component';
import { TcSmartFormModule } from '@tc/smart-form';
import { DevisModule } from './modules/devis/devis.module';
import { DevisPageComponent } from './pages/devis-page/devis-page.component';
import { LivraisonDetailPageComponent } from './pages/livraison-detail-page/livraison-detail-page.component';
import { CommandesPageComponent } from './pages/commandes-page/commandes-page.component';
import { CommandeModule } from './modules/commande/commande.module';
import { FacturesPageComponent } from './pages/factures-page/factures-page.component';
import { CommandeDetailPageComponent } from './pages/commande-detail-page/commande-detail-page.component';
import { FactureModule } from './modules/facture/facture.module';
import { ElevageSelectedResolver } from './resolvers/elevage-selected.resolvers';
import { DevisDetailPageComponent } from './pages/devis-detail/devis-detail.component';
import { XpertPageComponent } from './pages/xpert-page/xpert-page.component';
import { XpertModule } from './modules/xpert/xpert.module';
import { XrOptimaPurchaseHistoryPageComponent } from './pages/xroptima-purchase-history/xroptima-purchase-history.component';
import { XrOptimaPurchaseModule } from './modules/xroptima-purchase/xroptima-purchase.module';
import { CatalogArticlesComponent } from './pages/catalog-articles/catalog-articles.component';
import { ArticleModule } from './modules/article/article.module';
import { ConsultationStockComponent } from './pages/consultation-stock/consultation-stock.component';
import { XpertService } from './services/xpert.service';
import { EncaissementService } from './services/encaissement.service';
import { PdfGeneratorService } from './services/pdf-generator.service';
import { StockModule } from './modules/stock/stock.module';
import { DocumentService } from './services/document.service';
import { LivraisonModule } from './modules/livraison/livraison.module';
import { EncaissementModule } from './modules/encaissement/encaissement.module';
import { LivraisonPageComponent } from './pages/livraison-page/livraison-page.component';
import { ElevageService } from './services/elevage.service';
import { XpertTransfertPageComponent } from './pages/xpert-transfert-page/xpert-transfert-page.component';
import { XpertDepositPageComponent } from './pages/xpert-deposit-page/xpert-deposit-page.component';
import { BankDepositPageComponent } from './pages/bank-deposit-page/bank-deposit-page.component';
import { DosesPageComponent } from './pages/doses-page/doses-page.component';
import { RestockPageComponent } from './pages/restock-page/restock-page.component';
import { RestockDetailPageComponent } from './pages/restock-detail-page/restock-detail-page.component';

const modules = [
  MenuModule,
  HomeModule,
  ElevageModule,
  DevisModule,
  CommandeModule,
  FactureModule,
  XpertModule,
  XrOptimaPurchaseModule,
  ArticleModule,
  StockModule,
  LivraisonModule,
  EncaissementModule,
];

const pages = [
  CustomLoginComponent,
  HomeComponent,
  EtlPageComponent,
  EtlXpert2SagePageComponent,
  EtlXpertExtractOnlyPageComponent,
  ForbiddenPageComponent,
  ElevagePageComponent,
  ElevageDetailComponent,
  DevisPageComponent,
  CommandesPageComponent,
  FacturesPageComponent,
  CommandeDetailPageComponent,
  LivraisonDetailPageComponent,
  DevisDetailPageComponent,
  XpertPageComponent,
  XrOptimaPurchaseHistoryPageComponent,
  BankDepositPageComponent,
  CatalogArticlesComponent,
  ConsultationStockComponent,
  LivraisonPageComponent,
  XpertDepositPageComponent,
  XpertTransfertPageComponent,
  DosesPageComponent,
  RestockPageComponent,
  RestockDetailPageComponent,
];

const components = [...pages];

const services = [
  XpertService,
  DocumentService,
  ElevageService,
  EncaissementService,
  PdfGeneratorService,
];

const resolvers = [OfflineModeResolver, ElevageSelectedResolver];

@NgModule({
  declarations: [...components, ...pages],
  imports: [
    ...modules,
    CommonModule,
    TcCoreModule,
    CustomRoutingModule,
    LayoutModule,
    CustomLayoutModule,
    TcButtonsModule,
    TcSmartFormModule,
  ],
  exports: [...components],
  providers: [...services, ...resolvers],
})
export class CustomModule {
  constructor() {}
}
