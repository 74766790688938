import { Depot } from './../../../../depot/interfaces/depot.interface';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  TcSmartFormConfig,
} from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import { TcSmartButton } from '@tc/buttons';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridReadyEvent,
  TcTranslateService,
} from '@tc/core';
import { Observable, Subscription } from 'rxjs';
import * as R from 'ramda';
import { DataType } from 'breeze-client';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
  TcDataService,
  loadTcDataSuccess,
} from '@tc/data-store';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { initTcSmartForm, setTcSmartFormModel } from '@tc/smart-form';
import { Actions, ofType } from '@ngrx/effects';
import { XpertMobileDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { DocumentLigne } from '../../../../elevage/interfaces/document-ligne.interface';
import { DepotStockType } from '../../../../../typings/depot.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { XpertService } from '../../../../../services/xpert.service';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';

@Component({
  selector: 'app-restock-detail-grid',
  templateUrl: './restock-detail-grid.component.html',
  styleUrls: ['./restock-detail-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RestockDetailGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'restock-detail-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  dataStore$: Observable<NgRxTcDataState>;
  buttonsList: TcSmartButton[];
  restockDocument: XpertMobileDocument | null;
  depot: Depot | null;
  subscription = new Subscription();
  numeroPiece: string;
  numeroDepot: string;

  private refresh: () => void;
  private refreshLaunched = false;

  private readonly articleDataService: ITcDataService<any> =
    this.dataService.getService('Article', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'depot',
    });

  private readonly depotDataService: ITcDataService<any> =
    this.dataService.getService('refDepot', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,emplacement',
    });

  constructor(
    store$: Store<any>,
    private activatedRoute: ActivatedRoute,
    public readonly translate: TcTranslateService,
    private readonly actions$: Actions,
    private readonly xpertService: XpertService,
    private readonly dataService: TcDataService,
    private readonly router: Router
  ) {
    super(store$);

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Function to sync form and grid data with the loaded document
   */
  public refreshFormAndGrid() {
    if (this.refresh) {
      if (this.refreshLaunched === false) {
        this.refreshLaunched = true;
        this.setFormValues();
        this.refresh();
      }
    }
  }

  public async onReady(event: TcGridReadyEvent) {
    this.refresh = event.refresh;
  }

  async ngOnInit() {
    this.numeroPiece = this.activatedRoute.snapshot.queryParams['numeroPiece'];
    this.numeroDepot = this.activatedRoute.snapshot.queryParams['numeroDepot'];

    const xpert = await this.xpertService.getConnectedXpert();
    // If the numeroDepot is not in the expert depots, we redirect to the list page
    if (
      !this.numeroDepot ||
      !this.numeroPiece ||
      !xpert.depot.find((depot) => depot.numero.toString() === this.numeroDepot)
    ) {
      // XAS-84 - Hide features
      // this.router.navigateByUrl(`/${CustomRoutes.Restock}`);
      return;
    }

    // Load the depot
    const { data: depotsSecteur } = await this.depotDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'typeDeStock',
            value: DepotStockType.StockSecteur,
            filterType: FilterTypesEnum.Equal,
          },
          {
            key: 'numero',
            value: this.numeroDepot,
            filterType: FilterTypesEnum.In,
          },
        ],
      }
    );

    this.depot = depotsSecteur[0];
    this.restockDocument = this.depot?.documents?.find(
      (item) => item.numeroPiece === this.numeroPiece
    );

    this.initHeader();

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'restock-detail-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      columnNumberPerDevice: {
        extraSmallDevice: 9,
        smallDevice: 9,
        mediumDevice: 9,
        largeDevice: 9,
        extraLargeDevice: 9,
        extraExtraLargeDevice: 9,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'RestockDetail',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document.ligne',
          data: this.getLines.bind(this),
          breezeStructuralTypeExtension: {
            datePeremention: DataType.String,
            numeroLot: DataType.String,
          },
        },
      },
      columns: [
        {
          field: 'article.reference',
          maxWidth: 270,
          sort: 'asc',
          comparator: (valueA, valueB) =>
            valueA && valueB
              ? valueA.localeCompare(valueB, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              : 1,
        },
        {
          field: 'libelle',
          minWidth: 250,
          cellRenderer: (params) =>
            `<span title="${params?.data?.libelle}">${params?.data?.libelle}</span>`,
        },
        {
          field: 'uniteVente',
          maxWidth: 120,
        },
        {
          field: 'numeroLot',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          maxWidth: 120,
        },
        {
          field: 'datePeremention',
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
          maxWidth: 270,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
        {
          field: 'quantite',
          width: 160,
          maxWidth: 160,
          cellClass: `quantityCell text-align-right`,
          headerClass: 'text-align-right',
          comparator: (num1, num2) => {
            return num1.toString().localeCompare(num2, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
      ],
    };

    super.ngOnInit();

    this.subscription.add(
      this.actions$.pipe(ofType(loadTcDataSuccess)).subscribe(() => {
        this.refreshLaunched = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  initHeader() {
    const formConfig: TcSmartFormConfig = {
      configType: TcConfigTypes.TcForm,
      storeKey: this.storeKey,
      autoSubmit: false,
      fieldConfigs: this.getHeaderFields(),
    };

    this.store$.dispatch(
      initTcSmartForm({
        storeKey: formConfig.storeKey,
        config: formConfig,
      })
    );

    this.setFormValues();
  }

  public setFormValues() {
    const formDefaultValue = {
      date:
        this.restockDocument?.date || new Date().toISOString().split('T')[0],
      adresse: this.depot.numero,
    };

    this.store$.dispatch(
      setTcSmartFormModel({
        storeKey: this.storeKey,
        model: formDefaultValue,
      })
    );
  }

  private getHeaderFields() {
    const formFieldConfigs: FormlyFieldConfig[] = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'date',
                className: 'date-picker',
                type: TcFormlyComponent.TcDatePicker,
                templateOptions: {
                  appearance: 'outline',
                  disabled: true,
                  matIcon: 'calendar_today',
                },
                smColSpan: 2,
                lgColSpan: 2,
                xlColSpan: 2,
                xxlColSpan: 2,
              }),
              formlyControl({
                key: 'adresse',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  appearance: 'outline',
                  storeKey: `${this.storeKey}.filter.adresse`,
                  disabled: true,
                  valueFieldName: 'value',
                  labelFieldName: 'label',
                  options: [
                    {
                      value: this.depot.numero,
                      label: `${
                        this.depot.code
                          ? this.depot.nom + ' - ' + this.depot.code
                          : this.depot.nom
                      }`,
                    },
                  ],
                },
                smColSpan: 3,
                lgColSpan: 3,
                xlColSpan: 3,
                xxlColSpan: 3,
              }),
            ],
          }),
        ],
      }),
    ];

    return formFieldConfigs;
  }

  public async getLines(): Promise<any[]> {
    const toDisplay = [];
    const lignes = R.clone(this.restockDocument?.ligne ?? []);
    if (
      this.restockDocument !== null &&
      this.restockDocument?.ligne?.length > 0
    ) {
      const articleFilter = lignes
        .map((ligne) => ligne.article.reference)
        .join(',');

      const { data: articles } = await this.articleDataService.getData(
        0,
        null,
        {
          filters: [
            {
              key: 'reference',
              value: articleFilter,
              filterType: FilterTypesEnum.In,
            },
          ],
        }
      );

      lignes.forEach((ligne: DocumentLigne) => {
        const updatedLigne = R.clone(ligne);
        const fullArticle = articles.find(
          (article) => article.reference === updatedLigne.article.reference
        );

        // Try to find the movement with the lot number and the peremption date in the article movements
        const articleDepot = fullArticle?.stock?.find(
          (depotItem) => depotItem.depot.numero === this.depot?.numero
        );
        if (articleDepot) {
          const movements = articleDepot.depot?.movement;
          if (movements && movements.length > 0) {
            // Find the movement that is matching the line number
            const lineMovement = movements.find(
              (movement) =>
                movement.ligneEntree.numeroInterne ===
                updatedLigne.numeroInterne
            );
            if (lineMovement) {
              updatedLigne.numeroLot = lineMovement.numeroLot;
              updatedLigne.datePeremention = lineMovement.datePeremention;
            }
          }
        }

        updatedLigne.libelle = fullArticle?.intitule;

        toDisplay.push(updatedLigne);
      });
    }

    // Only declared as a promise to comply to the interface. Returns the data directly.
    return toDisplay;
  }
}
