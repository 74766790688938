import { createReducer, on } from '@ngrx/store';
import { clearPasswordChange, setPasswordChange } from './xpert.actions';
import { XpertState, initialXpertState } from './xpert.state';

export const XpertReducer = createReducer<XpertState>(
  initialXpertState,
  on(clearPasswordChange, (state) => ({
    ...state,
    passwordChange: {
      xpert: null,
      storeKey: null,
      password: null,
    },
  })),
  on(setPasswordChange, (state, payload) => ({
    ...state,
    passwordChange: payload.data,
  }))
);
