import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { TcConfigTypes, TcGridAddButtonConfig } from '@tc/abstract';
import { initTcFilter } from '@tc/core';
import { initTcGridStore } from '@tc/core';
import { initTcPanelList } from '@tc/panel-list';
import { InitTcListDataPayload, initTcListDataStore } from '@tc/data-store';
import { DEFAULT_TC_DATA_STATE_KEY, NgRxTcDataState } from '@tc/data-store';
import { hasValue } from '@tc/utils';
import { can } from '@tc/permissions';
import * as R from 'ramda';

@Injectable()
export class TcStoreInitEffects {
  dataStore$: Observable<NgRxTcDataState>;

  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  initTcListData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initTcListDataStore),
        tap(async (payload: InitTcListDataPayload) => {
          const {
            storeKey,
            listConfig,
            callbackAction,
            callbackActionPayload,
          } = payload;

          const filterConfig = listConfig.filterConfig;

          if (filterConfig) {
            const { isPersistant } = filterConfig;

            this.store$.dispatch(
              initTcFilter({
                storeKey,
                config: filterConfig,
                isPersistant,
              })
            );
          }

          switch (listConfig.configType) {
            case TcConfigTypes.TcGrid: {
              // Initialise tc grid store
              const {
                columns,
                gridOptions,
                infiniteScrollPercent,
                cssClass,
                emptyDataOnDestroy,
                columnNumberPerDevice,
                pinnedRowsConfig,
                selectedRows,
              } = listConfig as any;

              let addButton: TcGridAddButtonConfig = null;

              if ((listConfig as any).addButton) {
                addButton = R.clone((listConfig as any).addButton);

                // Handle permissions from action and key
                if (
                  addButton?.permissionAction &&
                  addButton.permissionSubject
                ) {
                  const hasPermission = await this.store$
                    .select(
                      can(
                        addButton.permissionAction,
                        addButton.permissionSubject
                      )
                    )
                    .pipe(take(1))
                    .toPromise();

                  addButton.hide = !hasPermission;
                }
              }
              // Handle permissions from custom method
              if (addButton?.permissionCustom === false) {
                addButton.hide = true;
              }

              this.store$.dispatch(
                initTcGridStore({
                  storeKey,
                  columns,
                  columnNumberPerDevice,
                  gridOptions,
                  infiniteScrollPercent,
                  addButton,
                  cssClass,
                  emptyDataOnDestroy,
                  pinnedRowsConfig,
                  selectedRows,
                })
              );
              break;
            }
            case TcConfigTypes.TcPanelList: {
              // Initialise tc panel list
              const { component, itemSize } = listConfig as any;

              this.store$.dispatch(
                initTcPanelList({
                  storeKey,
                  component,
                  itemSize,
                })
              );
              break;
            }
          }

          // If we have a callback action dispatch it.
          if (callbackAction) {
            this.store$.dispatch(callbackAction(callbackActionPayload));
          }
        })
      ),
    { dispatch: false }
  );
}
