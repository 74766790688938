import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  TcComponentLookup,
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcTranslateService,
} from '@tc/core';
import { TcSmartComponent } from '@tc/abstract';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, on } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  clearPasswordChange,
  saveNewPassword,
  setPasswordChange,
} from '../../../store/xpert.actions';
import { DOCUMENT } from '@angular/common';

@TcComponentLookup('XpertPasswordPopupComponent')
@Component({
  selector: 'app-xpert-password-popup-component',
  templateUrl: './xpert-password-popup-component.html',
  styleUrls: ['./xpert-password-popup-component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpertPasswordPopupComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  data: any = null;
  formStoreKey = 'xpert-popup';
  form: FormGroup = new FormGroup({});
  formModel: any;
  formFields: FormlyFieldConfig[];
  enterPassword: string;
  confirmPassword: string;
  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `prompt.close-button`,
  };
  buttonsList: TcSmartButton[];

  constructor(
    store$: Store<any>,
    @Inject(MAT_DIALOG_DATA) data,
    private translate: TcTranslateService,
    private dialogRef: MatDialogRef<XpertPasswordPopupComponent>,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(store$);
    this.data = data;
    this.formStoreKey = data.formStoreKey;
  }

  ngOnInit() {
    this.initFields();

    const buttons: TcSmartButton[] = [];

    buttons.push({
      label: 'cancel',
      action: this.onClose.bind(this),
      class: 'btn-secondary dialog-button',
    });

    buttons.push({
      label: 'confirm',
      ionIcon: 'checkmark-outline',
      action: this.onSubmit.bind(this),
      class: 'btn-primary dialog-button passwordChangeButton',
    });

    this.buttonsList = buttons;
  }

  changeSubmitButtonState(enable: boolean) {
    const element = this.document.querySelector(
      '.passwordChangeButton'
    ) as HTMLElement;
    if (element) {
      switch (enable) {
        case true:
          element.classList.remove('btn-disabled');
          break;
        case false:
          element.classList.add('btn-disabled');
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.store$.dispatch(clearPasswordChange());
  }

  onSubmit() {
    const initialFormControl = this.form.get('enter-password');
    const confirmFormControl = this.form.get('confirm-password');

    if (
      this.form.valid &&
      initialFormControl.value &&
      confirmFormControl.value &&
      initialFormControl.value === confirmFormControl.value
    ) {
      this.store$.dispatch(saveNewPassword());
      this.onClose();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  checkPasswordMatching(): boolean {
    const initialFormControl = this.form.get('enter-password');
    const confirmFormControl = this.form.get('confirm-password');

    // The form is not filled yet
    if (!initialFormControl.value || !confirmFormControl.value) {
      this.store$.dispatch(clearPasswordChange());
      this.changeSubmitButtonState(false);
      // Return true to not block the required validation
      return true;
    }

    // Passwords do not match
    if (
      initialFormControl.value &&
      confirmFormControl.value &&
      initialFormControl.value !== confirmFormControl.value
    ) {
      initialFormControl.setErrors({ passwordMismatch: true });
      confirmFormControl.setErrors({ passwordMismatch: true });
      this.form.updateValueAndValidity();
      this.store$.dispatch(clearPasswordChange());
      this.changeSubmitButtonState(false);
      return false;
    }

    // Everything is fine
    initialFormControl.setErrors(null);
    confirmFormControl.setErrors(null);
    this.form.updateValueAndValidity();
    this.store$.dispatch(
      setPasswordChange({
        data: {
          xpert: { ...this.data.entityData },
          storeKey: this.data.formStoreKey,
          password: initialFormControl.value,
        },
      })
    );
    this.changeSubmitButtonState(true);
    return true;
  }

  initFields() {
    this.formFields = [
      formlyColumn({
        fields: [
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'enter-password',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: true,
                  type: 'password',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                validators: [
                  () => {
                    return this.checkPasswordMatching();
                  },
                ],
                validation: {
                  messages: {
                    passwordMismatch:
                      this.translate.instant('password-mismatch'),
                  },
                },
                expressionProperties: {
                  'templateOptions.label':
                    this.translate.stream('enter-password'),
                },
                colSpan: 12,
              }),
              formlyControl({
                key: 'confirm-password',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: true,
                  type: 'password',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                validators: [
                  () => {
                    return this.checkPasswordMatching();
                  },
                ],
                validation: {
                  messages: {
                    passwordMismatch:
                      this.translate.instant('password-mismatch'),
                  },
                },
                expressionProperties: {
                  'templateOptions.label':
                    this.translate.stream('confirm-password'),
                },
                colSpan: 12,
              }),
            ],
          }),
        ],
        colSpan: 12,
      }),
    ];
  }
}
