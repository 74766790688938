import { getCurrentElevage } from './../../elevage/store/elevage.selectors';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TcNavMenuNodes } from '@tc/core';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  initMenu,
  setMenuItems,
} from '../../../../modules/menu/store/menu.actions';
import { getAbilities } from '@tc/permissions';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { TcSpinnerService } from '@tc/store';
import { routerCancelAction, routerNavigatedAction } from '@ngrx/router-store';
import { Router } from '@angular/router';
import { ConfigService } from '../../../../shared/services/config.service';
import { CustomConfigKeys } from '../../../shared/interfaces/custom-config.interface';

@Injectable()
export class MenuEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly spinner: TcSpinnerService,
    private readonly router: Router,
    private readonly config: ConfigService
  ) {}

  initMenu = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initMenu),
        tap(async () => {
          const items = await this.getMenuItems();
          this.store$.dispatch(setMenuItems({ items }));
        })
      ),
    { dispatch: false }
  );

  private async getMenuItems() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    /**
     * NOTE: If this array gets changed probably the array form apps\frontend\src\app\custom\pages\home-page\home.component.ts:126:7
     *       needs to be updated
     */
    const items: TcNavMenuNodes[] = [
      {
        label: 'home-nav.title',
        route: '/',
      },
      {
        label: 'breeding-nav.title',
        menuItemClass: 'menu-item-parent',
        expanded: true,
        items: [
          {
            label: 'breeding-list-nav.title',
            route: CustomRoutes.ElevageList,
          },
          {
            label: 'breeding-detail-nav.title',
            route: CustomRoutes.ElevageDetail,
            onSelected: async (args) => {
              if (this.router.url === `/${args.route}`) return;

              const elevage = await this.store$
                .select(getCurrentElevage)
                .pipe(take(1))
                .toPromise();

              if (!elevage) return;

              this.spinner.showSpinner(null);
              this.actions$
                .pipe(
                  ofType(routerNavigatedAction, routerCancelAction),
                  take(1)
                )
                .subscribe(() => this.spinner.hideSpinner(null));
            },
          },
          {
            label: 'xroptima-purchase-history-nav.title',
            route: CustomRoutes.XrOptimaPurchaseHistory,
          },
          // XAS-84 - Hide features
          // {
          //   label: 'no-order-delivery-nav.title',
          //   route: CustomRoutes.Livraison,
          // },

          // {
          //   label: 'cash-collection-nav.title',
          //   route: '',
          //   menuItemClass: 'menu-item-grayed',
          // },
          {
            label: 'make-estimate-take-order-nav.title',
            route: CustomRoutes.MakeEstimateOrTakeOrder,
          },
          ...(this.config.get(CustomConfigKeys.enableDevisCommandeDoses)
            ? [
                {
                  label: 'doses.title',
                  route: CustomRoutes.Doses,
                },
              ]
            : []),
        ],
      },
      // XAS-84 - Hide features
      // {
      //   label: 'articles-nav.title',
      //   menuItemClass: 'menu-item-parent',
      //   expanded: true,
      //   items: [
      //     {
      //       label: 'articles-catalog-nav.title',
      //       route: CustomRoutes.CatalogArticles,
      //     },
      //   ],
      // },
      // XAS-84 - Hide features
      // {
      //   label: 'stock-nav.title',
      //   menuItemClass: 'menu-item-parent',
      //   expanded: true,
      //   items: [
      //     {
      //       label: 'stock-consultation-nav.title',
      //       route: CustomRoutes.ConsultationStock,
      //     },
      //     {
      //       label: 'xpert-deposit-nav.title',
      //       route: CustomRoutes.XpertDeposit,
      //     },
      //     {
      //       label: 'xpert-transfert-nav.title',
      //       route: CustomRoutes.XpertTransfert,
      //     },
      //     {
      //       label: 'restock-nav.title',
      //       route: CustomRoutes.Restock,
      //     },
      //   ],
      // },
      {
        label: 'xpert-nav.title',
        menuItemClass: 'menu-item-parent',
        expanded: true,
        hidden:
          !abilities.can('list', 'Collaborateur') &&
          !this.config.get(CustomConfigKeys.enableRemiseEnBanque),
        items: [
          {
            label: 'xpert-list-nav.title',
            route: CustomRoutes.XpertList,
            hidden: !abilities.can('list', 'Collaborateur'),
          },
          {
            label: 'bank-deposit.title',
            route: CustomRoutes.BankDeposit,
            hidden: !this.config.get(CustomConfigKeys.enableRemiseEnBanque),
          },

          // MMN: these menu items will be enabled again in version C
          // {
          //   label: 'xpert-dashboard-nav.title',
          //   route: '',
          // },
        ],
      },
    ];

    return items;
  }
}
