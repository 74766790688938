import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { map, take } from 'rxjs/operators';
import { ConfigKeys } from '../../shared/interfaces/config.interface';
import { TcOfflineModeService } from '@tc/breeze';
import { TcDataServiceFactory } from '@tc/data-store';
import { getAuthenticatedUser } from '../../modules/auth/store/auth.selectors';
import { getCurrentUserSuccess } from '../../modules/auth/store/auth.actions';
import { ConfigService } from '../../shared/services/config.service';
import { getTcPermissionsState, loadTcPermissionsSuccess } from '@tc/permissions';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
/**
 * A guard that checks if the application is in offline mode before allowing access to a route.
 * @implements CanActivate
 */
export class OfflineModeGuard implements CanActivate {
  private offlineModeService: TcOfflineModeService =
    this.tcDataServiceFactory.getOfflineModeService(
      this.config.get(ConfigKeys.offlineModeDataProviderType)
    );

  /**
   * Constructs a new instance of the class.
   * @param {Store<TcAppState>} store$ - The store object for managing the application state.
   * @param {Actions} actions$ - The actions object for dispatching actions.
   * @param {TcDataServiceFactory} tcDataServiceFactory - The factory object for creating instances of TcDataService.
   * @param {ConfigService} config - The service object for retrieving configuration settings.
   * @returns None
   */
  constructor(
    private readonly store$: Store<TcAppState>,
    private readonly actions$: Actions,
    private readonly tcDataServiceFactory: TcDataServiceFactory,
    private readonly config: ConfigService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isInOfflineMode = await this.offlineModeService.hasOfflineMode();

    if (isInOfflineMode) {
      const user = await this.store$
        .select(getAuthenticatedUser)
        .pipe(take(1))
        .toPromise();

      const tcPermissions = await this.store$
        .select(getTcPermissionsState)
        .pipe(take(1))
        .toPromise();

      // we need to set tc permissions before resolvers executed
      // otherwise RouteResolver will redirect to ForbiddenRoute page
      if (user && !tcPermissions.rights) {
        this.store$.dispatch(getCurrentUserSuccess({ user }));

        return this.actions$.pipe(
          ofType(loadTcPermissionsSuccess),
          take(1),
          map(() => true)
        )
          .toPromise();
      }
    }

    return true;
  }
}
