import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';
import { Subscription } from 'rxjs';
import { Elevage } from '../../modules/elevage/interfaces/elevage.interface';
import { getCurrentElevage } from '../../modules/elevage/store/elevage.selectors';
import { CustomRoutes } from '../../typings/custom-routes.enum';

@Component({
  selector: 'app-devis-page',
  templateUrl: './devis-page.component.html',
  styleUrls: ['./devis-page.component.scss'],
})
export class DevisPageComponent extends TcPage implements OnInit {
  constructor(store: Store<any>, private readonly router: Router) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'devis-page.title',
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
