import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { TcSmartComponent } from '@tc/abstract';
import { LoginMethod } from '../../../enums/login-method.enum';
import { UserLoginFormOptions } from '../../../interfaces/user-login.interface';
import {
  facebookLogin,
  googleLogin,
  login,
  loginWindows,
} from '../../../store/auth.actions';
import { ConfigService } from '../../../../../shared/services/config.service';
import { ConfigKeys } from '../../../../../shared/interfaces/config.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

/**
 * Represents a login component that handles user authentication.
 * @class LoginComponent
 * @extends TcSmartComponent
 */
export class LoginComponent extends TcSmartComponent {
  public readonly loginMethod = LoginMethod;
  public isWindowsLogin = false;

  loginMethods: LoginMethod[];

  constructor(store$: Store<TcAppState>, configService: ConfigService) {
    super(store$);

    const useWindowsLogin = configService.get(ConfigKeys.useWindowsLogin);
    if (useWindowsLogin) {
      this.store$.dispatch(loginWindows());
      this.isWindowsLogin = true;
    }

    this.loginMethods = configService.get(ConfigKeys.loginMethods) || [];
  }

  public login(options: UserLoginFormOptions) {
    switch (options?.mode) {
      case LoginMethod.Google:
        this.store$.dispatch(googleLogin());
        break;
      case LoginMethod.Facebook:
        this.store$.dispatch(facebookLogin());
        break;
      case LoginMethod.Password:
        this.store$.dispatch(
          login({ username: options.username, password: options.password })
        );
        break;
    }
  }
}
