import { createReducer, on } from '@ngrx/store';
import { setXrOptimaCanMakeOrder } from './xroptima.actions';

import { initialXrOptimaState, XrOptimaState } from './xroptima.state';
export const XrOptimaReducer = createReducer<XrOptimaState>(
  initialXrOptimaState,
  on(setXrOptimaCanMakeOrder, (state, { canMakeOrder }) => ({
    ...state,
    docButtonsState: {
      ...state.docButtonsState,
      canMakeOrder,
    },
  }))
);
