import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TcCoreModule } from '@tc/core';
import { SharedModule } from '../../../shared/shared.module';

import { FacturesGridComponent } from './components/smart/factures-grid/factures-grid.component';
import { TcButtonsModule } from '@tc/buttons';

const components = [FacturesGridComponent];

const services = [];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, TcCoreModule, SharedModule, TcButtonsModule],
  providers: [...services],
  exports: [...components],
})
export class FactureModule {}
