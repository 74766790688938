import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from '../../modules/auth/models/user.model';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
/**
 * A service class for managing user-related operations.
 * Extends the CrudService class.
 * @param {HttpClient} httpClient - The HttpClient instance for making HTTP requests.
 * @param {ConfigService} config - The ConfigService instance for retrieving configuration settings.
 * @returns None
 */
export class UsersService extends CrudService {
  constructor(public httpClient: HttpClient, public config: ConfigService) {
    super(CustomEntitiesEnum.Users, httpClient, config);
  }

  /**
   * Retrieves the current user's information from the server.
   * @returns {Promise<UserModel>} A promise that resolves to the user's information.
   */
  public async getMe(): Promise<UserModel> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient
      .get<UserModel>(`${url}/me`, {
        withCredentials: true,
        headers: headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }

  /**
   * Retrieves the permissions from the entity service.
   * @returns {Promise<any>} A promise that resolves to the permissions data.
   */
  public async getPermissions(): Promise<any> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return this.httpClient
      .get<any>(`${url}/permissions`, {
        withCredentials: true,
        headers: headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise();
  }
}
