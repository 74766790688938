import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getAuthenticationState } from '../../modules/auth/store/auth.selectors';
import { GenericRoutes } from '../../shared/typings/generic-routes';
import {
  loginFailure,
  loginWindows,
  logout,
  saveToken,
  verifyMfa,
} from '../auth/store/auth.actions';
import jwt_decode from "jwt-decode";
import { ConfigService } from '../../shared/services/config.service';
import { ConfigKeys } from '../../shared/interfaces/config.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * AuthGuard class that implements the CanActivate interface to control access to routes based on authentication status.
 * @constructor
 * @param {Router} router - The router service for navigating to different routes.
 * @param {ConfigService} config - The configuration service for retrieving application configuration.
 * @param {Store<TcAppState>} store$ - The store service for managing application state.
 */
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly config: ConfigService,
    private readonly store$: Store<TcAppState>
  ) {}

  /**
   * Determines whether the user can activate a particular route.
   * @param {ActivatedRouteSnapshot} route - The route that is being activated.
   * @param {RouterStateSnapshot} state - The current state of the router.
   * @returns {Observable<boolean> | boolean} - An observable or boolean indicating whether the route can be activated.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const loginExternSuccess =
      route.routeConfig.path === GenericRoutes.LoginOauth;
    if (loginExternSuccess) {
      this.onLoginExternSuccess(route.queryParams.token);
      return false;
    }

    const loginExternFailed =
      route.routeConfig.path === GenericRoutes.LoginOauthFailed;
    if (loginExternFailed) {
      this.onLoginExternFailed();
      return false;
    }

    return this.store$.pipe(
      select(getAuthenticationState),
      map((authenticationState) => {
        const userOpenLogin = state.url === `/${GenericRoutes.Login}`;
        const isUserAuthenticated = !!authenticationState.token;
        if (userOpenLogin) {
          return this.onUserOpenLogin(isUserAuthenticated);
        }

        const userOpenLoginMfa = state.url === `/${GenericRoutes.LoginMfa}`;
        const shouldLoginMfa = authenticationState.mfaOptions?.mfa;
        if (userOpenLoginMfa) {
          return this.onUserOpenLoginMfa(shouldLoginMfa);
        }

        if (!isUserAuthenticated) {
          this.router.navigate([`/${GenericRoutes.Login}`]);
          return false;
        }

        if (this.config.get(ConfigKeys.jwt_expiration_check)) {
          // Decode JWT-Token, check its expiration date and log the user out if the token expired
          let shouldLogout = false;
          if (authenticationState.token) {
            const decodedToken = jwt_decode(authenticationState.token);
            // Multiply by 1000 to get number of milliseconds
            const expirationDate = decodedToken.exp * 1000;
            if (new Date().getTime() > expirationDate) {
              shouldLogout = true;
            }
          }
          if (shouldLogout) {
            this.store$.dispatch(logout());
            return false;
          }
        }

        return true;
      })
    );
  }

  /**
   * Handles the user action of opening the login MFA (Multi-Factor Authentication) page.
   * @param {boolean} shouldLoginMfa - Indicates whether the user should be redirected to the login MFA page.
   * @returns {boolean} - Returns the value of shouldLoginMfa.
   */
  private onUserOpenLoginMfa(shouldLoginMfa: boolean) {
    if (!shouldLoginMfa) {
      this.router.navigate(['/']);
    }

    return shouldLoginMfa;
  }

  /**
   * Handles the action when the user opens the login page.
   * If the user is already authenticated, it redirects them to the home page.
   * @param {boolean} isUserAuthenticated - Indicates whether the user is already authenticated.
   * @returns {boolean} - Returns true if the user is not authenticated, false otherwise.
   */
  private onUserOpenLogin(isUserAuthenticated: boolean) {
    if (isUserAuthenticated) {
      this.router.navigate(['/']);
    }

    return !isUserAuthenticated;
  }

  /**
   * Handles the case when an external login fails.
   * Dispatches a loginFailure action with the corresponding error message.
   * Navigates to the login page.
   * @returns None
   */
  private onLoginExternFailed() {
    this.store$.dispatch(
      loginFailure({ error: new Error('login-form.errors.login-failed') })
    );

    this.router.navigate([`/${GenericRoutes.Login}`]);
  }

  /**
   * Callback function called when the external login is successful.
   * It saves the token to the store and dispatches an action to verify MFA.
   * @param {string} token - The token received from the external login.
   * @returns None
   */
  private onLoginExternSuccess(token: string) {
    this.store$.dispatch(saveToken({ token }));
    this.store$.dispatch(verifyMfa());
  }
}
