import { Inject, Injectable } from '@angular/core';
import { TcBreezeService } from '@tc/breeze';
import { TcDataProviderConfig, TcDataProviderType } from '@tc/abstract';
import { ITcDataService } from '@tc/abstract';
import { TcBreezeDataService } from '@tc/breeze';
import { HttpClient } from '@angular/common/http';
import { CONFIG_SERVICE, IConfigService } from '@tc/config';
import { TcRestApiDataService } from '@tc/rest-api';
import { TcBreezeOfflineModeService } from '../../../tc-breeze/tc-breeze-offline-mode.service';
import { TcOfflineModeService } from '../../../tc-breeze/interfaces/tc-offline-mode.interface';
import { TcSpinnerService } from '../../../tc-store/services';

/**
 * Create a basic, generic entity data service
 * suitable for persistence of most entities.
 * Assumes a common REST-y web API
 */
@Injectable()
export class TcDataServiceFactory {
  constructor(
    private breeze: TcBreezeService,
    private httpClient: HttpClient,
    @Inject(CONFIG_SERVICE) public config: IConfigService,
    private readonly spinner: TcSpinnerService
  ) {}

  /**
   * Create a default {ITcDataService} for the given entity type
   * @param storeKey {string} Name of the entity type for this data service
   */
  create<T>(
    storeKey: string,
    dataProvider: TcDataProviderConfig
  ): ITcDataService<T> {
    switch (dataProvider.providerType) {
      case TcDataProviderType.BreezeJs:
        return new TcBreezeDataService<T>(
          storeKey,
          this.breeze,
          dataProvider,
          this.spinner
        );
      case TcDataProviderType.RestApi:
        return new TcRestApiDataService<T>(
          storeKey,
          dataProvider,
          this.httpClient,
          this.config
        );
      default:
        throw new Error(`Unknown providerType: ${dataProvider.providerType}`);
    }
  }

  /**
   * Returns the right TcOfflineModeService with the right service in the constructor
   * @param providerType {TcDataProviderType} Name of the provider type for this offline mode service
   */
  getOfflineModeService = (
    providerType: TcDataProviderType
  ): TcOfflineModeService => {
    switch (providerType) {
      case TcDataProviderType.BreezeJs:
        return new TcBreezeOfflineModeService(this.breeze);
      default:
        throw new Error(`Unknown providerType: ${providerType}`);
    }
  };
}
