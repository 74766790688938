import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TcCoreModule } from '@tc/core';
import { SharedModule } from '../../../shared/shared.module';
import { TcButtonsModule } from '@tc/buttons';
import { TcDataService, TcDataServiceFactory } from '@tc/data-store';
import { TcBreezeService } from '@tc/breeze';
import { CatalogArticleGridComponent } from './components/smart/catalog-article-grid/catalog-article-grid.component';
import { DosesGridComponent } from './components/smart/doses-grid/doses-grid.component';
import { EffectsModule } from '@ngrx/effects';
import { CatalogArticleEffects } from './store/catalog-article.effects';
import { StoreModule } from '@ngrx/store';
import { catalogArticleReducer } from './store/catalog-article.reducer';
import { PromotionPopupComponent } from './components/smart/promotion-popup/promotion-popup.component';
import { PromotionGridComponent } from './components/smart/promotion-grid/promotion-grid.component';
import { TcSmartFormModule } from '@tc/smart-form';
import { TcDialogModule } from '@tc/dialog';

const components = [
  CatalogArticleGridComponent,
  DosesGridComponent,
  PromotionPopupComponent,
  PromotionGridComponent,
];

const services = [TcDataServiceFactory, TcDataService, TcBreezeService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    SharedModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcDialogModule,
    EffectsModule.forFeature([CatalogArticleEffects]),
    StoreModule.forFeature('catalogArticle', catalogArticleReducer),
  ],
  providers: [...services],
  exports: [...components],
})
export class ArticleModule {}
