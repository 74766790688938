import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginMfaInterface } from '../../modules/auth/interfaces/login-mfa.interface';
import { LoginResponseInterface } from '../../modules/auth/interfaces/login-response.interface';
import { ConfigService } from '../../shared/services/config.service';
import { CustomEntitiesEnum } from '../../shared/typings/custom-entities.enum';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Service class for handling authentication-related operations.
 */
export class AuthService extends CrudService {
  constructor(public config: ConfigService, public httpClient: HttpClient) {
    super(CustomEntitiesEnum.Auth, httpClient, config);
  }

  /**
   * Logs in a user with the provided username and password.
   * @param {string} username - The username of the user.
   * @param {string} password - The password of the user.
   * @returns {Promise<LoginResponseInterface>} A promise that resolves to the login response interface.
   */
  async login(
    username: string,
    password: string
  ): Promise<LoginResponseInterface> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<LoginResponseInterface>(
        `${url}/login`,
        {
          username,
          password,
        },
        {
          withCredentials: true,
          headers,
          observe: 'body',
          reportProgress: false,
        }
      )
      .toPromise<LoginResponseInterface>();
  }

  /**
   * Performs a login request using Windows authentication.
   * @returns {Promise<LoginResponseInterface>} A promise that resolves to the login response interface.
   * @throws {Error} If the login request fails.
   */
  async loginWindows(): Promise<LoginResponseInterface> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<LoginResponseInterface>(
        `${url}/loginWindows`,
        {},
        {
          withCredentials: true,
          headers,
          observe: 'body',
          reportProgress: false,
        }
      )
      .toPromise<LoginResponseInterface>();
  }

  /**
   * Refreshes the access token by making a GET request to the entity service endpoint.
   * @returns A promise that resolves to an object containing the refreshed access token.
   */
  async refresh(): Promise<{ accessToken: string }> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .get<{ accessToken: string }>(`${url}/refresh`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise<{ accessToken: string }>();
  }

  /**
   * Restores a user session using the provided refresh token and access token.
   * @param {Object} payload - The payload object containing the refresh token and access token.
   * @param {string} payload.refreshToken - The refresh token used to restore the session.
   * @param {string} payload.accessToken - The access token used to restore the session.
   * @returns {Promise<Required<LoginResponseInterface>>} - A promise that resolves to the required login response interface.
   */
  async restoreSession(payload: {
    refreshToken: string;
    accessToken: string;
  }): Promise<Required<LoginResponseInterface>> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<Required<LoginResponseInterface>>(
        `${url}/restoreSession`,
        payload,
        {
          withCredentials: true,
          headers,
          observe: 'body',
          reportProgress: false,
        }
      )
      .toPromise();
  }

  /**
   * Performs a multi-factor authentication login using the provided code.
   * @param {string} code - The authentication code.
   * @returns {Promise<{ accessToken: string }>} - A promise that resolves to an object containing the access token.
   */
  async mfaLogin(code: string): Promise<{ accessToken: string }> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .post<{ accessToken: string }>(
        `${url}/mfa`,
        {
          code,
        },
        {
          withCredentials: true,
          headers,
          observe: 'body',
          reportProgress: false,
        }
      )
      .toPromise<{ accessToken: string }>();
  }

  /**
   * Retrieves the MFA (Multi-Factor Authentication) status for the current user.
   * @returns {Promise<LoginMfaInterface>} A promise that resolves to the MFA status object.
   */
  async mfaStatus(): Promise<LoginMfaInterface> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();

    return await this.httpClient
      .get<LoginMfaInterface>(`${url}/mfa/status`, {
        withCredentials: true,
        headers,
        observe: 'body',
        reportProgress: false,
      })
      .toPromise<LoginMfaInterface>();
  }

  /**
   * Initiates the Google login process by redirecting the user to the Google login page.
   * @returns None
   */
  googleLogin() {
    const url = this.getEntityServiceEndpoint();

    location.href = `${url}/google`;
  }

  /**
   * Initiates a Facebook login process by redirecting the user to the Facebook login page.
   * @returns None
   */
  facebookLogin() {
    const url = this.getEntityServiceEndpoint();

    location.href = `${url}/facebook`;
  }
}
