import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartComponent,
} from '@tc/abstract';
import { TcFormlyComponent, TcTranslateService } from '@tc/core';
import { TcLocalStorageService } from '@tc/local-storage';
import { hasOfflineMode } from '@tc/store';
import {
  login,
  loginFailure,
  loginOffline,
} from '../../../modules/auth/store/auth.actions';
import { ConfigService } from '../../../shared/services/config.service';
import { ConfigKeys } from '../../../shared/interfaces/config.interface';
import {
  initTcListDataStore,
  TcDataService,
  TcDataServiceFactory,
} from '@tc/data-store';
import { DepotStockType } from '../../typings/depot.enum';
import { TcOfflineModeService } from '@tc/breeze';
import { AuthenticationService } from '../../../services/authentication.service';
import { LocalStorageKeys } from '../../typings/local-storage-keys.enum';
import { getFormatedVehicleName } from '../../shared/util';

@Component({
  selector: 'app-custom-login',
  templateUrl: './custom-login.component.html',
  styleUrls: ['./custom-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomLoginComponent extends TcSmartComponent implements OnInit {
  readonly vehicleStoreKey: string = 'custom-login-vehicle';
  readonly containerStoreKey: string = 'custom-login-container';

  form: FormGroup = new FormGroup({});
  formModel: any;
  formFields: FormlyFieldConfig[];

  private offlineModeService: TcOfflineModeService =
    this.tcDataServiceFactory.getOfflineModeService(
      this.config.get(ConfigKeys.offlineModeDataProviderType)
    );
  private usersDataService: ITcDataService<any> = this.dataService.getService(
    'Collaborateur',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Collaborateur',
      fields: 'idXpert, password, prenom, nom, email',
    }
  );

  constructor(
    public readonly store$: Store<any>,
    public readonly translate: TcTranslateService,
    public readonly localStorage: TcLocalStorageService,
    private readonly tcDataServiceFactory: TcDataServiceFactory,
    private readonly config: ConfigService,
    private readonly dataService: TcDataService,
    private readonly authenticationService: AuthenticationService
  ) {
    super(store$);

    this.initFormModel();
    this.initFormFields();
  }

  async ngOnInit() {
    // Update store for offline mode support
    this.store$.dispatch(
      hasOfflineMode({
        providerType: this.config.get(ConfigKeys.offlineModeDataProviderType),
        relaunch: false,
      })
    );

    const lastLoginVehicle = await this.localStorage.get(
      LocalStorageKeys.LastLoginVehicleKey
    );
    const lastLoginContainer = await this.localStorage.get(
      LocalStorageKeys.LastLoginContainerKey
    );

    this.formModel = {
      idXpert: this.formModel.idXpert ?? null,
      password: this.formModel.password ?? null,
      vehicleChoice: lastLoginVehicle ?? null,
      containerChoice: lastLoginContainer ?? null,
    };
  }

  //Initialize the form model before the browser autofills the fields
  initFormModel() {
    this.formModel = {
      idXpert: null,
      password: null,
      vehicleChoice: null,
      containerChoice: null,
    };
  }

  private initFormFields() {
    const vehicleDataProvider = {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'emplacement',
      filter: {
        filters: [
          {
            key: 'typeDeStock',
            value: DepotStockType.StockSecteur,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      },
      transformFn: (result) =>
        result
          .reduce((acc, item) => acc.concat(item.emplacement), [])
          .filter((item) => item.code !== 'DEFAUT')
          .sort((a, b) =>
            getFormatedVehicleName(a).localeCompare(getFormatedVehicleName(b))
          ),
    };
    const containerDataProvider = {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero, code, nom',
      filter: {
        filters: [
          {
            key: 'typeDeStock',
            value: DepotStockType.CuveXpert,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      },
    };

    this.formFields = [
      {
        key: 'idXpert',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'text',
          appearance: 'outline',
        },
        expressionProperties: {
          'templateOptions.label': this.translate.stream('identifier'),
        },
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'password',
          appearance: 'outline',
        },
        expressionProperties: {
          'templateOptions.label': this.translate.stream('password'),
        },
      },
      {
        key: 'vehicleChoice',
        type: TcFormlyComponent.TcSmartSelect,
        templateOptions: {
          required: true,
          appearance: 'outline',
          storeKey: this.vehicleStoreKey,

          dataProvider: vehicleDataProvider,
          valueFieldName: 'code',
          labelFn: getFormatedVehicleName,
        },
        expressionProperties: {
          'templateOptions.label': this.translate.stream('vehicle-choice'),
        },
      },
      {
        key: 'containerChoice',
        type: TcFormlyComponent.TcSmartSelect,
        templateOptions: {
          required: true,
          appearance: 'outline',
          storeKey: this.containerStoreKey,

          dataProvider: containerDataProvider,
          valueFieldName: 'numero',
          labelFn: (item: any) => {
            return `${item.code ? item.nom + ' - ' + item.code : item.nom}`;
          },
        },
        expressionProperties: {
          'templateOptions.label': this.translate.stream('container-choice'),
        },
      },
    ];

    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.vehicleStoreKey,
        listConfig: {
          storeKey: this.vehicleStoreKey,
          dataProvider: vehicleDataProvider,
          configType: TcConfigTypes.TcFormField,
        },
      })
    );
    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.containerStoreKey,
        listConfig: {
          storeKey: this.containerStoreKey,
          dataProvider: containerDataProvider,
          configType: TcConfigTypes.TcFormField,
        },
      })
    );
  }

  async login() {
    if (this.form.valid) {
      this.setLocalStorageLastUsedSelectValues();

      if (await this.offlineModeService.hasOfflineMode()) {
        await this.offlineLogin(
          this.form.value.idXpert,
          this.form.value.password
        );
      } else {
        this.store$.dispatch(
          login({
            username: this.form.value.idXpert,
            password: this.form.value.password,
          })
        );
      }
    }
  }

  private async setLocalStorageLastUsedSelectValues() {
    this.localStorage.set(
      LocalStorageKeys.LastLoginVehicleKey,
      this.formModel.vehicleChoice
    );
    this.localStorage.set(
      LocalStorageKeys.LastLoginContainerKey,
      this.formModel.containerChoice
    );
    this.localStorage.set(LocalStorageKeys.ElevageGridResetSecteur, true);
  }

  private async offlineLogin(username: string, password: string) {
    const userMapping: any[] = this.config.get(ConfigKeys.userMapping);
    const loginField = userMapping.find(
      (field) => field.userField === 'login'
    )?.databaseField;

    const { data } = await this.usersDataService.getData(0, 1, {
      filters: [
        {
          key: loginField,
          value: username,
        },
      ],
    });

    const [user] = data;

    if (user) {
      this.store$.dispatch(
        loginOffline({
          username,
          password,
          user: this.authenticationService.getMappedUser(user),
        })
      );
    } else {
      this.store$.dispatch(
        loginFailure({
          error: new Error('login-form.errors.inavlid-username-or-password'),
        })
      );
    }
  }
}
