import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { TcGridValidator } from '../../services/tc-grid-validator';
import { TcValidationsService } from '../../services/tc-validations.service';
import { Observable, Subscription } from 'rxjs';
import { selectByKey } from '@tc/store';
import * as R from 'ramda';
import { select, Store } from '@ngrx/store';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  getTcData,
  NgRxTcDataState,
  updateItemSuccess,
} from '@tc/data-store';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { selectValueByKey } from '@tc/store';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'tc-grid-mat-input-editor',
  templateUrl: './tc-grid-mat-input-editor.component.html',
  styleUrls: ['./tc-grid-mat-input-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcGridMatInputEditorComponent
  extends TcGridValidator
  implements AgEditorComponent, AfterViewInit, OnDestroy
{
  @ViewChild('input') input: ElementRef;
  dataStore$: Observable<NgRxTcDataState>;

  public label: string;
  public value: string;
  public type: string;
  public mask: string;
  public disabledFlag: boolean;
  public decimalMarker: string;
  public allowNegativeNumbers: boolean;
  public replacePeriodWithComma: boolean;
  public selectTextOnClick: boolean;
  public smartState: { storeKey: string; field: string };
  public gridDataSubscription: Subscription;
  public beforeChange: (value) => any; // Created to cleanup some values before any onchange function.
  public onValueChange: (value, rowData) => void;
  public disabled: (rowData) => boolean; // Custom function to disable the input
  public onValueChangeDelayInMillis = 0; // Delay in milliseconds before the onChange event is fired. By default, it will fire immediatly
  public disableFocusAfterInit = false;
  private delayTimeoutId: any;

  constructor(
    private store$: Store<any>,
    public readonly tcValidationsService: TcValidationsService,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(tcValidationsService);
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  ngAfterViewInit() {
    if (this.disableFocusAfterInit) {
      return;
    }
    setTimeout(() => this.input?.nativeElement?.focus());
  }

  public agInit(params) {
    this.data = params?.data ? R.clone(params.data) : null;
    this.value = params?.value;
    this.label = params?.label;
    this.type = params?.type ?? 'text';
    this.validators = params?.validators;
    this.smartState = params?.smartState;
    this.beforeChange = params?.beforeChange;
    this.onValueChange = params?.onValueChange;
    this.disabled = params?.disabled;
    this.onValueChangeDelayInMillis = params?.onValueChangeDelayInMillis;
    this.disableFocusAfterInit = params?.disableFocusAfterInit;
    this.selectTextOnClick = params?.selectTextOnClick ?? false;
    this.mask = params?.mask;
    this.decimalMarker = params?.decimalMarker;
    this.allowNegativeNumbers = params?.allowNegativeNumbers;
    this.replacePeriodWithComma = params?.replacePeriodWithComma ?? false;

    if (this.smartState) {
      this.subscribeToStore();
    }

    if (this.disabled) {
      this.disabledFlag = this.disabled(this.data);
    }
  }

  public async onChange(updated) {
    // Cancel the previous timeout if it exists
    if (this.delayTimeoutId) {
      clearTimeout(this.delayTimeoutId);
    }
    // Set a new timeout with the given delay time
    this.delayTimeoutId = setTimeout(async () => {
      // Process to cleanup the value before doing anything else
      if (this.beforeChange) {
        // Start putting the value as it is to force trigger the change
        this.value = updated;
        // Launch the change detection
        this.changeDetector.detectChanges();
        // Clean the value
        updated = this.beforeChange(updated);
        // Change it again to be sure the form value is updated in HTML
        this.value = updated;
      }

      // Go on with the onChange event
      super.validate(updated);
      if (this.smartState && (this.errors?.length ?? 0) === 0) {
        await this.setValueInStore(updated, this.data);
        // Clone here to correct a readonly crash
        const data = R.clone(this.data);
        data[this.smartState.field] = updated;
        this.data = data;
      }

      if (this.onValueChange) {
        this.onValueChange(updated, this.data);
      }

      // Clear the timeout
      this.delayTimeoutId = null;
    }, this.onValueChangeDelayInMillis);
  }

  public getValue() {
    return this.value;
  }

  keyUp(event) {
    if (event?.key === '.' && this.replacePeriodWithComma) {
      if (event?.target?.value === '') {
        event.target.value = ',';
      } else {
        if (!event?.target?.value.includes(',')) {
          event.target.value = event.target.value + ',';
        }
      }
    }
  }

  public onContainerClick(event) {
    if (this.selectTextOnClick) {
      // Event target is a child of the div and can be different if you click on a margin.
      // Go back on the top div and find the input inside.
      const div = event.target.closest('div.input--container') as HTMLElement;
      const input = div.querySelector('input') as HTMLInputElement;
      // Browser behavior can set the cursor position inside the input.
      // To overcome it, we need to remove all selection and select again after default behavior.
      this.document.defaultView.getSelection().removeAllRanges();
      input.select();
    }
  }

  // all editions will be in popup
  // https://www.ag-grid.com/angular-grid/cell-editing/#popup
  public isPopup() {
    return true;
  }

  subscribeToStore() {
    this.gridDataSubscription = selectByKey(
      getTcData,
      this.dataStore$,
      this.smartState.storeKey
    ).subscribe((data) => {
      const relatedRow = data.find((row) => row._id === this.data?._id);
      if (relatedRow && (this.errors?.length ?? 0) === 0)
        this.value = relatedRow[this.smartState.field];
    });
  }

  async setValueInStore(value, data) {
    const gridData = R.clone(
      await selectValueByKey(
        getTcData,
        this.dataStore$,
        this.smartState.storeKey
      )
    );

    const item = gridData.find((row) => row._id === data?._id);
    if (item) {
      item[this.smartState.field] = value;

      this.store$.dispatch(
        updateItemSuccess({ storeKey: this.smartState.storeKey, item })
      );
    }
  }

  ngOnDestroy(): void {
    this.gridDataSubscription?.unsubscribe();
  }
}
