import { Injectable } from '@angular/core';
import { TcService } from '@tc/abstract';
import { LoginResponseInterface } from '../modules/auth/interfaces/login-response.interface';
import { AuthService } from './business-services/auth.service';
import { TcLocalStorageService } from '@tc/local-storage';
import { ConfigService } from '../shared/services/config.service';
import { ConfigKeys } from '../shared/interfaces/config.interface';
import { MD5, HmacMD5, HmacSHA256, HmacSHA1 } from 'crypto-js';
import { LoginEncryptionMethod } from '../modules/auth/interfaces/login-encryption';
import { UserModel } from '../modules/auth/models/user.model';
import { get } from 'lodash';
import { Store } from '@ngrx/store';
import { saveToken } from '../modules/auth/store/auth.actions';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Represents an authentication service that handles user authentication and session management.
 * @class AuthenticationService
 * @extends TcService
 */
export class AuthenticationService extends TcService {
  private refreshTokenStorageKey = 'refreshToken';
  private accessTokenStorageKey = 'accessToken';

  /**
   * Constructs a new instance of the class.
   * @param {AuthService} authService - The authentication service.
   * @param {TcLocalStorageService} localStorageService - The local storage service.
   * @param {ConfigService} config - The configuration service.
   * @param {Store<any>} store$ - The store object.
   * @returns None
   */
  constructor(
    private readonly authService: AuthService,
    private readonly localStorageService: TcLocalStorageService,
    private readonly config: ConfigService,
    public readonly store$: Store<any>,
    private readonly permissionService: PermissionsService
  ) {
    super();
  }

  /**
   * Method to restore the session from offline mode to have a new valid access token from backend.
   * It will only work if you had previously a valid refresh token and a old access token saved in the local storage.
   * @returns LoginResponseInterface
   */
  public async restoreSession(): Promise<LoginResponseInterface> {
    const refreshToken: string = await this.localStorageService.get(
      this.refreshTokenStorageKey
    );
    const accessToken: string = await this.localStorageService.get(
      this.accessTokenStorageKey
    );

    if (refreshToken && accessToken) {
      const result = await this.authService.restoreSession({
        refreshToken,
        accessToken,
      });

      if (result) {
        await this.localStorageService.set(
          this.accessTokenStorageKey,
          result.accessToken
        );
        await this.localStorageService.set(
          this.refreshTokenStorageKey,
          result.refreshToken
        );
        this.store$.dispatch(saveToken({ token: result.accessToken }));
        setTimeout(() => {
          // Reload the user with latest data in the store and the local storage
          this.permissionService.refreshPermissions();
        }, 1000);
      }

      return result;
    } else {
      return null;
    }
  }

  /**
   * Logs in the user with the provided username and password.
   * @param {string} username - The username of the user.
   * @param {string} password - The password of the user.
   * @returns {Promise<LoginResponseInterface>} A promise that resolves to the login response interface.
   * @throws {Error} If the username or password is invalid.
   */
  public async login(
    username: string,
    password: string
  ): Promise<LoginResponseInterface> {
    if (!username || !password) {
      throw new Error('login-form.errors.inavlid-username-or-password');
    }

    const res = await this.authService.login(username, password);

    if (!res?.accessToken) {
      throw new Error('login-form.errors.inavlid-username-or-password');
    }

    if (this.config.get(ConfigKeys.supportsOfflineMode)) {
      await this.localStorageService.set(
        this.accessTokenStorageKey,
        res.accessToken
      );
      await this.localStorageService.set(
        this.refreshTokenStorageKey,
        res.refreshToken
      );
    }

    return res;
  }

  /**
   * Logs in the user using Windows authentication.
   * @returns {Promise<LoginResponseInterface>} A promise that resolves to the login response interface.
   * @throws {Error} If the login attempt fails or the access token is missing.
   */
  public async loginWindows(): Promise<LoginResponseInterface> {
    const res = await this.authService.loginWindows();

    if (!res?.accessToken) {
      throw new Error('login-form.errors.inavlid-username-or-password');
    }

    return res;
  }

  /**
   * Performs multi-factor authentication using the provided code.
   * @param {string} code - The authentication code.
   * @returns {Promise<{ accessToken: string }>} - A promise that resolves to an object containing the access token.
   * @throws {Error} - Throws an error if the authentication code is invalid.
   */
  public async mfa(code: string): Promise<{ accessToken: string }> {
    const res = await this.authService.mfaLogin(code);

    if (!res?.accessToken) {
      throw new Error('login-mfa-form.errors.inavlid-code');
    }

    return res;
  }

  /**
   * Refreshes the access token by calling the `refresh` method of the `authService`.
   * @returns {Promise<{ accessToken: string }>} - A promise that resolves to an object containing the refreshed access token.
   */
  public async refresh(): Promise<{ accessToken: string }> {
    return await this.authService.refresh();
  }

  /**
   * Checks if the provided password is valid for the given user.
   * @param {object} user - The user object containing the password to check against.
   * @param {string} pass - The password to validate.
   * @returns {boolean} - True if the password is valid, false otherwise.
   * @throws {Error} - If the encryption method specified in the configuration is not supported.
   */
  public isValidPassword(user, pass: string): boolean {
    let cryptPass;
    const encryption = this.config.get(ConfigKeys.defaultEncryption);

    if (encryption) {
      switch (encryption) {
        case LoginEncryptionMethod.MD5:
          cryptPass = this.config.get(ConfigKeys.salt)
            ? HmacMD5(pass, user.salt).toString()
            : MD5(pass).toString();
          break;

        case LoginEncryptionMethod.SHA256:
          cryptPass = HmacSHA256(pass, user.salt).toString();
          break;

        case LoginEncryptionMethod.SHA1:
          cryptPass = HmacSHA1(pass, user.salt).toString();
          break;

        default:
          throw new Error(
            `There is no handler for DEFAULT_ENCRYPTION: ${encryption}`
          );
      }
    } else {
      cryptPass = pass;
    }

    return user.password === cryptPass;
  }

  /**
   * Maps the properties of a user object based on the provided user mapping configuration.
   * @param {Record<string, any>} user - The user object to map.
   * @param {Object} [initialValue={}] - The initial value for the mapped user object.
   * @returns {UserModel} - The mapped user object.
   */
  getMappedUser(user: Record<string, any>, initialValue = {}): UserModel {
    const userMapping: any[] = this.config.get(ConfigKeys.userMapping);

    return userMapping.reduce((res, field) => {
      const userMappingValue = get(user, field.databaseField);
      if (userMappingValue || userMappingValue !== undefined) {
        if (
          field.userField === 'roles' &&
          Array.isArray(userMappingValue) === false
        ) {
          res[field.userField] = [{ role: userMappingValue }];
        } else {
          res[field.userField] = userMappingValue;
        }
      }

      return res;
    }, initialValue);
  }
}
