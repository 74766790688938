import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { TcVersionModule } from '@tc/version';
import { TcButtonsModule } from '@tc/buttons';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { encaissementReducer } from './store/encaissement.reducer';
import { TcSmartFormModule } from '@tc/smart-form';
import { EncaissementEffects } from './store/encaissement.effects';
import { EncaissementPopupComponent } from './components/smart/encaissement-popup/encaissement-popup.component';
import { BankDepositGridComponent } from './components/smart/bank-deposit-grid/bank-deposit-grid.component';
import { TcDialogModule } from '@tc/dialog';

const components = [EncaissementPopupComponent, BankDepositGridComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    TcSmartFormModule,
    TcVersionModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcDialogModule,
    EffectsModule.forFeature([EncaissementEffects]),
    StoreModule.forFeature('encaissement', encaissementReducer),
  ],
  exports: [...components],
})
export class EncaissementModule {}
