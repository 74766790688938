import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';

@Component({
  selector: 'app-restock-page',
  templateUrl: './restock-page.component.html',
  styleUrls: ['./restock-page.component.scss'],
})
export class RestockPageComponent extends TcPage implements OnInit {
  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'restock.title',
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
