import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from '../../../shared/services/config.service';
import { CustomConfigKeys } from '../../shared/interfaces/custom-config.interface';

@Injectable({
  providedIn: 'root',
})
export class BankDepositPageGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly config: ConfigService
  ) {}

  /**
   * @returns {Observable<boolean> | boolean} - An observable or boolean indicating whether the route can be activated.
   */
  canActivate(): Observable<boolean> | boolean {
    if (this.config.get(CustomConfigKeys.enableRemiseEnBanque)) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
