import { createReducer, on } from '@ngrx/store';
import {
  clearMfaOptions,
  clearUserData,
  getCurrentUserSuccess,
  loginAs,
  loginFailure,
  loginSuccess,
  logout,
  refreshCurrentUserSuccess,
  saveMfaOptions,
  saveToken,
} from './auth.actions';
import { AuthState, initialAuthState } from './auth.state';

/**
 * Reducer function for managing the authentication state.
 * @param {AuthState} state - The current state of the authentication.
 * @param {Action} action - The action to be performed on the state.
 * @returns The updated state after applying the action.
 */
export const authReducer = createReducer<AuthState>(
  initialAuthState,
  on(saveToken, (state, { token }) => ({
    ...state,
    isAuthenticated: true,
    errorMessage: null,
    token,
  })),
  on(saveMfaOptions, (state, options) => ({
    ...state,
    isAuthenticated: true,
    errorMessage: null,
    token: null,
    mfaOptions: {
      token: state.token,
      ...options,
    },
  })),
  on(loginSuccess, (state) => ({
    ...state,
    isAuthenticated: true,
    errorMessage: null,
  })),
  on(clearUserData, (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    loginAs: null,
  })),
  on(clearMfaOptions, (state) => ({
    ...state,
    mfaOptions: null,
  })),
  on(getCurrentUserSuccess, (state, { user }) => ({
    ...state,
    user: { ...user },
  })),
  on(refreshCurrentUserSuccess, (state, { user }) => ({
    ...state,
    user: { ...user },
  })),
  on(loginFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: true,
    errorMessage: error.message,
    user: null,
  })),
  on(logout, (state) => ({
    ...state,
    ...initialAuthState,
  })),
  on(loginAs, (state, { login }) => ({
    ...state,
    ...initialAuthState,
    loginAs: login,
  }))
);
