import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage, TcTranslateService } from '@tc/core';
import { IPageState } from '@tc/store';
import { SyncService } from '../../services/sync.service';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-etl-page',
  templateUrl: './etl-page.component.html',
  styleUrls: ['./etl-page.component.scss'],
})
export class EtlPageComponent extends TcPage implements OnInit {
  constructor(
    store: Store<any>,
    private readonly syncService: SyncService,
    private readonly translateService: TcTranslateService,
    private dialog: MatDialog,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
  ) {
    super(store);
  }

  async ngOnInit() {
    const pageInfo: IPageState = {
      title: 'forbidden-page.title',
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

    await this.authenticationService.restoreSession();
    const SyncIsAvailable = await this.syncService.isSyncAvailable(false);
    if (SyncIsAvailable) {
      // Launch the ETL
      this.syncService.launchEtlSage2Xpert();

      // Display prompt confirmation
      const dialog = this.dialog.open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translateService.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translateService.instant('globalMessages.sync-etl-launch'),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      });
      dialog.afterClosed().subscribe(() => {
        // Navigate to homepage
        this.router.navigateByUrl('/');
      });
    } else {
      // ETL is already running
      const dialog = this.dialog.open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translateService.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translateService.instant(
            'globalMessages.sync-etl-already-launched'
          ),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      });
      dialog.afterClosed().subscribe(() => {
        // Navigate to homepage
        this.router.navigateByUrl('/');
      });
    }
  }
}
