import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcNotificationService, TcPage, TcTranslateService } from '@tc/core';
import { IPageState, TcSpinnerService } from '@tc/store';
import { SyncService } from '../../services/sync.service';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-etl-xpert-extract-only-page',
  templateUrl: './etl-xpert-extract-only-page.component.html',
  styleUrls: ['./etl-xpert-extract-only-page.component.scss'],
})
export class EtlXpertExtractOnlyPageComponent extends TcPage implements OnInit {
  constructor(
    store: Store<any>,
    private readonly syncService: SyncService,
    private readonly translateService: TcTranslateService,
    private dialog: MatDialog,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly spinner: TcSpinnerService,
    private readonly notificationService: TcNotificationService
  ) {
    super(store);
  }

  async ngOnInit() {
    const pageInfo: IPageState = {
      title: 'forbidden-page.title',
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();

    await this.authenticationService.restoreSession();

    this.spinner.showSpinner('XpertExtractOnly');

    // Launch the ETL
    const result = await this.syncService.launchEtlXpertExtractOnly();

    this.spinner.hideSpinner('XpertExtractOnly');

    if ((result as any)?.success) {
      // Display success prompt
      const dialog = this.dialog.open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translateService.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translateService.instant('xpert-extract-only-successful'),
          confirm: this.translateService.instant('close'),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      });
      dialog.afterClosed().subscribe(() => {
        // Navigate to homepage
        this.router.navigateByUrl('/');
      });
    } else {
      this.notificationService.error(
        this.translateService.instant('globalMessages.generic-error')
      );
    }
  }
}
