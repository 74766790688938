import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  ListOrder,
  FilterTypesEnum,
  MaterialColor,
  ITcDataService,
} from '@tc/abstract';
import { TcGridCellComponent } from '@tc/core';

import { navigate, TcSmartGridComponent } from '@tc/advanced-components';
import {
  DocumentDomaine,
  DocumentType,
} from '../../../../../typings/document.enum';
import { Subscription } from 'rxjs';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import * as R from 'ramda';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';
import { DiscountType } from '../../../../../typings/discount-type.enum';
import { DataType } from 'breeze-client';
import { Xpert } from '../../../../xpert/interfaces/xpert.interface';
import { XpertService } from '../../../../../services/xpert.service';
import { DualChoice } from '../../../../../typings/dual-choice.enum';
import { DocumentLigne } from '../../../../elevage/interfaces/document-ligne.interface';
import { TcDataService } from '@tc/data-store';
import { Article } from '../../../../article/interfaces/article.interface';
import { Gamme } from '../../../../../typings/gamme.enum';
import { DAS } from '../../../../../../custom/typings/DAS.enum';
import { decimalsForXpertMobile } from '../../../../../shared/decimals';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';

@Component({
  selector: 'app-devis-grid',
  templateUrl: './devis-grid.component.html',
  styleUrls: ['./devis-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DevisGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'devis-grid';

  subscription = new Subscription();
  elevage: Elevage;
  xpert: Xpert;

  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'reference,intitule',
    }
  );

  constructor(
    store$: Store<any>,
    private readonly xpertService: XpertService,
    private readonly dataService: TcDataService
  ) {
    super(store$);

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);
  }

  private async formatElevageDocuments(
    elevageDocuments: (ElevageDocument & { [dynamicProperties: string]: any })[]
  ) {
    for (const document of elevageDocuments) {
      const initialMontant = 0;
      const montant =
        document.ligne?.reduce(
          (previousValue, ligne) =>
            previousValue + (ligne.prixUnitaire ?? 0) * (ligne.quantite ?? 0),
          initialMontant
        ) ?? 0;

      const discount =
        document.ligne?.reduce((prev, line) => {
          let discountAmount = line.montantRemise;
          if (line.typeRemise === DiscountType.Pourcentage) {
            discountAmount =
              (line.quantite * line.prixUnitaire * line.montantRemise) / 100;
          }

          const sum = prev + discountAmount;
          return sum;
        }, 0) ?? 0;

      document.montant = montant - discount;

      let articleRefs = document.ligne.map((ligne) => ligne.article.reference);

      articleRefs = articleRefs.filter(
        (ar, index, self) => ar && self.indexOf(ar) === index
      );

      const articleFilter = articleRefs.join(',');

      const { data: relatedArticles } = await this.articleDataService.getData(
        0,
        null,
        {
          filters: [
            {
              key: 'reference',
              value: articleFilter,
              filterType: FilterTypesEnum.In,
            },
          ],
        }
      );

      document.hasMonitoringArticles = (relatedArticles as Article[]).some(
        (article) =>
          article.DAS?.offre?.gamme?.intitule?.toLowerCase() ===
          Gamme.Monitoring.toLowerCase()
      );

      document.estimateHasRelatedOrder = elevageDocuments.some(
        (doc) =>
          doc.domaine === DocumentDomaine.Vente &&
          doc.type === DocumentType.VenteBC &&
          doc.ligne.some(
            (ligne) => ligne.numeroPieceDevisOrigine === document.numeroPiece
          )
      );

      // EX-134: only articles from XR Crea or XR Optima can be ordered
      document.canBeTransformedInOrder = (relatedArticles as Article[]).every(
        (article) =>
          article.DAS?.intitule?.toLowerCase() === DAS.XRCrea.toLowerCase() ||
          article.DAS?.intitule.toLowerCase() === DAS.XROptima.toLowerCase()
      );
    }
  }

  async ngOnInit() {
    this.xpert = await this.xpertService.getConnectedXpert();

    const elevageDocuments = R.clone(this.elevage.documents);
    await this.formatElevageDocuments(elevageDocuments);

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'devis-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageDevis',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document',
          data: elevageDocuments,
          breezeStructuralTypeExtension: {
            noteDeReglement: DataType.String,
            description: DataType.String,
            montant: DataType.Double,
            hasMonitoringArticles: DataType.Boolean,
            estimateHasRelatedOrder: DataType.Boolean,
            canBeTransformedInOrder: DataType.Boolean,
          },
        },
        filter: {
          filters: [
            {
              key: 'domaine',
              value: DocumentDomaine.Vente.toString(),
              filterType: FilterTypesEnum.Equal,
            },
            {
              key: 'type',
              value: DocumentType.Devis.toString(),
              filterType: FilterTypesEnum.Equal,
            },
          ],
        },
      },
      columns: [
        {
          field: 'numeroPiece',
          maxWidth: 150,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          comparator: gridComparatorForString,
        },
        {
          field: 'date',
          sort: ListOrder.Desc,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          maxWidth: 150,
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
          comparator: gridComparatorForString,
        },
        {
          field: 'prescripteur',
          minWidth: 190,
          width: 190,
          valueFormatter: (params) => {
            return `${params.value?.nom ? params.value.nom + ' ' : ''}${
              params.value?.prenom ?? ''
            }`;
          },
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (prescripteur1, prescripteur2) => {
            const fullName1 = `${
              prescripteur1.nom ? prescripteur1.nom + ' ' : ''
            }${prescripteur1.prenom ?? ''}`;
            const fullName2 = `${
              prescripteur2.nom ? prescripteur2.nom + ' ' : ''
            }${prescripteur2.prenom ?? ''}`;

            return fullName1.localeCompare(fullName2);
          },
        },
        {
          field: 'noteDeReglement',
          headerClass: 'text-align-left',
          comparator: gridComparatorForString,
        },
        {
          field: 'description',
          comparator: gridComparatorForString,
        },
        {
          field: 'montant',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) => {
            let value = '';
            if (
              this.xpert.monitoring.toLowerCase() !==
                DualChoice.Yes.toLowerCase() &&
              params.data.hasMonitoringArticles === 'true'
            ) {
              value = '-';
            } else {
              value = `${
                params.data.montant
                  ? `${params.data.montant.toLocaleString(undefined, {
                      minimumFractionDigits: decimalsForXpertMobile,
                    })} €`
                  : ''
              }`;
            }

            return value;
          },
          comparator: gridComparatorForNumber,
        },
        {
          field: 'actions',
          sortable: false,
          width: 120,
          maxWidth: 120,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          valueGetter: (params) => [' '],
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'eye-outline',
                tooltip: 'eye-tooltip',
                action: navigate,
                actionPayload: {
                  route: `/${CustomRoutes.DevisDetail}`,
                  queryParams: [
                    {
                      paramName: 'devisDocNum',
                      dataPropertyName: 'numeroPiece',
                    },
                  ],
                },
              },
              {
                color: MaterialColor.Warn,
                ionIcon: 'document-text-outline',
                tooltip: 'make-order',
                action: navigate,
                actionPayload: {
                  route: `/${CustomRoutes.DetailCommandesList}`,
                  queryParams: [
                    {
                      paramName: 'devisDocNum',
                      dataPropertyName: 'numeroPiece',
                    },
                  ],
                },
                disabled: (data) => data.estimateHasRelatedOrder === 'true',
                permissionCustom: (data) =>
                  data.canBeTransformedInOrder === 'true',
              },
            ],
          },
        },
      ],
      columnNumberPerDevice: {
        extraSmallDevice: 7,
        smallDevice: 7,
        mediumDevice: 7,
        largeDevice: 7,
        extraLargeDevice: 7,
        extraExtraLargeDevice: 7,
      },
    };

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
