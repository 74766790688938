import { BaseTcStorePayload } from '@tc/abstract';
import { CustomRoutes } from '../../../typings/custom-routes.enum';

export interface EditOrderArticlesPayload extends BaseTcStorePayload {
  orderDocNum: string;
  origin: ArticleOrigin;
}

/**
 * Enum for the origin of the article added into the estimate / order. It symbolizes the page of the application from which the article was added.
 * It is used to redirect the user to the correct page when editing the articles of an estimate / order.
 */
export enum ArticleOrigin {
  XrOptimaPurchaseHistory = 'XrOptimaPurchaseHistory', // Historique des achats XR-Optima
  DeliveryWithoutOrder = 'DeliveryWithoutOrder', // Faire une livraison sans commande
  OrderEstimateOptima = 'OrderEstimateOptima', // COMMANDE/DEVIS - OPTIMA
  OrderEstimateDoses = 'OrderEstimateDoses', // COMMANDE/DEVIS - DOSES
  CatalogArticles = 'CatalogArticles',
}

export interface SaveOrderPayload extends BaseTcStorePayload {
  orderDocNum: string;
  route: CustomRoutes;
}
