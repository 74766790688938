import { Injectable } from '@angular/core';
import { TDocumentDefinitions } from 'pdfmake/build/pdfmake';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export enum PdfGeneratorTableLayout {
  HeaderFooterBorder = 'headerFooterBorder',
}

pdfMake.tableLayouts = {
  [PdfGeneratorTableLayout.HeaderFooterBorder]: {
    hLineWidth: (i, node) => {
      // line after first row (header) and before last row (footer)
      return i === 1 || i === node.table.body.length - 1 ? 1 : 0;
    },
    vLineWidth: (i) => 0,
    paddingLeft: (i) => (i === 0 ? 0 : 5),
    paddingRight: (i, node) => (i === node.table.widths.length - 1 ? 0 : 5),
  },
};

@Injectable({ providedIn: 'root' })
export class PdfGeneratorService {
  getBase64(document: TDocumentDefinitions): Promise<string> {
    const pdf = pdfMake.createPdf(document);

    return new Promise((resolve) => {
      pdf.getBase64((data) => {
        resolve(data);
      });
    });
  }

  public print(document: TDocumentDefinitions): void {
    const pdf = pdfMake.createPdf(document);

    pdf.print();
  }

  public mmToPt(mm: number): number {
    return mm * 2.83465;
  }

  public download(document: TDocumentDefinitions, filename: string): void {
    const pdf = pdfMake.createPdf(document);
    pdf.download(filename);
  }
}
