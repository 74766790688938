import { createReducer, on } from '@ngrx/store';
import { setExceededQuantitiesState } from './livraison.actions';
import { initialLivraisonState, LivraisonState } from './livraison.state';

export const livraisonReducer = createReducer<LivraisonState>(
  initialLivraisonState,
  on(setExceededQuantitiesState, (state, { exceeded }) => ({
    ...state,
    quantities: {
      hasExceedingQuantities: exceeded,
    },
  }))
);
