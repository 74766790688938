import { createAction, props } from '@ngrx/store';
import { CreateOrderFromXrOptimaPayload } from './xroptima.payloads';

export const createOrderFromXrOptima = createAction(
  '[XrOptima] Create Order From XrOptima',
  props<CreateOrderFromXrOptimaPayload>()
);

export const setXrOptimaCanMakeOrder = createAction(
  '[XrOptima] Set State of Order Button',
  props<{ canMakeOrder: boolean }>()
);
