import { Depot } from '../modules/depot/interfaces/depot.interface';
import { gzip, ungzip } from 'pako';

export const getFormatedVehicleName = ({
  code,
  intitule,
}: {
  code: string;
  intitule: string;
}): string => {
  return `${intitule} - ${code}`;
};

export const getFormatedDepotName = (depot: Depot): string => {
  const label = depot.code ? depot.nom + ' - ' + depot.code : depot.nom;
  return label;
};

/**
 * Compares two string values for sorting in a grid.
 * @param valueA - The first string value to compare.
 * @param valueB - The second string value to compare.
 * @returns A number indicating the sort order of the two values.
 */
export const gridComparatorForString = (valueA, valueB) => {
  const valueACleaned = valueA ? valueA : '';
  const valueBCleaned = valueB ? valueB : '';
  return valueACleaned.localeCompare(valueBCleaned, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

/**
 * Compares two numbers for sorting in a grid.
 * @param num1 - The first number to compare.
 * @param num2 - The second number to compare.
 * @returns A number indicating the sort order of the two values.
 */
export const gridComparatorForNumber = (num1, num2) => {
  const num1Cleaned = num1 ? num1 : '0';
  const num2Cleaned = num2 ? num2 : '0';
  return num1Cleaned.toString().localeCompare(num2Cleaned, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

/**
 * Encodes a string to Base64.
 *
 * @param str - The string to encode.
 * @returns The Base64 encoded string.
 */
export const encodeBase64 = (str: string): string => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
};

/**
 * Decodes a base64 encoded string.
 *
 * @param encodedStr - The base64 encoded string to decode.
 * @returns The decoded string.
 */
export const decodeBase64 = (encodedStr: string): string => {
  return decodeURIComponent(
    atob(encodedStr)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

/**
 * Compresses a string using gzip.
 *
 * @param str - The string to compress.
 * @returns The compressed string.
 */
export const compress = (str: string): string => {
  const result = uint8ArrayToString(gzip(str));
  return result;
};

/**
 * Converts a Uint8Array to a string representation.
 *
 * @param array - The Uint8Array to convert.
 * @returns The string representation of the Uint8Array.
 */
export const uint8ArrayToString = (array: Uint8Array): string => {
  return array.join(',');
};

/**
 * Converts a string to a Uint8Array.
 *
 * @param str - The string to convert.
 * @returns The Uint8Array representation of the string.
 */
export const stringToUint8Array = (str: string): Uint8Array => {
  // Split the string by commas
  const strArray = str.split(',');

  // Convert the string array to a number array
  const numArray = strArray.map(Number);

  // Create a Uint8Array from the number array
  return new Uint8Array(numArray);
};

/**
 * Decompresses a string using gzip.
 *
 * @param str - The string to decompress.
 * @returns The decompressed string.
 */
export const decompress = (str: string): string => {
  return ungzip(stringToUint8Array(str), { to: 'string' });
};

/**
 * Encodes an object to be used in a QR code with minimal size.
 *
 * @param data - The object to encode.
 * @returns The Base64 encoded string of the compressed object.
 */
export const encodeQRCode = (data: Object): string => {
  return encodeBase64(compress(JSON.stringify(data)));
};

/**
 * Decodes a QR code string to an object.
 *
 * @param data - The QR code string to decode.
 * @returns The object decoded from the QR code string.
 */
export const decodeQRCode = (data: string): Object => {
  return JSON.parse(decompress(decodeBase64(data)));
};

export const numericComparator = (valueA, valueB) => {
  // Convert values to numbers
  const numA = parseFloat(valueA);
  const numB = parseFloat(valueB);

  // Compare as numbers
  if (numA === numB) {
    return 0;
  } else if (numA < numB) {
    return -1;
  } else {
    return 1;
  }
};
