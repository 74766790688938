import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getCurrentElevage } from '../modules/elevage/store/elevage.selectors';
import { ElevageService } from '../services/elevage.service';
import { CustomRoutes } from '../typings/custom-routes.enum';

@Injectable()
export class ElevageSelectedResolver implements Resolve<any> {
  constructor(
    private readonly store$: Store<any>,
    private readonly router: Router,
    private readonly elevageService: ElevageService
  ) {}

  async resolve(): Promise<any> {
    // #XPR-BUG-017 : Init elevage-grid if he's not set to avoid errors when saving data.
    // Since this resolver is set on all pages that potentially load / save something into the current elevage
    // it seems it was the right place to put it. Don't await it, as it seems to block offline mode data restauration.
    // at this point, we just want it to be launched. We don't care about his completion.
    this.elevageService.initElevageGridWithMinimalSetup();

    const currentElevage = await this.store$
      .select(getCurrentElevage)
      .pipe(take(1))
      .toPromise();
    if (!currentElevage) {
      // Navigate to the list of elevages if there is no current selected elevage
      const redirectTo = '/' + CustomRoutes.ElevageList;
      this.router.navigateByUrl(redirectTo);
    }
  }
}
