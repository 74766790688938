import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CatalogArticleDocButtonState,
  CatalogArticleState,
} from './catalog-article.state';
import * as R from 'ramda';

export const getCatalogArticleState =
  createFeatureSelector<CatalogArticleState>('catalogArticle');

export const getCatalogArticleStateByStoreKey = (
  state: CatalogArticleState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

export const getCurrentDocument = createSelector(
  getCatalogArticleState,
  (state: CatalogArticleState) => state.currentDocument
);

export const getCurrentDocumentArticleOrigin = createSelector(
  getCatalogArticleState,
  (state: CatalogArticleState) => state.currentDocumentArticleOrigin
);

export const getEstimateBtnDisabledState = createSelector(
  getCatalogArticleStateByStoreKey,
  (state: CatalogArticleDocButtonState) => {
    return !R.propOr(false, 'canMakeEstimate')(state);
  }
);

export const getOrderBtnDisabledState = createSelector(
  getCatalogArticleStateByStoreKey,
  (state: CatalogArticleDocButtonState) => {
    return !R.propOr(false, 'canMakeOrder')(state);
  }
);
