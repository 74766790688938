export enum DocumentDomaine {
  Vente = 0,
  Achat = 1,
  Stock = 2,
}

export enum DocumentType {
  Devis = 0,
  VenteBC = 1,
  PL = 2,
  VenteBL = 3,
  VenteBR = 4,
  VenteBA = 5,
  VenteFacture = 6,
  VenteFactureCompta = 7,
  VenteArchive = 8,
  DA = 10,
  PC = 11,
  AchatBC = 12,
  AchatBL = 13,
  AchatBR = 14,
  AchatBA = 15,
  AchatFacture = 16,
  AchatFactureCompta = 17,
  AchatArchive = 18,
  MouvementEntree = 20,
  MouvementSortie = 21,
  DepreciationStock = 22,
  VirementDepotADepot = 23,
  PreparationFabri = 24,
  OrdreFabrication = 25,
  BonFabrication = 26,
  StockArchive = 27,
}
