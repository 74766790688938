import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  MaterialColor,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
} from '@tc/abstract';

import {
  editTcGridButtonClicked,
  TcSmartGridComponent,
} from '@tc/advanced-components';
import {
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcGridCellComponent,
} from '@tc/core';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { MaterialButtonType } from '@tc/buttons';

@Component({
  selector: 'app-xpert-grid',
  templateUrl: './xpert-grid.component.html',
  styleUrls: ['./xpert-grid.component.scss'],
})
export class XpertGridComponent extends TcSmartGridComponent implements OnInit {
  storeKey = 'xpert-grid';

  constructor(store$: Store<any>) {
    super(store$);
  }

  ngOnInit() {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'Collaborateur',
        fields:
          'nom,prenom,idXpert,territoire,adresse,codePostal,ville,telPortable',
      },
      columns: [
        {
          field: 'nom',
          maxWidth: 150,
          comparator: gridComparatorForString,
        },
        {
          field: 'prenom',
          maxWidth: 150,
          comparator: gridComparatorForString,
        },
        {
          field: 'idXpert',
          maxWidth: 150,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        {
          field: 'territoire.groupe.secteur.libelle',
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.territoire?.groupe?.secteur?.libelle
              ? valueA?.territoire?.groupe?.secteur?.libelle
              : '';
            const valueBCleaned = valueB?.territoire?.groupe?.secteur?.libelle
              ? valueB?.territoire?.groupe?.secteur?.libelle
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'adresse',
          comparator: gridComparatorForString,
        },
        {
          field: 'codePostal',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          maxWidth: 150,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'ville',
          comparator: gridComparatorForString,
        },
        {
          field: 'telPortable',
          comparator: gridComparatorForString,
        },
        {
          field: 'actions',
          sortable: false,
          maxWidth: 160,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'lock-open-outline',
                tooltip: 'eye-tooltip',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'XpertPasswordPopupComponent',
                  width: '29.75em',
                  height: '19.25em',
                },
                name: 'edit',
              },
            ],
          },
        },
      ],
      filterConfig: this.getFilterConfig(),
      columnNumberPerDevice: {
        extraSmallDevice: 8,
        smallDevice: 8,
        mediumDevice: 8,
        largeDevice: 8,
        extraLargeDevice: 8,
        extraExtraLargeDevice: 8,
      },
    };

    super.ngOnInit();
  }

  getFilterConfig(): TcSmartFilterConfig {
    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'xpert-filter',
                  className: 'search-icon-filter',
                  type: TcFormlyComponent.FormlyInput,
                  templateOptions: {
                    filterOn: [
                      'nom',
                      'prenom',
                      'territoire.groupe.secteur.libelle',
                      'adresse',
                      'codePostal',
                      'ville',
                    ],
                    filterMultiWord: true,
                    appearance: 'outline',
                  },
                  colSpan: 12,
                }),
              ],
              colSpan: 3,
            }),
          ],
        }),
      ],
    };
  }
}
