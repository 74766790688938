import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TcCoreModule } from '@tc/core';
import { SharedModule } from '../../../shared/shared.module';
import { ConsultationStockGridComponent } from './components/smart/consultation-stock-grid/consultation-stock-grid.component';
import { ArticleMovementPopupComponent } from './components/smart/article-movement-popup/article-movement-popup.component';
import { StockEffects } from './store/stock.effects';
import { StockService } from './services/stock.services';
import { StoreModule } from '@ngrx/store';
import { stockReducer } from './store/stock.reducer';
import { TcButtonsModule } from '@tc/buttons';
import { ArticleMovementSelectionComponent } from './components/smart/article-movement-selection/article-movement-selection.component';
import { TcSmartFormModule } from '@tc/smart-form';
import { XpertDepositDetailComponent } from './components/smart/xpert-deposit-detail/xpert-deposit-detail.component';
import { XpertTransfertDetailComponent } from './components/smart/xpert-transfert-detail/xpert-transfert-detail.component';
import { MovementDepotSelectionComponent } from './components/smart/movement-depot-selection/movement-depot-selection.component';
import { RestockGridComponent } from './components/smart/restock-grid/restock-grid.component';
import { RestockDetailGridComponent } from './components/smart/restock-detail-grid/restock-detail-grid.component';
import { QrCodeScanPopupComponent } from './components/smart/qr-code-scan-popup/qr-code-scan-popup.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrCodeModule } from 'ng-qrcode';

const components = [
  ConsultationStockGridComponent,
  ArticleMovementPopupComponent,
  ArticleMovementSelectionComponent,
  XpertDepositDetailComponent,
  XpertTransfertDetailComponent,
  MovementDepotSelectionComponent,
  RestockGridComponent,
  RestockDetailGridComponent,
  QrCodeScanPopupComponent,
];

const services = [StockService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    TcButtonsModule,
    SharedModule,
    TcSmartFormModule,
    ZXingScannerModule,
    QrCodeModule,
    EffectsModule.forFeature([StockEffects]),
    StoreModule.forFeature('stock', stockReducer),
  ],
  providers: [...services],
  exports: [...components],
})
export class StockModule {}
