import { TcNavMenuNodes } from '@tc/core';

/**
 * Represents the state of a menu.
 * @interface MenuState
 * @property {TcNavMenuNodes[]} items - An array of menu items.
 */
export interface MenuState {
  items: TcNavMenuNodes[];
}

/**
 * The initial state of the menu.
 */
export const initialMenuState: MenuState = {
  items: [],
};
