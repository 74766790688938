import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { TcTranslateService, TcFormComponent } from '@tc/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { ArticlesMovement, QrCodeData } from '../../../store/stock.state';
import { saveMovement, setMovement } from '../../../store/stock.actions';
import { filter, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { getMovement } from '../../../store/stock.selectors';
import { decodeQRCode } from '../../../../../shared/util';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { TcDataService } from '@tc/data-store';

@Component({
  selector: 'app-qr-code-scan-popup',
  templateUrl: './qr-code-scan-popup.component.html',
  styleUrls: ['./qr-code-scan-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QrCodeScanPopupComponent
  extends TcFormComponent<any>
  implements OnInit, OnDestroy
{
  error = false;
  qrCodeContent: string = null;
  qrCodeMessage: string = null;
  buttonsList: TcSmartButton[];
  buttonsListError: TcSmartButton[];
  message: string;
  subscriptions = new Subscription();

  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `prompt.close-button`,
  };

  closeFullButtonConfig: TcSmartButton = {
    matIcon: 'close',
    name: `prompt.close-button`,
  };

  private mouvementStockDataService: ITcDataService<any> =
    this.dataService.getService('MouvementStock', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'MouvementStock',
      fields: 'document',
    });

  constructor(
    private dialogRef: MatDialogRef<QrCodeScanPopupComponent>,
    public readonly translate: TcTranslateService,
    private readonly store$: Store<any>,
    public elem: ElementRef,
    private readonly dataService: TcDataService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(translate, elem);

    // This popup can display a QRCode if the data is provided. In this case, she will not display the scanner.
    if (data?.qrCodeContent) {
      this.qrCodeContent = data?.qrCodeContent;
      if (data?.qrCodeMessage) {
        this.qrCodeMessage = data?.qrCodeMessage;
      }
    }
  }

  async ngOnInit() {
    super.ngOnInit();
    this.initButtons();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClose() {
    this.dialogRef.close();
  }

  retry() {
    this.error = false;
    this.message = null;
  }

  initButtons() {
    const buttons: TcSmartButton[] = [];

    buttons.push({
      label: this.qrCodeContent ? 'close' : 'cancel',
      action: this.onClose.bind(this),
      class: 'btn-secondary dialog-button',
    });

    this.buttonsList = buttons;

    const buttonsError: TcSmartButton[] = [];

    buttonsError.push({
      label: 'retry',
      action: this.retry.bind(this),
      class: 'btn-secondary dialog-button',
    });

    buttonsError.push({
      label: 'cancel',
      action: this.onClose.bind(this),
      class: 'btn-secondary dialog-button',
    });

    this.buttonsListError = buttonsError;
  }

  public async handleQrCodeResult(resultString: string) {
    this.message = this.translate.instant('processing-qr-code');
    let result: QrCodeData = null;
    try {
      // Decode the base64 string and decompress the result. Then parse the JSON string to an object. This was done this way because the QRCode has a limited maximum size. So we need to compress the data to fit in the QRCode.
      result = decodeQRCode(resultString) as QrCodeData;
    } catch (e) {
      this.error = true;
      console.error('Error during decoding of the QrCode', e);
      console.error('QrCode content', resultString);
      this.message = this.translate.instant('invalid-qr-code');
      return;
    }

    // Morph the result into a ArticlesMovement object
    const movement: ArticlesMovement = {
      departure: {
        numeroDepot: result.a.n,
        codeEmplacement: result.a.c,
      },
      destination: {
        numeroDepot: result.b.n,
        codeEmplacement: result.b.c,
      },
      articles: result.c.map((article) => {
        return {
          _id: article.r + '-' + article.l,
          reference: article.r,
          numeroLot: article.l,
          description: '',
          quantiteEnStock: article.q,
          quantite: article.q,
        };
      }),
      qrCodeData: result.d.map((qrCode) => {
        return {
          documentType: qrCode.t,
          numXpertMobile: qrCode.n,
          numPiece: qrCode.p,
          idXpert: qrCode.x,
          date: qrCode.d,
        };
      }),
    };

    // Compare to know if the result is a ArticleMovement object
    if (movement && this.isValidArticlesMovement(movement)) {
      // Verify that the QrCode was not already scanned
      const { data: existingMovements } =
        await this.mouvementStockDataService.getData(0, null, {
          filters: [
            {
              key: 'document.numXpertMobile',
              value: movement.qrCodeData
                .map((qrCode) => qrCode.numXpertMobile)
                .join(','),
              filterType: FilterTypesEnum.In,
            },
          ],
        });

      if (existingMovements.length > 0) {
        this.error = true;
        this.message = this.translate.instant('qr-code-already-scanned');
        return;
      }

      const subscription = this.store$
        .select(getMovement)
        .pipe(
          filter(
            (movementData) =>
              movementData?.articles.length > 0 &&
              movementData?.qrCodeData.length > 0 &&
              movementData?.departure != null &&
              movementData?.destination != null
          ), // Ensure the object is complete
          take(1) // This ensures that the subscription will only receive one value and then complete
        )
        .subscribe((movementData) => {
          // Execute your code here when `movement` state changes
          this.store$.dispatch(
            saveMovement({ storeKey: 'xpert-transfert-grid' })
          );
          this.onClose();
        });
      this.subscriptions.add(subscription);

      // Launch the action to save the movement in the store and trigger the saving process
      this.store$.dispatch(setMovement({ data: movement }));
    } else {
      this.error = true;
      this.message = this.translate.instant('invalid-qr-code');
      return;
    }
  }

  /**
   * Checks if the provided object is an instance of ArticlesMovement.
   * @param obj - The object to be checked.
   * @returns A boolean indicating whether the object is an instance of ArticlesMovement.
   */
  private isValidArticlesMovement(obj: any): obj is ArticlesMovement {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      typeof obj?.departure.numeroDepot === 'number' &&
      obj?.departure.numeroDepot != null &&
      typeof obj?.destination.numeroDepot === 'number' &&
      obj?.destination.numeroDepot != null &&
      Array.isArray(obj.articles) &&
      obj.articles.length > 0 &&
      Array.isArray(obj.qrCodeData) &&
      obj.qrCodeData.length > 0
    );
  }

  /**
   * Handles the error that occurs during QRCode scan.
   *
   * @param error - The error object representing the scan error.
   */
  public handleScanError(error: any) {
    console.error('Error during QRCode scan', error);
  }
}
