export interface EncaissementState {
  currentEncaissement: Encaissement | null;
  encaissementButtonsState: EncaissementButtonsState;
}

export interface EncaissementButtonsState {
  bankDepositEncaissementButton: boolean;
  bankDepositSaveButton: boolean;
}

export enum ModeReglement {
  cash = 'Espèces',
  check = 'Chèque',
}

export enum StatutRemisEnBanque {
  no = 0,
  csvToGenerate = 1,
  csvGenerated = 2,
}

export interface EncaissementDocument {
  numXpertMobile: string;
  domaine: number;
  type: number;
  numeroPiece: string | null;
  echeances: {
    date: string; // date
    montant: number;
  }[];
}

export interface Encaissement {
  date: string; // date
  montant: number;
  modeReglement: ModeReglement | null;
  numeroCheque?: string;
  remisEnBanque?: string; // date
  statutRemisEnBanque: StatutRemisEnBanque;
  elevage: {
    _id: string;
    numero: string;
    nom: string;
  };
  documents: EncaissementDocument[];
  collaborateur: {
    _id: string;
    idXpert: string;
    nom: string;
    prenom: string;
    IBAN: string;
  };
}

export const initialEncaissementState: EncaissementState = {
  currentEncaissement: null,
  encaissementButtonsState: {
    bankDepositEncaissementButton: false,
    bankDepositSaveButton: false,
  },
};
