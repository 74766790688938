import { Component } from '@angular/core';
import { AbstractLayoutComponent } from '../abstract-layout/abstract-layout.component';

@Component({
  selector: 'app-vertical-layout',
  templateUrl: './vertical-layout.component.html',
  styleUrls: ['./vertical-layout.component.scss'],
})
/**
 * Represents a vertical layout component that extends the AbstractLayoutComponent class.
 * This component is responsible for arranging its child components in a vertical layout.
 */
export class VerticalLayoutComponent extends AbstractLayoutComponent {}
