import {
  CdkPortalOutletAttachedRef,
  ComponentPortal,
} from '@angular/cdk/portal';
import { Component, ComponentRef, OnInit, EventEmitter } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-component-panel-wrapper',
  templateUrl: './component-panel-wrapper.component.html',
  styleUrls: ['./component-panel-wrapper.component.scss'],
})
export class ComponentPanelWrapperComponent
  extends FieldWrapper
  implements OnInit
{
  componentPortal: ComponentPortal<any>;

  ngOnInit(): void {
    this.componentPortal = new ComponentPortal(this.to.component);
  }

  initRenderedComponentData(ref: CdkPortalOutletAttachedRef) {
    if (this.to.componentData) {
      const componentRef = ref as ComponentRef<any>;

      Object.keys(this.to.componentData).forEach((key) => {
        if (typeof this.to.componentData[key] === 'function') {
          const eventEmitter = componentRef.instance[key] as EventEmitter<any>;
          eventEmitter?.subscribe(this.to.componentData[key]);
        } else {
          componentRef.instance[key] = this.to.componentData[key];
        }
      });
    }
  }
}
