import { LayoutComponent } from './modules/layout/components/smart/layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/guards/auth.guard';
import { TermsGuard } from './modules/guards/terms.guard';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginMfaPageComponent } from './pages/login-mfa-page/login-mfa-page.component';
import { LoginOauthFailedPageComponent } from './pages/login-oauth-failed-page/login-oauth-failed-page.component';
import { LoginOauthPageComponent } from './pages/login-oauth-page/login-oauth-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { TermsConditionsPageComponent } from './pages/terms-conditions-page/terms-conditions-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { GenericRoutes } from './shared/typings/generic-routes';

/**
 * An array of route objects that define the routes for the application.
 * @type {Routes}
 */
const routes: Routes = [
  // Empty routes with empty child path must be declared before the others
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [{ path: '', component: HomePageComponent, pathMatch: 'full' }],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: GenericRoutes.Login,
        component: LoginPageComponent,
        pathMatch: 'full',
      },
      {
        path: GenericRoutes.LoginMfa,
        component: LoginMfaPageComponent,
        pathMatch: 'full',
      },
      {
        path: GenericRoutes.LoginOauth,
        component: LoginOauthPageComponent,
        pathMatch: 'full',
      },
      {
        path: GenericRoutes.LoginOauthFailed,
        component: LoginOauthFailedPageComponent,
        pathMatch: 'full',
      },
      {
        path: GenericRoutes.TermsOfUse,
        component: TermsConditionsPageComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: GenericRoutes.Users,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [{ path: '', component: UsersPageComponent, pathMatch: 'full' }],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
