import { FactureGridDocument } from '../../../interfaces/facture-grid-document.interface';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  ListOrder,
  FilterTypesEnum,
  TcFilterMultiWordOperator,
  MaterialColor,
} from '@tc/abstract';

import { TcSmartGridComponent } from '@tc/advanced-components';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { Observable, Subscription } from 'rxjs';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import * as R from 'ramda';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import {
  DocumentDomaine,
  DocumentType,
} from '../../../../../typings/document.enum';
import { DataType } from 'breeze-client';
import { decimalsForXpertMobile } from '../../../../../shared/decimals';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { selectByKey } from '@tc/store';
import {
  DEFAULT_TC_GRID_STATE_KEY,
  NgRxTcGridState,
  TcGridReadyEvent,
  getTcGridSelection,
} from '@tc/core';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { EncaissementPopupComponent } from '../../../../encaissement/components/smart/encaissement-popup/encaissement-popup.component';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';
import { CustomConfigKeys } from 'apps/frontend/src/app/custom/shared/interfaces/custom-config.interface';

@Component({
  selector: 'app-factures-grid',
  templateUrl: './factures-grid.component.html',
  styleUrls: ['./factures-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FacturesGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'factures-grid';

  gridStore$: Observable<NgRxTcGridState>;
  subscription = new Subscription();
  elevage: Elevage;
  displaySelectionOnHeader = false;
  buttonsList: TcSmartButton[] = [];
  selectedRows: any[] = [];
  selectedRowsTotal: number = 0;

  private onlyUnpaid = false;

  constructor(
    store$: Store<any>,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private documentDOM: Document,
    private route: ActivatedRoute,
    private readonly config: ConfigService
  ) {
    super(store$);

    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    this.subscription.add(
      this.store$.pipe(select(getCurrentElevage)).subscribe((elevage) => {
        this.elevage = elevage;
      })
    );
  }

  private formatElevageDocuments(
    elevageDocuments: ElevageDocument[]
  ): FactureGridDocument[] {
    let newDocuments = [];

    const ecrituresComptables = this.elevage.ecrituresComptables ?? [];

    elevageDocuments.forEach((document) => {
      if (document.echeance && document.echeance.length > 0) {
        document.echeance.forEach((echeance) => {
          // Find the ecriture comptable for the current echeance
          // XPR-166 : Sort ecrituresComptables by numero DESC in case of multiple ecrituresComptables for the same document. We need the last one (most recent one)
          const ecritureComptables = ecrituresComptables
            .filter(
              (ecriture) =>
                ecriture.dateEcheance === echeance.date &&
                ecriture.numeroFacture === document.numeroPiece
            )
            .sort((a, b) => b.numero - a.numero);
          // Get first ecritureComptable
          const ecritureComptable = ecritureComptables[0];
          const newDocument: FactureGridDocument = R.clone(document);
          newDocument.description = '';
          newDocument.dateEcheance = echeance.date;
          // XAS-23
          newDocument.lettree = ecritureComptable?.lettree ?? false;
          newDocument.montantARegler =
            newDocument.lettree === true ? 0 : newDocument.totalTTC;
          newDocuments.push(newDocument);
        });
      }
    });

    // Remove documents with montantARegler = 0 if onlyUnpaid is true
    if (this.onlyUnpaid) {
      newDocuments = newDocuments.filter(
        (document) => document.montantARegler > 0
      );
    }

    // Check if we must display the selection checkbox on the header
    this.displaySelectionOnHeader = newDocuments.some(
      (document) => document.montantARegler > 0
    );

    return newDocuments;
  }

  public async onReady(event: TcGridReadyEvent) {
    event.api.addEventListener('selectionChanged', function () {
      const selectedNodes = event.api.getSelectedNodes();

      // Determine if header checkbox was checked
      const headerCheckbox =
        selectedNodes.length === event.api.getDisplayedRowCount();

      if (headerCheckbox) {
        // Manually select only the rows that are selectable
        event.api.forEachNode(function (node) {
          if (node.data.montantARegler === 0) {
            node.setSelected(false);
          } else {
            node.setSelected(true);
          }
        });
      }
    });
  }

  async ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    this.onlyUnpaid = queryParams?.impaye === 'true';

    // Remap documents to FactureGridDocument
    const elevageDocuments = this.formatElevageDocuments(
      R.clone(this.elevage.documents)
    );

    // Handle the button encaissement state based on the selection : > 0, button is available, else it's disabled
    this.subscription.add(
      selectByKey(getTcGridSelection, this.gridStore$, this.storeKey).subscribe(
        async (selectedRows: any[]) => {
          if (selectedRows?.length > 0) {
            this.selectedRows = selectedRows;
            // Iterate over the invoices to calculate the total amount.
            this.selectedRowsTotal = 0;
            this.selectedRows.forEach((invoice) => {
              this.selectedRowsTotal += invoice.montantARegler;
            });
            this.changeEncaissementButtonState(false);
          } else {
            this.selectedRows = [];
            this.selectedRowsTotal = 0;
            this.changeEncaissementButtonState(true);
          }
        }
      )
    );

    // Define the button encaissement
    this.buttonsList = this.config.get(CustomConfigKeys.enableRemiseEnBanque)
      ? [
          {
            label: 'make-cash-collection',
            class: 'encaissementButton btn-disabled',
            color: MaterialColor.Primary,
            type: MaterialButtonType.Raised,

            action: () => {
              this.dialog.open(EncaissementPopupComponent, {
                data: {
                  invoices: this.selectedRows,
                  total: this.selectedRowsTotal,
                },
                width: '30em',
              });
            },
          },
        ]
      : [];

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'factures-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageFactures',
        dynamicCollectionFrom: {
          breezeStructuralType: 'tcpComplex.document',
          data: elevageDocuments,
          breezeStructuralTypeExtension: {
            description: DataType.String,
            dateEcheance: DataType.String,
            montantARegler: DataType.Double,
            montant: DataType.Double,
          },
        },
        filter: {
          filters: [
            {
              key: 'domaine',
              value: DocumentDomaine.Vente.toString(),
              filterType: FilterTypesEnum.Equal,
            },
            {
              key: 'type',
              filterOn: ['type'],
              value: `${DocumentType.VenteFacture.toString()} ${DocumentType.VenteFactureCompta.toString()}`,
              filterMultiWord: true,
              filterMultiWordOperator: TcFilterMultiWordOperator.Or,
              filterType: FilterTypesEnum.Equal,
            },
          ],
        },
      },
      columns: [
        {
          headerCheckboxSelection:
            this.config.get(CustomConfigKeys.enableRemiseEnBanque) &&
            this.displaySelectionOnHeader,
          checkboxSelection: (params) => {
            return params?.data?.montantARegler > 0;
          },
          field: 'numeroPiece',
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          maxWidth: 150,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'date',
          sort: ListOrder.Desc,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
          maxWidth: 150,
          valueFormatter: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            return formattedDate;
          },
          comparator: gridComparatorForString,
        },
        {
          field: 'dateEcheance',
          maxWidth: 150,
          headerClass: 'text-align-right',
          cellClass: (params) => {
            let cellClass = 'text-align-right cell-echeance';

            if (params.value) {
              const date = new Date(params.value);
              date.setHours(0, 0, 0, 0);
              const now = new Date();
              now.setHours(0, 0, 0, 0);

              if (now > date) cellClass += ' cell-echue';
            }

            return cellClass;
          },
          cellRenderer: (params) => {
            if (!params.value) return '';

            const date = new Date(params.value);
            const formattedDate = new Intl.DateTimeFormat('fr-FR').format(date);
            const cellHtml = `<span class="date-echeance">${formattedDate}</span>`;
            return cellHtml;
          },
          comparator: gridComparatorForString,
        },
        {
          field: 'description',
          comparator: gridComparatorForString,
        },
        {
          field: 'totalTTC',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          valueFormatter: (params) =>
            `${
              params.data.totalTTC
                ? `${params.data.totalTTC.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`
                : ''
            }`,
          comparator: gridComparatorForNumber,
        },
        {
          field: 'montantARegler',
          valueFormatter: (params) =>
            `${
              params.data.montantARegler || params.data.montantARegler === 0
                ? `${params.data.montantARegler.toLocaleString(undefined, {
                    minimumFractionDigits: decimalsForXpertMobile,
                  })} €`
                : ''
            }`,
          comparator: gridComparatorForNumber,
          width: 180,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
      ],
    };

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  changeEncaissementButtonState(disable: boolean) {
    const elements = this.documentDOM.querySelectorAll('.encaissementButton');
    if (!disable) {
      if (elements?.length > 0) {
        elements.forEach((element) => {
          element?.classList.remove('btn-disabled');
        });
      }
    } else {
      if (elements?.length > 0) {
        elements.forEach((element) => {
          element?.classList.add('btn-disabled');
        });
      }
    }
  }
}
