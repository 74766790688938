import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-tc-formly-toggle',
  templateUrl: './tc-formly-toggle.component.html',
  styleUrls: ['./tc-formly-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcFormlyToggleComponent extends FieldType implements OnInit {
  labelPosition?: 'before' | 'after' = 'after';

  @ViewChild(MatSlideToggle, { static: true }) slideToggle!: MatSlideToggle;

  ngOnInit() {
    this.to.hideFieldUnderline = this.to.hideFieldUnderline ?? true;
    this.to.hideLabel = this.to.hideLabel ?? true;
    this.to.floatLabel = this.to.floatLabel ?? 'always';

    setTimeout(() => {
      if (!this.field.formControl.value) {
        this.formControl.setValue(
          this.field.templateOptions.unCheckedValue ?? false
        );
      }

      this.slideToggle.checked =
        this.field.formControl.value ===
        (this.field.templateOptions.checkedValue ?? true);
    });
  }

  public onChange($event) {
    this.formControl.setValue(
      $event.checked
        ? this.to.checkedValue ?? true
        : this.to.unCheckedValue ?? false
    );

    this.slideToggle.checked = $event.checked;

    if (this.to.onChange) {
      this.to.onChange($event);
    }
  }
}
