import { Injectable } from '@angular/core';
import { TcService } from '../tc-abstract/tc-service';
import { OfflineModeSyncResponse } from './interfaces/offline-mode-sync-response.interface';
import { TcOfflineModeService } from './interfaces/tc-offline-mode.interface';
import { TcBreezeService } from './tc-breeze.service';

@Injectable({
  providedIn: 'root',
})
export class TcBreezeOfflineModeService
  extends TcService
  implements TcOfflineModeService
{
  syncPercentage$ = this.breeze.syncPercentage$;
  offlineModeState = this.breeze.offlineModeState;

  constructor(private breeze: TcBreezeService) {
    super();
  }

  async hasOfflineMode(): Promise<boolean> {
    return await this.breeze.hasOfflineMode();
  }

  async activateOfflineMode(): Promise<OfflineModeSyncResponse> {
    return await this.breeze.activateOfflineMode();
  }

  async terminateOfflineMode(): Promise<OfflineModeSyncResponse> {
    return await this.breeze.terminateOfflineMode();
  }
}
