import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as R from 'ramda';
import {
  EncaissementButtonsState,
  EncaissementState,
} from './encaissement.state';

export const getEncaissementState =
  createFeatureSelector<EncaissementState>('encaissement');

export const getEncaissementStateByStoreKey = (
  state: EncaissementState,
  props: { storeKey: string }
) => {
  return R.prop(props.storeKey)(state);
};

export const getCurrentEncaissement = createSelector(
  getEncaissementState,
  (state: EncaissementState) => state.currentEncaissement
);

export const getBankDepositEncaissementButtonState = createSelector(
  getEncaissementStateByStoreKey,
  (state: EncaissementButtonsState) => {
    return !R.propOr(false, 'bankDepositEncaissementButton')(state);
  }
);

export const getBankDepositSaveButtonState = createSelector(
  getEncaissementStateByStoreKey,
  (state: EncaissementButtonsState) => {
    return !R.propOr(false, 'bankDepositSaveButton')(state);
  }
);
