import { getCurrentElevage } from './../modules/elevage/store/elevage.selectors';
import { Injectable } from '@angular/core';
import { XpertService } from './xpert.service';
import { Store } from '@ngrx/store';
import {
  Encaissement,
  EncaissementDocument,
  ModeReglement,
  StatutRemisEnBanque,
} from '../modules/encaissement/store/encaissement.state';
import { take } from 'rxjs/operators';
import { Elevage } from '../modules/elevage/interfaces/elevage.interface';
import { FactureGridDocument } from '../modules/facture/interfaces/facture-grid-document.interface';

@Injectable({
  providedIn: 'root',
})
export class EncaissementService {
  constructor(
    private readonly store$: Store<any>,
    private xpertService: XpertService
  ) {}

  /**
   * Return a new Encaissement object with minimal data inside based on connected Xpert and Elevage.
   * @returns A Promise that resolves a new Encaissement object.
   */
  public async getNewEncaissementWithSessionData(): Promise<Encaissement> {
    const elevage: Elevage = await this.store$
      .select(getCurrentElevage)
      .pipe(take(1))
      .toPromise();

    const collaborateur = await this.xpertService.getConnectedXpert();

    if (!collaborateur || !elevage) {
      throw new Error(
        'Impossible to retrieve the connected collaborateur or the current elevage.'
      );
    }

    const encaissement = {
      date: new Date().toISOString(),
      montant: 0,
      modeReglement: null,
      statutRemisEnBanque: StatutRemisEnBanque.no,
      elevage: {
        _id: elevage?._id,
        numero: elevage.numero,
        nom: elevage.nom,
      },
      documents: [],
      collaborateur: {
        _id: collaborateur?._id,
        idXpert: collaborateur.idXpert,
        nom: collaborateur.nom,
        prenom: collaborateur.prenom,
        IBAN: collaborateur.IBAN,
      },
    };

    return encaissement;
  }

  /**
   * Retrieves the Encaissement object for the given invoices.
   *
   * @param invoices - An array of FactureGridDocument objects representing the invoices.
   * @returns A Promise that resolves to an Encaissement object.
   */
  public async getEncaissementForInvoices(
    montant: number,
    date: string,
    modeReglement: ModeReglement,
    numeroCheque: string | null,
    invoices: FactureGridDocument[]
  ): Promise<Encaissement> {
    // Map the FactureGridDocument to EncaissementDocument
    const documents: EncaissementDocument[] = [];
    for (const invoice of invoices) {
      // Check if the document already exists
      const document = documents.find(
        (doc) =>
          doc.numXpertMobile === invoice.numXpertMobile &&
          doc.domaine === invoice.domaine &&
          doc.type === invoice.type &&
          doc.numeroPiece === invoice.numeroPiece
      );
      if (document) {
        // Add the echeance to the document
        document.echeances.push({
          date: invoice.dateEcheance,
          montant: invoice.montantARegler,
        });
      } else {
        // Create a new document
        documents.push({
          numXpertMobile: invoice.numXpertMobile,
          domaine: invoice.domaine,
          type: invoice.type,
          numeroPiece: invoice.numeroPiece,
          echeances: [
            {
              date: invoice.dateEcheance,
              montant: invoice.montantARegler,
            },
          ],
        });
      }
    }
    const encaissement = await this.getNewEncaissementWithSessionData();
    encaissement.montant = montant;
    encaissement.date = date;
    encaissement.modeReglement = modeReglement;
    if (numeroCheque) {
      encaissement.numeroCheque = numeroCheque;
    }
    encaissement.documents = documents;
    return encaissement;
  }
}
