export interface TcSpinnerState {
  loading: SpinnerState;
}

export interface SpinnerState {
  actionsInProgress: number;
  isDisabled: boolean;
}

export const initialSpinnerState: SpinnerState = {
  actionsInProgress: 0,
  isDisabled: false,
};
