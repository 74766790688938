import { TcFilterStoreModule } from './tc-filter-store/tc-filter-store.module';
import { FormlyGenericPanelComponent } from './components/formly/formly-generic-panel/formly-generic-panel.component';
import { ExpansionPanelComponent } from './components/formly/expansion-panel/expansion-panel.component';
import { TcFormlyWrapper } from './enums/tc-formly-wrapper.enum';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  InjectionToken,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import moment from 'moment';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { TcAppDateAdapter } from './adapters/datepicker-adapter';
import { TcService } from '@tc/abstract';
import { TcDateRangeComponent } from './components/dumb/tc-daterange/tc-daterange.component';
import { TcDisplayHtmlComponent } from './components/dumb/tc-display-html/tc-display-html.component';
import { TcFilterComponent } from './components/dumb/tc-filter/tc-filter.component';
import { TcListComponent } from './components/dumb/tc-list/tc-list.component';
import { TcMultiSelectComponent } from './components/dumb/tc-multi-select/tc-multi-select.component';
import { TcPdfViewerComponent } from './components/dumb/tc-pdf-viewer/tc-pdf-viewer.component';
import { TcSelectComponent } from './components/dumb/tc-select/tc-select.component';
import { TcSimpleFilterComponent } from './components/dumb/tc-simple-filter/tc-simple-filter.component';
import { TcToggleComponent } from './components/dumb/tc-toggle/tc-toggle.component';
import { TcFormlyAutocompleteComponent } from './components/formly/tc-formly-autocomplete/tc-formly-autocomplete.component';
import { TcFormlyDatePicker } from './components/formly/tc-formly-date-picker/tc-formly-date-picker.component';
import { TcFormlyTextareaFieldComponent } from './components/formly/textarea/textarea.component';
import { TcEntityReferenceComponent } from './components/smart/tc-entity-reference/tc-entity-reference.component';
import { TcGenericFilterComponent } from './components/smart/tc-generic-filter/tc-generic-filter.component';
import { TcGenericListComponent } from './components/smart/tc-generic-list/tc-generic-list.component';
import { TcListNavItemComponent } from './components/smart/tc-list-nav-item/tc-list-nav-item.component';
import { TcNavMenuComponent } from './components/smart/tc-nav-menu/tc-nav-menu.component';
import { TCPageTitleComponent } from './components/smart/tc-page-title/tc-page-title.component';
import { TcCameraUploadComponent } from './components/tc-camera-upload/tc-camera-upload.component';
import { TcConfirmDialogComponent } from './components/tc-confirm-dialog/tc-confirm-dialog.component';
import { TcContentComponent } from './components/tc-content/tc-content.component';
import { TcDialogComponent } from './components/tc-dialog/tc-dialog.component';
import { TcLanguageComponent } from './components/tc-language/tc-language.component';
import { TcLongTextComponent } from './components/tc-long-text/tc-long-text.component';
import { TcMenuComponent } from './components/tc-menu/tc-menu.component';
import { TcPlaceholderComponent } from './components/tc-placeholder/tc-placeholder.component';
import { TcReportingDetailsComponent } from './components/tc-reporting-details/tc-reporting-details.component';
import { TcReportingFormComponent } from './components/tc-reporting-form/tc-reporting-form.component';
import { TcReportingListComponent } from './components/tc-reporting-list/tc-reporting-list.component';
import { TcSliderListComponent } from './components/tc-slider-list/tc-slider-list.component';
import { TcSpinnerComponent } from './components/tc-spinner/tc-spinner.component';
import { TcTreeComponent } from './components/tc-tree/tc-tree.component';
import { TcUploadFileComponent } from './components/tc-upload-file/tc-upload-file.component';
import { getConfig } from './config/get-config';
import { GetConfigService } from './config/get-config.service';
import { TcInfiniteScrollDirective } from './directives/tc-infinite-scroll.directive';
import { TcPreventDoubleClickDirective } from './directives/tc-prevent-doubleclick.directive';
import { TcErrorHandler } from './handlers/tc-error-handler';
import { TcMissingTranslationHandler } from './handlers/tc-missing-translations-handler';
import { HTTPListenerInterceptor } from './interceptors/http-listener.interceptor';
import { MaterialModule } from './material/material.module';
import { TcCurrencyFormat } from './pipes/tc-currency-format.pipe';
import { TcConfigService } from './services/tc-config.service';
import { TcCsvService } from './services/tc-csv.service';
import { TcLoggerService } from './services/tc-logger.service';
import { TcNotificationService } from './services/tc-notification.service';
import { TcReportingQueryService } from './services/tc-reporting-query.service';
import {
  CustomSerializer,
  HttpErrorEffects,
  SpinnerEffects,
  TcAppReducers,
  TcMetaReducers,
  TcSpinnerService,
} from '@tc/store';
import { TcTranslateService } from './services/tc-translate.service';
import { TcUploadFileService } from './services/tc-upload-file.service';
import { SettingsEffects, SettingsModule } from './settings';
import { MatPaginatorI18nService } from './translate/paginator-i18n.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { UtilsService } from './shared/services/utils.service';
import { RouterModule } from '@angular/router';
import { TcGridModule } from './tc-grid/tc-grid.module';
import { PanelWrapperComponent } from './components/formly/panel-wrapper/panel-wrapper.component';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { TcFormlyComponent } from './enums/tc-formly-component.enum';
import { TcBreezeService } from '@tc/breeze';
import { TcLocalStorageService } from '@tc/local-storage';
import { ngfModule } from 'angular-file';
import { TcFileUploaderComponent } from './components/tc-file-uploader/tc-file-uploader.component';
import { TcFileComponent } from './components/tc-file-uploader/tc-file/tc-file.component';
import { TcFileService } from 'libs/tc/core/src/tc-core/services/tc-file.service';
import {
  FormlyCustomValidators,
  NonNullFieldValidator,
  EmptyOrEmailFieldValidator,
  registerTranslateExtension,
} from './shared/utils/formly-utils';
import { TcFormlyFileUploaderComponent } from './components/formly/tc-formly-file-uploader/tc-formly-file-uploader.component';
import { TcDataStoreModule } from '@tc/data-store';
import { TcFormlyInputComponent } from './components/formly/tc-formly-input/tc-formly-input.component';
import { TcFormlySelectComponent } from './components/formly/tc-formly-select/tc-formly-select.component';
import { TcFormlyFieldButtonComponent } from './components/formly/tc-formly-field-button/tc-formly-field-button.component';
import { TcFormlyMultiSelectComponent } from './components/formly/tc-formly-multi-select/tc-formly-multi-select.component';
import { TcButtonsModule } from '@tc/buttons';
import { TcSmartMultiSelectComponent } from './components/smart/tc-smart-multi-select/tc-smart-multi-select.component';
import { TcSmartAutocompleteComponent } from './components/smart/tc-smart-autocomplete/tc-smart-autocomplete.component';
import { TcPermissionsModule } from '../tc-permissions/tc-permissions.module';
import { TcFormlyDateRangePicker } from './components/formly/tc-formly-date-range-picker/tc-formly-date-range-picker.component';
import { ServiceLocator } from './shared/services/service-locator';
import { TC_MAT_DATE_FORMAT } from './constants';
import { ClearInputSuffixWrapperComponent } from './components/formly/tc-formly-clear-input-suffix-wrapper/clear-input-suffix-wrapper.component';
import { TcFormlySmartSelectComponent } from './components/formly/tc-formly-smart-select/tc-formly-smart-select.component';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { TcMediaService } from './services/tc-media.service';
import { TCPageButtonsComponent } from './components/smart/tc-page-buttons/tc-page-buttons.component';
import { TCVersionHeaderComponent } from './components/smart/tc-version-header/tc-version-header.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { OfflineModeEffects } from '../tc-store/effects/offline-mode.effects';
import { ComponentPanelWrapperComponent } from './components/formly/component-panel-wrapper/component-panel-wrapper.component';
import { PortalModule } from '@angular/cdk/portal';
import { TcFormlyToggleComponent } from './components/formly/tc-formly-toggle/tc-formly-toggle.component';

moment.locale('fr');
export const TC_LIST_DEFAULT_NB_LINES = new InjectionToken<string>(
  'tcListDefaultNbLines'
);

const formlyComponents = [
  TcFormlyAutocompleteComponent,
  TcFormlyDatePicker,
  TcFormlyDateRangePicker,
  TcFormlyTextareaFieldComponent,
  TcFormlyMultiSelectComponent,
  TcFormlySmartSelectComponent,
];

const components = [
  ...formlyComponents,
  TcSpinnerComponent,
  TcLanguageComponent,
  TcGenericListComponent,
  TcConfirmDialogComponent,
  TcDialogComponent,
  TcListComponent,
  TcMenuComponent,
  TcSliderListComponent,
  TcUploadFileComponent,
  TcCameraUploadComponent,
  TcLongTextComponent,
  TcPlaceholderComponent,
  TcContentComponent,
  TcEntityReferenceComponent,
  TcListNavItemComponent,
  TcNavMenuComponent,
  TcSimpleFilterComponent,
  TcGenericFilterComponent,
  TcDisplayHtmlComponent,
  TCPageTitleComponent,
  TCPageButtonsComponent,
  TcReportingFormComponent,
  TcTreeComponent,
  TcReportingDetailsComponent,
  TcReportingListComponent,
  TcFormlyMultiSelectComponent,
  TcFormlyFileUploaderComponent,
  TcFilterComponent,
  TcDateRangeComponent,
  TcToggleComponent,
  TcMultiSelectComponent,
  TcSmartMultiSelectComponent,
  TcSmartAutocompleteComponent,
  TcSelectComponent,
  TcPdfViewerComponent,
  TcFormlyInputComponent,
  TcFormlySelectComponent,
  TcFormlyToggleComponent,
  TcFormlyFieldButtonComponent,
  ExpansionPanelComponent,
  PanelWrapperComponent,
  ComponentPanelWrapperComponent,
  FormlyGenericPanelComponent,
  ClearInputSuffixWrapperComponent,
  TcFileUploaderComponent,
  TcFileComponent,
  TCVersionHeaderComponent,
];

const directives = [TcPreventDoubleClickDirective, TcInfiniteScrollDirective];

const services = [
  TcService,
  TcConfigService,
  TcSpinnerService,
  TcTranslateService,
  TcLoggerService,
  TcNotificationService,
  TcUploadFileService,
  TcCsvService,
  TcReportingQueryService,
  TcUploadFileService,
  UtilsService,
  TcBreezeService,
  TcLocalStorageService,
  TcFileService,
  TcMediaService,
];

const pipes = [TcCurrencyFormat];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [
    // angular
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    DragDropModule,

    // swipper module
    SwiperModule,

    // settings module
    SettingsModule,

    // material module
    MaterialModule,

    // tc grid module
    TcGridModule,

    // tc filter store module
    TcFilterStoreModule,

    // tc data store module
    TcDataStoreModule,

    // tc action buttons module
    TcButtonsModule,

    // tc permissions module
    TcPermissionsModule,

    // ng formly
    FormlyModule.forRoot({
      types: [
        {
          name: TcFormlyComponent.TcAutoComplete,
          component: TcFormlyAutocompleteComponent,
          wrappers: ['form-field', TcFormlyWrapper.ClearInputSuffix],
        },
        {
          name: TcFormlyComponent.TcMultiSelect,
          component: TcFormlyMultiSelectComponent,
          wrappers: ['form-field', TcFormlyWrapper.ClearInputSuffix],
        },
        {
          name: TcFormlyComponent.TcSmartSelect,
          component: TcFormlySmartSelectComponent,
          wrappers: ['form-field', TcFormlyWrapper.ClearInputSuffix],
        },
        {
          name: TcFormlyComponent.TcFileUploader,
          component: TcFormlyFileUploaderComponent,
        },
        {
          name: TcFormlyComponent.TcDatePicker,
          component: TcFormlyDatePicker,
          wrappers: ['form-field'],
          defaultOptions: {
            className: 'small-date-picker',
          },
        },
        {
          name: TcFormlyComponent.TcDateRangePicker,
          component: TcFormlyDateRangePicker,
          wrappers: ['form-field'],
        },
        {
          name: TcFormlyComponent.TcTextarea,
          component: TcFormlyTextareaFieldComponent,
          wrappers: ['form-field', TcFormlyWrapper.ClearInputSuffix],
        },
        {
          name: TcFormlyComponent.FormlyInput,
          component: TcFormlyInputComponent,
          wrappers: ['form-field', TcFormlyWrapper.ClearInputSuffix],
        },
        {
          name: TcFormlyComponent.FormlySelect,
          // component: TcFormlySelectComponent,
          wrappers: ['form-field', TcFormlyWrapper.ClearInputSuffix],
        },
        {
          name: TcFormlyComponent.TcToggle,
          component: TcFormlyToggleComponent,
          wrappers: ['form-field'],
        },
        {
          name: TcFormlyComponent.TcButton,
          component: TcFormlyFieldButtonComponent,
        },
      ],
      wrappers: [
        {
          name: TcFormlyWrapper.Panel,
          component: PanelWrapperComponent,
        },
        {
          name: TcFormlyWrapper.ExpansionPanel,
          component: ExpansionPanelComponent,
        },
        {
          name: TcFormlyWrapper.GenericPanel,
          component: FormlyGenericPanelComponent,
        },
        {
          name: TcFormlyWrapper.ClearInputSuffix,
          component: ClearInputSuffixWrapperComponent,
        },
        {
          name: TcFormlyWrapper.ComponentPanel,
          component: ComponentPanelWrapperComponent,
        },
      ],
      validators: [
        {
          name: FormlyCustomValidators.NonNullField,
          validation: NonNullFieldValidator,
        },
        {
          name: FormlyCustomValidators.EmptyOrEmail,
          validation: EmptyOrEmailFieldValidator,
        },
      ],
    }),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    FormlySelectModule,

    // Uncomment if we will ever need the FormlyDatePicker
    // FormlyMatDatepickerModule,

    NgxDaterangepickerMd.forRoot(),
    NgxMaskModule.forRoot({}),

    // store
    StoreModule.forRoot(TcAppReducers, { metaReducers: TcMetaReducers }),
    EffectsModule.forRoot([
      HttpErrorEffects,
      SpinnerEffects,
      SettingsEffects,
      OfflineModeEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),

    // logger
    LoggerModule.forRoot({ level: NgxLoggerLevel.INFO }),

    // translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TcMissingTranslationHandler,
      },
      isolate: false,
    }),

    // toastr
    ToastrModule.forRoot(),

    // infinite scroll
    InfiniteScrollModule,

    // tiny mce
    EditorModule,

    // date material-moment-adapter
    MatMomentDateModule,

    // Pdf Viewer
    PdfViewerModule,
    RouterModule,

    // Formly slide toggle
    FormlyMatToggleModule,

    // File uploader
    ngfModule,
    PortalModule,
  ],
  providers: [
    ...services,
    ...pipes,
    {
      provide: APP_INITIALIZER,
      useFactory: getConfig,
      deps: [HttpClient, GetConfigService],
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListenerInterceptor,
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    { provide: ErrorHandler, useClass: TcErrorHandler },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: TC_MAT_DATE_FORMAT },
    {
      provide: DateAdapter,
      useClass: TcAppDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [TcTranslateService],
    },
    CurrencyPipe,
  ],
  exports: [
    ...components,
    ...directives,
    ...pipes,
    // translate module
    TranslateModule,
    // material module
    MaterialModule,
    // formly module
    FormlyModule,
    FormlyMaterialModule,
    // angular
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // tc grid module
    TcGridModule,

    // tc filter module
    TcFilterStoreModule,
  ],
})
export class TcCoreModule {
  constructor(
    private injector: Injector,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    // Create global Service Injector.
    ServiceLocator.injector = this.injector;

    // registering of ionicons for using with mat-icon
    this.matIconRegistry.addSvgIconResolver((name, namespace) =>
      namespace === 'ion'
        ? this.sanitizer.bypassSecurityTrustResourceUrl(
            `assets/ionicons/${name}.svg`
          )
        : null
    );
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './app/custom/assets/i18n/', suffix: '.json' },
  ]);
}
