import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LivraisonQuantities, LivraisonState } from './livraison.state';
import * as R from 'ramda';

export const getLivraisonState =
  createFeatureSelector<LivraisonState>('livraison');

export const getLivraisonStateByStoreKey = (
  state: LivraisonState,
  props: { storeKey: string }
) => R.prop(props.storeKey)(state);

export const getExceededQuantitiesState = createSelector(
  getLivraisonStateByStoreKey,
  (state: LivraisonQuantities) => {
    return R.propOr(false, 'hasExceedingQuantities')(state);
  }
);
