import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ElevageState } from './elevage.state';

export const getElevageState = createFeatureSelector<ElevageState>('elevage');

export const getCurrentElevage = createSelector(
  getElevageState,
  (state: ElevageState) => state.currentElevage
);

export const getShowElevageContactRoutes = createSelector(
  getElevageState,
  (state: ElevageState) => state.shownElevageContactRoutes
);

export const getLastFilteredSecteur = createSelector(
  getElevageState,
  (state: ElevageState) => state.lastFilteredSecteur
);
