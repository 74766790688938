import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { TcSpinnerState } from '@tc/store';
import { TcComponent } from '@tc/abstract';

@Component({
  selector: 'tc-spinner',
  templateUrl: './tc-spinner.component.html',
  styleUrls: ['./tc-spinner.component.css'],
})
export class TcSpinnerComponent extends TcComponent implements OnInit {
  @Input()
  spinnerOverlay = '';

  @Input()
  spinnerClass = '';

  @Input()
  customContent = false;

  isActive: Observable<boolean>;

  constructor(private store: Store<TcSpinnerState>) {
    super();

    this.isActive = this.store.pipe(
      select(
        (state: TcSpinnerState) =>
          state.loading.actionsInProgress > 0 && !state.loading.isDisabled
      )
    );
  }

  ngOnInit() {}
}
