import { createReducer, on } from '@ngrx/store';
import { setHomeButtonsLists } from './home.actions';
import { initialHomeState, HomeState } from './home.state';

export const homeReducer = createReducer<HomeState>(
  initialHomeState,
  on(
    setHomeButtonsLists,
    (state, { elevageButtonsList, stockButtonsList, xpertButtonsList }) => ({
      ...state,
      elevageButtonsList: elevageButtonsList,
      stockButtonsList: stockButtonsList,
      xpertButtonsList: xpertButtonsList,
    })
  )
);
