export enum CustomRoutes {
  AccesNonAutorise = 'acces-non-autorise',
  Etl = 'etl',
  EtlXpert2Sage = 'export-csv',
  EtlXpertExtractOnly = 'etl-recette-extract-only',
  ElevageDetail = 'elevage-detail',
  ElevageList = 'liste-des-elevages',
  DevisList = 'devis-list',
  CommandesList = 'order-list',
  FactureList = 'invoices-list',
  DetailCommandesList = 'details-de-commande',
  DevisDetail = 'devis-detail',
  XpertList = 'xpert-list',
  XrOptimaPurchaseHistory = 'xroptima-purchase-history',
  CatalogArticles = 'catalogue-articles',
  Doses = 'faire-devis-prendre-commande-doses',
  // XAS-84 - Hide features
  // ConsultationStock = 'consultation-stock',
  MakeEstimateOrTakeOrder = 'faire-devis-prendre-commande',
  BankDeposit = 'remise-en-banque',
  // XAS-84 - Hide features
  // Livraison = 'livraison',
  // DeliveriesList = 'entete-de-livraison',
  // XpertDeposit = 'depot-xpert',
  // XpertTransfert = 'transfert-xpert',
  // Restock = 'liste-de-reappro',
  // RestockDetail = 'details-de-reappro',
}
