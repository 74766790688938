export const ElevageGridFilterFields = [
  'numero',
  'nom',
  'adresse',
  'complementAdresse',
  'codePostal',
  'ville',
  'pays',
  'telephone',
  'email',
  'siret',
  'numeroTVAIntracommunautaire',
  'codeAPE',
  'categorieComptable',
  'categorieTarifParDefaut',
  'contratBovinViande',
  'contratBovinLait',
  'contratCaprin',
  'adherent',
  'cuve',
  'contactPrincipal',
  'doubleactif',
  'engagedansautresstructures',
  'coordonneesGPS',
  'nombreAssocies',
  'elevageBovin',
  'elevageCaprin',
  'elevageOvin',
  'elevageAutres',
  'caprinAdherentCapgene',
  'caprinNbChevres',
  'carpinNbChevrettes',
  'caprinNbBoucs',
  'caprinRace1',
  'caprinRace2',
  'caprinRace3',
  'caprinProductionParChevreParAn',
  'caprinAOP',
  'caprinBIO',
  'caprinModederepro',
  'caprinDeclenchementchaleurs',
  'caprinControledeperf',
  'caprinDeboucheslait',
  'caprinDebouchesviandes',
  'bovinNbvaches',
  'bovinProductionParVacheParAn',
  'bovinControleDePerf',
  'bovinRace1',
  'bovinRace2',
  'bovinRace3',
  'bovinPourcentageAchatdeDosesExt',
  'bovinNomsDesVendeursDeDose',
  'bovinBIO',
  'bovinIPE',
  'bovinMontenaturelle',
  'bovinSuivireproQui',
  'bovinNomduveterinaire',
  'bovinDeboucheslait',
  'bovinDebouchesviandes',
  'bovinRobot',
  'bovinTypederation',
  'portable1',
  'portable2',
  'editionFacturePapier',
  'geographie',

  'reglement.intitule',
  'reglement.condition',
  'reglement.nbJours',

  'site.numeroInterne',
  'site.numeroCompteTiers',
  'site.intitule',
  'site.adresse',
  'site.coordonneesGPS',
  'site.codePostal',
  'site.ville',
  'site.telephone',
  'site.adresseEMail',
  'site.contactSurSite',
  'site.geographie',

  'contact.nom',
  'contact.prenom',
  'contact.fonction',
  'contact.telportable',
  'contact.telephone',
  'contact.email',

  'documents.domaine',
  'documents.type',
  'documents.categorieComptable',
  'documents.date',
  'documents.dateLivraison',
  'documents.tiersPayeur._id', // todo needed?
  'documents.tiersPayeur.numero',
  'documents.numeroPiece',
  'documents.siteLivraison._id', // todo needed?
  'documents.siteLivraison.intitule',
  'documents.alerteQuotaTaureau',
  'documents.noteDeReglement',
  'documents.ligne.domaine',
  'documents.ligne.numeroInterne',
  'documents.ligne.numeroPiece',
  'documents.ligne.type',
  'documents.ligne.article.reference',
  'documents.ligne.libelle',
  'documents.ligne.quantite',
  'documents.ligne.prixUnitaire',
  'documents.ligne.taxe',
  'documents.ligne.dateLivraison',
  'documents.ligne.codeTaxe',
  'documents.ligne.numeroPieceDevisOrigine',
  'documents.ligne.numeroPieceBonCommandeOrigine',
  'documents.ligne.numeroPieceBonLivraisonOrigine',
  'documents.ligne.datePieceDevisOrigine',
  'documents.ligne.datePieceBonCommandeOrigine',
  'documents.ligne.datePieceBonLivraisonOrigine',
  'documents.ligne.montantRemise',
  'documents.ligne.typeRemise',
  'documents.ligne.alerteQuotaTaureau',
  'documents.echeance.date',
  'documents.echeance.montant',
  'documents.echeance.ligneEquilibrage',
  'documents.echeance.numeroReglement',
  'documents.provenance',
  'documents.prescripteur.nom',
  'documents.prescripteur.prenom',
  // 'historiqueAchatsAppros', // todo no data in DB yet
  // 'ecrituresComptables', // todo no data in DB yet

  'syntheseFinanciere.caN',
  'syntheseFinanciere.caN1',
  'syntheseFinanciere.ca12M',
  'syntheseFinanciere.commandesEnCours',
  'syntheseFinanciere.devisEnCours',
  'syntheseFinanciere.factures.date',
  'syntheseFinanciere.factures.numeroDocument',
  'syntheseFinanciere.factures.echeance',
  'syntheseFinanciere.factures.montant',
  'syntheseFinanciere.factures.paye',
  'syntheseFinanciere.factures.echue',

  'lastUpdatedOn',
  'createdOn',
  // 'encaissements', // todo no data in DB yet

  'territoire.ID',
  'territoire.numero',
  'territoire.libelle',
  'territoire.groupe.numero',
  'territoire.groupe.libelle',
  'territoire.groupe.secteur.numero',
  'territoire.groupe.secteur.libelle',
];
