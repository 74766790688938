import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { TcDataProviderType } from '../../tc-abstract/component-configs';
import { TcOfflineModeService } from '../../tc-breeze/interfaces/tc-offline-mode.interface';
import { TcDataServiceFactory } from '../../tc-data-store/data-services';
import { TcPromptDialogComponent } from '@tc/dialog';
import {
  activateOfflineMode,
  offlineModeActivated,
  hasOfflineMode,
  terminateOfflineMode,
  offlineModeDeactivated,
  updateSyncPercentage,
} from '../actions/offline-mode.actions';
import { TcAppState } from '../store';
import { MatDialog } from '@angular/material/dialog';
import { TcTranslateService } from '../../tc-core/services/tc-translate.service';
import { TcNotificationService } from '../../tc-core/services/tc-notification.service';
import { TcComponentLookupRegistry } from '../../tc-core/decorators';
import {
  SyncResponsePopupPayload,
  TcOfflineModeStates,
} from '../../tc-breeze/interfaces';
import { getSyncPercentage } from '@tc/store';
import { TcLocalStorageService } from '../../tc-local-storage/tc-local-storage.service';

@Injectable()
export class OfflineModeEffects {
  offlineModeServiceFactory: (
    providerType: TcDataProviderType
  ) => TcOfflineModeService;

  private offlineModeService: TcOfflineModeService;
  private lastSyncDateKey = 'lastSyncDate';

  constructor(
    private actions$: Actions,
    protected tcAppStore$: Store<TcAppState>,
    private tcdataServiceFactory: TcDataServiceFactory,
    private readonly dialog: MatDialog,
    private readonly translateService: TcTranslateService,
    private readonly notificationService: TcNotificationService,
    private readonly localStorage: TcLocalStorageService
  ) {
    this.offlineModeServiceFactory =
      this.tcdataServiceFactory.getOfflineModeService;
  }

  hasOfflineMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hasOfflineMode),
        map(async ({ providerType, relaunch, popupComponentName }) => {
          const isOfflineModeActivated = await this.offlineModeServiceFactory(
            providerType
          ).hasOfflineMode();
          if (isOfflineModeActivated) {
            this.tcAppStore$.dispatch(offlineModeActivated());
          } else {
            this.tcAppStore$.dispatch(offlineModeDeactivated());
            // Option to activate offline mode if he is not launched
            if (relaunch === true) {
              this.tcAppStore$.dispatch(
                activateOfflineMode({ providerType, popupComponentName })
              );
            }
          }
          return isOfflineModeActivated;
        })
      ),
    { dispatch: false }
  );

  activateOfflineMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateOfflineMode),
        map(async ({ providerType, popupComponentName }) => {
          const offlineModeService =
            this.offlineModeServiceFactory(providerType);

          if (!this.offlineModeService) {
            this.offlineModeService = offlineModeService;
            this.startSyncTracking();
          }

          if (offlineModeService.offlineModeState !== TcOfflineModeStates.Off) {
            return;
          }

          const dialogRef = this.dialog.open(TcPromptDialogComponent, {
            width: '37.5em',
            data: {
              title: this.translateService.instant('globalLabels.sync'),
              disableTextTranslation: true,
              text: this.translateService.instant(
                'globalMessages.wait-for-sync'
              ),
              displayCancelButton: false,
              displayConfirmButton: false,
              disableClose: true,
              progress$: this.tcAppStore$.pipe(select(getSyncPercentage)),
            },
          });

          const result = await offlineModeService.activateOfflineMode();

          if (!result.success) {
            dialogRef.close();
            console.error(result?.errors);
            this.notificationService.error(
              this.translateService.instant('globalMessages.error-during-sync')
            );
            return;
          }
          dialogRef.close();

          if (popupComponentName) {
            const data: SyncResponsePopupPayload = {
              title: 'sync-result',
              ...result,
            };
            await this.showCustomPopup(popupComponentName, data);
          }

          this.localStorage.set(this.lastSyncDateKey, new Date().toISOString());
          this.tcAppStore$.dispatch(offlineModeActivated());
          return result;
        })
      ),
    { dispatch: false }
  );

  terminateOfflineMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(terminateOfflineMode),
        map(async ({ providerType, relaunch, popupComponentName }) => {
          const result = await this.offlineModeServiceFactory(
            providerType
          ).terminateOfflineMode();
          if (!result.success) {
            console.error(result?.errors);
            this.notificationService.error(
              this.translateService.instant('globalMessages.error-during-sync')
            );
            return;
          }

          // Krakenn Task 78924: do not show result popup after first sync
          // Uncomment the following code if the result popup is needed back
          // if (popupComponentName) {
          //   const data = {
          //     title: 'sync-data-sent',
          //     ...result,
          //   };
          //   await this.showCustomPopup(popupComponentName, data);
          // }

          this.tcAppStore$.dispatch(offlineModeDeactivated());
          if (relaunch === true) {
            this.tcAppStore$.dispatch(
              activateOfflineMode({ providerType, popupComponentName })
            );
          }
          return result;
        })
      ),
    { dispatch: false }
  );

  showCustomPopup(popupComponentName: string, data: SyncResponsePopupPayload) {
    return new Promise((resolve) => {
      const popupComponent = TcComponentLookupRegistry.get(popupComponentName);
      const dialog = this.dialog.open(popupComponent, {
        width: '27.5em',
        disableClose: true,
        data,
      });
      dialog.afterClosed().subscribe(resolve);
    });
  }

  private startSyncTracking() {
    if (this.offlineModeService?.syncPercentage$) {
      this.offlineModeService.syncPercentage$.subscribe((percentage) => {
        this.tcAppStore$.dispatch(updateSyncPercentage({ percentage }));
      });
    }
  }
}
