import { I } from '@angular/cdk/keycodes';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcAppState } from '@tc/store';
import { from, Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import {
  getAuthenticationState
} from '../../modules/auth/store/auth.selectors';

@Injectable({
  providedIn: 'root',
})
/**
 * AuthInterceptorService is a class that implements the HttpInterceptor interface
 * to intercept HTTP requests and add authentication headers.
 */
export class AuthInterceptorService implements HttpInterceptor {
  /**
   * Constructs a new instance of the class.
   * @param {Store<TcAppState>} store$ - The store object used for state management.
   */
  constructor(private readonly store$: Store<TcAppState>) {}

  /**
   * Intercepts HTTP requests and adds authentication headers before forwarding the request.
   * @param {HttpRequest<any>} req - The HTTP request object.
   * @param {HttpHandler} next - The next HTTP handler in the chain.
   * @returns An Observable that emits an HttpEvent<any> object.
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.getAuthData()).pipe(
      mergeMap((authData) => {
        let headers = req.headers;
        if(authData?.loginAs){
          headers = headers.set('login-as', authData.loginAs);
        }
        
        const { token } = authData;
        if(token){
          headers=headers.set('Authorization', `Bearer ${token}`);
        }

        const request = req.clone({
              headers: headers
            });

        return next.handle(request);
      })
    );
  }

  /**
   * Retrieves the authentication data including the token and loginAs value.
   * @returns A promise that resolves to an object containing the token and loginAs values.
   */
  private async getAuthData(): Promise<{token: string, loginAs: string}> {
    const authenticationState = await this.store$
      .select(getAuthenticationState)
      .pipe(take(1))
      .toPromise();

    return { token: authenticationState.mfaOptions?.token || authenticationState.token, loginAs: authenticationState.loginAs};
  }
}
