/**
 * Main state
 */
export interface XpertState {
  passwordChange: PasswordChange;
}

export interface PasswordChange {
  xpert: any | null;
  storeKey: string | null;
  password: string | null;
}

/**
 * Initial state
 */
export const initialXpertState: XpertState = {
  passwordChange: {
    xpert: null,
    storeKey: null,
    password: null,
  },
};
