import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getOfflineModeActivated, terminateOfflineMode } from '@tc/store';
import { ConfigService } from '../../../../../../shared/services/config.service';
import { ConfigKeys } from '../../../../../../shared/interfaces/config.interface';
import { SyncService } from '../../../../../services/sync.service';
import { AuthenticationService } from '../../../../../../services/authentication.service';
import { TcLocalStorageService } from '@tc/local-storage';

@Component({
  selector: 'app-sync-button',
  templateUrl: './sync-button.component.html',
  styleUrls: ['./sync-button.component.scss'],
})
export class SyncButtonComponent implements OnInit, OnDestroy {
  lastSyncDate;
  constructor(
    public readonly store$: Store<any>,
    private readonly config: ConfigService,
    private readonly syncService: SyncService,
    private readonly authenticationService: AuthenticationService,
    private readonly localStorage: TcLocalStorageService
  ) {}

  showSyncDataBtn = false;
  subscription: Subscription = new Subscription();

  ngOnInit() {
    // Subscribe to the store to know if the button should be displayed or not
    const subscription = this.store$
      .pipe(select(getOfflineModeActivated))
      .subscribe(async (data) => {
        this.showSyncDataBtn = data;
        setTimeout(async () => {
          this.lastSyncDate = await this.localStorage.get('lastSyncDate');
        }, 100);
      });
    this.subscription.add(subscription);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async launchSync() {
    const response = await this.authenticationService.restoreSession();

    if (response?.accessToken) {
      const isSyncAvailable = await this.syncService.isSyncAvailable();

      if (isSyncAvailable) {
        this.store$.dispatch(
          terminateOfflineMode({
            providerType: this.config.get(
              ConfigKeys.offlineModeDataProviderType
            ),
            relaunch: true,
            popupComponentName: 'SyncResponsePopupComponent',
          })
        );
      }
    }
  }
}
