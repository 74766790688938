import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TcSmartButton } from '@tc/buttons';

@Component({
  selector: 'app-tc-formly-field-button',
  templateUrl: './tc-formly-field-button.component.html',
  styleUrls: ['./tc-formly-field-button.component.scss'],
})
export class TcFormlyFieldButtonComponent extends FieldType {
  defaultOptions = {
    // These properties get automatically set as default for this.to
    templateOptions: {
      config: {} as TcSmartButton,
    },
  };
}
