import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { TcVersionModule } from '@tc/version';
import { TcButtonsModule } from '@tc/buttons';
import { CustomLayoutComponent } from './components/smart/custom-layout/custom-layout.component';
import { TcLayoutModule } from '@tc/layout';
import { StoreModule } from '@ngrx/store';
import { layoutReducer } from './store/layout.reducer';
import { ElevageModule } from '../elevage/elevage.module';
import { LoginLayoutComponent } from './components/dumb/login-layout/login-layout.component';
import { SyncButtonComponent } from './components/smart/sync-button/sync-button.component';
import { SyncResponsePopupComponent } from './components/dumb/sync-response-popup/sync-response-popup.component';

const dialogs = [SyncResponsePopupComponent];

@NgModule({
  declarations: [
    LoginLayoutComponent,
    CustomLayoutComponent,
    SyncButtonComponent,
    ...dialogs,
  ],
  imports: [
    CommonModule,
    TcCoreModule,
    RouterModule,
    TcVersionModule,
    TcButtonsModule,
    TcLayoutModule,
    ElevageModule,
    StoreModule.forFeature('layout', layoutReducer),
  ],
})
export class CustomLayoutModule {}
