import { ArticleOrigin } from '../../commande/store/commande.payloads';
import { ElevageDocument } from '../../elevage/interfaces/elevage-document.interface';

export interface CatalogArticleState {
  docButtonsState: CatalogArticleDocButtonState;
  currentDocument: ElevageDocument | null; // Used by estimates/orders/delivery to store the working document.
  currentDocumentArticleOrigin: ArticleOrigin | null;
}

export interface CatalogArticleDocButtonState {
  canMakeEstimate: boolean;
  canMakeOrder: boolean;
}

export const initialCatalogArticleState: CatalogArticleState = {
  currentDocument: null,
  currentDocumentArticleOrigin: null,
  docButtonsState: {
    canMakeEstimate: false,
    canMakeOrder: false,
  },
};
