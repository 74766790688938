import { ConfigKeys } from '../../../shared/interfaces/config.interface';
/**
 * An object containing various custom configuration keys used in the application.
 * @property {string} enableDevisCommandeDoses - switch for CustomRoutes.Doses.
 */
export const CustomConfigKeys = {
  ...ConfigKeys,
  enableDevisCommandeDoses: 'enableDevisCommandeDoses',
  enableRemiseEnBanque: 'enableRemiseEnBanque',
};
