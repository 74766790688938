import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-ngx-input',
  templateUrl: './ngx-input.component.html',
  styleUrls: ['./ngx-input.component.scss'],
})
/**
 * A custom input component for ngx-formly that represents a time input field.
 * @class NgxInputFormlyComponent
 * @extends FieldType
 * @implements OnInit
 */
export class NgxInputFormlyComponent extends FieldType implements OnInit {
  public mask = '00:00';
  public required = false;

  /**
   * Initializes the component.
   * Sets the value of the 'mask' property to the value of 'to.mask' if it exists, otherwise keeps the current value.
   * Sets the value of the 'required' property to the value of 'to.required' if it exists, otherwise keeps the current value.
   * @returns None
   */
  ngOnInit() {
    this.mask = this.to.mask || this.mask;
    this.required = this.to.required || this.required;
  }

  /**
   * Handles the change event for the form control.
   * If the "to" property has a "setValue" method, it sets the value of the form control to the current value.
   * @returns None
   */
  public handleChange() {
    if (this.to.setValue) {
      this.to.setValue(this.formControl.value);
    }
  }
}
