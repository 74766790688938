import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  TcTranslateService,
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormComponent,
} from '@tc/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { TcSmartButton } from '@tc/buttons';
import { Article } from '../../../../article/interfaces/article.interface';
import { ArticlesMovement } from '../../../store/stock.state';
import { ModeSuiviStock } from '../../../../../typings/mode-suivi-stock.enum';
import {
  FilterTypesEnum,
  TcConfigTypes,
  TcDataProviderConfig,
  TcDataProviderType,
} from '@tc/abstract';
import { initTcListDataStore } from '@tc/data-store';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ArticleMovementPopupComponent } from '../article-movement-popup/article-movement-popup.component';
import { getMovement } from '../../../store/stock.selectors';
import * as R from 'ramda';
import { setMovementDeparture } from '../../../store/stock.actions';

@Component({
  selector: 'app-article-movement-selection',
  templateUrl: './article-movement-selection.component.html',
  styleUrls: ['./article-movement-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleMovementSelectionComponent
  extends TcFormComponent<any>
  implements OnInit, OnDestroy
{
  private subscriptions: Subscription = new Subscription();
  storeKeyArticleAutocomplete = 'article-autocomplete';
  form: FormGroup = new FormGroup({});
  model: { article: Article };
  formFields: FormlyFieldConfig[];
  buttonsList: TcSmartButton[];
  movement: ArticlesMovement;

  constructor(
    public readonly translate: TcTranslateService,
    private readonly store$: Store<any>,
    public elem: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private dialog: MatDialog
  ) {
    super(translate, elem);
  }

  async ngOnInit() {
    super.ngOnInit();

    // Init store for autocomplete results
    this.store$.dispatch(
      initTcListDataStore({
        storeKey: this.storeKeyArticleAutocomplete,
        listConfig: {
          storeKey: this.storeKeyArticleAutocomplete,
          dataProvider: this.getArticleAutocompleteDataProvider(),
          configType: TcConfigTypes.TcFormField,
        },
      })
    );

    // Subscription on movement to get current departure
    const movementSubscription = this.store$
      .select(getMovement)
      .subscribe((movement) => {
        this.movement = movement;
      });
    this.subscriptions.add(movementSubscription);

    // Subscription to form changes to detect selected article
    const subcriptionValueChanges = this.form.valueChanges.subscribe(
      (value) => {
        const article = value.article;
        if (article && typeof article === 'object') {
          // User has selected an article, open popup
          const dialog = this.dialog.open(ArticleMovementPopupComponent, {
            width: '33em',
            data: {
              departure: R.clone(this.movement.departure),
              article: R.clone(article),
            },
          });
          dialog.afterClosed().subscribe(() => {
            // Autocleanup the reference when the article popup is closed
            const element = this.document.querySelector('.articleAutocomplete');
            const input = element.querySelector('input') as HTMLInputElement;
            this.form.controls.article.reset();
            // Form control is not working in this case, used pure javascript
            input.value = null;
            input.blur();
          });
        }
      }
    );
    this.subscriptions.add(subcriptionValueChanges);

    this.initFields();
    this.initButtons();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initFields() {
    this.formFields = [
      formlyColumn({
        fields: [
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'article',
                className: 'articleAutocomplete',
                type: TcFormlyComponent.TcAutoComplete,
                templateOptions: {
                  storeKey: this.storeKeyArticleAutocomplete,
                  placeholder: 'search-articles-placeholder',
                  filterOn: ['intitule', 'reference'],
                  appearance: 'outline',
                  dataProvider: this.getArticleAutocompleteDataProvider(),
                  allowNewItem: false,
                  autocompleteMinLength: 1,
                  labelFieldName: 'intitule',
                  autoSelectIfOneResult: true,
                },
              }),
            ],
          }),
        ],
      }),
    ];
  }

  getArticleAutocompleteDataProvider(): TcDataProviderConfig {
    return {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'reference,intitule',
      filter: {
        filters: [
          {
            key: 'typeContrat',
            value: '',
            filterType: FilterTypesEnum.IsNotNullOrEmptyString,
          },
          {
            key: 'modeSuiviStock',
            value: [
              ModeSuiviStock.SuiviCMUP,
              ModeSuiviStock.SuiviLotsDLUO,
            ].join(','),
            filterType: FilterTypesEnum.In,
          },
        ],
      },
    };
  }

  focusArticleInput() {
    const element = this.document.querySelector('.articleAutocomplete');
    const input = element.querySelector('input') as HTMLInputElement;
    this.form.controls.article.reset();
    input.value = null;
    input.focus();
  }

  initButtons() {
    const buttons: TcSmartButton[] = [];

    buttons.push({
      label: 'scan-products',
      action: this.focusArticleInput.bind(this),
      ionIcon: 'barcode-outline',
      class: 'btn-secondary dialog-button',
    });

    this.buttonsList = buttons;
  }
}
