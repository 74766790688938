import { EntityMetadataMap } from 'ngrx-data';

/**
 * An object that maps entity names to their corresponding metadata.
 * @type {EntityMetadataMap}
 */
const entityMetadata: EntityMetadataMap = {
  StarShip: {},
};

/**
 * An empty object used to store plural names.
 */
const pluralNames = {};

/**
 * An object that contains entity configuration data.
 * @property {object} entityMetadata - Metadata about the entities.
 * @property {object} pluralNames - Plural names for the entities.
 */
export const entityConfig = {
  entityMetadata,
  pluralNames,
};
