import { Component } from '@angular/core';
import { TcPage } from '@tc/core';

@Component({
  selector: 'app-terms-conditions-page',
  templateUrl: './terms-conditions-page.component.html',
  styleUrls: ['./terms-conditions-page.component.scss'],
})
/**
 * Represents the Terms and Conditions page component.
 * Extends the TcPage class.
 */
export class TermsConditionsPageComponent extends TcPage {
  constructor() {
    super();
  }
}
