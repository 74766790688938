import { createAction, props } from '@ngrx/store';

export const initLayout = createAction('[Layout] Init Layout');

export const setLayoutHiddenSidenavRoutes = createAction(
  '[Layout] Set Layout Hidden Sidenav Routes',
  props<{ routes: string[] }>()
);

export const setVisibleXrOptimaLogoRoutes = createAction(
  '[Layout] Set Visisble XrOptima Logo Routes',
  props<{ routes: string[] }>()
);
