import { can } from '@tc/permissions';
import { Injectable } from '@angular/core';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
  TcFilterDef,
  TcFilterItem,
} from '@tc/abstract';
import { TcDataService, loadTcDataSuccess } from '@tc/data-store';
import { TcLocalStorageService } from '@tc/local-storage';
import { Article } from '../modules/article/interfaces/article.interface';
import { Depot } from '../modules/depot/interfaces/depot.interface';
import { Emplacement } from '../modules/depot/interfaces/emplacement.interface';
import { DepotStockType } from '../typings/depot.enum';
import { LocalStorageKeys } from '../typings/local-storage-keys.enum';
import { ModeSuiviStock } from '../typings/mode-suivi-stock.enum';
import { XpertService } from './xpert.service';
import { DAS } from '../typings/DAS.enum';
import { TcSpinnerService, selectValueByKey } from '@tc/store';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  NgRxTcFilterState,
  getTcFilters,
} from '@tc/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  // Key for the linked DAS filters
  DASFilkterKey = 'DAS.intitule';
  DASOffreFilterKey = 'DAS.offre.intitule';
  DASGammeFilterKey = 'DAS.offre.gamme.intitule';
  DASFamilleFilterKey = 'DAS.offre.gamme.famille.intitule';
  DASSousFamilleFilterKey = 'sousFamille';

  // Store observables
  filterStore$: Observable<NgRxTcFilterState>;

  private articleDataService: ITcDataService<any> = this.dataService.getService(
    'Article',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'DAS,reference,intitule,stock,sousFamille,conditionnementXpert',
    }
  );

  private depotDataService: ITcDataService<any> = this.dataService.getService(
    'refDepot',
    {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
    }
  );

  constructor(
    private readonly store$: Store<any>,
    private dataService: TcDataService,
    private xpertService: XpertService,
    private localStorage: TcLocalStorageService,
    private spinnerService: TcSpinnerService
  ) {
    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  public async getArticleStockData(...additionalStockDepots: Depot[]) {
    const articleFilters: TcFilterItem[] = [
      {
        key: 'modeSuiviStock',
        value: ModeSuiviStock.NoSuivi.toString(),
        filterType: FilterTypesEnum.NotEqual,
      },
      {
        key: 'DAS.intitule',
        value: DAS.XROptima,
        filterType: FilterTypesEnum.Equal,
      },
    ];

    const collaboratorsFilters =
      await this.xpertService.getConnectedXpertArticlesFilters();
    for (const collaboratorFilter of collaboratorsFilters) {
      articleFilters.push(collaboratorFilter);
    }

    const { data: articles } = await this.articleDataService.getData(0, null, {
      filters: articleFilters,
    });

    const formattedArticles = await this.formatStockData(
      articles,
      ...additionalStockDepots
    );
    return formattedArticles;
  }

  public async formatStockData(data: any[], ...additionalStockDepots: Depot[]) {
    const currentVehicleCode = await this.localStorage.get(
      LocalStorageKeys.LastLoginVehicleKey
    );

    const currentContainerNumero = await this.localStorage.get(
      LocalStorageKeys.LastLoginContainerKey
    );

    data.forEach((entry: Article & { [stocks: string]: number }) => {
      const emplacements = entry.stock.reduce(
        (prev, item) => prev.concat(item.depot.emplacement),
        []
      ) as Emplacement[];
      entry.myVehicleStock =
        emplacements.find((empl) => empl.code === currentVehicleCode)
          ?.quantiteStockEmplacement ?? 0;
      entry.myContainerStock =
        entry.stock.find(
          (stock) => stock.depot.numero === currentContainerNumero
        )?.depot.quantiteStockTotale ?? 0;

      // Find the depot that contains the current vehicle
      const currentVehicleDepot = entry.stock.find((stock) =>
        stock.depot.emplacement.some((empl) => empl.code === currentVehicleCode)
      )?.depot;

      if (!currentVehicleDepot) {
        entry.mySecteurStock = 0;
      } else {
        // Find the default emplacement of the current vehicle depot
        const defaultEmplacement = currentVehicleDepot.emplacement.find(
          (emplacement) => emplacement.code === 'DEFAUT'
        );

        entry.mySecteurStock =
          defaultEmplacement?.quantiteStockEmplacement ?? 0;
      }

      additionalStockDepots.forEach((depot) => {
        let stock = 0;

        if (depot.numero.toString().startsWith('vehicle')) {
          const vehicleCode = depot.numero.toString().replace('vehicle-', '');
          stock =
            emplacements.find((empl) => empl.code === vehicleCode)
              ?.quantiteStockEmplacement ?? 0;
        } else {
          stock =
            entry.stock.find(
              (stockObject) => stockObject.depot.numero === depot.numero
            )?.depot?.quantiteStockTotale ?? 0; //TODO only show quantity for visible emplacements for the Cuve de répartition terrain. For now there is no way to check if an emplacement is visible or not
        }

        entry[`stock_${depot.numero}`] = stock;
      });
    });

    return data;
  }

  public async getStockConsultationDepots() {
    const { data: depots } = await this.depotDataService.getData(0, null, {
      filters: [
        {
          key: 'typeDeStock',
          value: DepotStockType.StockSecteur,
          filterType: FilterTypesEnum.Equal,
        },
      ],
    });

    return depots;
  }

  /**
   * Retrieves the stock quantity of an article from a specific vehicle in a related depot.
   * @param article - The article for which to retrieve the stock quantity.
   * @param depot - The related depot where the stock is located.
   * @returns The stock quantity of the article in the specified vehicle and depot.
   */
  public getDosesStockFromDepot(article: Article, depot: Depot): number {
    const stock = article?.stock.find(
      (stockObj) => stockObj.depot.numero === depot.numero
    );
    const quantiteStockTotale = stock?.depot?.quantiteStockTotale ?? 0;
    return quantiteStockTotale < 0 ? 0 : quantiteStockTotale;
  }

  /**
   * Retrieves the total stock quantity for a given article from the specified depots on the terrain.
   * @param article - The article for which to retrieve the stock quantity.
   * @param depotsTerrain - The depots on the terrain to consider.
   * @returns The total stock quantity for the article from the specified depots on the terrain.
   */
  public getDosesStockFromDepotTerrain(
    article: Article,
    depotsTerrain: Depot[]
  ): number {
    const quantiteStockTotale = article?.stock
      .filter(
        (stock) =>
          depotsTerrain.some(
            (depotTerrain) => depotTerrain.numero === stock.depot.numero
          ) && stock.depot.quantiteStockTotale > 0
      )
      .reduce((sum, stock) => sum + stock.depot.quantiteStockTotale, 0);

    return quantiteStockTotale < 0 ? 0 : quantiteStockTotale;
  }

  /**
   * Checks if there are enough doses in stock from the terrain depot for a given article and quantity.
   * @param article - The article to check the stock for.
   * @param quantity - The required quantity of doses.
   * @returns A Promise that resolves to a boolean indicating whether there are enough doses in stock. Will return true if article is not from DAS XRCrea to not block other DAS articles.
   */
  public async hasDosesStockFromDepotTerrain(
    reference: string,
    quantity: number
  ): Promise<boolean> {
    const { data: depotsTerrain } = await this.depotDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'typeDeStock',
            value: DepotStockType.CuveRepartitionTerrain,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      }
    );

    const articleFilters: TcFilterItem[] = [
      {
        key: 'reference',
        value: reference,
        filterType: FilterTypesEnum.Equal,
      },
      {
        key: 'DAS.intitule',
        value: DAS.XRCrea,
        filterType: FilterTypesEnum.Equal,
      },
    ];

    const { data: articles } = await this.articleDataService.getData(0, null, {
      filters: articleFilters,
    });

    // Article may not be from DAS Crea. Ignore.
    if (articles.length === 0) {
      return true;
    }

    const fullArticle = articles[0] as Article;

    const dosesStock = this.getDosesStockFromDepotTerrain(
      fullArticle,
      depotsTerrain
    );

    return dosesStock >= quantity;
  }

  /**
   * DAS / Offre / Gamme / Famille / Sous-famille filters are linked. When a filter is applied, the others values are updated. This function is enforcing this behavior.
   * This was put in a service because it is used in multiple components (Article list / doses list).
   */
  async applyDASSubFilters(
    storeKey: string,
    filterKey: string | null,
    defaultArticleFilters: TcFilterItem[] = [],
    manualFiltering: (articles: Article[]) => Promise<any[]> | null = null
  ) {
    this.spinnerService.showSpinner('applyDASSubFilters');
    // If no filter key is provided, use the DAS one
    if (filterKey === null) {
      filterKey = this.DASFilkterKey;
    }

    // Get current filters
    const currentFilters: TcFilterDef = await selectValueByKey(
      getTcFilters,
      this.filterStore$,
      storeKey
    );

    const filters = currentFilters?.filters ?? [];
    const filtersToApply: TcFilterItem[] = [...defaultArticleFilters];

    // Apply the filters depending on the filter key in a cascading mode
    switch (filterKey) {
      case this.DASFilkterKey:
        filters.find((f) => f.key === this.DASFilkterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASFilkterKey)
          );
        break;
      case this.DASOffreFilterKey:
        filters.find((f) => f.key === this.DASFilkterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASFilkterKey)
          );
        break;
      case this.DASGammeFilterKey:
        filters.find((f) => f.key === this.DASFilkterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASFilkterKey)
          );
        filters.find((f) => f.key === this.DASOffreFilterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASOffreFilterKey)
          );
        break;
      case this.DASFamilleFilterKey:
        filters.find((f) => f.key === this.DASFilkterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASFilkterKey)
          );
        filters.find((f) => f.key === this.DASOffreFilterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASOffreFilterKey)
          );
        filters.find((f) => f.key === this.DASGammeFilterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASGammeFilterKey)
          );
        break;
      case this.DASSousFamilleFilterKey:
        filters.find((f) => f.key === this.DASFilkterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASFilkterKey)
          );
        filters.find((f) => f.key === this.DASOffreFilterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASOffreFilterKey)
          );
        filters.find((f) => f.key === this.DASGammeFilterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASGammeFilterKey)
          );
        filters.find((f) => f.key === this.DASFamilleFilterKey) &&
          filtersToApply.push(
            filters.find((f) => f.key === this.DASFamilleFilterKey)
          );
        break;
    }

    // Load the list of found articles corresponding to the filters
    const articles = await this.articleDataService.getData(0, null, {
      filters: filtersToApply,
    });
    let articlesFound: Article[] = articles?.data ?? [];

    // Callback for manual filtering from component (doses screen is making some calculations to allow the article to display. We need to replicate the same behavior here.)
    if (manualFiltering !== null) {
      articlesFound = await manualFiltering(articlesFound);
    }

    let articleMapped: {
      value: string;
      label: string;
    }[];

    // Transform the found articles to the combo values and launch the method for the next filter
    const storeKeyToUse = `${storeKey}.filter-${filterKey}`;
    switch (filterKey) {
      case this.DASFilkterKey:
        articleMapped = this.transformToDASCombo(articlesFound);
        this.store$.dispatch(
          loadTcDataSuccess({
            storeKey: storeKeyToUse,
            data: articleMapped,
            total: articleMapped.length,
            take: 9999999,
            skip: 0,
          })
        );
        this.applyDASSubFilters(
          storeKey,
          this.DASOffreFilterKey,
          defaultArticleFilters,
          manualFiltering
        );
        break;
      case this.DASOffreFilterKey:
        articleMapped = this.transformToOffreCombo(articlesFound);
        this.store$.dispatch(
          loadTcDataSuccess({
            storeKey: storeKeyToUse,
            data: articleMapped,
            total: articleMapped.length,
            take: 9999999,
            skip: 0,
          })
        );
        this.applyDASSubFilters(
          storeKey,
          this.DASGammeFilterKey,
          defaultArticleFilters,
          manualFiltering
        );
        break;
      case this.DASGammeFilterKey:
        articleMapped = this.transformToGammeCombo(articlesFound);
        this.store$.dispatch(
          loadTcDataSuccess({
            storeKey: storeKeyToUse,
            data: articleMapped,
            total: articleMapped.length,
            take: 9999999,
            skip: 0,
          })
        );
        this.applyDASSubFilters(
          storeKey,
          this.DASFamilleFilterKey,
          defaultArticleFilters,
          manualFiltering
        );
        break;
      case this.DASFamilleFilterKey:
        articleMapped = this.transformToFamilleCombo(articlesFound);
        this.store$.dispatch(
          loadTcDataSuccess({
            storeKey: storeKeyToUse,
            data: articleMapped,
            total: articleMapped.length,
            take: 9999999,
            skip: 0,
          })
        );
        this.applyDASSubFilters(
          storeKey,
          this.DASSousFamilleFilterKey,
          defaultArticleFilters,
          manualFiltering
        );
        break;
      case this.DASSousFamilleFilterKey:
        articleMapped = this.transformToSousFamilleCombo(articlesFound);
        this.store$.dispatch(
          loadTcDataSuccess({
            storeKey: storeKeyToUse,
            data: articleMapped,
            total: articleMapped.length,
            take: 9999999,
            skip: 0,
          })
        );
        break;
    }

    this.spinnerService.hideSpinner('applyDASSubFilters');
  }

  /**
   * Transforms an array of Article objects into an array of objects suitable for a combo.
   * @param result - The array of Article objects.
   * @returns An array of objects with `value` and `label` properties.
   */
  public transformToDASCombo(result: Article[]): {
    value: string;
    label: string;
  }[] {
    let DASFilter = result.map((res) => res.DAS?.intitule);
    DASFilter = DASFilter.filter(
      (das, index, self) => das && self.indexOf(das) === index
    );

    const response = DASFilter.map((das) => ({
      value: das,
      label: das,
    }));

    return response;
  }

  /**
   * Transforms an array of Article objects into an array of objects suitable for a combo.
   * @param result - The array of Article objects.
   * @returns An array of objects with `value` and `label` properties.
   */
  public transformToOffreCombo(result: Article[]): {
    value: string;
    label: string;
  }[] {
    let offres = result.map((res) => res.DAS?.offre?.intitule);
    offres = offres.filter(
      (offre, index, self) => offre && self.indexOf(offre) === index
    );

    const response = offres.map((offre) => ({
      value: offre,
      label: offre,
    }));

    return response;
  }

  /**
   * Transforms an array of Article objects into an array of objects suitable for a combo.
   * @param result - The array of Article objects.
   * @returns An array of objects with `value` and `label` properties.
   */
  public transformToGammeCombo(result: Article[]): {
    value: string;
    label: string;
  }[] {
    let gammes = result.map((res) => res.DAS?.offre?.gamme?.intitule);
    gammes = gammes.filter(
      (gamme, index, self) => gamme && self.indexOf(gamme) === index
    );

    const response = gammes.map((gamme) => ({
      value: gamme,
      label: gamme,
    }));

    return response;
  }

  /**
   * Transforms an array of Article objects into an array of objects suitable for a combo.
   * @param result - The array of Article objects.
   * @returns An array of objects with `value` and `label` properties.
   */
  public transformToFamilleCombo(result: Article[]): {
    value: string;
    label: string;
  }[] {
    let familles = result.map(
      (res) => res.DAS?.offre?.gamme?.famille?.intitule
    );
    familles = familles.filter(
      (famille, index, self) => famille && self.indexOf(famille) === index
    );

    const response = familles.map((famille) => ({
      value: famille,
      label: famille,
    }));

    return response;
  }

  /**
   * Transforms an array of Article objects into an array of objects suitable for a combo.
   * @param result - The array of Article objects.
   * @returns An array of objects with `value` and `label` properties.
   */
  public transformToSousFamilleCombo(result: Article[]): {
    value: string;
    label: string;
  }[] {
    let sousFamilles = result.map((res) => res.sousFamille);
    sousFamilles = sousFamilles.filter(
      (famille, index, self) => famille && self.indexOf(famille) === index
    );

    const response = sousFamilles.map((sousFamille) => ({
      value: sousFamille,
      label: sousFamille,
    }));

    return response;
  }
}
