import { selectByKey, selectValueByKey } from '@tc/store';
import { NgRxTcDataState, TcDataService } from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  DEFAULT_TC_GRID_STATE_KEY,
  getTcGridColumns,
  getTcGridSelection,
  NgRxTcGridState,
  setTcGridColumns,
} from '@tc/core';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import * as R from 'ramda';
import {
  createEstimateFromCatalog,
  createEstimateFromDose,
  createOrderFromCatalog,
  createOrderFromDose,
  createOrderFromEstimate,
  recalculatePrice,
  redirectToArticleOrigin,
  resetCatalogArticleState,
  setCurrentDocument,
  updateCatalogGridColumns,
} from './catalog-article.actions';
import { Article } from '../interfaces/article.interface';
import { getCurrentElevage } from '../../elevage/store/elevage.selectors';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { DiscountType } from '../../../typings/discount-type.enum';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
} from './catalog-article.selectors';
import { DocumentDomaine, DocumentType } from '../../../typings/document.enum';
import { XpertService } from '../../../services/xpert.service';
import { ElevageDocument } from '../../elevage/interfaces/elevage-document.interface';
import { DocumentLigne } from '../../elevage/interfaces/document-ligne.interface';
import { DocumentService } from '../../../services/document.service';
import { PriceService } from './../../../services/price.service';
import { Router } from '@angular/router';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { Dose } from '../interfaces/dose.interface';
import { Elevage } from '../../elevage/interfaces/elevage.interface';
import { ElevageSite } from '../../elevage/interfaces/elevage-site.interface';
import { ArticleOrigin } from '../../commande/store/commande.payloads';
import { setCurrentEncaissement } from '../../encaissement/store/encaissement.actions';

@Injectable()
export class CatalogArticleEffects {
  gridStore$: Observable<NgRxTcGridState>;
  filterStore$: Observable<NgRxTcDataState>;

  private readonly articleDataService: ITcDataService<any> =
    this.dataService.getService('Article', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'DAS',
    });

  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly xpertService: XpertService,
    private readonly docService: DocumentService,
    private readonly priceService: PriceService,
    private readonly dataService: TcDataService,
    private readonly router: Router
  ) {
    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  updateCatalogGridColumns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateCatalogGridColumns),
        tap(async (payload) => {
          const { storeKey, filterFieldValue } = payload;

          let columns = R.clone(
            await selectByKey(getTcGridColumns, this.gridStore$, storeKey)
              .pipe(take(1))
              .toPromise()
          ) as any[];

          if (
            filterFieldValue &&
            !columns.some((col) => (col.field as string) === 'commissions')
          ) {
            columns.push({
              field: 'commissions',
              maxWidth: 150,
              cellClass: ' text-align-right',
              headerClass: 'text-align-right',
              valueFormatter: (params) =>
                params.data.commissions ? params.data.commissions : '',
            });
          } else if (!filterFieldValue) {
            columns = columns.filter(
              (col) => (col.field as string) !== 'commissions'
            );
          }

          this.store$.dispatch(setTcGridColumns({ storeKey, columns }));
        })
      ),
    { dispatch: false }
  );

  createEstimateFromCatalog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createEstimateFromCatalog),
        tap(async (payload) => {
          const { storeKey, devisDocNum, origin } = payload;

          const selectedArticles: Article[] = R.clone(
            await selectValueByKey(
              getTcGridSelection,
              this.gridStore$,
              storeKey
            )
          );

          await this.createEstimate(
            selectedArticles,
            storeKey,
            devisDocNum,
            origin
          );
        })
      ),
    { dispatch: false }
  );

  createEstimateFromDose$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createEstimateFromDose),
        tap(async (payload) => {
          const { storeKey, devisDocNum } = payload;

          const selectedDoses: Dose[] = R.clone(
            await selectValueByKey(
              getTcGridSelection,
              this.gridStore$,
              storeKey
            )
          );

          const dosesReferences = selectedDoses.map((dose) => dose.reference);

          const { data: selectedArticles } =
            await this.articleDataService.getData(0, null, {
              filters: [
                {
                  key: 'reference',
                  value: dosesReferences.join(','),
                  filterType: FilterTypesEnum.In,
                },
              ],
            });

          await this.createEstimate(
            selectedArticles,
            storeKey,
            devisDocNum,
            ArticleOrigin.OrderEstimateDoses
          );
        })
      ),
    { dispatch: false }
  );

  createOrderFromCatalog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createOrderFromCatalog),
        tap(async (payload) => {
          const { storeKey, orderDocNum, origin } = payload;

          const selectedArticles: Article[] = R.clone(
            await selectValueByKey(
              getTcGridSelection,
              this.gridStore$,
              storeKey
            )
          );

          await this.createOrder(
            selectedArticles,
            storeKey,
            orderDocNum,
            origin
          );
        })
      ),
    { dispatch: false }
  );

  createOrderFromDose$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createOrderFromDose),
        tap(async (payload) => {
          const { storeKey, orderDocNum } = payload;

          const selectedDoses: Dose[] = R.clone(
            await selectValueByKey(
              getTcGridSelection,
              this.gridStore$,
              storeKey
            )
          );

          const dosesReferences = selectedDoses.map((dose) => dose.reference);

          const { data: selectedArticles } =
            await this.articleDataService.getData(0, null, {
              filters: [
                {
                  key: 'reference',
                  value: dosesReferences.join(','),
                  filterType: FilterTypesEnum.In,
                },
              ],
            });

          await this.createOrder(
            selectedArticles,
            storeKey,
            orderDocNum,
            ArticleOrigin.OrderEstimateDoses
          );
        })
      ),
    { dispatch: false }
  );

  createOrderFromEstimate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createOrderFromEstimate),
        tap(async (payload) => {
          const { storeKey, devisDocNum } = payload;

          const elevage = R.clone(
            await this.store$
              .select(getCurrentElevage)
              .pipe(take(1))
              .toPromise()
          );

          const estimate = elevage.documents.find(
            (document) =>
              document.numeroPiece === devisDocNum &&
              document.domaine === DocumentDomaine.Vente &&
              document.type === DocumentType.Devis
          );

          if (!estimate) throw new Error('Estimate document not found');
          const newOrder = R.clone(estimate) as ElevageDocument;
          newOrder.type = DocumentType.VenteBC;

          const xpert = await this.xpertService.getConnectedXpert();
          // We change the numXpertMobile but we keep the same numeroPiece : both a devis and a estimate can exist on the same number.
          newOrder.numXpertMobile =
            await this.docService.generateNewNumXpertMobile(xpert);

          newOrder.dateCommande = new Date().toISOString();

          newOrder.ligne = newOrder?.ligne.map((data) => ({
            ...data,
            numeroPieceDevisOrigine: devisDocNum,
            datePieceDevisOrigine: estimate.date,
          }));

          // Send the document to the store
          this.store$.dispatch(
            setCurrentDocument({
              document: newOrder,
              articleOrigin: ArticleOrigin.OrderEstimateOptima,
            })
          );
          this.store$.dispatch(recalculatePrice());

          this.store$.dispatch(
            navigate({
              route: `/${CustomRoutes.DetailCommandesList}`,
              queryParams: { devisDocNum },
              storeKey: storeKey,
            })
          );
        })
      ),
    { dispatch: false }
  );

  recalculatePrice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recalculatePrice),
        tap(async () => {
          this.priceService.computePricesForDocument();
        })
      ),
    { dispatch: false }
  );

  redirectToArticleOrigin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToArticleOrigin),
        tap(async ({ reset, devisDocNum, orderDocNum, storeKey }) => {
          const origin = await this.store$
            .select(getCurrentDocumentArticleOrigin)
            .pipe(take(1))
            .toPromise();

          if (reset) {
            this.store$.dispatch(resetCatalogArticleState({}));
            this.store$.dispatch(
              setCurrentEncaissement({ encaissement: null })
            );
          }

          let route: CustomRoutes;
          const queryParams = {};
          if (storeKey && (devisDocNum || orderDocNum)) {
            queryParams['storeKey'] = storeKey;
            if (devisDocNum) {
              queryParams['devisDocNum'] = devisDocNum;
            }
            if (orderDocNum) {
              queryParams['orderDocNum'] = orderDocNum;
            }
          }

          switch (origin) {
            case ArticleOrigin.OrderEstimateDoses:
              route = CustomRoutes.Doses;
              break;
            case ArticleOrigin.XrOptimaPurchaseHistory:
              route = CustomRoutes.XrOptimaPurchaseHistory;
              break;
            // XAS-84 - Hide features
            // case ArticleOrigin.DeliveryWithoutOrder:
            //   route = CustomRoutes.Livraison;
            //   break;
            // XAS-84 - Hide features
            // case ArticleOrigin.CatalogArticles:
            //   route = CustomRoutes.CatalogArticles;
            //   break;
            case ArticleOrigin.OrderEstimateOptima:
            default:
              route = CustomRoutes.MakeEstimateOrTakeOrder;
              break;
          }

          if (storeKey) {
            this.store$.dispatch(
              navigate({
                route: `/${route}`,
                queryParams,
                storeKey: storeKey,
              })
            );
          } else {
            this.router.navigateByUrl(`/${route}`);
          }
        })
      ),
    { dispatch: false }
  );

  resetCatalogArticleState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetCatalogArticleState),
        tap(({ redirectTo }) => {
          if (!redirectTo) return;

          this.router.navigateByUrl(redirectTo);
        })
      ),
    { dispatch: false }
  );

  /**
   * Creates an estimate document based on the provided articles and other parameters.
   * If a document already exists in the store, it is used as a basis. Otherwise, a new document is created.
   * The created document is then sent to the store and other necessary actions are dispatched.
   *
   * @param articles - The articles to include in the estimate.
   * @param storeKey - The key of the store where the document will be stored.
   * @param devisDocNum - The document number for the estimate.
   * @param origin - The origin of the articles.
   * @returns A Promise that resolves when the order is created.
   */
  async createEstimate(
    articles: Article[],
    storeKey: string,
    devisDocNum: string,
    origin: ArticleOrigin
  ) {
    const elevage = await this.store$
      .select(getCurrentElevage)
      .pipe(take(1))
      .toPromise();

    const xpert = await this.xpertService.getConnectedXpert();

    const prescripteur = {
      _id: xpert.idXpert,
      nom: xpert.nom,
      prenom: xpert.prenom,
    };

    const siteLivraison = elevage.site?.find((site) => site.estPrincipal);

    // Check if you have an document already in the store. If yes, use it as basis.
    let document: ElevageDocument | null = R.clone(
      await this.store$.select(getCurrentDocument).pipe(take(1)).toPromise()
    );

    // Security : if the current document is not of the right type, create it from scratch
    document = document?.type === DocumentType.Devis ? document : null;

    let numXpertMobile = document?.numXpertMobile;
    let numeroPiece = document?.numeroPiece;

    // No document in the store, create it
    if (document === null) {
      numXpertMobile = await this.docService.generateNewNumXpertMobile(xpert);
      numeroPiece = (
        (await this.docService.getLastTempNumeroPiece()) + 1
      ).toString();
    }

    document = this.getDocumentForEstimate(
      document,
      numXpertMobile,
      prescripteur,
      numeroPiece,
      siteLivraison,
      articles,
      elevage
    );

    // Send the document to the store
    this.store$.dispatch(
      setCurrentDocument({ document: document, articleOrigin: origin })
    );
    this.store$.dispatch(recalculatePrice());

    // Depending of the origin, we navigate with the right queryParams
    switch (origin) {
      // XAS-84 - Hide features
      // case ArticleOrigin.CatalogArticles:
      case ArticleOrigin.OrderEstimateOptima:
      case ArticleOrigin.OrderEstimateDoses:
        this.store$.dispatch(
          navigate({
            route: `/${CustomRoutes.DevisDetail}`,
            queryParams: { devisDocNum },
            storeKey: storeKey,
          })
        );
        break;
    }

    // Return promise to not break await flow.
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  /**
   * Creates an order with the given articles, store key, and order document number.
   * If a document already exists in the store, it is used as a basis. Otherwise, a new document is created.
   * The created document is then sent to the store and other necessary actions are dispatched.
   *
   * @param articles - The articles to include in the order.
   * @param storeKey - The key of the store.
   * @param orderDocNum - The order document number.
   * @param origin - The origin of the articles.
   * @returns A Promise that resolves when the order is created.
   */
  async createOrder(
    articles: Article[],
    storeKey: string,
    orderDocNum: string,
    origin: ArticleOrigin
  ): Promise<boolean> {
    const elevage = await this.store$
      .select(getCurrentElevage)
      .pipe(take(1))
      .toPromise();

    const xpert = await this.xpertService.getConnectedXpert();

    const prescripteur = {
      _id: xpert.idXpert,
      nom: xpert.nom,
      prenom: xpert.prenom,
    };

    const siteLivraison = elevage.site?.find((site) => site.estPrincipal);

    // Check if you have an document already in the store. If yes, use it as basis.
    let document: ElevageDocument | null = R.clone(
      await this.store$.select(getCurrentDocument).pipe(take(1)).toPromise()
    );

    // Security : if the current document is not of the right type, create it from scratch
    document = document?.type === DocumentType.VenteBC ? document : null;

    let numXpertMobile = document?.numXpertMobile;
    let numeroPiece = document?.numeroPiece;

    // No document in the store, create it
    if (document === null) {
      numXpertMobile = await this.docService.generateNewNumXpertMobile(xpert);
      numeroPiece = (
        (await this.docService.getLastTempNumeroPiece()) + 1
      ).toString();
    }

    document = this.getDocumentForOrder(
      document,
      numXpertMobile,
      prescripteur,
      numeroPiece,
      siteLivraison,
      articles,
      elevage
    );

    // Send the document to the store
    this.store$.dispatch(
      setCurrentDocument({ document: document, articleOrigin: origin })
    );
    this.store$.dispatch(recalculatePrice());

    // Depending of the origin, we navigate with the right queryParams
    switch (origin) {
      // XAS-84 - Hide features
      // case ArticleOrigin.CatalogArticles:
      case ArticleOrigin.OrderEstimateOptima:
      case ArticleOrigin.OrderEstimateDoses:
        this.store$.dispatch(
          navigate({
            route: `/${CustomRoutes.DetailCommandesList}`,
            queryParams: { orderDocNum },
            storeKey: storeKey,
          })
        );
        break;
    }

    // Return promise to not break await flow.
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  /**
   * Generates a document for an estimate.
   *
   * @param numXpertMobile - The XpertMobile number.
   * @param prescripteur - The prescripteur information.
   * @param numeroPiece - The piece number.
   * @param siteLivraison - The delivery site information.
   * @param articles - The list of articles.
   * @param elevage - The elevage information.
   * @returns The generated ElevageDocument.
   */
  getDocumentForEstimate(
    document: ElevageDocument | null,
    numXpertMobile: string,
    prescripteur: {
      _id: string;
      nom: string;
      prenom: string;
    },
    numeroPiece: string,
    siteLivraison: ElevageSite | null,
    articles: Article[],
    elevage: Elevage
  ): ElevageDocument {
    if (!document) {
      document = {
        numXpertMobile: numXpertMobile,
        domaine: DocumentDomaine.Vente,
        type: DocumentType.Devis,
        prescripteur: prescripteur,
        createdOn: new Date().toISOString(),
        date: new Date().toISOString().split('T')[0],
        dateLivraison: null,
        numeroPiece: numeroPiece,
        ligne: [],
        noteDeReglement: null,
        montant: null,
        totalTTC: null,
        dateCommande: null,
        echeance: null,
        siteLivraison: siteLivraison
          ? {
              _id: siteLivraison.numeroInterne.toString(),
              intitule: siteLivraison.intitule,
            }
          : null,
      };
    }

    // Iterate on each selected article and add it to existing document lines if not already defined
    articles.forEach((articleRow: Article) => {
      // Search for document ligne in the document
      let ligne: DocumentLigne = document.ligne.find(
        (item) => item.article.reference === articleRow.reference
      );
      if (!ligne) {
        ligne = {
          numXpertMobile: numXpertMobile,
          domaine: DocumentDomaine.Vente,
          uniteVente: articleRow.uniteVente,
          conditionnementXpert: articleRow.conditionnementXpert,
          numeroPiece: numeroPiece,
          numeroInterne: null,
          type: DocumentType.Devis,
          quantite: 0,
          taxe: articleRow.taxe.taux,
          codeTaxe: articleRow.taxe.code,
          article: { reference: articleRow.reference },
          libelle: articleRow.intitule,
          typeRemise: articleRow.typeRemise
            ? (+articleRow.typeRemise as DiscountType)
            : null,
          dateLivraison: null,
          datePieceDevisOrigine: new Date().toISOString().split('T')[0],
          montantRemise:
            articleRow.tarif?.find(
              (tarif) => tarif.elevage.numero === elevage.numero
            )?.remiseGenerale ?? 0,
          prixUnitaire:
            articleRow.tarif?.find(
              (tarif) => tarif.elevage.numero === elevage.numero
            )?.prixVente ?? 0,
        };

        // Add the article to the list if he was not already present
        document.ligne.push(ligne);
      }
    });

    return document;
  }

  /**
   * Generates a document for an order.
   *
   * @param numXpertMobile - The XpertMobile number.
   * @param prescripteur - The prescripteur information.
   * @param numeroPiece - The piece number.
   * @param siteLivraison - The delivery site information.
   * @param articles - The list of articles.
   * @param elevage - The elevage information.
   * @returns The generated ElevageDocument.
   */
  getDocumentForOrder(
    document: ElevageDocument | null,
    numXpertMobile: string,
    prescripteur: {
      _id: string;
      nom: string;
      prenom: string;
    },
    numeroPiece: string,
    siteLivraison: ElevageSite | null,
    articles: Article[],
    elevage: Elevage
  ): ElevageDocument {
    if (!document) {
      document = {
        numXpertMobile: numXpertMobile,
        domaine: DocumentDomaine.Vente,
        type: DocumentType.VenteBC,
        prescripteur: prescripteur,
        createdOn: new Date().toISOString(),
        date: new Date().toISOString().split('T')[0],
        dateLivraison: null,
        numeroPiece: numeroPiece,
        ligne: [],
        noteDeReglement: null,
        montant: null,
        totalTTC: null,
        dateCommande: new Date().toISOString().split('T')[0],
        echeance: null,
        siteLivraison: siteLivraison
          ? {
              _id: siteLivraison.numeroInterne.toString(),
              intitule: siteLivraison.intitule,
            }
          : null,
      };
    }

    articles.forEach((articleRow: any) => {
      // Search for document ligne in the document
      let ligne: DocumentLigne = document.ligne.find(
        (item) => item.article.reference === articleRow.reference
      );
      if (!ligne) {
        ligne = {
          numXpertMobile: numXpertMobile,
          domaine: DocumentDomaine.Vente,
          uniteVente: articleRow.uniteVente,
          conditionnementXpert: articleRow.conditionnementXpert,
          numeroPiece: numeroPiece,
          numeroInterne: null,
          numeroPieceBonLivraisonOrigine: null,
          type: DocumentType.VenteBC,
          quantite: 0,
          taxe: articleRow.taxe.taux,
          codeTaxe: articleRow.taxe.code,
          article: { reference: articleRow.reference },
          libelle: articleRow.intitule,
          typeRemise: articleRow.typeRemise
            ? (+articleRow.typeRemise as DiscountType)
            : null,
          dateLivraison: null,
          datePieceDevisOrigine: null,
          montantRemise:
            articleRow.tarif?.find(
              (tarif) => tarif.elevage.numero === elevage.numero
            )?.remiseGenerale ?? 0,
          prixUnitaire:
            articleRow.tarif?.find(
              (tarif) => tarif.elevage.numero === elevage.numero
            )?.prixVente ?? 0,
        };

        // Add the article to the list if he was not already present
        document.ligne.push(ligne);
      }
    });

    return document;
  }
}
