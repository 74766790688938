import { TcSmartButton } from '@tc/buttons';

export interface HomeState {
  elevageButtonsList: TcSmartButton[];
  stockButtonsList: TcSmartButton[];
  xpertButtonsList: TcSmartButton[];
}

export const initialHomeState: HomeState = {
  elevageButtonsList: [],
  stockButtonsList: [],
  xpertButtonsList: [],
};
