export interface LivraisonState {
  quantities: LivraisonQuantities;
}

export interface LivraisonQuantities {
  hasExceedingQuantities: boolean;
}

export const initialLivraisonState: LivraisonState = {
  quantities: {
    hasExceedingQuantities: false,
  },
};
