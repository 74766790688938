import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TcCoreModule } from '@tc/core';
import { TcDialogModule } from '@tc/dialog';
import { TcSmartFormModule } from '@tc/smart-form';
import { SharedModule } from '../../../shared/shared.module';
import { ContactsGridComponent } from './components/smart/contacts-grid/contacts-grid.component';
import { SitesGridComponent } from './components/smart/sites-grid/sites-grid.component';
import { ElevageContactComponent } from './components/smart/elevage-contact/elevage-contact.component';
import { ElevageGridComponent } from './components/smart/elevage-grid/elevage-grid.component';
import { ElevageContactPopupComponent } from './components/smart/elevage-contact-popup/elevage-contact-popup.component';
import { ElevageSitePopupComponent } from './components/smart/elevage-site-popup/elevage-site-popup.component';
import { elevageReducer } from './store/elevage.reducer';
import { ElevageEffects } from './store/elevage.effects';
import { TcDataService, TcDataServiceFactory } from '@tc/data-store';
import { TcBreezeService } from '@tc/breeze';
import { TcButtonsModule } from '@tc/buttons';

const components = [
  ElevageContactComponent,
  ElevageContactPopupComponent,
  ElevageSitePopupComponent,
  ElevageGridComponent,
  ContactsGridComponent,
  SitesGridComponent,
];

const services = [TcDataServiceFactory, TcDataService, TcBreezeService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    TcDialogModule,
    TcSmartFormModule,
    SharedModule,
    TcButtonsModule,
    EffectsModule.forFeature([ElevageEffects]),
    StoreModule.forFeature('elevage', elevageReducer),
  ],
  providers: [...services],
  exports: [...components],
})
export class ElevageModule {}
