import { createAction, props } from '@ngrx/store';
import { BaseTcStorePayload } from '@tc/abstract';

export const resetLivraisonRowQuantity = createAction(
  '[Livraison] Reset row quantity',
  props<{ storeKey: string; rowData: any }>()
);

export const setExceededQuantitiesState = createAction(
  '[Livraison] Set exceeded quantities state',
  props<{ exceeded: boolean }>()
);

export const verifyDeliveryWithoutOrder = createAction(
  '[Livraison] Verify delivery without order',
  props<BaseTcStorePayload>()
);

export const removeDeliveryLine = createAction(
  '[Livraison] Remove Delivery Line From Grid',
  props<{ storeKey: string; rowData: any }>()
);

export const saveDelivery = createAction(
  '[Livraison] Save Delivery',
  props<BaseTcStorePayload>()
);

export const updateDeliveryDocument = createAction(
  '[Livraison] Update Delivery Document',
  props<{ storeKey: string }>()
);

export const updateDeliveryLine = createAction(
  '[Livraison] Update Delivery Line',
  props<{ storeKey: string; rowData: any }>()
);

export const recalculatePrice = createAction(
  '[Livraison] Recalculate Delivery Price'
);
