import { createReducer, on } from '@ngrx/store';
import {
  setBankDepositEncaissementButtonState,
  setBankDepositSaveButtonState,
  setCurrentEncaissement,
} from './encaissement.actions';
import {
  EncaissementState,
  initialEncaissementState,
} from './encaissement.state';

export const encaissementReducer = createReducer<EncaissementState>(
  initialEncaissementState,
  on(setCurrentEncaissement, (state, { encaissement }) => ({
    ...state,
    currentEncaissement: encaissement,
  })),
  on(setBankDepositEncaissementButtonState, (state, { active }) => ({
    ...state,
    encaissementButtonsState: {
      ...state.encaissementButtonsState,
      bankDepositEncaissementButton: active,
    },
  })),
  on(setBankDepositSaveButtonState, (state, { active }) => ({
    ...state,
    encaissementButtonsState: {
      ...state.encaissementButtonsState,
      bankDepositSaveButton: active,
    },
  }))
);
