import { Injectable, isDevMode } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { TcDataService } from '../../data-services/services/tc-data.service';
import {
  createItem,
  createItemSuccess,
  deleteItem,
  deleteItemSuccess,
  loadTcData,
  loadTcDataSuccess,
  loadTcDataTotalSuccess,
  loadTcMoreData,
  loadTcMoreDataSuccess,
  refreshTcData,
  setTcDataFilters,
  softDeleteItem,
  updateItem,
  updateItemSuccess,
} from '../actions/tc-data-actions';
import {
  CreateItemPayload,
  DeleteItemPayload,
  LoadTcDataPayload,
  LoadTcMoreDataPayload,
  RefreshTcDataPayload,
  UpdateItemPayload,
} from '../actions/tc-data-payload';
import {
  getTcData,
  getTcDataFilters,
  getTcDataProvider,
  getTcDataSeparatedInlineCountCall,
  getTcDataSkip,
  getTcDataSort,
  getTcDataTake,
  getTcWriteDataProvider,
} from '../selectors/tc-data-selectors';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
} from '../state/tc-data-state';
import { selectValueByKey, TcSpinnerService } from '@tc/store';
import { hasValue } from '@tc/utils';
import { TcNotificationService } from '../../../tc-core/services/tc-notification.service';
import * as R from 'ramda';
import {
  BaseTcStorePayload,
  ITcDataService,
  TcDataProviderConfig,
  TcSmartFormConfig,
} from '@tc/abstract';
import { TcTranslateService } from '../../../tc-core/services/tc-translate.service';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormConfig,
  getTcSmartFormCurrentModel,
  getTcSmartFormInvalidity,
  NgRxTcSmartFormState,
  submitTcSmartFormCurrentModel,
  updateTcSmartFormCurrentModel,
} from '@tc/smart-form';
import { TcBreezeService } from '@tc/breeze';

@Injectable()
export class TcDataEffects {
  dataStore$: Observable<NgRxTcDataState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly dataService: TcDataService,
    private readonly spinner: TcSpinnerService,
    private notification: TcNotificationService,
    private translateService: TcTranslateService,
    private breeze: TcBreezeService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  private async getDataServiceForStoreKey(
    storeKey: string,
    isWrite: boolean = false
  ): Promise<ITcDataService<any>> {
    const dataProvider = await selectValueByKey(
      isWrite ? getTcWriteDataProvider : getTcDataProvider,
      this.dataStore$,
      storeKey
    );

    return this.dataService.getService<any>(storeKey, dataProvider);
  }

  /**
   * Load tc data effect
   */
  loadTcData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcData),
        tap(async (payload: LoadTcDataPayload) => {
          const { storeKey, skip, take, filter, sort } = payload;

          const action = `${payload.storeKey} - spinner`;

          let pageSize;
          if (take) {
            pageSize = take;
          } else {
            pageSize = await selectValueByKey(
              getTcDataTake,
              this.dataStore$,
              storeKey
            );
          }

          let storeFilter;
          // If the filters property is defined we use it as the new filter
          // because if it's an empty array it means that the filters got cleared
          if (filter?.filters !== undefined) {
            storeFilter = filter;
          } else {
            storeFilter = await selectValueByKey(
              getTcDataFilters,
              this.dataStore$,
              storeKey
            );
          }

          let storeSort;
          if (sort !== undefined) {
            storeSort = sort;
          } else {
            storeSort = await selectValueByKey(
              getTcDataSort,
              this.dataStore$,
              storeKey
            );
          }

          // If you got some filters, register them directly into the store, as default filters must be up before the first loading of the list.
          this.store$.dispatch(
            setTcDataFilters({
              storeKey: payload.storeKey,
              filter: storeFilter,
            })
          );

          const dataService = await this.getDataServiceForStoreKey(storeKey);
          const result = await dataService.getData(
            skip,
            pageSize,
            storeFilter,
            storeSort
          );

          const { data, total } = result;
          this.store$.dispatch(
            loadTcDataSuccess({
              storeKey: payload.storeKey,
              data,
              total,
              take: pageSize,
              skip,
              filter: storeFilter,
              sort: storeSort,
              createdOn: new Date().toISOString(),
            })
          );

          const separatedInlineCountCall = await selectValueByKey(
            getTcDataSeparatedInlineCountCall,
            this.dataStore$,
            storeKey
          );

          if (separatedInlineCountCall) {
            const total = await dataService.getDataInlineCount(storeFilter);

            this.store$.dispatch(
              loadTcDataTotalSuccess({
                storeKey: payload.storeKey,
                total,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Load more data effect
   */
  loadTcMoreData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTcMoreData),
        tap(async (payload: LoadTcMoreDataPayload) => {
          const { storeKey, skip } = payload;

          const pageSize = await selectValueByKey(
            getTcDataTake,
            this.dataStore$,
            storeKey
          ); // this.gridStore$.pipe(select(getTcGridTake, {storeKey }), take(1)).toPromise();
          const storeFilter = await selectValueByKey(
            getTcDataFilters,
            this.dataStore$,
            storeKey
          ); // this.gridStore$.pipe(select(getTcGridFilters, {storeKey}), take(1)).toPromise();
          const storeSort = await selectValueByKey(
            getTcDataSort,
            this.dataStore$,
            storeKey
          );

          const dataService = await this.getDataServiceForStoreKey(storeKey);
          const result = await dataService.getData(
            skip,
            pageSize,
            storeFilter,
            storeSort
          );

          const { data, total } = result;

          this.store$.dispatch(
            loadTcMoreDataSuccess({
              storeKey: payload.storeKey,
              data,
              total,
              take: pageSize,
              skip,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Refresh tc data effect
   */
  refreshTcData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTcData),
        tap(async (payload: RefreshTcDataPayload) => {
          const { storeKey, filter } = payload;

          // RefreshTcData is called only by tc-filter when the filter changes.
          // When data is refreshed because of changes in the filter the skip should be set to 0
          const skip = 0;

          this.store$.dispatch(
            loadTcData({
              storeKey,
              skip,
              filter,
            })
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * Add new item in data effect
   */
  createItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createItem),
        tap(async (payload: CreateItemPayload) => {
          const { storeKey, item } = payload;

          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          try {
            const dataProvider: TcDataProviderConfig = await selectValueByKey(
              getTcDataProvider,
              this.dataStore$,
              storeKey
            );

            let newItem = R.clone(item);
            /*
             * Only create the item in the DB/offline data if it's not a dynamic collection. Otherwise, the dynamic collection's state will be marked as changed,
             * and if we're in offline mode, when we actually save the data inside the DB, it will throw an error since the dynamic collection
             * does not exist inside the DB.
             * It has no incidence on the persistence of the data since we always provide data to the dynamic collection anyway
             */
            if (!dataProvider?.dynamicCollectionFrom) {
              // save to db
              newItem = await dataService.upsert(newItem);

              // Show notification message
              const translatedCreateMessage =
                this.translateService.instant('create-successful');
              this.notification.success(translatedCreateMessage);
            } else {
              newItem._id = this.breeze.getObjectId().toHexString(); //Assign an ID to mark the object as already existing in the future
            }

            this.store$.dispatch(
              createItemSuccess({
                storeKey,
                item: newItem,
              })
            );
          } catch (error) {
            this.notification.error(
              this.translateService.instant('generic-error')
            );

            if (isDevMode()) {
              this.notification.error(
                this.translateService.instant(error.message)
              );
              console.error(error);
            }
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Update item in data effect
   */
  updateItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateItem),
        tap(async (payload: UpdateItemPayload) => {
          const { storeKey, item } = payload;
          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          try {
            const dataProvider: TcDataProviderConfig = await selectValueByKey(
              getTcDataProvider,
              this.dataStore$,
              storeKey
            );

            /*
             * Only update the item in the DB/offline data if it's not a dynamic collection. Otherwise, the dynamic collection's state will be marked as changed,
             * and if we're in offline mode, when we actually save the data inside the DB, it will throw an error since the dynamic collection
             * does not exist inside the DB.
             * It has no incidence on the persistence of the data since we always provide data to the dynamic collection anyway
             */
            if (!dataProvider?.dynamicCollectionFrom) {
              // save to db
              await dataService.upsert(R.clone(item));

              // Show notification message if needed. By default, true.
              let displaySuccessNotification = true;
              if (payload.displaySuccessNotification !== undefined) {
                displaySuccessNotification = payload.displaySuccessNotification;
              }
              if (displaySuccessNotification === true) {
                const translatedUpdateMessage =
                  this.translateService.instant('update-successful');
                this.notification.success(translatedUpdateMessage);
              }
            }

            this.store$.dispatch(
              updateItemSuccess({
                storeKey,
                item,
              })
            );
          } catch (error) {
            this.notification.error(
              this.translateService.instant('generic-error')
            );

            if (isDevMode()) {
              this.notification.error(
                this.translateService.instant(error.message)
              );
              console.error(error);
            }
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Sets item as deleted
   */
  softDelteItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(softDeleteItem),
        tap(async (payload: UpdateItemPayload) => {
          const { storeKey, item } = payload;
          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          try {
            // save to db
            await dataService.upsert(R.clone(item));

            // Show notification message
            const translatedUpdateMessage =
              this.translateService.instant('delete-successful');
            this.notification.success(translatedUpdateMessage);

            this.store$.dispatch(
              deleteItemSuccess({
                storeKey,
                item,
              })
            );
          } catch (error) {
            this.notification.error(
              this.translateService.instant('generic-error')
            );

            if (isDevMode()) {
              this.notification.error(
                this.translateService.instant(error.message)
              );
              console.error(error);
            }
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Delete item in data effect
   */
  deleteItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteItem),
        tap(async (payload: DeleteItemPayload) => {
          const { storeKey, item } = payload;
          const dataService = await this.getDataServiceForStoreKey(
            storeKey,
            true
          );

          // save to db
          await dataService.delete(R.clone(item));

          // Show notification message
          const translatedUpdateMessage =
            this.translateService.instant('delete-successful');
          this.notification.success(translatedUpdateMessage);

          this.store$.dispatch(
            deleteItemSuccess({
              storeKey,
              item,
            })
          );
        })
      ),
    { dispatch: false }
  );

  updateItemSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateItemSuccess),
        tap(async (payload: BaseTcStorePayload) => {
          const { storeKey } = payload;
          await this.updateParentFormProperty(storeKey);
        })
      ),
    { dispatch: false }
  );

  createItemSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createItemSuccess),
        tap(async (payload: BaseTcStorePayload) => {
          const { storeKey } = payload;
          await this.updateParentFormProperty(storeKey);
        })
      ),
    { dispatch: false }
  );

  private async updateParentFormProperty(childStoreKey: string) {
    const dataProvider: TcDataProviderConfig = await selectValueByKey(
      getTcDataProvider,
      this.dataStore$,
      childStoreKey
    );

    if (dataProvider?.fromParentModelProperty) {
      const parentFormCurrentModel = await selectValueByKey(
        getTcSmartFormCurrentModel,
        this.formStore$,
        dataProvider.fromParentModelProperty.formStoreKey
      );
      const pathArray =
        dataProvider.fromParentModelProperty.propertyPath.split('.');

      const propertiesToRemove = [];
      if (dataProvider.dynamicCollectionFrom?.breezeStructuralTypeExtension) {
        propertiesToRemove.push(
          ...Object.keys(
            dataProvider.dynamicCollectionFrom.breezeStructuralTypeExtension
          )
        );
      }

      let data: any[] = R.clone(
        await selectValueByKey(getTcData, this.dataStore$, childStoreKey)
      );

      data = data.map((item) => {
        Object.keys(item).forEach((key) => {
          if (propertiesToRemove.includes(key)) delete item[key];
        });

        return item;
      });

      const parentFormNewModel = R.assocPath(
        pathArray,
        data,
        parentFormCurrentModel
      );

      const parentFormInvalidity = await selectValueByKey(
        getTcSmartFormInvalidity,
        this.formStore$,
        dataProvider.fromParentModelProperty.formStoreKey
      );

      this.store$.dispatch(
        updateTcSmartFormCurrentModel({
          storeKey: dataProvider.fromParentModelProperty.formStoreKey,
          invalid: parentFormInvalidity,
          currentModel: parentFormNewModel,
        })
      );

      const smartFormconfig: TcSmartFormConfig = await selectValueByKey(
        getTcSmartFormConfig,
        this.formStore$,
        dataProvider.fromParentModelProperty.formStoreKey
      );

      if (smartFormconfig.autoSubmit) {
        this.store$.dispatch(
          submitTcSmartFormCurrentModel({
            storeKey: dataProvider.fromParentModelProperty.formStoreKey,
          })
        );
      }
    }
  }
}
