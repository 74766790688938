import { createAction, props } from '@ngrx/store';
import { TcNavMenuNodes } from '@tc/core';

/**
 * this action is triggered after permissions are loaded
 * an effect for this action should be added in custom module for initializing the menu items
 */
export const initMenu = createAction('[Menu] Init Menu');

/**
 * Action creator for setting menu items.
 * @param {TcNavMenuNodes[]} items - The menu items to set.
 * @returns An action object with the type and payload.
 */
export const setMenuItems = createAction(
  '[Menu] Set Menu Items',
  props<{ items: TcNavMenuNodes[] }>()
);
