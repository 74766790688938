import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  TcConfigTypes,
  TcDataProviderType,
  FilterTypesEnum,
  TcSmartFilterConfig,
  TcFilterItem,
  ITcDataService,
} from '@tc/abstract';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  DEFAULT_TC_GRID_STATE_KEY,
  formlyColumn,
  formlyComponent,
  formlyControl,
  formlyRow,
  getTcGridSelection,
  NgRxTcFilterState,
  NgRxTcGridState,
  TcFormlyComponent,
  updateTcFilterSuccess,
} from '@tc/core';
import { TcSmartGridComponent } from '@tc/advanced-components';
import { Observable, Subscription } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcButtonsListComponent, TcSmartButton } from '@tc/buttons';
import { DocumentLigne } from '../../../../elevage/interfaces/document-ligne.interface';
import { Elevage } from '../../../../elevage/interfaces/elevage.interface';
import { XpertService } from '../../../../../services/xpert.service';
import {
  createEstimateFromDose,
  createOrderFromDose,
  setCanMakeEstimate,
  setCanMakeOrder,
} from '../../../store/catalog-article.actions';
import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { DualChoiceNumeric } from '../../../../../typings/dual-choice.enum';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
} from '@tc/smart-form';
import { getCurrentElevage } from '../../../../elevage/store/elevage.selectors';
import { DAS } from '../../../../../typings/DAS.enum';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
  getEstimateBtnDisabledState,
  getOrderBtnDisabledState,
} from '../../../store/catalog-article.selectors';
import { ActivatedRoute } from '@angular/router';
import { DocumentType } from '../../../../../../custom/typings/document.enum';
import { TcDataService } from '@tc/data-store';
import { ElevageDocument } from '../../../../elevage/interfaces/elevage-document.interface';
import { DataType } from 'breeze-client';
import { TcLocalStorageService } from '@tc/local-storage';
import { LocalStorageKeys } from '../../../../../typings/local-storage-keys.enum';
import { DepotStockType } from '../../../../../typings/depot.enum';
import { Dose } from '../../../interfaces/dose.interface';
import { ArticleService } from '../../../../../services/article.service';
import { ArticleOrigin } from '../../../../commande/store/commande.payloads';
import {
  gridComparatorForNumber,
  gridComparatorForString,
} from '../../../../../shared/util';
import { Actions, ofType } from '@ngrx/effects';
import { Article } from '../../../interfaces/article.interface';

@Component({
  selector: 'app-doses-grid',
  templateUrl: './doses-grid.component.html',
  styleUrls: ['./doses-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DosesGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'doses-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];
  defaultArticleFilters: TcFilterItem[] = [];

  gridStore$: Observable<NgRxTcGridState>;
  filterStore$: Observable<NgRxTcFilterState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  buttonsList: TcSmartButton[];
  elevage: Elevage;
  commandeDetailLines: DocumentLigne[];
  subscription = new Subscription();
  documentInEdition: ElevageDocument = null;

  devisDocNum: string = null;
  orderDocNum: string = null;

  private readonly articleDataService: ITcDataService<any> =
    this.dataService.getService('Article', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
      fields: 'DAS',
    });
  private readonly depotDataService: ITcDataService<any> =
    this.dataService.getService('refDepot', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refDepot',
      fields: 'numero,emplacement',
    });

  private articleLines: Dose[] = [];

  constructor(
    store$: Store<any>,
    private dataService: TcDataService,
    private readonly xpertService: XpertService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly localStorage: TcLocalStorageService,
    private readonly articleService: ArticleService,
    private readonly actions$: Actions
  ) {
    super(store$);

    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.filterStore$ = this.store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.loadCurrentElevage();
  }

  loadCurrentElevage() {
    const elevageSubscription = this.store$
      .pipe(select(getCurrentElevage))
      .subscribe((elevage) => {
        this.elevage = elevage;
      });

    this.subscription.add(elevageSubscription);
  }

  private async loadXrCreaArticles(): Promise<Dose[]> {
    // Active articles that are not in sleep mode and from DAS XRCrea
    const articleFilters: TcFilterItem[] = [
      {
        key: 'enSommeil',
        value: DualChoiceNumeric.No.toString(),
        filterType: FilterTypesEnum.Equal,
      },
      {
        key: 'DAS.intitule',
        value: DAS.XRCrea,
        filterType: FilterTypesEnum.Equal,
      },
    ];

    const collaboratorsFilters =
      await this.xpertService.getConnectedXpertArticlesFilters();
    for (const collaboratorFilter of collaboratorsFilters) {
      articleFilters.push(collaboratorFilter);
    }

    this.defaultArticleFilters = articleFilters;

    let { data: articles } = await this.articleDataService.getData(0, null, {
      filters: articleFilters,
    });

    const doses = await this.filterArticlesForDoses(articles);

    return doses;
  }

  /**
   * Filters the given array of articles and returns an array of doses.
   *
   * @param articles - The array of articles to filter.
   * @returns A promise that resolves to an array of doses.
   */
  public async filterArticlesForDoses(articles: Article[]): Promise<Dose[]> {
    // Get connexion cuve
    const containerKey = await this.localStorage.get(
      LocalStorageKeys.LastLoginContainerKey
    );

    const { data: connexionDepots } = await this.depotDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'numero',
            value: containerKey,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      }
    );

    const connexionDepot = connexionDepots[0];

    const { data: depotsTerrain } = await this.depotDataService.getData(
      0,
      null,
      {
        filters: [
          {
            key: 'typeDeStock',
            value: DepotStockType.CuveRepartitionTerrain,
            filterType: FilterTypesEnum.Equal,
          },
        ],
      }
    );

    // Filter articles that don't end with 'PR'. Request from ticket XAS-15.
    articles = articles.filter((article) => !article.reference.endsWith('PR'));

    // Filter only articles that have stocks in the current vehicle's depot or in the cuve de répartition terrain depots or is OneGenetics
    articles = articles.filter((article) => {
      const articleStocks = article.stock;
      let stockInConnectedDepot = 0;
      let stockInDepotTerrain = 0;
      let isOneGenetics = false;

      if (articleStocks) {
        stockInConnectedDepot = this.articleService.getDosesStockFromDepot(
          article,
          connexionDepot
        );

        stockInDepotTerrain = this.articleService.getDosesStockFromDepotTerrain(
          article,
          depotsTerrain
        );
      }

      isOneGenetics = article.estOneGenetics;

      return (
        stockInConnectedDepot > 0 || stockInDepotTerrain > 0 || isOneGenetics
      );
    });

    // Remap articles to Dose for performances. Keeping full article with nested objects is very heavy for the grid.
    const doses = articles.map((article) => {
      const stockInConnectedDepot = this.articleService.getDosesStockFromDepot(
        article,
        connexionDepot
      );

      const stockInDepotTerrain =
        this.articleService.getDosesStockFromDepotTerrain(
          article,
          depotsTerrain
        );

      const dose = {
        reference: article.reference,
        DAS: article.DAS,
        intitule: article.intitule,
        famille: article.DAS.offre.gamme.famille.intitule,
        sousFamille: article.sousFamille,
        stockCuve: stockInConnectedDepot,
        dispoCoop: stockInDepotTerrain,
      };

      return dose;
    });

    return doses;
  }

  async ngOnInit() {
    const documentInEditionSubscription = this.store$
      .pipe(select(getCurrentDocument))
      .subscribe(async (data) => {
        this.documentInEdition = data;
      });

    this.subscription.add(documentInEditionSubscription);

    this.articleLines = await this.loadXrCreaArticles();
    this.devisDocNum = this.activatedRoute.snapshot.queryParams['devisDocNum'];
    this.orderDocNum = this.activatedRoute.snapshot.queryParams['orderDocNum'];

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      gridOptions: {},
      columnNumberPerDevice: {
        extraSmallDevice: 14,
        smallDevice: 14,
        mediumDevice: 14,
        largeDevice: 14,
        extraLargeDevice: 14,
        extraExtraLargeDevice: 14,
      },
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'Doses',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Article',
          data: this.articleLines,
          breezeStructuralTypeExtension: {
            stockCuve: DataType.Int32,
            dispoCoop: DataType.Int32,
          },
        },
      },
      columns: [
        {
          field: 'reference',
          minWidth: 140,
          headerCheckboxSelection: this.elevage ? true : false,
          checkboxSelection: this.elevage ? true : false,
          comparator: gridComparatorForString,
        },
        {
          field: 'intitule',
          flex: 1,
          cellRenderer: (params) =>
            `<span title="${params?.data?.intitule}">${params?.data?.intitule}</span>`,
          minWidth: 300,
          comparator: gridComparatorForString,
        },
        {
          field: 'DAS.offre.gamme.famille.intitule',
          // On nested objects, standard gridComparator will not work correctly. Use custom comparator instead.
          comparator: (valueA, valueB) => {
            const valueACleaned = valueA?.DAS?.offre?.gamme?.famille?.intitule
              ? valueA?.DAS?.offre?.gamme?.famille?.intitule
              : '';
            const valueBCleaned = valueB?.DAS?.offre?.gamme?.famille?.intitule
              ? valueB?.DAS?.offre?.gamme?.famille?.intitule
              : '';
            return valueACleaned.localeCompare(valueBCleaned, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          field: 'sousFamille',
          comparator: gridComparatorForString,
        },
        {
          field: 'stockCuve',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        {
          field: 'dispoCoop',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
          comparator: gridComparatorForNumber,
        },
        /*
          // TODO uncomment if promotion popup is needed on doses grid
          {
          field: 'actions',
          sortable: false,
          minWidth: 100,
          maxWidth: 100,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          valueGetter: (params) => [' '],
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'eye-outline',
                tooltip: 'eye-tooltip-promotion',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'PromotionPopupComponent',
                  width: '28.75em',
                  height: 'auto',
                },
                name: 'promotions-popup',
              },
            ],
          },
        },*/
      ],
      filterConfig: this.getFilterConfig(),
    };

    super.ngOnInit();

    this.watchSelection();

    this.subscription.add(
      this.actions$.pipe(ofType(updateTcFilterSuccess)).subscribe((data) => {
        this.articleService.applyDASSubFilters(
          this.storeKey,
          null,
          this.defaultArticleFilters,
          this.filterArticlesForDoses.bind(this)
        );
      })
    );

    this.articleService.applyDASSubFilters(
      this.storeKey,
      null,
      this.defaultArticleFilters,
      this.filterArticlesForDoses.bind(this)
    );
  }

  watchSelection() {
    const selectedRowsSubscription = selectByKey(
      getTcGridSelection,
      this.gridStore$,
      this.storeKey
    ).subscribe(async (selectedRows: Dose[]) => {
      await this.setEstimateBtnState(selectedRows);
      await this.setOrderBtnState(selectedRows);
    });

    this.subscription.add(selectedRowsSubscription);
  }

  async setEstimateBtnState(selectedRows: Dose[]) {
    let canMakeEstimate = false;
    if (
      this.documentInEdition !== null &&
      this.documentInEdition?.type === DocumentType.Devis
    ) {
      // Get the origin of the article
      const origin = await this.store$
        .select(getCurrentDocumentArticleOrigin)
        .pipe(take(1))
        .toPromise();
      if (origin === ArticleOrigin.OrderEstimateDoses) {
        canMakeEstimate = true;
      }
    } else {
      if (selectedRows.length > 0) {
        canMakeEstimate = true;
      }
    }

    this.store$.dispatch(setCanMakeEstimate({ canMakeEstimate }));
  }

  async setOrderBtnState(selectedRows: Dose[]) {
    let canMakeOrder = false;
    if (
      this.documentInEdition !== null &&
      this.documentInEdition?.type === DocumentType.VenteBC
    ) {
      // Get the origin of the article
      const origin = await this.store$
        .select(getCurrentDocumentArticleOrigin)
        .pipe(take(1))
        .toPromise();
      if (origin === ArticleOrigin.OrderEstimateDoses) {
        canMakeOrder = true;
      }
    } else {
      if (selectedRows.length > 0) {
        canMakeOrder = true;
      }
    }

    this.store$.dispatch(setCanMakeOrder({ canMakeOrder }));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getFilterConfig(): TcSmartFilterConfig {
    return {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      isPersistant: false,
      fields: [
        formlyColumn({
          fields: [
            formlyRow({
              fields: [
                formlyControl({
                  key: 'mainFilter',
                  className: 'search-icon-filter',
                  templateOptions: {
                    filterOn: ['reference', 'intitule'],
                    filterMultiWord: true,
                    appearance: 'outline',
                  },
                  colSpan: 3,
                }),
                formlyControl({
                  key: 'race',
                  type: TcFormlyComponent.NgxInput,
                  templateOptions: {
                    filterOn: ['reference'],
                    filterType: FilterTypesEnum.StartsWith,
                    filterMultiWord: false,
                    mask: '00?',
                    appearance: 'outline',
                  },
                  colSpan: 1,
                }),
                formlyControl({
                  key: this.articleService.DASOffreFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filter: true,
                    focusOnFilter: true,
                    filterPlaceholder: 'search',
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.offre`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    resetIfValueMissing: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'DAS',
                      distinct: true,
                      transformFn: (result) => {
                        return this.articleService.transformToOffreCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: 2,
                }),
                formlyControl({
                  key: this.articleService.DASGammeFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    filter: true,
                    focusOnFilter: true,
                    filterPlaceholder: 'search',
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.gamme`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    resetIfValueMissing: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'DAS',
                      distinct: true,
                      transformFn: (result) => {
                        return this.articleService.transformToGammeCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: 3,
                }),
                formlyControl({
                  key: this.articleService.DASFamilleFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.famille`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    resetIfValueMissing: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'DAS',
                      distinct: true,
                      transformFn: (result) => {
                        return this.articleService.transformToFamilleCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: 2,
                }),
                formlyControl({
                  key: this.articleService.DASSousFamilleFilterKey,
                  type: TcFormlyComponent.TcSmartSelect,
                  templateOptions: {
                    appearance: 'outline',
                    storeKey: `${this.storeKey}.filter.sousFamille`,
                    defaultValue: '',
                    valueFieldName: 'value',
                    labelFieldName: 'label',
                    resetIfValueMissing: true,
                    dataProvider: {
                      configType: TcConfigTypes.TcDataProvider,
                      providerType: TcDataProviderType.BreezeJs,
                      dataSet: 'Article',
                      fields: 'sousFamille',
                      distinct: true,
                      transformFn: (result) => {
                        return this.articleService.transformToSousFamilleCombo(
                          result
                        );
                      },
                    },
                  },
                  colSpan: 2,
                }),
                formlyComponent({
                  component: TcButtonsListComponent,
                  className: `sale-docs-btn-list ${
                    this.elevage ? '' : 'hidden'
                  }`,
                  componentData: {
                    buttonsList: [
                      {
                        label:
                          this.documentInEdition !== null
                            ? 'select-articles'
                            : 'make-quote',
                        class:
                          this.documentInEdition !== null &&
                          this.documentInEdition?.type !== DocumentType.Devis
                            ? 'hidden'
                            : 'btn-primary btn-text',
                        action: createEstimateFromDose,
                        actionPayload: {
                          storeKey: this.storeKey,
                          devisDocNum: this.devisDocNum ?? undefined, //we need undefined and not null if the devisDocNum does not exist
                        },
                        smartStateKey: 'catalogArticle',
                        disableSelector: getEstimateBtnDisabledState,
                        disableStoreKey: 'docButtonsState',
                      },
                      {
                        label:
                          this.documentInEdition !== null
                            ? 'select-articles'
                            : 'make-order',
                        class:
                          this.documentInEdition !== null &&
                          this.documentInEdition?.type !== DocumentType.VenteBC
                            ? 'hidden'
                            : 'btn-primary btn-text',
                        action: createOrderFromDose,
                        actionPayload: {
                          storeKey: this.storeKey,
                          orderDocNum: this.orderDocNum ?? undefined,
                        },
                        smartStateKey: 'catalogArticle',
                        disableSelector: getOrderBtnDisabledState,
                        disableStoreKey: 'docButtonsState',
                      },
                    ],
                  },
                  colSpan: 10,
                }),
              ],
            }),
          ],
        }),
      ],
    };
  }
}
