import { ngxMaskOptions } from '../../app.module';

/**
 * An object containing various configuration keys used in the application.
 * @property {string} API_BASE_PATH - The base path for API requests.
 * @property {string} jwt_renewal - The key for JWT renewal configuration.
 * @property {string} jwt_expiration_check - The key for JWT expiration check configuration.
 * @property {string} idle_timeout - The key for idle timeout configuration.
 * @property {string} logout_timeout - The key for logout timeout configuration.
 * @property {string} logout_disabled - The key for logout disabled configuration.
 * @property {object} translate - An object containing translation configuration keys.
 * @property {string} translate.language - The key for language translation configuration.
 * @property {
 */
export const ConfigKeys = {
  API_BASE_PATH: 'API_BASE_PATH',
  jwt_renewal: 'jwt_renewal',
  jwt_expiration_check: 'jwt_expiration_check',
  idle_timeout: 'idle_timeout',
  logout_timeout: 'logout_timeout',
  logout_disabled: 'logout_disabled',
  translate: {
    language: 'translate.language',
  },
  logger: {
    level: 'logger.level',
    serverLogLevel: 'logger.serverLogLevel',
    serverLoggingUrl: 'logger.serverLoggingUrl',
  },
  notifications: {
    closeButton: 'notifications.closeButton',
    timeOut: 'notifications.timeOut',
  },
  layoutConfig: {
    responsiveOptions: 'layoutConfig.responsiveOptions',
    logoImageName: 'layoutConfig.logoImageName',
  },
  spinnerExceptions: 'spinnerExceptions',
  tcErrorHandlerExclusionList: 'tcErrorHandlerExclusionList',
  appTitle: 'appTitle',
  defaultPageSize: 'defaultPageSize',
  defaultAutocompleteMinLength: 'defaultAutocompleteMinLength',
  filterInputMinLength: 'filterInputMinLength',
  defaultFileUploaderFileTypes: 'defaultFileUploaderFileTypes',
  defaultForbiddenRoute: 'defaultForbiddenRoute',
  helpConfig: 'helpConfig',
  useWindowsLogin: 'useWindowsLogin',
  loginMethods: 'loginMethods',
  supportsOfflineMode: 'supportsOfflineMode',
  offlineModeDBName: 'offlineModeDBName',
  offlineModeDataProviderType: 'offlineModeDataProviderType',
  redirectRouteAfterLogin: 'redirectRouteAfterLogin',
  userMapping: 'USER_MAPPING',
  defaultEncryption: 'DEFAULT_ENCRYPTION',
  salt: 'SALT',
  syncChunkSizes: 'syncChunkSizes',
  persistentStoreKeys: 'persistentStoreKeys',
  restoreDatesWhenStoreRestoreData: 'restoreDatesWhenStoreRestoreData',
  customModuleData: 'customModuleData',
  disableKeepAlive: 'disableKeepAlive',
  verifyTermsConditions: 'verifyTermsConditions',
  ngxMaskOptions: 'ngxMaskOptions',
  breezeIgnoreAccents: 'breezeIgnoreAccents',
};
