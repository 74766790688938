import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TermsConditionsState } from './terms-conditions.state';

/**
 * Retrieves the state of the 'termsConditions' feature from the store.
 * @returns The state of the 'termsConditions' feature.
 */
export const getTermsConditionsState =
  createFeatureSelector<TermsConditionsState>('termsConditions');

/**
 * Returns a selector function that retrieves the value of the 'termsAccepted' property
 * from the 'TermsConditionsState' slice of the Redux store.
 * @param {Function} getTermsConditionsState - The selector function that retrieves the 'TermsConditionsState' slice from the Redux store.
 * @param {TermsConditionsState} state - The current state of the 'TermsConditionsState' slice.
 * @returns The value of the 'termsAccepted' property from the 'TermsConditionsState' slice.
 */
export const getTermsAccepted = createSelector(
  getTermsConditionsState,
  (state: TermsConditionsState) => state.termsAccepted
);

/**
 * Returns a selector function that retrieves the terms to validate from the terms and conditions state.
 * @param {TermsConditionsState} state - The terms and conditions state object.
 * @returns The terms to validate from the state.
 */
export const getTermsToValidate = createSelector(
  getTermsConditionsState,
  (state: TermsConditionsState) => state.termsToValidate
);
