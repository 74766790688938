/**
 * Enum representing generic routes in the application.
 */
export enum GenericRoutes {
  Login = 'login',
  LoginMfa = 'login-mfa',
  LoginOauth = 'login/oauth',
  LoginOauthFailed = 'login/oauth/failed',
  TermsOfUse = 'terms-of-use',
  Users = 'users',
  Auth = 'auth',
}
