import { PinnedRowColumnConfig } from './tc-grid-pinned-row-column-config';
import { PinnedRowPosition } from './tc-grid-pinned-rows.enum';

export class TcPinnedRowsConfig {
  /*
   *  A custom id which will be assigned to each pinned row
   */
  rowId: string;

  /*
   *  The position of the pinned row, usually either top or bottom
   */
  position: PinnedRowPosition;

  /*
   *  Custom CSS class to assign to the pinned row
   */
  cssClass?: string;

  /*
   *  Sets the config of the columns of the grid (specified by the column field), for the pinned row
   */
  columnConfigs?: PinnedRowColumnConfig[];
}
