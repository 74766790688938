import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  MaterialColor,
} from '@tc/abstract';
import { MaterialButtonType } from '@tc/buttons';
import {
  DEFAULT_TC_GRID_STATE_KEY,
  NgRxTcGridState,
  TcGridCellComponent,
} from '@tc/core';
import {
  TcSmartGridComponent,
  editTcGridButtonClicked,
  addTcGridButtonClicked,
} from '@tc/advanced-components';
import { ElevageSite } from '../../../interfaces/elevage-site.interface';
import { PermissionAction } from '../../../../../../custom/typings/permission-action.enum';
import { PermissionSubject } from '../../../../../../custom/typings/permission-subject.enum';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { hasValue } from '@tc/utils';

@Component({
  selector: 'app-sites-grid',
  templateUrl: './sites-grid.component.html',
  styleUrls: ['./sites-grid.component.scss'],
})
export class SitesGridComponent extends TcSmartGridComponent implements OnInit {
  storeKey = 'sites-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];
  gridStore$: Observable<NgRxTcGridState>;

  @Input()
  sites: ElevageSite[];

  constructor(store$: Store<any>) {
    super(store$);

    this.gridStore$ = this.store$.pipe(
      select(DEFAULT_TC_GRID_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  async ngOnInit() {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'sites-grid',
      gridOptions: {
        rowClassRules: {
          'row-bold': (params) => params.data.estPrincipal,
        },
      },
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageSite',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Elevage.site',
          data: this.sites,
        },
        fromParentModelProperty: {
          formStoreKey: 'elevage-grid',
          propertyPath: 'site',
        },
      },
      columns: [
        {
          field: 'contactSurSite',
          minWidth: 100,
          cellRenderer: (params) =>
            `<span title="${params?.data?.contactSurSite ?? ''}">${
              params?.data?.contactSurSite ?? ''
            }</span>`,
        },
        {
          field: 'intitule',
          minWidth: 110,
          cellRenderer: (params) =>
            `<span title="${params?.data?.intitule ?? ''}">${
              params?.data?.intitule ?? ''
            }</span>`,
        },
        {
          field: 'adresse',
          minWidth: 90,
          cellRenderer: (params) =>
            `<span title="${params?.data?.adresse ?? ''}">${
              params?.data?.adresse ?? ''
            }</span>`,
        },
        {
          field: 'codePostal',
          minWidth: 75,
          maxWidth: 75,
          headerClass: 'text-align-right',
          cellClass: 'text-align-right',
        },
        {
          field: 'ville',
          minWidth: 100,
          cellRenderer: (params) =>
            `<span title="${params?.data?.ville ?? ''}">${
              params?.data?.ville ?? ''
            }</span>`,
        },
        {
          field: 'telephone',
          minWidth: 120,
          maxWidth: 300,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
        {
          field: 'adresseEMail',
          minWidth: 80,
          cellRenderer: (params) =>
            `<span title="${params?.data?.adresseEMail ?? ''}">${
              params?.data?.adresseEMail ?? ''
            }</span>`,
        },
        {
          field: 'coordonneesGPS',
          minWidth: 170,
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
        {
          field: 'geographie',
          minWidth: 120,
          maxWidth: 300,
          cellRenderer: (params) =>
            `<span title="${params?.data?.geographie ?? ''}">${
              params?.data?.geographie ?? ''
            }</span>`,
        },
        {
          field: 'actions',
          sortable: false,
          minWidth: 100,
          maxWidth: 100,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'eye-outline',
                tooltip: 'eye-tooltip',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'ElevageSitePopupComponent',
                  width: '30em',
                  height: '42.5em',
                },
                name: 'edit',
                permissionAction: PermissionAction.Update,
                permissionSubject: PermissionSubject.Elevage,
              },
            ],
          },
        },
      ],
      addButton: {
        detailsPopupComponent: 'ElevageSitePopupComponent',
        action: addTcGridButtonClicked,
        ionIcon: 'add-outline',
        permissionAction: PermissionAction.Create,
        permissionSubject: PermissionSubject.Elevage,
        detailsPopupWidth: '30em',
        detailsPopupHeight: '42.5em',
      },
      columnNumberPerDevice: {
        extraSmallDevice: 10,
        smallDevice: 10,
        mediumDevice: 10,
        largeDevice: 10,
        extraLargeDevice: 10,
        extraExtraLargeDevice: 10,
      },
    };

    super.ngOnInit();
  }
}
