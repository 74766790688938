import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MenuState } from './menu.state';

export const getMenuState = createFeatureSelector<MenuState>('menu');

/**
 * Selects the menu items from the application's menu state.
 * @param {MenuState} state - The menu state object.
 * @returns The menu items from the menu state.
 */
export const getAppMenuItems = createSelector(
  getMenuState,
  (state: MenuState) => state.items
);
