import { createReducer, on } from '@ngrx/store';
import {
  loadTcPermissionsSuccess,
  refreshTcPermissionsSuccess,
} from './tc-permissions.actions';
import {
  initialTcPermissionsState,
  TcPermissionsState,
} from './tc-permissions.state';

export const tcPermissionsReducer = createReducer<TcPermissionsState>(
  initialTcPermissionsState,
  on(
    loadTcPermissionsSuccess,
    (state, { rights, authenticatedUserPermissions }) => ({
      ...state,
      rights,
      authenticatedUserPermissions,
    })
  ),
  on(
    refreshTcPermissionsSuccess,
    (state, { rights, authenticatedUserPermissions }) => ({
      ...state,
      rights,
      authenticatedUserPermissions,
    })
  )
);
