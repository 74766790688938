import {
  breeze,
  EntityManager,
  EntityQuery,
  EntityState,
  FetchStrategy,
  FilterQueryOp,
  MergeStrategy,
  Predicate,
} from 'breeze-client';
import { TcBreezeService } from '@tc/breeze';
import {
  FilterTypesEnum,
  ListOrder,
  TcFilterMultiWordOperator,
  TcSortDef,
} from '@tc/abstract';
import { TcFilterDef, TcFilterItem } from '@tc/abstract';
import { TcFilterTypes } from '@tc/abstract';
import { TcDataProviderConfig } from '@tc/abstract';
import { ITcDataResult, ITcDataService } from '@tc/abstract';
import * as R from 'ramda';
import { TC_FILTER_INTERNAL } from '@tc/core';
import { removePropertiesFromObject } from '@tc/utils';
import { TcSpinnerService } from '../tc-store/services';
import { Spinners } from '../tc-core/enums/tc-spinner-types.enum';

/**
 * A basic, generic entity data service
 */
export class TcBreezeDataService<T> implements ITcDataService<T> {
  protected _name: string;
  protected storeKey: string;
  protected dynamicCollectionKey = 'dynamic';
  dataProvider: TcDataProviderConfig;

  get name() {
    return this._name;
  }

  constructor(
    storeKey: string,
    private breeze: TcBreezeService,
    dataProvider: TcDataProviderConfig,
    private readonly spinner: TcSpinnerService
  ) {
    this._name = `TcBreezeDataService`;
    this.storeKey = storeKey;
    this.dataProvider = dataProvider;
  }

  async getData(
    skip: number,
    take: number,
    filter?: TcFilterDef,
    sort?: TcSortDef
  ): Promise<ITcDataResult<T>> {
    this.spinner.showSpinner(Spinners.OfflineDataLoading);

    // Start the offline mode and restore the data from local storage in memory if needed.
    await this.breeze.initOfflineMode();

    const manager = await this.breeze.getEntityManager();

    if (this.dataProvider.dynamicCollectionFrom) {
      await this.createDynamicCollection(manager);
    }

    let query = EntityQuery.from(this.dataProvider.dataSet);
    const hasId = await this.breeze.hasIdColumn(this.dataProvider.dataSet);

    if (this.dataProvider.distinct) {
      if (this.dataProvider.fields.includes(',')) {
        throw new Error(
          'The fields parameters should only contain one field when using distinct'
        );
      }

      query = query.withParameters({ $distinct: this.dataProvider.fields });
    } else {
      // Auto add the id column in the fields declaration to get it everytime (for offline mode)
      const fields = hasId
        ? this.dataProvider.fields + ',_id'
        : this.dataProvider.fields;

      query = query
        .take(take)
        .skip(skip)
        .select(fields)
        .inlineCount(!this.dataProvider.separatedInlineCountCall);
    }

    const addFilterToQuery = (filter) => {
      const predicate: Predicate = this.getFilterPredicates(filter);

      if (predicate !== null && predicate !== undefined) {
        query = query.where(predicate);
      }
    };

    // If we have a dataProvider filter we first query by it
    if (this.dataProvider.filter) addFilterToQuery(this.dataProvider.filter);

    // And if we also have a filter argument we query by it too
    // resulting in a $filter: (this.dataProvider.filter) and (filter) query parameter
    if (filter) addFilterToQuery(filter);

    const addSortToQuery = (sort) => {
      const isDescending = sort.order === ListOrder.Desc;

      query = query.orderBy(sort.key, isDescending);
    };

    if (sort) {
      addSortToQuery(sort);
    } else if (this.dataProvider.sortOrder) {
      addSortToQuery(this.dataProvider.sortOrder);
    }

    if (
      (await this.breeze.hasOfflineMode()) ||
      this.dataProvider?.dynamicCollectionFrom
    ) {
      query = query.using(FetchStrategy.FromLocalCache);
    }

    const queryResult = await manager.executeQuery(query);
    let results: any;
    results = queryResult.results;

    // Convert entity to raw JSON if not coming from the backend
    if (
      (await this.breeze.hasOfflineMode()) &&
      queryResult.results.length > 0
    ) {
      // Don't call this method if your not in offline mode : it may create partial entities if your query is not complete on all the collection
      const entities = await this.breeze.updateCacheAndGetEntities(
        this.dataProvider.dataSet,
        queryResult.results
      );
      const exported = manager.exportEntities(entities, {
        asString: false,
        includeMetadata: false,
      });

      results =
        exported['entityGroupMap'][
          `${this.dataProvider.dataSet}${
            this.dataProvider.dynamicCollectionFrom
              ? ':#' + this.dynamicCollectionKey
              : ''
          }`
        ]['entities'];

      for (const index in results) {
        let item = results[index];
        results[index] = removePropertiesFromObject(item, [
          'entityAspect',
          'complexAspect',
        ]);
      }
    }

    if (this.dataProvider.transformFn) {
      results = this.dataProvider.transformFn(results);
    }

    this.spinner.hideSpinner(Spinners.OfflineDataLoading);

    return { data: results, total: queryResult.inlineCount };
  }

  async getDataInlineCount(filter?: TcFilterDef): Promise<number> {
    const manager = await this.breeze.getEntityManager();

    let query = EntityQuery.from(this.dataProvider.dataSet)
      .take(0)
      .inlineCount();

    const addFilterToQuery = (filter) => {
      const predicate: Predicate = this.getFilterPredicates(filter);

      if (predicate !== null && predicate !== undefined) {
        query = query.where(predicate);
      }
    };

    // If we have a dataProvider filter we first query by it
    if (this.dataProvider.filter) addFilterToQuery(this.dataProvider.filter);

    // And if we also have a filter argument we query by it too
    // resulting in a $filter: (this.dataProvider.filter) and (filter) query parameter
    if (filter) addFilterToQuery(filter);

    if (await this.breeze.hasOfflineMode()) {
      query = query.using(FetchStrategy.FromLocalCache);
    }

    const queryResult = await manager.executeQuery(query);

    return queryResult.inlineCount;
  }

  private async createDynamicCollection(manager: EntityManager) {
    const metadata = JSON.parse(manager.metadataStore.exportMetadata());
    const hasMetadata = metadata.structuralTypes.some(
      (el) => el.shortName === this.dataProvider.dataSet && !el.namespace
    );
    if (hasMetadata) {
      throw Error(
        `Can't create dynamic dataset ${this.dataProvider.dataSet} as it is already presented in metadata.`
      );
    }

    const wasCreated = manager.metadataStore.getAsEntityType(
      this.dataProvider.dataSet,
      true
    );

    // Create dynamic collection if not present in the metadata
    if (!wasCreated) {
      const foundMetadata = metadata.structuralTypes.find(
        (el) =>
          el.shortName ===
          `${this.dataProvider.dynamicCollectionFrom?.breezeStructuralType}`
      );

      if (!foundMetadata) {
        throw Error(
          `Can't find metadata path: ${this.dataProvider.dynamicCollectionFrom.breezeStructuralType}.`
        );
      }

      let typeExtensionProperties = [];
      if (this.dataProvider.dynamicCollectionFrom.breezeStructuralTypeExtension)
        typeExtensionProperties = Object.keys(
          this.dataProvider.dynamicCollectionFrom.breezeStructuralTypeExtension
        ).map((property) => ({
          name: property,
          dataType:
            this.dataProvider.dynamicCollectionFrom
              .breezeStructuralTypeExtension[property].name,
        }));

      const dynamicCollection = {
        shortName: this.dataProvider.dataSet,
        autoGeneratedKeyType: true,
        defaultResourceName: this.dataProvider.dataSet,
        dataProperties: [
          ...foundMetadata.dataProperties,
          ...typeExtensionProperties,
        ],
        namespace: this.dynamicCollectionKey,
      };

      // Autogenerate the first column if is not in the collection configuration (implicit for TCP)
      if (
        !dynamicCollection.dataProperties.some(
          (el) => el.name === '_id' && el.isPartOfKey === true
        )
      ) {
        dynamicCollection.dataProperties.push({
          name: '_id',
          dataType: 'String',
          isPartOfKey: true,
        });
      }

      metadata.structuralTypes.push(dynamicCollection);

      manager.metadataStore.importMetadata(metadata);
    }

    // Clear all previous data if the entity manager is not empty
    await this.breeze.clearOfflineCollectionData(this.dataProvider.dataSet);

    // Check if you have a static array or a method to execute in data
    let data: any[];
    if (typeof this.dataProvider?.dynamicCollectionFrom?.data === 'function') {
      data = await this.dataProvider?.dynamicCollectionFrom?.data();
    } else {
      data = this.dataProvider?.dynamicCollectionFrom?.data;
    }

    data.map((el) => {
      const dynamicEntity = { ...el };
      // Check if id value exists inside Entity
      if (!el._id) dynamicEntity._id = this.breeze.getObjectId();

      manager.createEntity(
        this.dataProvider.dataSet,
        dynamicEntity,
        EntityState.Unchanged
      );
    });
  }

  getFilterPredicates(filter: TcFilterDef): Predicate {
    const {
      filters,
      anyFieldContainsFields,
      anyFieldStartsWithFields,
      anyFieldEqualsFields,
    } = filter;
    let predicate: Predicate;

    if (filters === null || filters === undefined || filters.length <= 0)
      return null;

    const filtersWithAnyFieldContains = filters.filter(
      (f) => f.type === TcFilterTypes.anyFieldContains
    );

    if (
      anyFieldContainsFields?.length > 0 &&
      filtersWithAnyFieldContains?.length > 0
    ) {
      predicate = Predicate.or([
        ...this.applyAnyFieldContains(
          anyFieldContainsFields,
          filtersWithAnyFieldContains
        ),
      ]);
    }

    const startWithFilters = filters.filter(
      (f) => f.type === TcFilterTypes.anyFieldStartsWith
    );

    if (anyFieldStartsWithFields?.length > 0 && startWithFilters?.length > 0) {
      if (predicate) {
        predicate.or([
          ...this.applyAnyFieldStartsWith(
            anyFieldStartsWithFields,
            startWithFilters
          ),
        ]);
      } else {
        predicate = Predicate.or([
          ...this.applyAnyFieldStartsWith(
            anyFieldStartsWithFields,
            startWithFilters
          ),
        ]);
      }
    }

    const equalsFilters = filters.filter(
      (f) => f.type === TcFilterTypes.anyFieldEquals
    );

    if (anyFieldEqualsFields?.length > 0 && equalsFilters?.length > 0) {
      if (predicate) {
        predicate.or([
          ...this.applyAnyFieldEquals(anyFieldEqualsFields, equalsFilters),
        ]);
      } else {
        predicate = Predicate.or([
          ...this.applyAnyFieldEquals(anyFieldEqualsFields, equalsFilters),
        ]);
      }
    }

    const standardFilters = filters.filter(
      (f) =>
        f.type !== TcFilterTypes.anyFieldContains &&
        f.type !== TcFilterTypes.anyFieldStartsWith &&
        f.type !== TcFilterTypes.anyFieldEquals
    );

    if (standardFilters?.length > 0) {
      if (predicate) {
        predicate = Predicate.and([
          predicate,
          Predicate.and([...this.applyStandardFilters(standardFilters)]),
        ]);
      } else {
        predicate = Predicate.and([
          ...this.applyStandardFilters(standardFilters),
        ]);
      }
    }

    return predicate;
  }

  applyStandardFilters(standardFilters: TcFilterItem[]) {
    const predArr: Predicate[] = [];

    for (const f in standardFilters) {
      const filter = standardFilters[f];
      const p = this.setFilterPredicate(filter);

      if (p !== null && p !== undefined) {
        predArr.push(p);
      }
    }

    return predArr;
  }

  /*
   * convert complex object value { key: { prop: value } } to complex property { "key.prop": value }
   */
  destructureKeyValue(key: string, value: any) {
    if (value != null && typeof value === 'object' && !Array.isArray(value)) {
      key += '.' + Object.keys(value)[0];
      value = value[Object.keys(value)[0]];
      return this.destructureKeyValue(key, value);
    }

    return { key, value };
  }

  setFilterPredicate(filter: TcFilterItem): Predicate {
    const filterType = filter.filterType;

    let { key, value } = this.destructureKeyValue(filter.key, filter.value);

    // Check if the they contains any alteration resulted from formlyControl
    if (key.includes(TC_FILTER_INTERNAL)) {
      // Take only the real key, in order to properly filter the db
      key = key.substr(0, key.indexOf(TC_FILTER_INTERNAL));
    }

    /* by default breeze generates dataType=Double for number properties
     * eg: for value 1 it generates the expesion '1d' which crashes in mongo
     * so we force integer values to Int64 type */
    if (value === parseInt(value, 10)) {
      value = { value, dataType: breeze.DataType.Int64 };
    }

    // Mongo Query package uses Regex Expressions so we need to escape special regex charactes such as '+'
    // Solution form https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    if (typeof value === 'string') {
      value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    if (filter?.filterOn && filter?.filterOn.length > 0 && value) {
      if (filter.filterMultiWord) {
        // Multi word behavior
        const predicates: Predicate[] = [];

        //Break filter value into words
        const words = value
          .trim()
          .split(' ')
          .filter((word) => word !== '');

        //Generate a list with predicates for each word
        words.forEach((word) => {
          const wordPredicates: Predicate[] = [];

          //Generate a predicate for each key * word
          filter.filterOn.forEach((field) => {
            wordPredicates.push(
              this.generatePredicate(filterType, field, word)
            );
          });

          predicates.push(Predicate.or(wordPredicates));
        });

        // Create a big preadicate with all the words predicates
        switch (filter.filterMultiWordOperator) {
          case TcFilterMultiWordOperator.Or: {
            return Predicate.or(predicates);
          }
          case TcFilterMultiWordOperator.And:
          default: {
            return Predicate.and(predicates);
          }
        }
      } else {
        // Base behavior
        const predicates: Predicate[] = [];
        for (const field of filter.filterOn) {
          predicates.push(this.generatePredicate(filterType, field, value));
        }
        return Predicate.or(predicates);
      }
    } else {
      return this.generatePredicate(filterType, key, value);
    }
  }

  /**
   * Get a Predicate object based on filter type, field name, value to search
   * @param filterType Type of filter
   * @param field Name of the field to apply the filter
   * @param value Value of the filter
   * @returns Predicate
   */
  private generatePredicate(
    filterType: FilterTypesEnum,
    field: string,
    value: any
  ): Predicate {
    switch (filterType) {
      case FilterTypesEnum.Equal: {
        return new Predicate(field, FilterQueryOp.Equals, value);
      }
      case FilterTypesEnum.NotEqual: {
        return new Predicate(field, FilterQueryOp.NotEquals, value);
      }
      case FilterTypesEnum.IsNotNullOrEmptyString: {
        return value
          ? new Predicate(field, FilterQueryOp.NotEquals, null).and(
              new Predicate(field, FilterQueryOp.NotEquals, '')
            )
          : new Predicate(field, FilterQueryOp.Equals, null).or(
              new Predicate(field, FilterQueryOp.Equals, '')
            );
      }
      case FilterTypesEnum.IsNotEmpty: {
        return value
          ? new Predicate(field, FilterQueryOp.NotEquals, null).and(
              new Predicate(field, FilterQueryOp.NotEquals, '')
            )
          : null;
      }
      case FilterTypesEnum.In: {
        if (value === null) return null;

        let values;

        if (typeof value === 'string') {
          values = value.split(',');
        } else {
          // In case we have an array
          values = value;
        }

        if (values !== null && values.length > 0) {
          return new Predicate(field, FilterQueryOp.In, values);
        }

        return null;
      }
      case FilterTypesEnum.IsNull: {
        return new Predicate(
          field,
          value ? FilterQueryOp.NotEquals : FilterQueryOp.Equals,
          null
        );
      }
      case FilterTypesEnum.IsNotNull: {
        return new Predicate(
          field,
          value ? FilterQueryOp.Equals : FilterQueryOp.NotEquals,
          null
        );
      }
      case FilterTypesEnum.Contains: {
        return value === ''
          ? null
          : new Predicate(field, FilterQueryOp.Contains, value);
      }
      case FilterTypesEnum.StartsWith: {
        return value === ''
          ? null
          : new Predicate(field, FilterQueryOp.StartsWith, value);
      }
      case FilterTypesEnum.DateRange: {
        if (value === null || value === undefined) return null;

        const dates = value.split('|');

        return Predicate.create(
          field,
          FilterQueryOp.GreaterThanOrEqual,
          new Date(dates[0])
        ).and(field, FilterQueryOp.LessThanOrEqual, new Date(dates[1]));
      }
      case FilterTypesEnum.DateRangeFromString: {
        if (value === null || value === undefined) return null;

        const dates = value.replace(/\\/g, '').split('|'); //Remove the backslashes escapes that we added before, which were meant for mongo query's regular expression

        return Predicate.create(
          field,
          FilterQueryOp.GreaterThanOrEqual,
          dates[0]
        ).and(field, FilterQueryOp.LessThanOrEqual, dates[1]);
      }
      case FilterTypesEnum.DateGreaterThanOrEqual: {
        return value === ''
          ? null
          : new Predicate(field, FilterQueryOp.GreaterThanOrEqual, value);
      }
      case FilterTypesEnum.DateLessThanOrEqual: {
        return value === ''
          ? null
          : new Predicate(field, FilterQueryOp.LessThanOrEqual, value);
      }
      default: {
        return new Predicate(field, FilterQueryOp.Equals, value);
      }
    }
  }

  applyAnyFieldContains(
    anyFieldContainsKeys: string[],
    filtersWithAnyFieldContains: TcFilterItem[]
  ) {
    const anyFieldPredicate: Predicate[] = [];

    for (const f in filtersWithAnyFieldContains) {
      const filter = filtersWithAnyFieldContains[f];

      for (const index in anyFieldContainsKeys) {
        const key = anyFieldContainsKeys[index];

        const pred = Predicate.create(
          key,
          FilterQueryOp.Contains,
          filter.value
        );

        anyFieldPredicate.push(pred);
      }
    }

    return anyFieldPredicate;
  }

  applyAnyFieldStartsWith(
    anyFieldStartsWithKeys: string[],
    filtersWithAnyFieldStartsWith: TcFilterItem[]
  ) {
    const anyFieldPredicate: Predicate[] = [];

    for (const f in filtersWithAnyFieldStartsWith) {
      const filter = filtersWithAnyFieldStartsWith[f];

      for (const index in anyFieldStartsWithKeys) {
        const key = anyFieldStartsWithKeys[index];

        const pred = Predicate.create(
          key,
          FilterQueryOp.StartsWith,
          filter.value
        );

        anyFieldPredicate.push(pred);
      }
    }

    return anyFieldPredicate;
  }

  applyAnyFieldEquals(
    anyFieldEqualsKeys: string[],
    filtersWithAnyFieldEquals: TcFilterItem[]
  ) {
    const anyFieldPredicate: Predicate[] = [];

    for (const f in filtersWithAnyFieldEquals) {
      const filter = filtersWithAnyFieldEquals[f];

      for (const index in anyFieldEqualsKeys) {
        const key = anyFieldEqualsKeys[index];

        const pred = Predicate.create(key, FilterQueryOp.Equals, filter.value);

        anyFieldPredicate.push(pred);
      }
    }

    return anyFieldPredicate;
  }

  /**
   * Creates or updates a document based on whether or not it had an id
   * NOTE: Wrap this in a try catch block and only display the real error in dev mode
   * so that we don't show English error messages coming from the server/breeze or form the
   * method itself directly to the client.
   * @param item, i.e the document
   * @returns the newly created/updated document
   */
  async upsert(item: T): Promise<T> {
    const itemCopy = R.clone(item);
    // If the item has no id property or it is null that means we are creating a new docuemnt
    if (
      (item && (item as any)._id === null) ||
      (item as any)._id === undefined
    ) {
      (item as any)._id = this.breeze.getObjectId();

      const newItem = await this.breeze.createEntity(
        this.dataProvider.dataSet,
        item
      );

      itemCopy._id = (newItem as any)._id;

      await this.breeze.sync(this.dataProvider.dataSet);
      // If the item alread has an id property that means we are updating an old document
    } else if (item && (item as any)._id) {
      // Await for the entity before sync
      await this.breeze.createEntity(
        this.dataProvider.dataSet,
        item,
        EntityState.Modified,
        MergeStrategy.OverwriteChanges
      );

      await this.breeze.sync(this.dataProvider.dataSet);
    } else {
      throw Error("There is something wrong with the object's id");
    }

    return itemCopy;
  }

  /**
   * Remove a document
   * @param item
   */
  async delete(item: T): Promise<any> {
    // Await for the entity before sync
    await this.breeze.createEntity(
      this.dataProvider.dataSet,
      item,
      EntityState.Deleted,
      MergeStrategy.OverwriteChanges
    );

    await this.breeze.sync(this.dataProvider.dataSet);
  }
}
