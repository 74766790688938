import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfigService } from '../../shared/services/config.service';
import { hasOfflineMode, offlineModeActivated } from '@tc/store';
import { ConfigKeys } from '../../shared/interfaces/config.interface';
import { SyncService } from '../services/sync.service';
import { TcOfflineModeService } from '@tc/breeze';
import { TcDataServiceFactory } from '@tc/data-store';

@Injectable()
export class OfflineModeResolver implements Resolve<any> {
  private offlineModeService: TcOfflineModeService =
    this.tcDataServiceFactory.getOfflineModeService(
      this.config.get(ConfigKeys.offlineModeDataProviderType)
    );

  constructor(
    private readonly store$: Store<any>,
    private readonly router: Router,
    private readonly config: ConfigService,
    private readonly syncService: SyncService,
    private readonly tcDataServiceFactory: TcDataServiceFactory
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const isInOfflineMode = await this.offlineModeService.hasOfflineMode();

    if (isInOfflineMode) {
      this.store$.dispatch(offlineModeActivated());
    } else {
      const isSyncAvailable = await this.syncService.isSyncAvailable();

      if (isSyncAvailable) {
        // Launch the auto activation of the offline mode for XpertMobile if the offline mode is not set
        this.store$.dispatch(
          hasOfflineMode({
            providerType: this.config.get(
              ConfigKeys.offlineModeDataProviderType
            ),
            relaunch: true,
            popupComponentName: 'SyncResponsePopupComponent',
          })
        );
      }
    }

    return true;
  }
}
