import { TermsOfUse } from '../interfaces/terms-of-use.interface';

/**
 * Represents the state of the terms and conditions acceptance.
 */
export interface TermsConditionsState {
  termsAccepted: boolean;
  termsToValidate: TermsOfUse;
}

/**
 * The initial state for the authentication terms and conditions.
 * @type {TermsConditionsState}
 * @property {boolean} termsAccepted - Indicates whether the terms and conditions have been accepted.
 * @property {null} termsToValidate - The terms and conditions to be validated.
 */
export const initialAuthState: TermsConditionsState = {
  termsAccepted: false,
  termsToValidate: null,
};
