import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcPage } from '@tc/core';
import { IPageState } from '@tc/store';
import { CanDeactivateComponent } from '../../../modules/guards/deactivation-confirmation.guard';
import { CommandeDetailGridComponent } from '../../modules/commande/components/smart/commande-detail-grid/commande-detail-grid.component';

@Component({
  selector: 'app-commande-detail-page',
  templateUrl: './commande-detail-page.component.html',
  styleUrls: ['./commande-detail-page.component.scss'],
})
export class CommandeDetailPageComponent
  extends TcPage
  implements OnInit, CanDeactivateComponent
{
  @ViewChild(CommandeDetailGridComponent)
  commandeDetailGridComponent: CommandeDetailGridComponent;

  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'commande-detail-page.title',
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }

  shouldConfirmDeactivation(): Promise<boolean> {
    return this.commandeDetailGridComponent.isChanged();
  }
}
