import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { FilterTypesEnum } from '@tc/abstract';
import { TcSmartMultiSelectOptions } from '../../../interfaces/tc-smart-multi-select-options';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TcFormSmartFieldConfig } from '../../../abstract/tc-form-smart-field-config';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';
import { ConfigKeys } from 'apps/frontend/src/app/shared/interfaces/config.interface';
import { Observable } from 'rxjs/internal/Observable';
import { TcFormlyTemplateOptions } from '../../../abstract/tc-formly-template-options';

@Component({
  selector: 'tc-formly-multi-select',
  templateUrl: './tc-formly-multi-select.component.html',
  styleUrls: ['./tc-formly-multi-select.component.scss'],
})
export class TcFormlyMultiSelectComponent extends FieldType implements OnInit {
  get inputClearedObservable(): Observable<Symbol> {
    return (this.to as TcFormlyTemplateOptions)?.inputCleared$;
  }

  /**
   * Default options
   */
  private readonly defaultMultiselectOptions: TcSmartMultiSelectOptions = {
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
    autocompleteMinLength: 3,
    maxOptionsNumber: 10,
    filterOptionsType: FilterTypesEnum.StartsWith,
  };

  /**
   * Display method for deplaying items
   */
  public displayMethod;

  /**
   * Options
   */
  public multiselectOptions: TcSmartMultiSelectOptions;

  /**
   * Smart filed config for conecting to the store
   */
  public smartConfigs: TcFormSmartFieldConfig;

  /**
   * Default unique field in case the used developer doesn't specify one
   * Used for filtering out already selected items.
   */
  private defaultUniqueFieldName: string = '_id';

  /**
   * Used for emiting only certing properties of an items to the form model
   */
  private emitFields: string | string[];

  /**
   * Used as a bridge between the multiselect component and the formControl
   */
  selectedValues: any;

  /**
   * The name of the input field. For testing purposes
   */
  fieldName: string;

  constructor(private readonly config: ConfigService) {
    super();

    const defaultAutocompleteMinLength = this.config.get(
      ConfigKeys.defaultAutocompleteMinLength
    ) as number;

    if (defaultAutocompleteMinLength)
      this.defaultMultiselectOptions.autocompleteMinLength =
        defaultAutocompleteMinLength;
  }

  ngOnInit() {
    this.displayMethod = this.to.display;

    const {
      storeKey,
      dataProvider: { fields: defaultLabelFieldName, distinct },
      uniqueFieldName,
      labelFieldName,
      emitFields,

      selectable,
      removable,
      addOnBlur,
      separatorKeysCodes,
      autocompleteMinLength,
      maxOptionsNumber,
      filterOptionsType,
      disabled,
      multipleFieldSource,
      defaultValue,
      closeMatIcon,
      closeFaIcon,
      closeIonIcon,
    } = this.to;

    this.smartConfigs = {
      storeKey: storeKey,
      uniqueFieldName: uniqueFieldName || this.defaultUniqueFieldName,
      labelFieldName: labelFieldName || defaultLabelFieldName,
    };

    this.multiselectOptions = {
      selectable: selectable || this.defaultMultiselectOptions.selectable,
      removable: removable || this.defaultMultiselectOptions.removable,
      addOnBlur: addOnBlur || this.defaultMultiselectOptions.addOnBlur,
      separatorKeysCodes:
        separatorKeysCodes || this.defaultMultiselectOptions.separatorKeysCodes,
      autocompleteMinLength:
        typeof autocompleteMinLength === 'number'
          ? autocompleteMinLength
          : this.defaultMultiselectOptions.autocompleteMinLength,
      maxOptionsNumber:
        maxOptionsNumber || this.defaultMultiselectOptions.maxOptionsNumber,
      filterOptionsType:
        filterOptionsType || this.defaultMultiselectOptions.filterOptionsType,
      closeMatIcon,
      closeFaIcon,
      closeIonIcon,
      disabled,
      multipleFieldSource,
      defaultValue,
    };

    this.emitFields = emitFields;

    /**
     * If a distinct field is specified the backend will return an array of objects
     * that have only one property (the distinct filed name specified) thus, the
     * label field name and the field that the autocomplete will emit should be
     * the distinct filed name specified
     */
    if (distinct) {
      this.smartConfigs.labelFieldName = defaultLabelFieldName;
      this.smartConfigs.uniqueFieldName = defaultLabelFieldName;
      this.emitFields = defaultLabelFieldName;
    }

    this.selectedValues = defaultValue ?? this.formControl.value;

    this.fieldName = (this.formControl as any)._fields[0]?.name;
  }

  /**
   * Called whenever the selected items change
   * @param items
   */
  public onChangeSelected(items: any[]) {
    this.selectedValues = items;

    /**
     * The input loses it's focus when a autocomplete option is selected but it
     * actually needs to remain focused
     */
    // this.field.focus = true;

    let mappedModel = [];
    if (items) {
      if (!this.emitFields) {
        mappedModel = items;
      } else {
        mappedModel = items.map((elem) => {
          if (Array.isArray(this.emitFields)) {
            const mappedObject = {};
            this.emitFields.forEach((field) => {
              mappedObject[field] = elem[field];
            });

            return mappedObject;
          } else {
            const mappedObject = {};
            mappedObject[this.emitFields] = elem[this.emitFields];
            return mappedObject;
          }
        });
      }
    }

    this.updateModel(mappedModel);
    this.formControl.setValue(mappedModel);
  }

  /**
   * Event to handle form status on focus
   */
  public onBlur() {
    this.formControl.markAsTouched();
    this.field.focus = false;
  }

  /**
   * Change label floting property
   * @param floatType : string = always | auto
   */
  public onChangeFloat(floatType) {
    this.formField.floatLabel = floatType;
  }

  /**
   * Update the model with items. Formly is not very compatible with arrays of objects.
   * On normal submit without touching the model, he's not sending all the data.
   * @param data Array of items
   */
  private updateModel(data: any[] | null) {
    if (
      this.multiselectOptions.multipleFieldSource &&
      this.multiselectOptions.multipleFieldSource.length > 0
    ) {
      var schema = this.model;
      for (let field of this.multiselectOptions.multipleFieldSource) {
        if (data) {
          const item = data.find(
            (item) => item[field] === item[this.smartConfigs.labelFieldName]
          );

          schema[field] = item ? item[field] : null;
        } else {
          schema[field] = null;
        }
      }
    }
    // Clear one time to avoid reference problems
    else if (
      this.field.key &&
      (typeof this.field.key === 'string' || typeof this.field.key === 'number')
    ) {
      // Update nested key
      var schema = this.model;
      var keys = (this.field.key as string).split('.');
      var len = keys.length;

      for (var i = 0; i < len - 1; i++) {
        var elem = keys[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
      }

      schema[keys[len - 1]] = null;
      // Update the model
      schema[keys[len - 1]] = data;
    }
  }
}
