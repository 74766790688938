import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcCoreModule } from '@tc/core';
import { ExampleDialogComponent } from './components/smart/example-dialog/example-dialog.component';
import { ExampleTcTextareaComponent } from './components/smart/example-tc-textarea/example-tc-textarea.component';

const dialogs = [ExampleDialogComponent, ExampleTcTextareaComponent];

@NgModule({
  declarations: [...dialogs],
  imports: [CommonModule, TcCoreModule],
  exports: [...dialogs],
})
/**
 * Represents a module for storing and organizing examples.
 * This class can be used to group related examples together and provide a
 * centralized location for accessing and managing them.
 */
export class ExamplesModule {}
