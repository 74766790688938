import { createReducer, on } from '@ngrx/store';
import { setCurrentElevage, setLastFilteredSecteur } from './elevage.actions';
import { initialElevageState, ElevageState } from './elevage.state';

export const elevageReducer = createReducer<ElevageState>(
  initialElevageState,
  on(setCurrentElevage, (state, { currentElevage }) => ({
    ...state,
    currentElevage,
  })),
  on(setLastFilteredSecteur, (state, { secteur }) => ({
    ...state,
    lastFilteredSecteur: secteur,
  }))
);
