import { Injectable } from '@angular/core';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
} from '@tc/abstract';
import { DEFAULT_TC_DATA_STATE_KEY, TcDataService } from '@tc/data-store';
import { ElevageDocument } from '../modules/elevage/interfaces/elevage-document.interface';
import { DocumentLignePriceEngine } from '../modules/elevage/interfaces/document-ligne-price-engine.interface';
import { NgRxTcGridState } from '@tc/core';
import { Observable } from 'rxjs';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
} from '@tc/smart-form';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { hasValue } from '@tc/utils';
import { getCurrentElevage } from '../modules/elevage/store/elevage.selectors';
import * as R from 'ramda';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
} from '../modules/article/store/catalog-article.selectors';
import { DocumentLigne } from '../modules/elevage/interfaces/document-ligne.interface';
import { setCurrentDocument } from '../modules/article/store/catalog-article.actions';
import { Elevage } from '../modules/elevage/interfaces/elevage.interface';
import { DiscountType } from '../typings/discount-type.enum';
import { Article } from '../modules/article/interfaces/article.interface';
import { DocumentDomaine, DocumentType } from '../typings/document.enum';

//MTU change

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  static readonly TARIF_ADHERENT = 'ADHERENT';

  // Min and max dates for replacing the dates NULL or equal to 1753-01-01
  private minDate = '0000-01-01';
  private maxDate = '9999-12-31';

  dataStore$: Observable<NgRxTcGridState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  private readonly refCategoriesTarifaireDataService: ITcDataService<any> =
    this.dataService.getService('refCategorieTarifaire', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refCategorieTarifaire',
    });

  private readonly blcMatchTarifsDataService: ITcDataService<any> =
    this.dataService.getService('BlcMatchTarifs', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'BlcMatchTarifs',
    });

  private readonly refRemiseTransportUGVDataService: ITcDataService<any> =
    this.dataService.getService('refRemiseTransportUGV', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'refRemiseTransportUGV',
    });

  private readonly promotionsDataService: ITcDataService<any> =
    this.dataService.getService('Promotions', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Promotions',
    });

  private readonly articleDataService: ITcDataService<any> =
    this.dataService.getService('Article', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Article',
    });

  private articles: any[] = [];
  private refCategorieTarifaires: any[] = [];
  private promotions: any[] = [];
  private refRemiseTransportUGV: any[] = [];
  private blcMatchTarifs: any[] = [];

  constructor(
    protected store$: Store<any>,
    private dataService: TcDataService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Typescript port of the C# function SqlReaderToDateTime in the old tarificateur used to return a default date if the date is null / empty / undefined or equal to 1753-01-01
   * @param currentDate The date from mongo
   * @param defaultDate The date to apply if the currentDate is null / empty / undefined or equal to 1753-01-01
   * @returns string date in ISO format YYYY-MM-DD
   */
  private cleanDate(
    currentDate: string | null | undefined,
    defaultDate: string
  ): string {
    /*
        public static DateTime SqlReaderToDateTime(object reader, DateTime dateTimeNull)
        {
            if (reader == DBNull.Value) return dateTimeNull;
            if ((DateTime)reader == new DateTime(1753, 1, 1)) return dateTimeNull;
            return (DateTime)reader;
        }
      */
    if (
      currentDate === null ||
      currentDate === undefined ||
      currentDate === '' ||
      currentDate.startsWith('1753-01-01')
    ) {
      return defaultDate;
    }

    return currentDate;
  }

  /**
   * Get the property name of elevage corresponding to a contract type string from articles
   * @param value string to check
   * @returns
   */
  private getElevageContractTypeProperty(value: string) {
    if (value === null || value === undefined) {
      return null;
    }
    value = value.trim().toLowerCase();
    switch (value) {
      case 'contrat bovin lait':
        return 'contratBovinLait';
      case 'contrat bovin viande':
        return 'contratBovinViande';
      case 'contrat caprin':
        return 'contratCaprin';
      case 'contrat crea':
        return 'contratCrea';
      default:
        return null;
    }
  }

  private async getArticles(): Promise<any[]> {
    if (this.articles.length === 0) {
      const { data: articles } = await this.articleDataService.getData(0, null);
      this.articles = articles;
    }
    return this.articles;
  }

  private async getRefCategorieTarifaires(): Promise<any[]> {
    // All refCategorieTarifaires matching the query "SELECT CT_Intitule FROM P_CATTARIF WHERE CT_Intitule <> ''
    if (this.refCategorieTarifaires.length === 0) {
      const { data: refCategorieTarifaires } =
        await this.refCategoriesTarifaireDataService.getData(0, null, {
          filters: [
            {
              key: 'intitule',
              value: 'true',
              filterType: FilterTypesEnum.IsNotNullOrEmptyString,
            },
          ],
        });
      this.refCategorieTarifaires = refCategorieTarifaires;
    }
    return this.refCategorieTarifaires;
  }

  private async getPromotions(): Promise<any[]> {
    if (this.promotions.length === 0) {
      const { data: promotions } = await this.promotionsDataService.getData(
        0,
        null
      );
      this.promotions = promotions;
    }
    return this.promotions;
  }

  private async getBlcMatchTarif(): Promise<any[]> {
    if (this.blcMatchTarifs.length === 0) {
      const { data: blcMatchTarifs } =
        await this.blcMatchTarifsDataService.getData(0, null);
      this.blcMatchTarifs = blcMatchTarifs;
    }
    return this.blcMatchTarifs;
  }

  private async getRefRemiseTransportUGV(): Promise<any[]> {
    if (this.refRemiseTransportUGV.length === 0) {
      const { data: refRemiseTransportUGV } =
        await this.refRemiseTransportUGVDataService.getData(0, null);
      this.refRemiseTransportUGV = refRemiseTransportUGV;
    }
    return this.refRemiseTransportUGV;
  }

  /**
   * Calculate prices for the given document
   * @param document ElevageDocument that need his prices to be updated
   * @returns ElevageDocument
   */
  public async calculatePrices(
    document: ElevageDocument,
    elevage: Elevage
  ): Promise<ElevageDocument> {
    // If the document has no lines, no need to do anything. End the call.
    if (document?.ligne.length === 0) {
      return document;
    }

    // All articles
    const articles = await this.getArticles();

    // All ref categories
    const refCategorieTarifaires = await this.getRefCategorieTarifaires();

    // All promotions
    const promotions = await this.getPromotions();

    // ALL BLC Tarifs
    const blcMatchTarifs = await this.getBlcMatchTarif();

    // All refRemiseTransportUGV
    const refRemiseTransportUGV = await this.getRefRemiseTransportUGV();

    //SAGE-C#-code     double nbUGV = 0;
    //SAGE-C#-code     double contLog = 0;
    let nbUGV = 0;
    let contLog = 0;
    let TQ_RefCF: string;

    //SAGE-C#-code     IBODocumentVenteLigne3 docLineContLog = null;
    let docLineContLog: DocumentLigne;

    //SAGE-C#-code     //Get the document's header
    //SAGE-C#-code     IBODocumentVente3 docHeader = _objetsMetierConnexion.BaseCial.FactoryDocumentVente.ReadPiece(_documentHeader.DocumentType, _documentHeader.DO_Piece);
    const docHeader = {
      date: document.date,
      datePieceDevisOrigine:
        document?.ligne && document?.ligne.length > 0
          ? document.ligne[0].datePieceDevisOrigine
          : undefined,
    };

    //SAGE-C#-code     progressBar.Maximum = docHeader.FactoryDocumentLigne.List.Count;
    const docHeader_FactoryDocumentLigne_List = document.ligne;

    const progressBar_Maximum = docHeader_FactoryDocumentLigne_List.length;

    //SAGE-C#-code     string catTarif = docHeader.CategorieTarif.CT_Intitule;
    const catTarifId = elevage.categorieTarifParDefaut;

    // WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING
    // *******************************************************************************
    // In case of changes of SAGE Price Engine DO NOT FORGET TO UPDATE function getCurrentPromotion & getTarifDegressif bellow to reflect the changes
    // *******************************************************************************
    // WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING

    // normally query the label in refCategorieTarifaire
    const catTarif = refCategorieTarifaires.find(
      (item) => String(item.numeroInterne) === String(catTarifId)
    ).intitule;

    //SAGE-C#-code     List<MatchTarifs> matchesTarifs = MatchTarifsDao.GetTarifWithoutOneCatTarif(_sqlConnexion.SqlConnection, catTarif);
    //     public static List<MatchTarifs> GetTarifWithoutOneCatTarif(SqlConnection sqlConnection, string catTarif)
    // {
    //     List<MatchTarifs> matchTarifs = new List<MatchTarifs>();
    //     var cmd = sqlConnection.CreateCommand();
    //     cmd.CommandText = @"SELECT ID, Tarif, CatTarif FROM _BLC_MatchTarifs WHERE CatTarif <> @CatTarif";
    //     cmd.Parameters.AddWithValue("@CatTarif", catTarif);
    //     using (var reader = cmd.ExecuteReader())
    //     {
    //         while (reader.Read())
    //         {
    //             matchTarifs.Add(new MatchTarifs()
    //             {
    //                 Id = (int)reader["ID"],
    //                 Tarif = reader["Tarif"] == DBNull.Value ? string.Empty : (string)reader["Tarif"],
    //                 CatTarif = reader["CatTarif"] == DBNull.Value ? string.Empty : (string)reader["CatTarif"]
    //             });
    //         }
    //     }
    //     return matchTarifs;
    // }
    // Sum up : query SELECT ID, Tarif, CatTarif FROM _BLC_MatchTarifs WHERE CatTarif <> @CatTarif

    const matchesTarifs = blcMatchTarifs.filter(
      (item) => item.catTarif !== catTarif
    );

    //SAGE-C#-code     //Get all Sage tariffs
    //SAGE-C#-code     List<Tarif> tarifs = await TarifDao.GetAllTarifs(_sqlConnexion.SqlConnection, matchesTarifs);
    // public async static Task<List<Tarif>> GetAllTarifs(SqlConnection sqlConnection, List<MatchTarifs> matchTarifs)
    // {
    //     List<Tarif> tarifs = new List<Tarif>();
    //     var cmd = new SqlCommand()
    //     {
    //         Connection = sqlConnection,
    //         CommandText = @"SELECT TF_No, TF_Intitule, TF_Debut, TF_Fin, TF_Objectif, AR_Ref
    //                         FROM F_TARIF
    //                         WHERE TF_Type = 1
    //                         AND TF_Interes = 2
    //                         AND (CT_Num IS NULL OR CT_Num = '') "
    //     };
    //     if (matchTarifs.Count > 0)
    //     {
    //         cmd.CommandText += "AND TF_Intitule NOT IN (@Tarifs)";
    //         cmd.Parameters.AddWithValue("@Tarifs", string.Join(", ", matchTarifs.Select(x => x.Tarif).ToList()));
    //     }

    const tarifs = promotions
      .map((promo) => {
        return {
          TF_No: promo.numero,
          TF_Intitule: promo.intitule,
          TF_Debut: this.cleanDate(promo.debut, this.minDate), // Port of C# function SqlReaderToDateTime
          TF_Fin: this.cleanDate(promo.fin, this.maxDate), // Port of C# function SqlReaderToDateTime
          TF_Objectif: promo.objectif,
          AR_Ref: promo.articlePromo.reference,
          TS_Refs: [],
        };
      })
      .filter((tarif) => {
        return !matchesTarifs.some(
          (match) => match.tarif === tarif.TF_Intitule
        );
      });

    //     using (var reader = await cmd.ExecuteReaderAsync())
    //     {
    //         while (await reader.ReadAsync())
    //         {
    //             var tarif = new Tarif();
    //             tarif.TF_No = (int)reader["TF_No"];
    //             tarif.TF_Intitule = reader["TF_Intitule"] == DBNull.Value || reader["TF_Intitule"] == DBNull.Value ? string.Empty : (string)reader["TF_Intitule"];
    //             tarif.TF_Debut = DateTimeService.SqlReaderToDateTime(reader["TF_Debut"], DateTime.MinValue);
    //             tarif.TF_Fin = DateTimeService.SqlReaderToDateTime(reader["TF_Fin"], DateTime.MaxValue);
    //             tarif.TF_Objectif = (Int16)reader["TF_Objectif"];
    //             tarif.AR_Ref = reader["AR_Ref"] == DBNull.Value ? string.Empty : (string)reader["AR_Ref"];
    //             tarifs.Add(tarif);
    //         }
    //     }
    //     return tarifs;
    // }

    //SAGE-C#-code     var validatedTarifsByDate = tarifs.Where(x => x.TF_Debut <= docHeader.DO_Date && docHeader.DO_Date <= x.TF_Fin).ToList(); // Filtering on document date
    const dateHeader = docHeader.datePieceDevisOrigine
      ? docHeader.datePieceDevisOrigine
      : docHeader.date;
    const validatedTarifsByDate = tarifs.filter(
      (t) => t.TF_Debut <= dateHeader && dateHeader <= t.TF_Fin
    );

    //SAGE-C#-code     //Get products of tarifs
    //SAGE-C#-code     foreach (var tarif in validatedTarifsByDate)
    //SAGE-C#-code         await tarif.GetTarif_TS_Refs(_sqlConnexion.SqlConnection);
    //     public async static Task GetTarif_TS_Refs(this Tarif tarif, SqlConnection sqlConnection)
    // {
    //     var cmd = new SqlCommand()
    //     {
    //         Connection = sqlConnection,
    //         CommandText = @"SELECT TS_Ref FROM F_TARIFSELECT WHERE TF_No = @TF_No"
    //     };
    //     cmd.Parameters.AddWithValue("@TF_No", tarif.TF_No);
    //     cmd.ExecuteNonQuery();
    //     using (var reader = await cmd.ExecuteReaderAsync())
    //     {
    //         while (await reader.ReadAsync())
    //         {
    //             if(reader["TS_Ref"] != DBNull.Value)
    //                 tarif.TS_Refs.Add((string)reader["TS_Ref"]);
    //         }
    //     }
    // }
    validatedTarifsByDate.forEach((validatedTarif) => {
      // Promotions.articles mapping : https://docs.google.com/spreadsheets/d/1mK_bFFuQdSM2efSVPe44SqYdZyl7WCIlhDOnmi2AYlo/edit#gid=0&range=F178
      const promotion = promotions.find(
        (promo) => String(promo.numero) === String(validatedTarif.TF_No)
      );
      if (promotion && promotion.articles.length > 0) {
        validatedTarif.TS_Refs = [
          ...validatedTarif.TS_Refs,
          ...promotion.articles.map((item) => item.reference),
        ];
      }
      if (promotion && promotion.familles.length > 0) {
        validatedTarif.TS_Refs = [
          ...validatedTarif.TS_Refs,
          ...promotion.familles.map((item) => item.code),
        ];
      }
      if (promotion && promotion.categories.length > 0) {
        validatedTarif.TS_Refs = [
          ...validatedTarif.TS_Refs,
          ...promotion.categories.map((item) => item.numeroInterne),
        ];
      }
      if (promotion && promotion.elevages.length > 0) {
        validatedTarif.TS_Refs = [
          ...validatedTarif.TS_Refs,
          ...promotion.elevages.map((item) => item.numero),
        ];
      }
    });

    //SAGE-C#-code     //Cumulation of quantities or montant according to tariffs
    //SAGE-C#-code     Dictionary<int, double> dicTariffs = new Dictionary<int, double>();
    const dicTariffs: { [key: number]: number } = {};

    //SAGE-C#-code     foreach (IBODocumentVenteLigne3 docLine in docHeader.FactoryDocumentLigne.List)
    //SAGE-C#-code     {
    docHeader_FactoryDocumentLigne_List.forEach((docLine) => {
      //SAGE-C#-code         if (!string.IsNullOrEmpty(docLine.Article?.AR_Ref))
      //SAGE-C#-code         {
      if (docLine.article.reference) {
        //SAGE-C#-code             var tarif = validatedTarifsByDate.Where(x => x.TS_Refs.Contains(docLine.Article?.AR_Ref)).FirstOrDefault();
        const tarif = validatedTarifsByDate.find((x) =>
          x.TS_Refs.includes(docLine.article.reference)
        );
        //SAGE-C#-code             if (tarif != null)
        //SAGE-C#-code             {
        if (tarif) {
          //SAGE-C#-code                 if (tarif.TF_Objectif == 0)
          // no need for the test, already filtered : https://docs.google.com/spreadsheets/d/1mK_bFFuQdSM2efSVPe44SqYdZyl7WCIlhDOnmi2AYlo/edit#gid=0&range=E171
          //SAGE-C#-code                     if (dicTariffs.ContainsKey(tarif.TF_No))
          //SAGE-C#-code                         dicTariffs[tarif.TF_No] += docLine.DL_Qte;
          //SAGE-C#-code                     else
          //SAGE-C#-code                         dicTariffs.Add(tarif.TF_No, docLine.DL_Qte);

          if (dicTariffs[tarif.TF_No] !== undefined) {
            dicTariffs[tarif.TF_No] += docLine.quantite;
          } else {
            dicTariffs[tarif.TF_No] = docLine.quantite;
          }

          //SAGE-C#-code                 else
          //SAGE-C#-code                     if (dicTariffs.ContainsKey(tarif.TF_No))
          //SAGE-C#-code                         dicTariffs[tarif.TF_No] += docLine.DL_Qte * docLine.DL_PrixUnitaire;
          //SAGE-C#-code                     else
          //SAGE-C#-code                         dicTariffs.Add(tarif.TF_No, docLine.DL_Qte * docLine.DL_PrixUnitaire);
          //SAGE-C#-code             }
          // no need for this, already filtered : https://docs.google.com/spreadsheets/d/1mK_bFFuQdSM2efSVPe44SqYdZyl7WCIlhDOnmi2AYlo/edit#gid=0&range=E171
          //SAGE-C#-code         }
        }
        //SAGE-C#-code         progressBar.Value++;
        //SAGE-C#-code     }
      }
    });

    //SAGE-C#-code     progressBar.Value = 0;

    //SAGE-C#-code     foreach (IBODocumentVenteLigne3 docLine in docHeader.FactoryDocumentLigne.List)
    //SAGE-C#-code     {
    docHeader_FactoryDocumentLigne_List.forEach((docLine) => {
      //SAGE-C#-code         try
      //SAGE-C#-code         {
      try {
        //SAGE-C#-code             if (string.IsNullOrEmpty(docLine.Article?.AR_Ref)) //Empty product = comment line
        //SAGE-C#-code                 continue;
        if (docLine.article.reference) {
          //load fullArticle
          const fullArticle = articles.find(
            (a) => a.reference === docLine.article.reference
          );

          //SAGE-C#-code             if(catTarif == "ADHERENT")
          //SAGE-C#-code                 nbUGV += (double)docLine.Article.InfoLibre["UGV"] * docLine.DL_Qte;
          if (catTarif === PriceService.TARIF_ADHERENT) {
            nbUGV += fullArticle.nombreUGV * docLine.quantite;
          }

          //SAGE-C#-code             var dL_MontantHT = docLine.DL_MontantHT;
          const dL_MontantHT = docLine?.montantHT ?? 0;

          //SAGE-C#-code             var cont = (double)docLine.Article?.InfoLibre["Contribution Logistique (%)"];
          const cont = fullArticle.contributionLogistiquePourcentage;
          //SAGE-C#-code             contLog += dL_MontantHT * (cont / 100);
          contLog += dL_MontantHT * (cont / 100);

          //SAGE-C#-code             if (docLine.Article?.AR_Ref == "ADV0107")
          //SAGE-C#-code                 docLineContLog = docLine;
          if (docLine.article.reference === 'ADV0107') {
            docLineContLog = docLine;
          }

          //SAGE-C#-code             //Contract type of the product
          //SAGE-C#-code             string contractType = docLine.Article?.InfoLibre["Type de contrat"];
          const contractType = fullArticle.typeContrat;

          //SAGE-C#-code             string contract = string.Empty;
          let contract = '';
          //SAGE-C#-code             if (!string.IsNullOrEmpty(contractType) && DocumentDao.ExistInfoLibre(_objetsMetierConnexion, contractType))  //Contract in the costumer according to the type of contract
          //        public static bool ExistInfoLibre(ObjetsMetierConnexion omConnexion, string infoLibreName)
          //   {
          //     for (int i = 1; i <= omConnexion?.BaseCial.CptaApplication.FactoryTiers.InfoLibreFields.Count; i++)
          //         if (omConnexion?.BaseCial.CptaApplication.FactoryTiers.InfoLibreFields[i].Name == infoLibreName)
          //             return true;
          //     return false;
          // }
          //SAGE-C#-code                 contract = docHeader.Client.InfoLibre[contractType];
          const elevageContractTypeProperty =
            this.getElevageContractTypeProperty(contractType);
          //todo @jbr : implémenter ExistInfoLibre
          if (elevageContractTypeProperty) {
            contract = elevage[elevageContractTypeProperty];
          }

          //SAGE-C#-code             DocumentLine documentLine = new DocumentLine();
          const documentLine: DocumentLignePriceEngine = {
            ...docLine,
            price: 0,
            discount: 0,
            remise: 0,
          };

          //SAGE-C#-code             if (!string.IsNullOrEmpty(contract)) //Product with contract
          //SAGE-C#-code                 documentLine = await DocumentDao.GetPriceAndDiscountWithContract(_sqlConnexion, docLine, contract);
          //public static async Task<DocumentLine> GetPriceAndDiscountWithContract(SqlConnexion sqlConnexion, IBODocumentVenteLigne3 ligne, string contract)
          // {
          //     DocumentLine documentLine = new DocumentLine();
          //     using (SqlCommand cmd = new SqlCommand())
          //     {
          //         cmd.Connection = sqlConnexion.SqlConnection;
          //         cmd.CommandText = @"SELECT AC.AC_PrixVen, AC.AC_Remise
          //                             FROM P_CATTARIF CT
          //                             LEFT OUTER JOIN F_ARTCLIENT AC ON CT.cbMarq = AC.AC_Categorie
          //                             WHERE CT.CT_Intitule = @CT_Intitule AND AC.AR_Ref = @AR_Ref AND AC.AC_QteMont = 0";
          //         cmd.Parameters.AddWithValue("@CT_Intitule", contract);
          //         cmd.Parameters.AddWithValue("@AR_Ref", ligne.Article.AR_Ref);
          //         await cmd.ExecuteNonQueryAsync();

          //         using (var reader = await cmd.ExecuteReaderAsync())
          //         {
          //             if (reader.Read())
          //             {
          //                 documentLine.Price = reader["AC_PrixVen"] == DBNull.Value ? 0 : (Decimal)reader["AC_PrixVen"];
          //                 documentLine.SetDiscount1(reader["AC_Remise"] == DBNull.Value ? 0 : (Decimal)reader["AC_Remise"]);
          //             }
          //         }
          //     }
          //     return documentLine;
          // }

          const tarifSpecifiqueWithContract = fullArticle.tarif.find(
            (t) => t.categorieTarifaire.intitule === contract
          );
          if (tarifSpecifiqueWithContract) {
            documentLine.price = tarifSpecifiqueWithContract.prixVente;
            documentLine.discount = tarifSpecifiqueWithContract.remiseGenerale;
            documentLine.remise = documentLine.discount;
          }
          //SAGE-C#-code             else //Product without contract
          //SAGE-C#-code             {
          else {
            //SAGE-C#-code                 var pricingType = await DocumentDao.GetPricingType(_sqlConnexion, docHeader, docLine);
            // public static async Task<int> GetPricingType(SqlConnexion sqlConnexion, IBODocumentVente3 header, IBODocumentVenteLigne3 line)
            // {
            //     int AC_Categorie = await GetCatTarifcbMarq(sqlConnexion, header);
            // public static async Task<int> GetCatTarifcbMarq(SqlConnexion sqlConnexion, IBODocumentVente3 header)
            // {
            //     int cbMarq = 0;
            //     using (SqlCommand cmd = new SqlCommand())
            //     {
            //         cmd.Connection = sqlConnexion.SqlConnection;
            //         cmd.CommandText = @"SELECT cbMarq FROM P_CATTARIF WHERE CT_Intitule = @CT_Intitule";
            //         cmd.Parameters.AddWithValue("@CT_Intitule", header.CategorieTarif.CT_Intitule);
            //         await cmd.ExecuteNonQueryAsync();

            //         using (var reader = await cmd.ExecuteReaderAsync())
            //         {
            //             if (reader.Read())
            //             {
            //                 cbMarq = (int)reader["cbMarq"];
            //             }
            //         }
            //     }
            //     return cbMarq;
            // }
            const AC_Categorie = catTarifId; //MTU : no cbMarq in SAGE mapping, but equal to AC_Categorie
            //     int pricingType = 0;
            //     using (SqlCommand cmd = new SqlCommand())
            //     {
            //         cmd.Connection = sqlConnexion.SqlConnection;
            //         cmd.CommandText = @"SELECT AC_QteMont FROM F_ARTCLIENT WHERE AC_Categorie = @AC_Categorie AND AR_Ref = @AR_Ref";
            //         cmd.Parameters.AddWithValue("@AC_Categorie", AC_Categorie);
            //         cmd.Parameters.AddWithValue("@AR_Ref", line.Article.AR_Ref);
            //         await cmd.ExecuteNonQueryAsync();

            //         using (var reader = await cmd.ExecuteReaderAsync())
            //         {
            //             if (reader.Read())
            //             {
            //                 pricingType = Convert.ToInt32(reader["AC_QteMont"]);
            //             }
            //         }
            //     }
            //     return pricingType;
            // }
            const pricingTypeTarif = fullArticle.tarif.find(
              (t) => String(t.categorieTarifaire._id) === String(catTarifId)
            );
            let pricingType = pricingTypeTarif?.quantiteOuMontant;
            if (!pricingType) {
              pricingType = 0;
            }

            //SAGE-C#-code                 switch (pricingType)
            //SAGE-C#-code                 {
            switch (pricingType) {
              case 0:
                //SAGE-C#-code                     case 0:
                //SAGE-C#-code                         documentLine = await DocumentDao.GetSimplePriceAndDiscountWithoutContract(_sqlConnexion, docHeader, docLine);
                // public static async Task<DocumentLine> GetSimplePriceAndDiscountWithoutContract(SqlConnexion sqlConnexion, IBODocumentVente3 header, IBODocumentVenteLigne3 ligne)
                // {
                //     int AC_Categorie = await GetCatTarifcbMarq(sqlConnexion, header);
                //     DocumentLine documentLine = new DocumentLine();
                //     using (SqlCommand cmd = new SqlCommand())
                //     {
                //         cmd.Connection = sqlConnexion.SqlConnection;
                //         cmd.CommandText = @"SELECT AC_PrixVen, AC_Remise
                //                             FROM F_ARTCLIENT
                //                             WHERE AC_Categorie = @AC_Categorie AND AR_Ref = @AR_Ref";
                //         cmd.Parameters.AddWithValue("@AC_Categorie", AC_Categorie);
                //         cmd.Parameters.AddWithValue("@AR_Ref", ligne.Article.AR_Ref);
                //         await cmd.ExecuteNonQueryAsync();

                const tarifSpecifiqueWithoutContract = fullArticle.tarif.find(
                  (t) => String(t.categorieTarifaire._id) === String(catTarifId)
                );
                //         using (var reader = await cmd.ExecuteReaderAsync())
                //         {
                //             if (reader.Read())
                //             {
                //                 documentLine.Price = reader["AC_PrixVen"] == DBNull.Value ? 0 : (Decimal)reader["AC_PrixVen"];
                //                 documentLine.SetDiscount1(reader["AC_Remise"] == DBNull.Value ? 0 : (Decimal)reader["AC_Remise"]);
                //             }
                //         }
                //     }
                //     return documentLine;
                // }

                documentLine.price =
                  tarifSpecifiqueWithoutContract?.prixVente ?? 0;
                documentLine.discount =
                  tarifSpecifiqueWithoutContract?.remiseGenerale ?? 0;
                documentLine.remise = documentLine?.discount ?? 0;
                break;
              //SAGE-C#-code                         break;
              //SAGE-C#-code                     case 1:
              //SAGE-C#-code                         documentLine = await DocumentDao.GetDegressiveDiscountWithoutContract(_sqlConnexion, docHeader, docLine);
              // public static async Task<DocumentLine> GetDegressiveDiscountWithoutContract(SqlConnexion sqlConnexion, IBODocumentVente3 header, IBODocumentVenteLigne3 ligne)
              // {
              //     int cbMarqCatTarif = await GetCatTarifcbMarq(sqlConnexion, header);
              // public static async Task<int> GetCatTarifcbMarq(SqlConnexion sqlConnexion, IBODocumentVente3 header)
              // {
              //     int cbMarq = 0;
              //     using (SqlCommand cmd = new SqlCommand())
              //     {
              //         cmd.Connection = sqlConnexion.SqlConnection;
              //         cmd.CommandText = @"SELECT cbMarq FROM P_CATTARIF WHERE CT_Intitule = @CT_Intitule";
              //         cmd.Parameters.AddWithValue("@CT_Intitule", header.CategorieTarif.CT_Intitule);
              //         await cmd.ExecuteNonQueryAsync();

              //         using (var reader = await cmd.ExecuteReaderAsync())
              //         {
              //             if (reader.Read())
              //             {
              //                 cbMarq = (int)reader["cbMarq"];
              //             }
              //         }
              //     }
              //     return cbMarq;
              // }
              case 1:
                //     string TQ_RefCF = CategorieTarifaireService.CbMarqToTQ_RefCF(cbMarqCatTarif);
                // public static string CbMarqToTQ_RefCF(int cbMarq)
                // {
                //     string TQ_RefCF = cbMarq.ToString();

                //     if (TQ_RefCF.Length == 1)
                //         TQ_RefCF = "0" + TQ_RefCF;

                //     return string.Format("a{0}", TQ_RefCF);
                // }

                // Useless variable cbMarqCatTarif, as AC_Categorie is replacing cbMarq in Xpertmobile. We use AC_Categorie directly.
                TQ_RefCF = String(AC_Categorie);
                if (TQ_RefCF.length === 1) {
                  TQ_RefCF = '0' + TQ_RefCF;
                }
                TQ_RefCF = 'a' + TQ_RefCF;
                //     DocumentLine documentLine = new DocumentLine();
                //     using (SqlCommand cmd = new SqlCommand())
                //     {
                //         cmd.Connection = sqlConnexion.SqlConnection;
                //         cmd.CommandText = @"SELECT TOP 1
                //                               CASE
                //   WHEN AC.AC_PrixVen = 0 THEN AR.AR_PrixVen
                //   WHEN AC.AC_PrixVen IS NULL THEN AR.AR_PrixVen
                // ELSE AC.AC_PrixVen END AS PrixVen,
                //                               TQ.TQ_Remise01REM_Valeur
                //                             FROM F_TARIFQTE TQ
                //                             LEFT OUTER JOIN F_ARTCLIENT AC ON TQ.AR_Ref = AC.AR_Ref
                //                             LEFT OUTER JOIN F_ARTICLE AR ON TQ.AR_Ref = AR.AR_Ref
                //                             WHERE
                //                                 TQ.AR_Ref = @AR_Ref
                //                                 AND @Quantity <= TQ.TQ_BorneSup //OK
                //                                 AND TQ.TQ_RefCF = @TQ_RefCF
                //                                 AND AC.AC_Categorie = @AC_Categorie
                //                             ORDER BY TQ_BorneSup;";
                //         cmd.Parameters.AddWithValue("@AR_Ref", ligne.Article.AR_Ref);
                //         cmd.Parameters.AddWithValue("@Quantity", ligne.DL_Qte);
                //         cmd.Parameters.AddWithValue("@AC_Categorie", cbMarqCatTarif);
                //         cmd.Parameters.AddWithValue("@TQ_RefCF", TQ_RefCF); //AC_Categorie (ex : 1) to TQ_RefCF (ex : a01)
                //         await cmd.ExecuteNonQueryAsync();
                const tarifDegressiveDiscountWithoutContract =
                  fullArticle.tarifDegressif
                    .filter(
                      (t) =>
                        String(t.categorieTarifaire._id) === String(TQ_RefCF) &&
                        docLine.quantite <= t.borneSuperieure
                    )
                    .sort((a, b) =>
                      a.borneSuperieure < b.borneSuperieure ? -1 : 1
                    )[0];

                const tarifWithoutContract = fullArticle.tarif.find(
                  (t) => String(t.categorieTarifaire._id) === String(catTarifId)
                );
                //         using (var reader = await cmd.ExecuteReaderAsync())
                //         {
                //             if (reader.Read())
                //             {
                //                 documentLine.Price = reader["PrixVen"] == DBNull.Value ? 0 : (Decimal)reader["PrixVen"];
                //                 documentLine.SetDiscount1(reader["TQ_Remise01REM_Valeur"] == DBNull.Value ? 0 : (Decimal)reader["TQ_Remise01REM_Valeur"]);
                //             }
                //         }
                //     }
                //     return documentLine;
                // }
                //SAGE-C#-code                         break;

                // Tarif dégressif
                // AC_PrixVen END AS PrixVen -> tarif.PrixVente
                if (
                  tarifWithoutContract &&
                  tarifWithoutContract.prixVente &&
                  tarifWithoutContract.prixVente !== 0
                ) {
                  documentLine.price = tarifWithoutContract.prixVente;
                } else {
                  // Prix de vente sur article
                  // WHEN AC.AC_PrixVen = 0 THEN AR.AR_PrixVen
                  // WHEN AC.AC_PrixVen IS NULL THEN AR.AR_PrixVen
                  documentLine.price = fullArticle.prixVente ?? 0;
                }

                // Remise tarif dégressif
                // TQ.TQ_Remise01REM_Valeur
                if (
                  tarifDegressiveDiscountWithoutContract &&
                  tarifDegressiveDiscountWithoutContract.remise &&
                  tarifDegressiveDiscountWithoutContract.remise !== 0
                ) {
                  documentLine.discount =
                    tarifDegressiveDiscountWithoutContract.remise;
                  documentLine.typeRemise =
                    tarifDegressiveDiscountWithoutContract.type;
                } else {
                  // DBNull.Value ? 0
                  documentLine.discount = 0;
                }
                break;

              //SAGE-C#-code                     case 3:
              case 3:
                //SAGE-C#-code                         documentLine = await DocumentDao.GetDegressivePriceWithoutContract(_sqlConnexion, docHeader, docLine);
                // public static async Task<DocumentLine> GetDegressivePriceWithoutContract(SqlConnexion sqlConnexion, IBODocumentVente3 header, IBODocumentVenteLigne3 ligne)
                // {
                //     int cbMarqCatTarif = await GetCatTarifcbMarq(sqlConnexion, header);
                //     string TQ_RefCF = CategorieTarifaireService.CbMarqToTQ_RefCF(cbMarqCatTarif);

                // Useless variable cbMarqCatTarif, as AC_Categorie is replacing cbMarq in Xpertmobile. We use AC_Categorie directly.
                TQ_RefCF = String(AC_Categorie);
                if (TQ_RefCF.length === 1) {
                  TQ_RefCF = '0' + TQ_RefCF;
                }
                TQ_RefCF = 'a' + TQ_RefCF;

                //     DocumentLine documentLine = new DocumentLine();
                //     using (SqlCommand cmd = new SqlCommand())
                //     {
                //         cmd.Connection = sqlConnexion.SqlConnection;
                //         cmd.CommandText = @"SELECT TOP 1 TQ_PrixNet
                //                             FROM F_TARIFQTE
                //                             WHERE AR_Ref = @AR_Ref AND @Quantity <= TQ_BorneSup AND TQ_RefCF = @TQ_RefCF
                //                             ORDER BY TQ_BorneSup;";
                //         cmd.Parameters.AddWithValue("@AR_Ref", ligne.Article.AR_Ref);
                //         cmd.Parameters.AddWithValue("@Quantity", ligne.DL_Qte);
                //         cmd.Parameters.AddWithValue("@TQ_RefCF", TQ_RefCF); //AC_Categorie (ex : 1) to TQ_RefCF (ex : a01)
                //         await cmd.ExecuteNonQueryAsync();
                const tarifDegressivePriceWithoutContract =
                  fullArticle.tarifDegressif
                    .filter(
                      (t) =>
                        String(t.categorieTarifaire._id) === String(TQ_RefCF) &&
                        docLine.quantite <= t.borneSuperieure
                    )
                    .sort((a, b) =>
                      a.borneSuperieure < b.borneSuperieure ? -1 : 1
                    )[0];
                //         using (var reader = await cmd.ExecuteReaderAsync())
                //         {
                //             if (reader.Read())
                //             {
                //                 documentLine.Price = reader["TQ_PrixNet"] == DBNull.Value ? 0 : (Decimal)reader["TQ_PrixNet"];
                //                 documentLine.SetDiscount1(0);
                //             }
                //         }
                //     }
                //     return documentLine;
                // }
                if (tarifDegressivePriceWithoutContract) {
                  documentLine.price =
                    tarifDegressivePriceWithoutContract.prixNet;
                  documentLine.discount = 0;
                  documentLine.remise = documentLine.discount;
                }
                //SAGE-C#-code                         break;
                //SAGE-C#-code                 }
                break;
            }
            //SAGE-C#-code             }
          }

          //SAGE-C#-code             var tarif = validatedTarifsByDate.Where(x => x.TS_Refs.Contains(docLine.Article?.AR_Ref)).FirstOrDefault();
          //mapping-SAGE https://docs.google.com/spreadsheets/d/1mK_bFFuQdSM2efSVPe44SqYdZyl7WCIlhDOnmi2AYlo/edit#gid=0&range=F178
          const tarif = validatedTarifsByDate.find((x) =>
            x.TS_Refs.includes(docLine.article.reference)
          );

          //SAGE-C#-code             if (tarif != null)
          //SAGE-C#-code                 if (dicTariffs.ContainsKey(tarif.TF_No))
          if (tarif)
            if (dicTariffs[tarif.TF_No] !== undefined) {
              //todo @MTU fix after TS_Refs in mapping
              //SAGE-C#-code                     documentLine = await DocumentDao.GetPanacheDiscount(_sqlConnexion, documentLine, tarif, dicTariffs[tarif.TF_No]);
              // public static async Task<DocumentLine> GetPanacheDiscount(SqlConnexion sqlConnexion, DocumentLine documentLine, Tarif tarif, double quantityOrAmount)
              const quantityOrAmount = dicTariffs[tarif.TF_No];
              // {
              //     using (SqlCommand cmd = new SqlCommand())
              //     {
              //         cmd.Connection = sqlConnexion.SqlConnection;
              //         cmd.CommandText = @"SELECT TOP 1 TR_Remise01REM_Valeur
              //                             FROM F_TARIFREMISE
              //                             WHERE TF_No = @TF_No AND @QuantityOrAmount <= TR_BorneSup
              //                             ORDER BY TR_BorneSup;";
              //         cmd.Parameters.AddWithValue("@TF_No", tarif.TF_No);
              //         cmd.Parameters.AddWithValue("@QuantityOrAmount", quantityOrAmount);
              //         await cmd.ExecuteNonQueryAsync();

              //         using (var reader = await cmd.ExecuteReaderAsync())
              //         {
              //             if (reader.Read())
              //             {
              //                 documentLine.SetDiscount2(
              //                     reader["TR_Remise01REM_Valeur"] == DBNull.Value ? 0 : (Decimal)reader["TR_Remise01REM_Valeur"],
              //                     tarif.TF_Intitule
              //                 );
              //             }
              //         }
              //     }
              //     return documentLine;
              // }
              const bonnePromo = promotions.find(
                (t) => String(t.numero) === String(tarif.TF_No)
              );
              const tarifPanacheDiscount = bonnePromo.paliers.find(
                (p) => quantityOrAmount <= p.borneSuperieure
              );

              documentLine.discount = tarifPanacheDiscount?.remise ?? 0;
              documentLine.remise2 = tarifPanacheDiscount?.remise ?? 0;
              documentLine.nomRemise2 = bonnePromo.intitule;
              documentLine.typeRemise = DiscountType.Pourcentage; // TR_Remise01REM_Type is not in the mapping. We assume it's a percentage as Type de la remise (1=Pourcentage) in the mapping sage seems to imply that.
            }

          //SAGE-C#-code             DocumentLineDao.Save(docLine, documentLine);
          // public static void Save(IBODocumentVenteLigne3 sageDocumentLine, DocumentLine documentLine)
          // {
          //     sageDocumentLine.DL_PrixUnitaire = (double)documentLine.Price;
          //     sageDocumentLine.Remise.FromString(documentLine.GetDiscount().ToString()); //#not-in-mapping-SAGE
          //     sageDocumentLine.InfoLibre["Remise 1"] = (double)documentLine.GetRemise1(); //#not-in-mapping-SAGE
          //     sageDocumentLine.InfoLibre["Remise 2"] = (double)documentLine.GetRemise2(); //#not-in-mapping-SAGE
          //     sageDocumentLine.InfoLibre["Nom remise 2"] = documentLine.GetNomRemise2(); //#not-in-mapping-SAGE
          //     sageDocumentLine.SetDefault();
          //     sageDocumentLine.Write();
          // }

          // Set up the tarifs back in docLine (update by reference directly in document.ligne, as the variable was not cloned)
          docLine.prixUnitaire = documentLine.price;
          docLine.montantRemise = documentLine.discount;
          docLine.remise1 = documentLine.remise;
          docLine.remise2 = documentLine.remise2;
          docLine.nomRemise2 = documentLine.nomRemise2;
          docLine.typeRemise = documentLine.typeRemise;

          //SAGE-C#-code         }
          //SAGE-C#-code         catch (Exception ex)
          //SAGE-C#-code         {
          //SAGE-C#-code             MessageBox.Show(ex.Message, "Erreur", MessageBoxButtons.OK, MessageBoxIcon.Error);
          //SAGE-C#-code         }
          //SAGE-C#-code         finally
          //SAGE-C#-code         {
          //SAGE-C#-code             progressBar.Value++;
          //SAGE-C#-code         }
        }
      } catch (error) {
        throw new Error(`PriceEngine ERROR: ${error}`);
      }

      //SAGE-C#-code     }
    });

    //SAGE-C#-code     if (nbUGV > 0)
    //SAGE-C#-code     {
    //SAGE-C#-code         try
    //SAGE-C#-code         {

    // XAS-73 : Check if the document is only composed of MATIERE PREMIERE articles, if it's the case, no UGV.
    const articlesReferences = document.ligne.map((l) => l.article.reference);
    const articlesInDocument = articles.filter(
      (a) =>
        articlesReferences.includes(a.reference) &&
        a.reference !== 'TRANSPORT_UGV'
    );
    const isOnlyMatierePremiere = articlesInDocument.every(
      (l) => l.sousFamille === 'MATIERE PREMIERE'
    );
    if (isOnlyMatierePremiere) {
      nbUGV = 0;
    }

    // XAS-73 : UGV should not be displayed if all articles of the document are sousFamille 'MATIERE PREMIERE' and no UGV if is < to 2
    if (nbUGV >= 2) {
      // XAS-73 : new calculation of UGV
      // Si TOTAL UGV < 2 : 0€ de remise
      // Si 2 ≤ TOTAL UGV < 3 : 30€ de remise
      // Si 3 ≤ TOTAL UGV < 4 : 60€ de remise
      // Si 4 ≤ TOTAL UGV < 5 : 90€ de remise
      // Si TOTAL UGV >= 5 : 120€ de remise
      const ugvBasePrice = 30;
      let ugvQuantity = 0;

      const ugvPalier = [
        {
          min: 2,
          max: 3,
          quantity: 1, // 30 €
        },
        {
          min: 3,
          max: 4,
          quantity: 2, // 60 €
        },
        {
          min: 4,
          max: 5,
          quantity: 3, // 90 €
        },
        {
          min: 5,
          max: Number.MAX_VALUE, // Will act like TOTAL UGV >= 5
          quantity: 4, // 120 €
        },
      ];

      // Find the quantity in ugvPalier based on nbUGV
      ugvPalier.forEach((p) => {
        if (p.min <= nbUGV && nbUGV < p.max) {
          ugvQuantity = p.quantity;
        }
      });

      try {
        if (ugvQuantity === 0) {
          // No quantity, remove UGV line if it exists
          const ugvLineToRemove = document.ligne.find(
            (item) => item.article.reference === 'TRANSPORT_UGV'
          );
          if (ugvLineToRemove) {
            document.ligne = document.ligne.filter(
              (item) => item.article.reference !== 'TRANSPORT_UGV'
            );
          }
        } else {
          //SAGE-C#-code             DocumentLine ugvLine = await DocumentDao.GetUGVLine(_sqlConnexion, nbUGV);
          // public static async Task<DocumentLine> GetUGVLine(SqlConnexion sqlConnexion, double nbUGV)
          // {
          //     DocumentLine documentLine = new DocumentLine();
          //     using (SqlCommand cmd = new SqlCommand())
          //     {
          //         cmd.Connection = sqlConnexion.SqlConnection;
          //         cmd.CommandText = @"SELECT TOP 1 Remise_Montant
          //                             FROM _Remise_Transport_UGV
          //                             WHERE @Nb_UGV >= Nb_UGV
          //                             ORDER BY Nb_UGV DESC";
          //         cmd.Parameters.AddWithValue("@Nb_UGV", nbUGV);
          //         await cmd.ExecuteNonQueryAsync();

          // XAS-42 : ugvQuantity is now calculated with hardcoded prices
          /*const remiseTransportUGV = refRemiseTransportUGV
          .filter((u) => nbUGV >= u.palierUGV)
          .sort((a, b) => (a.palierUGV > b.palierUGV ? -1 : 1))[0];*/

          //         using (var reader = await cmd.ExecuteReaderAsync())
          //         {
          //             if (reader.Read())
          //             {
          //                 documentLine.AR_Ref = "TRANSPORT_UGV";
          //                 documentLine.Quantity = 1;
          //                 documentLine.Price = reader["Remise_Montant"] == DBNull.Value ? 0 : (Decimal)reader["Remise_Montant"] * -1;
          //             }
          const ugvArticle = articles.find(
            (item) => item.reference === 'TRANSPORT_UGV'
          );
          const ugvDocumentLine: DocumentLigne = {
            numXpertMobile: document.numXpertMobile,
            domaine: document.domaine,
            numeroPiece: document.numeroPiece,
            type: document.type,
            article: { reference: ugvArticle.reference },
            libelle: ugvArticle.intitule,
            datePieceDevisOrigine: docHeader.datePieceDevisOrigine,
            quantite: ugvQuantity,
            prixUnitaire: ugvBasePrice * -1,
            montantRemise: 0,
          };

          //         }
          //     }
          //     return documentLine;
          // }
          const ugvLine = ugvDocumentLine;

          //SAGE-C#-code             if (ugvLine.AR_Ref != null)
          //SAGE-C#-code                 DocumentLineDao.AddLine(docHeader, ugvLine);
          // public static void AddLine(IBODocumentVente3 sageDocumentHeader, DocumentLine documentLine)
          // {
          //     IBODocumentVenteLigne3 sageDocumentLine = (IBODocumentVenteLigne3)sageDocumentHeader.FactoryDocumentLigne.Create();
          //     sageDocumentLine.SetDefaultArticleReference(documentLine.AR_Ref, documentLine.Quantity);
          //     sageDocumentLine.DL_PrixUnitaire = (double)documentLine.Price;
          //     sageDocumentLine.Remise.FromString(documentLine.GetDiscount().ToString());//#not-in-mapping-SAGE
          //     sageDocumentLine.SetDefault();
          //     sageDocumentLine.Write();

          //     sageDocumentLine.InfoLibre["Remise 1"] = documentLine.GetRemise1();//#not-in-mapping-SAGE
          //     //sageDocumentLine.InfoLibre["Remise 2"] = documentLine.GetRemise2();//#not-in-mapping-SAGE
          //     //sageDocumentLine.InfoLibre["Nom remise 2"] = documentLine.GetNomRemise2();//#not-in-mapping-SAGE
          //     sageDocumentLine.Write();

          // }
          if (ugvLine && ugvLine.article.reference) {
            // Add the ugv article to the document if she is not already here
            const ugvLineToUpdate = document.ligne.find(
              (item) => item.article.reference === ugvLine.article.reference
            );
            if (ugvLineToUpdate === undefined) {
              document.ligne.push(ugvLine);
            } else {
              ugvLineToUpdate.quantite = ugvQuantity;
              ugvLineToUpdate.prixUnitaire = ugvBasePrice * -1;
            }
          }
        }

        //SAGE-C#-code         }
        //SAGE-C#-code         catch (Exception ex)
        //SAGE-C#-code         {
        //SAGE-C#-code             MessageBox.Show(ex.Message, "Erreur", MessageBoxButtons.OK, MessageBoxIcon.Error);
        //SAGE-C#-code         }
      } catch (error) {
        throw new Error(`PriceEngine ERROR: ${error}`);
      }
      //SAGE-C#-code     }
    } else {
      // XAS-42, if the UGV line is in the document, remove it
      const ugvLineToRemove = document.ligne.find(
        (item) => item.article.reference === 'TRANSPORT_UGV'
      );
      if (ugvLineToRemove) {
        document.ligne = document.ligne.filter(
          (item) => item.article.reference !== 'TRANSPORT_UGV'
        );
      }
    }

    //SAGE-C#-code     if (contLog > 0)
    //SAGE-C#-code     {
    if (contLog > 0) {
      //SAGE-C#-code         DocumentLine contLogLine = new DocumentLine()
      //SAGE-C#-code         {
      //SAGE-C#-code             AR_Ref = "ADV0107",
      //SAGE-C#-code             Quantity = 1,
      //SAGE-C#-code             Price = (decimal)contLog
      //SAGE-C#-code         };
      //SAGE-C#-code         if (docLineContLog == null)
      //SAGE-C#-code         {
      //SAGE-C#-code             DocumentLineDao.AddLine(docHeader, contLogLine);

      //todo @MTU check that this works either if line exists or not in Document

      //SAGE-C#-code         }
      //SAGE-C#-code         else
      //SAGE-C#-code         {
      //SAGE-C#-code             DocumentLineDao.UpdateLine(docLineContLog, contLogLine);
      //SAGE-C#-code         }
      //SAGE-C#-code     }

      if (docLineContLog == null) {
        const contArticle = articles.find(
          (item) => item.reference === 'ADV0107'
        );

        const contLogLine: DocumentLigne = {
          numXpertMobile: document.numXpertMobile,
          domaine: document.domaine,
          numeroPiece: document.numeroPiece,
          type: document.type,
          datePieceDevisOrigine: docHeader.datePieceDevisOrigine,
          article: { reference: contArticle.reference },
          libelle: contArticle.intitule,
          quantite: 1,
          prixUnitaire: contLog,
          montantRemise: 0,
        };

        document.ligne.push(contLogLine);
      } else {
        // Update by reference, as the variable source should be pointing to document.ligne
        docLineContLog.prixUnitaire = contLog;
        docLineContLog.quantite = 1;
        docLineContLog.montantRemise = 0;
      }
    }

    //SAGE-C#-code     this.Close();

    // MTU initial
    // docHeader_FactoryDocumentLigne_List.forEach(line => {
    //     const newLine = R.clone(line);
    //     //debugger;

    //     console.warn("before : ",newLine.prixUnitaire);
    //     if(newLine.quantite>1)
    //     {
    //       newLine.prixUnitaire=9;
    //       newLine.prixUnitaireRemise=newLine.prixUnitaire;
    //     }
    //     console.warn("after : ",newLine.prixUnitaire);

    //     newLine.prixUnitaireTotal =
    //         +newLine.prixUnitaireRemise * +newLine.quantite;
    //     this.store$.dispatch(
    //         updateItemSuccess({ storeKey, item: newLine })
    //       );
    //});

    return document;
  }

  /**
   * Calculate the price for a the current document in the store
   * @returns boolean
   */
  public async computePricesForDocument(): Promise<boolean> {
    // Get current Document in edition
    let document: ElevageDocument = R.clone(
      await this.store$.select(getCurrentDocument).pipe(take(1)).toPromise()
    );

    if (!document || (document && document?.ligne.length === 0)) {
      return new Promise((resolve) => {
        resolve(true);
      });
    }

    // Get current elevage
    const elevage = R.clone(
      await this.store$.select(getCurrentElevage).pipe(take(1)).toPromise()
    );

    // Calculate the price
    document = await this.calculatePrices(document, elevage);

    const origin = await this.store$
      .select(getCurrentDocumentArticleOrigin)
      .pipe(take(1))
      .toPromise();

    // Send the modified document with new prices to the store
    this.store$.dispatch(
      setCurrentDocument({ document: document, articleOrigin: origin })
    );

    // Return promise to not break await flow.
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  /**
   * Creates a mock document for the given article, elevage, and xpert that can be used for calling price engine for simulating prices.
   * @param article - The article object.
   * @param elevage - The elevage object.
   * @param xpert - The xpert object.
   * @returns The created ElevageDocument.
   */
  public createMockDocument(
    article: Article,
    elevage: Elevage,
    xpert: any
  ): ElevageDocument {
    const prescripteur = {
      _id: xpert.idXpert,
      nom: xpert.nom,
      prenom: xpert.prenom,
    };

    const siteLivraison = elevage.site?.find((site) => site.estPrincipal);
    const numXpertMobile = 'xxxxx';
    const numeroPiece = 'yyyyy';
    const ligneArray: DocumentLigne[] = [];

    const document: ElevageDocument = {
      numXpertMobile: numXpertMobile,
      domaine: DocumentDomaine.Vente,
      type: DocumentType.Devis,
      prescripteur: prescripteur,
      createdOn: new Date().toISOString(),
      date: new Date().toISOString().split('T')[0],
      dateLivraison: null,
      numeroPiece: numeroPiece,
      ligne: ligneArray,
      noteDeReglement: null,
      montant: null,
      totalTTC: null,
      dateCommande: null,
      echeance: null,
      siteLivraison: siteLivraison
        ? {
            _id: siteLivraison.numeroInterne.toString(),
            intitule: siteLivraison.intitule,
          }
        : null,
    };
    const ligne = {
      numXpertMobile: numXpertMobile,
      domaine: DocumentDomaine.Vente,
      uniteVente: article.uniteVente,
      numeroPiece: numeroPiece,
      numeroInterne: undefined,
      type: DocumentType.Devis,
      quantite: 1,
      taxe: article.taxe.taux,
      codeTaxe: article.taxe.code,
      article: { reference: article.reference },
      libelle: article.intitule,
      typeRemise: article.typeRemise
        ? (+article.typeRemise as DiscountType)
        : undefined,
      dateLivraison: undefined,
      datePieceDevisOrigine: new Date().toISOString().split('T')[0],
      montantRemise:
        article.tarif?.find((tarif) => tarif.elevage.numero === elevage.numero)
          ?.remiseGenerale ?? 0,
      prixUnitaire:
        article.tarif?.find((tarif) => tarif.elevage.numero === elevage.numero)
          ?.prixVente ?? 0,
    };

    // Add the article to the list if he was not already present
    document.ligne.push(ligne);

    return document;
  }

  /**
   * Retrieves the current promotion for a given article and elevage.
   * @param article - The article for which to retrieve the promotion.
   * @param elevage - The elevage for which to retrieve the promotion.
   * @returns A Promise that resolves to the current promotion for the article and elevage, or undefined if no promotion is found.
   */
  public async getCurrentPromotion(
    article: Article,
    elevage: Elevage | null
  ): Promise<any | null> {
    const date = new Date().toISOString().split('T')[0];

    // Code of the main price engine function. Replicated to not break the legacy code : we follow the old code logic in price calculation. MTU forbid to modify that part of the code to keep the price engine as close as the original was.
    const promotions = await this.getPromotions();

    const refCategorieTarifaires = await this.getRefCategorieTarifaires();

    const blcMatchTarifs = await this.getBlcMatchTarif();

    const catTarifId = elevage?.categorieTarifParDefaut;
    const catTarif = refCategorieTarifaires.find(
      (item) => String(item.numeroInterne) === String(catTarifId)
    )?.intitule;
    const matchesTarifs = blcMatchTarifs.filter(
      (item) => item.catTarif !== catTarif
    );

    const tarifs = promotions
      .map((promo) => {
        return {
          TF_No: promo.numero,
          TF_Intitule: promo.intitule,
          TF_Debut: this.cleanDate(promo.debut, this.minDate), // Port of C# function SqlReaderToDateTime
          TF_Fin: this.cleanDate(promo.fin, this.maxDate), // Port of C# function SqlReaderToDateTime
          TF_Objectif: promo.objectif,
          AR_Ref: promo.articlePromo.reference,
          TS_Refs: [],
        };
      })
      .filter((tarifObj) => {
        return !matchesTarifs.some(
          (match) => match.tarif === tarifObj.TF_Intitule
        );
      });

    const validatedTarifsByDate = tarifs.filter(
      (t) => t.TF_Debut <= date && date <= t.TF_Fin
    );

    validatedTarifsByDate.forEach((validatedTarif) => {
      const promotion = promotions.find(
        (promo) => String(promo.numero) === String(validatedTarif.TF_No)
      );
      if (promotion && promotion.articles.length > 0) {
        validatedTarif.TS_Refs = [
          ...validatedTarif.TS_Refs,
          ...promotion.articles.map((item) => item.reference),
        ];
      }
    });

    const tarif = validatedTarifsByDate.find((x) =>
      x.TS_Refs.includes(article.reference)
    );

    if (tarif) {
      return promotions.find((p) => p.numero === tarif.TF_No);
    }

    return null;
  }

  /**
   * Retrieves the tarif degressif for the given article and elevage.
   * @param article - The article for which to retrieve the tarif degressif.
   * @param elevage - The elevage associated with the article (optional).
   * @returns An array of tarif degressif objects that match the provided article and elevage, or null if no match is found.
   */
  public async getTarifDegressif(
    article: Article,
    elevage: Elevage | null
  ): Promise<
    | {
        borneSuperieure: number;
        remise: number;
        type: DiscountType;
        prixNet: number;
        categorieTarifaire: {
          _id: string;
          intitule: string;
        };
      }[]
    | null
  > {
    const refCategorieTarifaires = await this.getRefCategorieTarifaires();
    const catTarifAdherent = refCategorieTarifaires.find(
      (item) => item.intitule === PriceService.TARIF_ADHERENT
    );
    const catTarifId =
      elevage?.categorieTarifParDefaut ?? catTarifAdherent.numeroInterne; // ADHERENT as default value if no elevage is provided
    const catTarif = refCategorieTarifaires.find(
      (item) => String(item.numeroInterne) === String(catTarifId)
    )?.intitule;

    const response =
      article.tarifDegressif.filter(
        (item) => item.categorieTarifaire.intitule === catTarif
      ) ?? null;

    if (response && response.length > 0) {
      return response;
    }

    return null;
  }
}
