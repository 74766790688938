import { createReducer, on } from '@ngrx/store';
import {
  CatalogArticleState,
  initialCatalogArticleState,
} from './catalog-article.state';
import {
  resetCatalogArticleState,
  setCanMakeEstimate,
  setCanMakeOrder,
  setCurrentDocument,
} from './catalog-article.actions';
import * as R from 'ramda';

export const catalogArticleReducer = createReducer<CatalogArticleState>(
  initialCatalogArticleState,
  on(setCurrentDocument, (state, { document, articleOrigin }) => ({
    ...state,
    currentDocument: document,
    currentDocumentArticleOrigin: articleOrigin,
  })),
  on(setCanMakeEstimate, (state, { canMakeEstimate }) => ({
    ...state,
    docButtonsState: {
      ...state.docButtonsState,
      canMakeEstimate,
    },
  })),
  on(setCanMakeOrder, (state, { canMakeOrder }) => ({
    ...state,
    docButtonsState: {
      ...state.docButtonsState,
      canMakeOrder,
    },
  })),
  on(resetCatalogArticleState, () => R.clone(initialCatalogArticleState))
);
