import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
  MaterialColor,
} from '@tc/abstract';
import { TcGridCellComponent } from '@tc/core';
import { MaterialButtonType } from '@tc/buttons';
import { ElevageContact } from '../../../interfaces/elevage-contact.interface';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';
import {
  addTcGridButtonClicked,
  editTcGridButtonClicked,
  TcSmartGridComponent,
} from '@tc/advanced-components';

@Component({
  selector: 'app-contacts-grid',
  templateUrl: './contacts-grid.component.html',
  styleUrls: ['./contacts-grid.component.scss'],
})
export class ContactsGridComponent
  extends TcSmartGridComponent
  implements OnInit
{
  storeKey = 'contacts-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  @Input()
  contacts: ElevageContact[];

  constructor(store$: Store<any>) {
    super(store$);
  }

  async ngOnInit() {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'elevage-contact-grid',
      gridOptions: {},
      emptyDataOnDestroy: true,
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.BreezeJs,
        dataSet: 'ElevageContact',
        dynamicCollectionFrom: {
          breezeStructuralType: 'Elevage.contact',
          data: this.contacts,
        },
        fromParentModelProperty: {
          formStoreKey: 'elevage-grid',
          propertyPath: 'contact',
        },
      },
      columns: [
        {
          field: 'nom',
        },
        {
          field: 'prenom',
        },
        {
          field: 'fonction',
        },
        {
          field: 'telportable',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
        {
          field: 'telephone',
          cellClass: 'text-align-right',
          headerClass: 'text-align-right',
        },
        {
          field: 'email',
        },
        {
          field: 'actions',
          sortable: false,
          maxWidth: 120,
          minWidth: 100,
          headerClass: 'text-align-center',
          cellClass: 'text-align-center',
          cellRenderer: TcGridCellComponent.SmartButtonRenderer,
          cellRendererParams: {
            buttons: [
              {
                color: MaterialColor.Warn,
                ionIcon: 'eye-outline',
                tooltip: 'eye-tooltip',
                action: editTcGridButtonClicked,
                type: MaterialButtonType.Icon,
                actionPayload: {
                  detailsPopupComponent: 'ElevageContactPopupComponent',
                  width: '28.75em',
                  height: '34.25em',
                },
                name: 'edit',
                permissionAction: PermissionAction.Update,
                permissionSubject: PermissionSubject.Elevage,
              },
            ],
          },
        },
      ],
      addButton: {
        detailsPopupComponent: 'ElevageContactPopupComponent',
        action: addTcGridButtonClicked,
        ionIcon: 'add-outline',
        permissionAction: PermissionAction.Create,
        permissionSubject: PermissionSubject.Elevage,
        detailsPopupWidth: '28.75em',
        detailsPopupHeight: '34.25em',
      },
      columnNumberPerDevice: {
        extraSmallDevice: 7,
        smallDevice: 7,
        mediumDevice: 7,
        largeDevice: 7,
        extraLargeDevice: 7,
        extraExtraLargeDevice: 7,
      },
    };

    super.ngOnInit();
  }
}
