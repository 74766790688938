import { Injectable } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { Store } from '@ngrx/store';
import { getAuthenticatedUser } from '../../modules/auth/store/auth.selectors';
import { take } from 'rxjs/operators';
import { ConfigKeys } from '../../shared/interfaces/config.interface';
import {
  FilterTypesEnum,
  ITcDataService,
  TcConfigTypes,
  TcDataProviderType,
  TcFilterItem,
} from '@tc/abstract';
import { TcDataService } from '@tc/data-store';
import { Xpert } from '../modules/xpert/interfaces/xpert.interface';
import { DualChoice } from '../typings/dual-choice.enum';
import { Gamme } from '../typings/gamme.enum';
import { VenteHorsZone } from '../typings/vente-hors-zone.enum';
import { ArticleSousFamilleType } from '../typings/article-sous-famille-type.enum';

@Injectable({
  providedIn: 'root',
})
export class XpertService {
  private readonly usersDataService: ITcDataService<any> =
    this.dataService.getService('Collaborateur', {
      configType: TcConfigTypes.TcDataProvider,
      providerType: TcDataProviderType.BreezeJs,
      dataSet: 'Collaborateur',
      fields: 'idXpert,monitoring,oneGenetics',
    });

  constructor(
    private store$: Store<any>,
    private dataService: TcDataService,
    private config: ConfigService
  ) {}

  public async getConnectedXpert() {
    const authenticatedUser = await this.store$
      .select(getAuthenticatedUser)
      .pipe(take(1))
      .toPromise();

    const userMapping: any[] = this.config.get(ConfigKeys.userMapping);
    const loginField = userMapping.find(
      (field) => field.userField === 'login'
    )?.databaseField;

    const {
      data: [xpert],
    } = await this.usersDataService.getData(0, 1, {
      filters: [
        {
          key: loginField,
          value: authenticatedUser.login,
        },
      ],
    });

    return xpert as Xpert;
  }

  /**
   * Get articles collection filters for connected user based on their setup
   * @returns TcFilterItem[]
   */
  public async getConnectedXpertArticlesFilters(): Promise<TcFilterItem[]> {
    const collaborateur = await this.getConnectedXpert();
    const articleFilters: TcFilterItem[] = [];

    // Monitoring
    if (
      collaborateur.monitoring?.toLowerCase() !== DualChoice.Yes.toLowerCase()
    ) {
      articleFilters.push({
        key: 'DAS.offre.gamme.intitule',
        value: Gamme.Monitoring,
        filterType: FilterTypesEnum.NotEqual,
      });
    }

    // Hors zone
    if (
      collaborateur.venteHorsZone?.toLowerCase() !==
      VenteHorsZone.Yes.toLowerCase()
    ) {
      articleFilters.push({
        key: 'sousFamille',
        value: ArticleSousFamilleType.HorsZone,
        filterType: FilterTypesEnum.NotEqual,
      });
    }

    // OneGenetics
    if (
      collaborateur.oneGenetics?.toLowerCase() !== DualChoice.Yes.toLowerCase()
    ) {
      articleFilters.push({ key: 'estOneGenetics', value: 'false' });
    }

    return articleFilters;
  }
}
