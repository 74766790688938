import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TcSmartComponent } from '@tc/abstract';
import {
  TcGridEventButtonParams,
  TcGridSmartButtonParams,
} from '../../interfaces/tc-grid.interface';
import { getNestedValue, setNestedValue } from '@tc/core';
import { navigate, openFilteredDataDetail } from '@tc/advanced-components';
import { Params } from '@angular/router';
import * as R from 'ramda';

/**
 * This is all in one combination of:
 * @TcGridButtonsRendererComponent
 * @TcGridFaButtonsRendererComponent
 * @TcButtonsListComponent
 *
 * Handles the diplay of smart buttons for @TcGrid
 * Simmilar to @TcGridEventButtonsRendererComponent but with store actions
 */
@Component({
  selector: 'tc-grid-smart-buttons-renderer',
  templateUrl: './tc-grid-smart-buttons-renderer.component.html',
  styleUrls: ['./tc-grid-smart-buttons-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TcGridSmartButtonsRendererComponent
  extends TcSmartComponent
  implements AgRendererComponent
{
  /**
   * Params specified in the cellRendererParams of the ag-grid column definition
   */
  public params: ICellRendererParams;

  /**
   * Buttons Configuraions from @params
   */
  public buttons: TcGridSmartButtonParams[];

  /**
   * Store key of the grid
   */
  protected storeKey: string;

  /**
   * Stores the disable states of the buttons
   */
  public disableButtons: boolean[] = [];

  /**
   * Stores the hidden states of the buttons
   */
  public hiddenButtons: boolean[] = [];

  constructor(store$: Store<any>) {
    super(store$);
  }

  agInit(params) {
    this.params = params;
    this.buttons = params?.buttons;
    this.storeKey = params?.storeKey;

    this.checkDisabledButtons();
    this.checkHiddenButtons();
  }

  /**
   * Handles the click event by triggering a store action
   * @param event => { clickEvent (native click event), buttonIndex }
   */
  public onClickHandler(event) {
    const { clickEvent, index } = event;


    const { action } = this.buttons[index];
    let { actionPayload } = this.buttons[index];

    // Check the action is a navigate action in order to calculate dynamic values of action payloads
    // i.e. actionPayload.queryParams
    if (action === navigate) {
      // Clone the actionPayload so that we can modify the callbackActionPayload
      // in order to set the dynamic query params.
      actionPayload = R.clone(actionPayload);

      let { queryParams: payloadQueryParams } = actionPayload;

      // If the queryParams is a function we first call it to get an Array
      if (typeof payloadQueryParams === 'function') {
        payloadQueryParams = payloadQueryParams(index);
      }

      // If the queryParams is an Array than we are dealing with dynamic query params
      if (Array.isArray(payloadQueryParams)) {
        const queryParams: Params = {};

        // Convert the dynamic query params into actual Params value
        payloadQueryParams.forEach((paramObject) => {
          const value = getNestedValue(
            this.params.node.data,
            paramObject.dataPropertyName
          );

          setNestedValue(queryParams, paramObject.paramName, value);
        });

        actionPayload.queryParams = queryParams;
      }

      // Check the action is a navigate action openFilteredDataDetail in order to dynamic values of action payloads
      // i.e. actionPayload.callbackActionPayload.filterValue
      if (actionPayload.callbackAction === openFilteredDataDetail) {
        const { filterProperty } = actionPayload.callbackActionPayload;

        actionPayload.callbackActionPayload.filterValue =
          actionPayload.queryParams[filterProperty];
      }
    }

    this.store$.dispatch(
      action({
        storeKey: this.storeKey,
        rowData: this.params.node.data,
        ...actionPayload,
      })
    );
  }

  /**
   * Determine which buttons should be dissabled
   */
  private checkDisabledButtons() {
    this.buttons.forEach((button) => {
      this.disableButtons.push(this.isDisabled(button));
    });
  }

  /**
   * Helper function for @checkDisabledButtons
   */
  private isDisabled(button: TcGridEventButtonParams) {
    if (!button.disabled) {
      return false;
    }

    const isDisabled = button.disabled(this.params?.node?.data);
    return !!isDisabled;
  }

  /**
   * Determine which buttons should be hidden
   */
  private checkHiddenButtons() {
    this.buttons.forEach((button) => {
      this.hiddenButtons.push(this.isHidden(button));
    });
  }

  /**
   * Helper function for @checkHiddenButtons
   */
  private isHidden(button: TcGridEventButtonParams) {
    if (!button.hidden) {
      return false;
    }

    const isHidden = button.hidden(this.params?.node?.data);
    return !!isHidden;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
