import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { initHome, setHomeButtonsLists } from './home.actions';
import { TcSmartButton } from '@tc/buttons';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { getAbilities } from '@tc/permissions';
import { CustomConfigKeys } from '../../../shared/interfaces/custom-config.interface';
import { ConfigService } from 'apps/frontend/src/app/shared/services/config.service';

@Injectable()
export class HomeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly config: ConfigService
  ) {}

  initHome = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initHome),
        tap(async () => {
          const elevageButtonsList = await this.getElevageButtonsList();
          const stockButtonsList = await this.getStockButtonsList();
          const xpertButtonsList = await this.getXpertButtonsList();

          this.store$.dispatch(
            setHomeButtonsLists({
              elevageButtonsList: elevageButtonsList,
              stockButtonsList: stockButtonsList,
              xpertButtonsList: xpertButtonsList,
            })
          );
        })
      ),
    { dispatch: false }
  );

  private async getElevageButtonsList() {
    const buttonsList: TcSmartButton[] = [
      {
        label: 'breeding-list-nav.title',
        ionIcon: 'list-outline',
        class: 'tc-btn-card-pink',
        action: navigate,
        actionPayload: {
          route: `/${CustomRoutes.ElevageList}`,
        },
      },
    ];

    return buttonsList;
  }

  private async getStockButtonsList() {
    const buttonsList: TcSmartButton[] = [
      // XAS-84 - Hide features
      // {
      //   label: 'stock-consultation-nav.title',
      //   ionIcon: 'file-tray-full-outline',
      //   class: 'tc-btn-card-yellow',
      //   action: navigate,
      //   actionPayload: {
      //     route: `/${CustomRoutes.ConsultationStock}`,
      //   },
      // },
      // XAS-84 - Hide features
      // {
      //   label: 'xpert-deposit-nav.title',
      //   ionIcon: 'body-outline',
      //   class: 'tc-btn-card-yellow',
      //   action: navigate,
      //   actionPayload: {
      //     route: `/${CustomRoutes.XpertDeposit}`,
      //   },
      // },
      // {
      //   label: 'xpert-transfert-nav.title',
      //   ionIcon: 'swap-horizontal-outline',
      //   class: 'tc-btn-card-yellow',
      //   action: navigate,
      //   actionPayload: {
      //     route: `/${CustomRoutes.XpertTransfert}`,
      //   },
      // },
      // {
      //   label: 'restock-request-nav.title',
      //   ionIcon: 'checkmark-done-outline',
      //   class: 'tc-btn-card-yellow btn-disabled',
      // },
    ];

    return buttonsList;
  }

  private async getXpertButtonsList() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    const buttonXpertList: TcSmartButton = {
      label: 'xpert-list-nav.title',
      ionIcon: 'list-outline',
      class: 'tc-btn-card-blue btn-disabled',
    };
    if (abilities.can('list', 'Collaborateur')) {
      buttonXpertList.class = 'tc-btn-card-blue';
      buttonXpertList.action = navigate;
      buttonXpertList.actionPayload = {
        route: `/${CustomRoutes.XpertList}`,
      };
    }

    const buttonsList: TcSmartButton[] = [
      buttonXpertList,
      {
        label: 'xpert-dashboard-nav.title',
        ionIcon: 'apps-outline',
        class: 'tc-btn-card-blue btn-disabled',
      },

      ...(this.config.get(CustomConfigKeys.enableRemiseEnBanque)
        ? [
            {
              label: 'bank-remittance-nav.title',
              ionIcon: 'cash-outline',
              class: 'tc-btn-card-blue',
              action: navigate,
              actionPayload: {
                route: `/${CustomRoutes.BankDeposit}`,
              },
            },
          ]
        : []),
    ];

    return buttonsList;
  }
}
