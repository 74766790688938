import { createAction, props } from '@ngrx/store';
import { Elevage } from '../interfaces/elevage.interface';

export const initElevage = createAction('[Elevage] Init Elevage');

export const setCurrentElevage = createAction(
  '[Elevage] Set Current Elevage',
  props<{ currentElevage: Elevage }>()
);

export const saveElevage = createAction(
  '[Elevage] Save Elevage',
  props<{ elevageNumero: string; storeKey: string }>()
);

export const elevageGridOpenDetail = createAction(
  '[Elevage] Elevage Grid Open Detail',
  props<{ storeKey: string; rowData: any }>()
);

export const setLastFilteredSecteur = createAction(
  '[Elevage] Set Last Filtered Secteur',
  props<{ secteur: string }>()
);
