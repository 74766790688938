import { createAction, props } from '@ngrx/store';
import { TermsOfUse } from '../interfaces/terms-of-use.interface';

/**
 * Action creator function that creates an action to verify the terms and conditions.
 * @returns An action object with the type '[TermsAndConditions] Verify Terms'.
 */
export const verifyTerms = createAction('[TermsAndConditions] Verify Terms');

/**
 * Action creator for accepting the terms and conditions.
 * @param {number} id - The ID of the terms and conditions being accepted.
 * @returns An action object with the type and payload.
 */
export const acceptTerms = createAction(
  '[TermsAndConditions] Accept Terms and Conditions',
  props<{ id: number }>()
);

/**
 * Creates an action to set the terms as accepted.
 * @returns An action object with the type '[TermsAndConditions] Set Terms Accepted'.
 */
export const lastTermsAccepted = createAction(
  '[TermsAndConditions] Set Terms Accepted'
);

/**
 * Action creator function that creates an action to save the terms to validate.
 * @param {TermsOfUse} options - The terms of use to save.
 * @returns An action object with the type and payload.
 */
export const saveTermsToValidate = createAction(
  '[TermsAndConditions] Save Terms to Validate',
  props<{ options: TermsOfUse }>()
);

/**
 * Action creator for opening the Terms of Use page.
 * @returns An action object with the type '[TermsAndConditions] Open Terms of Use Page'.
 */
export const openTermsOfUsePage = createAction(
  '[TermsAndConditions] Open Terms of Use Page'
);
