import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcSmartButton } from '@tc/buttons';
import { TcPage } from '@tc/core';
import { IPageState, terminateOfflineMode } from '@tc/store';
import { ConfigKeys } from '../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../shared/services/config.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { QrCodeScanPopupComponent } from '../../modules/stock/components/smart/qr-code-scan-popup/qr-code-scan-popup.component';

@Component({
  selector: 'app-xpert-transfert-page',
  templateUrl: './xpert-transfert-page.component.html',
  styleUrls: ['./xpert-transfert-page.component.scss'],
})
export class XpertTransfertPageComponent extends TcPage implements OnInit {
  constructor(
    store: Store<any>,
    private readonly config: ConfigService,
    private readonly authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) {
    super(store);
  }

  buttonsList: TcSmartButton[] = [
    {
      label: 'xpert-xpert-stock-receive',
      class: `mat-raised-button btn-secondary`,
      disableStoreKey: 'quantities',
      smartStateKey: 'livraison',
      action: async () => {
        await this.authenticationService.restoreSession();
        this.store.dispatch(
          terminateOfflineMode({
            providerType: this.config.get(
              ConfigKeys.offlineModeDataProviderType
            ),
            relaunch: true,
            popupComponentName: 'SyncResponsePopupComponent',
          })
        );
      },
    },
    {
      label: 'xpert-qr-code-scan',
      class: `mat-raised-button btn-primary`,
      ionIcon: 'qr-code-outline',
      action: async () => {
        this.dialog.open(QrCodeScanPopupComponent, {
          width: '40em',
        });
      },
    },
  ];

  ngOnInit() {
    const pageInfo: IPageState = {
      title: 'xpert-deposit-transfert-page.title',
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;

    super.ngOnInit();
  }
}
