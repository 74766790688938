import { createAction, props } from '@ngrx/store';
import { BaseTcStorePayload, TcSortDef } from '@tc/abstract';
import { FiltersTcDataPayload } from '@tc/data-store';
import {
  CreateItemPayload,
  CreateItemSuccessPayload,
  DeleteItemPayload,
  DeleteItemSuccessPayload,
  InitTcListDataPayload,
  LoadTcDataPayload,
  LoadTcDataPayloadSuccess,
  LoadTcDataTotalSuccessPayload,
  LoadTcMoreDataPayload,
  LoadTcMoreDataPayloadSuccess,
  RefreshTcDataPayload,
  SortTcDataPayload,
  UpdateItemPayload,
  UpdateItemSuccessPayload,
} from './tc-data-payload';

/**
 * the prefix for all the tc data actions
 */
export const TC_DATA_ACTIONS_PREFIX: string = '[tc-data]';

/**
 * the action to init tc list data
 */
export const initTcListDataStore = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Init list data`,
  props<InitTcListDataPayload>()
);

/**
 * the action to update tc list data
 */
export const updateTcListDataStore = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/update list data provider`,
  props<InitTcListDataPayload>()
);

/**
 * the action to trigger load tc data
 */
export const loadTcData = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Load Data`,
  props<LoadTcDataPayload>()
);

/**
 * the action to set filters
 */
export const setTcDataFilters = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Set filters for data`,
  props<FiltersTcDataPayload>()
);

/**
 * the action to set storing
 */
export const setTcDataSort = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Set sort for data`,
  props<SortTcDataPayload>()
);

/**
 * the action to trigger load tc data in store
 * the action will replace data in store
 */
export const loadTcDataSuccess = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Load Data Success`,
  props<LoadTcDataPayloadSuccess>()
);

/**
 * the action triggered when scrolling happens on components
 */
export const loadTcMoreData = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Load More Data`,
  props<LoadTcMoreDataPayload>()
);

/**
 * the action to trigger load tc component more data in store
 * the action will concat new data with the old data in store
 */
export const loadTcMoreDataSuccess = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Load More Data Success`,
  props<LoadTcMoreDataPayloadSuccess>()
);

/**
 *  this action is triggered everytime the filter changes
 */
export const refreshTcData = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Refresh Data`,
  props<RefreshTcDataPayload>()
);

/**
 * this action saves to db and lunches updateItemSuccess
 */
export const updateItem = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Update Item in DB`,
  props<UpdateItemPayload>()
);

/**
 * this action updates an item in data array
 */
export const updateItemSuccess = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Update Item in Store`,
  props<UpdateItemSuccessPayload>()
);

/**
 * this action saves to db and lunches createItemSuccess
 */
export const createItem = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Create Item in DB`,
  props<CreateItemPayload>()
);

/**
 * this action ads an new item in data array
 */
export const createItemSuccess = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Create Item in Store`,
  props<CreateItemSuccessPayload>()
);

/**
 * this action removes from db and lunches deleteItemSuccess
 */
export const deleteItem = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Delete Item in DB`,
  props<DeleteItemPayload>()
);

/**
 * this action marks deletef in db and lunches deleteItemSuccess
 */
export const softDeleteItem = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/SoftDelete Item in DB`,
  props<UpdateItemPayload>()
);

/**
 * this action removes an item in data array
 */
export const deleteItemSuccess = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Delete Item in Store`,
  props<DeleteItemSuccessPayload>()
);

/**
 * the action to trigger empty tc data
 * Specifically used by the grid upon sorting
 */
export const emptyTcData = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Empty Data`,
  props<BaseTcStorePayload>()
);

/**
 * the action to update the tc data total
 * Useful when the inline count call is separated
 * from the get data call
 */
export const loadTcDataTotalSuccess = createAction(
  `${TC_DATA_ACTIONS_PREFIX}/Load Total Success`,
  props<LoadTcDataTotalSuccessPayload>()
);
