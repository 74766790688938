import { createReducer, on } from '@ngrx/store';
import {
  offlineModeActivated,
  offlineModeDeactivated,
  updateSyncPercentage,
} from '../actions/offline-mode.actions';

export interface TcOfflineModeState {
  offlineModeInfo: OfflineModeState;
}

export interface OfflineModeState {
  offlineModeActivated: boolean;
  syncPercentage: number;
}

export const initialOfflineModeState: OfflineModeState = {
  offlineModeActivated: false,
  syncPercentage: 0,
};

export const offlineModeReducer = createReducer<OfflineModeState>(
  initialOfflineModeState,
  on(offlineModeActivated, (state) => ({
    ...state,
    offlineModeActivated: true,
  })),
  on(offlineModeDeactivated, (state) => ({
    ...state,
    offlineModeActivated: false,
  })),
  on(updateSyncPercentage, (state, { percentage }) => ({
    ...state,
    syncPercentage: percentage,
  }))
);
