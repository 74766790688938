import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LayoutState } from './layout.state';

export const getLayoutState = createFeatureSelector<LayoutState>('layout');

export const getLayoutHiddenSidenavRoutes = createSelector(
  getLayoutState,
  (state: LayoutState) => state.hiddenSidenavRoutes
);

export const getVisibleXrOptimaLogoRoutes = createSelector(
  getLayoutState,
  (state: LayoutState) => state.visibleXrOptimaLogoRoutes
);
