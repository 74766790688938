import { createReducer, on } from '@ngrx/store';
import {
  clearMovement,
  swapDepartureWithDestination,
  setMovementArticles,
  setMovementDeparture,
  setMovementDestination,
  setMovementQrCodeData,
  setMovement,
} from './stock.actions';
import { initialStockState, StockState } from './stock.state';

export const stockReducer = createReducer<StockState>(
  initialStockState,
  on(clearMovement, (state) => ({
    ...state,
    movement: {
      departure: null,
      destination: null,
      articles: [],
      qrCodeData: [],
    },
  })),
  on(setMovement, (state, payload) => ({
    ...state,
    movement: payload.data,
  })),
  on(setMovementDeparture, (state, payload) => ({
    ...state,
    movement: {
      ...state.movement,
      departure: payload.data,
    },
  })),
  on(setMovementDestination, (state, payload) => ({
    ...state,
    movement: {
      ...state.movement,
      destination: payload.data,
    },
  })),
  on(setMovementQrCodeData, (state, payload) => ({
    ...state,
    movement: {
      ...state.movement,
      qrCodeData: payload.data,
    },
  })),
  on(swapDepartureWithDestination, (state) => ({
    ...state,
    movement: {
      ...state.movement,
      departure: state.movement.destination,
      destination: state.movement.departure,
    },
  })),
  on(setMovementArticles, (state, payload) => ({
    ...state,
    movement: {
      ...state.movement,
      articles: payload.data,
    },
  }))
);
