export const selectedElevageStoreFields = [
  'numero',
  'nom',
  'adresse',
  'complementAdresse',
  'codePostal',
  'ville',
  'pays',
  'telephone',
  'email',
  'siret',
  'numeroTVAIntracommunautaire',
  'codeAPE',
  'territoire',
  'contratBovinViande',
  'contratBovinLait',
  'contratCaprin',
  'adherent',
  'cuve',
  'contactPrincipal',
  'doubleactif',
  'engagedansautresstructures',
  'coordonneesGPS, nombreAssocies',
  'elevageBovin',
  'elevageCaprin',
  'elevageOvin',
  'elevageAutres',
  'caprinAdherentCapgene',
  'caprinNbChevres',
  'carpinNbChevrettes',
  'caprinNbBoucs',
  'caprinRace1',
  'caprinRace2',
  'caprinRace3',
  'caprinProductionParChevreParAn',
  'caprinAOP',
  'caprinBIO',
  'caprinModederepro',
  'caprinDeclenchementchaleurs',
  'caprinControledeperf',
  'caprinDeboucheslait',
  'caprinDebouchesviandes',
  'bovinNbvaches',
  'bovinProductionParVacheParAn',
  'bovinControleDePerf',
  'bovinRace1',
  'bovinRace2',
  'bovinRace3',
  'bovinNomsDesVendeursDeDose',
  'bovinBIO',
  'bovinIPE',
  'bovinMontenaturelle',
  'bovinSuivireproQui',
  'bovinNomduveterinaire',
  'bovinDeboucheslait',
  'bovinDebouchesviandes',
  'bovinTypederation',
  'portable1',
  'portable2',
  'editionFacturePapier',
  'geographie',
  'reglement',
  'site',
  'contact',
  'syntheseFinanciere',
];
