import { createAction, props } from '@ngrx/store';
import { UpdateGridColumnsPayload } from './payloads/UpdateGridColumnsPayload';
import {
  ArticleMovementLine,
  ArticlesMovement,
  MovementDepot,
  QrCodeMovementData,
} from './stock.state';

export const updateStockGridColumns = createAction(
  '[Stock] Update Grid Columns',
  props<UpdateGridColumnsPayload>()
);

/**
 * Set the movement (will replace the current one)
 */
export const setMovement = createAction(
  '[Stock] Set Movement',
  props<{ data: ArticlesMovement }>()
);

/**
 * Set in the movement the references of the depot / emplacement where the articles come from.
 */
export const setMovementDeparture = createAction(
  '[Stock] Set Movement Departure',
  props<{ data: MovementDepot }>()
);

/**
 * Set in the movement the references of the depot / emplacement where the articles are sent.
 */
export const setMovementDestination = createAction(
  '[Stock] Set Movement Destination',
  props<{ data: MovementDepot }>()
);

/**
 * Swap Departure with Destination depots / emplacements
 */
export const swapDepartureWithDestination = createAction(
  '[Stock] Swap Movement Departure With Destination'
);

/**
 * Add or update an article in the movement (based on _id property)
 */
export const upsertArticleMovement = createAction(
  '[Stock] Upsert Article Movement',
  props<{ data: ArticleMovementLine }>()
);

/**
 * Delete an article in the movement (based on _id property)
 */
export const deleteArticleMovement = createAction(
  '[Stock] Delete Article Movement',
  props<{ data: ArticleMovementLine }>()
);

/**
 * Redefine all articles in the movement. Used by upsertArticleMovement and deleteArticleMovement.
 * You should not use it unless you know what you're doing.
 */
export const setMovementArticles = createAction(
  '[Stock] Set Movement Articles',
  props<{ data: ArticleMovementLine[] }>()
);

/**
 * Set the QrCode data for the movement
 */
export const setMovementQrCodeData = createAction(
  '[Stock] Set Movement QRCode Data',
  props<{ data: QrCodeMovementData[] }>()
);

/**
 * Reset all movement data to null/empty array
 */
export const clearMovement = createAction('[Stock] Clear Movement');

/**
 * Save the movement in the store
 */
export const saveMovement = createAction(
  '[Stock] Save Movement',
  props<{ storeKey: string }>()
);

/**
 * Save the movement in the store and call back the sync
 */
export const saveTransfertAndSync = createAction(
  '[Stock] Save Transfert And Sync',
  props<{ storeKey: string }>()
);

/**
 * Save the movement in the store and generate a QrCode
 */
export const saveTransfertAndDisplayQrCode = createAction(
  '[Stock] Save Transfert And display QrCode',
  props<{ storeKey: string }>()
);

/**
 * Action for removing a article from the grid
 */
export const removeArticleMovementLine = createAction(
  '[Stock] Remove Article Movement Line',
  props<{ storeKey: string; rowData: any }>()
);
