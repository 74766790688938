import { TcSpinnerService } from '@tc/store';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  NgRxTcDataState,
  updateItemSuccess,
} from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  distinctUntilChanged,
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  elevageGridOpenDetail,
  saveElevage,
  setCurrentElevage,
} from './elevage.actions';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { logout } from '../../../../modules/auth/store/auth.actions';
import { routerNavigatedAction } from '@ngrx/router-store';
import { getCurrentElevage } from './elevage.selectors';
import { Observable } from 'rxjs';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  NgRxTcSmartFormState,
  submitTcSmartFormCurrentModel,
} from '@tc/smart-form';
import { hasValue } from '@tc/utils';
import { ElevageService } from '../../../services/elevage.service';
import { setCurrentDocument } from '../../article/store/catalog-article.actions';
import { setCurrentEncaissement } from '../../encaissement/store/encaissement.actions';

@Injectable()
export class ElevageEffects {
  formStore$: Observable<NgRxTcSmartFormState>;
  dataStore$: Observable<NgRxTcDataState>;

  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly spinner: TcSpinnerService,
    private readonly elevageService: ElevageService
  ) {
    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  /**
   * Load tc grid data effect
   */
  elevageGridOpenDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(elevageGridOpenDetail),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;

          const action = `${storeKey} - spinner`;
          this.spinner.showSpinner(action);

          const previousElevage = await this.store$
            .select(getCurrentElevage)
            .pipe(take(1))
            .toPromise();

          await this.elevageService.setStoreCurrentElevage(rowData.numero);

          if (previousElevage?.numero !== rowData.numero) {
            // Clear up the working document if the elevage changed
            this.store$.dispatch(
              setCurrentDocument({ document: null, articleOrigin: null })
            );
            this.store$.dispatch(
              setCurrentEncaissement({ encaissement: null })
            );
          }

          this.store$.dispatch(
            navigate({
              route: `/${CustomRoutes.ElevageDetail}`,
              queryParams: [],
              storeKey: storeKey,
            })
          );

          this.actions$
            .pipe(ofType(routerNavigatedAction), take(1))
            .subscribe(() => this.spinner.hideSpinner(action));
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(async () => {
          this.store$.dispatch(setCurrentElevage({ currentElevage: null }));
        })
      ),
    { dispatch: false }
  );

  saveElevage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveElevage),
        tap(({ storeKey, elevageNumero }) => {
          const action = `${storeKey} - spinner`;
          this.spinner.showSpinner(action);

          this.store$.dispatch(submitTcSmartFormCurrentModel({ storeKey }));

          this.actions$
            .pipe(
              ofType(updateItemSuccess),
              take(1),
              switchMap(() =>
                this.elevageService.setStoreCurrentElevage(elevageNumero)
              )
            )
            .subscribe(() => {
              this.spinner.hideSpinner(action);
            });
        })
      ),
    { dispatch: false }
  );
}
