import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { Elevage } from '../interfaces/elevage.interface';

export interface ElevageState {
  currentElevage: Elevage | null;
  shownElevageContactRoutes: string[];
  lastFilteredSecteur: string;
}

export const initialElevageState: ElevageState = {
  currentElevage: null,
  shownElevageContactRoutes: [
    `/${CustomRoutes.ElevageDetail}`,
    // XAS-84 - Hide features
    // `/${CustomRoutes.DeliveriesList}`,
    `/${CustomRoutes.DevisList}`,
    `/${CustomRoutes.DevisDetail}`,
    `/${CustomRoutes.CommandesList}`,
    `/${CustomRoutes.DetailCommandesList}`,
    `/${CustomRoutes.ElevageDetail}`,
    `/${CustomRoutes.FactureList}`,
    `/${CustomRoutes.XrOptimaPurchaseHistory}`,
    // XAS-84 - Hide features
    //`/${CustomRoutes.CatalogArticles}`,
    // XAS-84 - Hide features
    // `/${CustomRoutes.Livraison}`,
    `/${CustomRoutes.MakeEstimateOrTakeOrder}`,
    `/${CustomRoutes.Doses}`,
    `/${CustomRoutes.BankDeposit}`,
  ],
  lastFilteredSecteur: null,
};
