import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  TcTranslateService,
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
  TcFormComponent,
} from '@tc/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MaterialButtonType, TcSmartButton } from '@tc/buttons';
import { Article } from '../../../../article/interfaces/article.interface';
import { ArticleMovementLine, MovementDepot } from '../../../store/stock.state';
import { StockService } from '../../../services/stock.services';
import { ModeSuiviStock } from '../../../../../typings/mode-suivi-stock.enum';
import { upsertArticleMovement } from '../../../store/stock.actions';
import * as R from 'ramda';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-article-movement-popup',
  templateUrl: './article-movement-popup.component.html',
  styleUrls: ['./article-movement-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleMovementPopupComponent
  extends TcFormComponent<any>
  implements OnInit, OnDestroy
{
  form: FormGroup = new FormGroup({});
  model: ArticleMovementLine;
  formFields: FormlyFieldConfig[];
  article: Article;
  departure: MovementDepot;
  buttonsList: TcSmartButton[];
  lots: { value: any; label: any }[] = [];
  lotsQuantity = {};
  subcription: Subscription = new Subscription();

  closeButtonConfig: TcSmartButton = {
    matIcon: 'close',
    type: MaterialButtonType.Icon,
    name: `prompt.close-button`,
  };

  confirmButtonConfig: TcSmartButton = {
    label: 'confirm',
    class: 'btn-secondary',
  };

  constructor(
    private dialogRef: MatDialogRef<ArticleMovementPopupComponent>,
    public readonly translate: TcTranslateService,
    private readonly stockService: StockService,
    private readonly store$: Store<any>,
    public elem: ElementRef,
    @Inject(MAT_DIALOG_DATA) data,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(translate, elem);
    // Set the data from the dialog parameters
    this.article = data.article;
    this.departure = data.departure;

    // If the article is in lot mode, get a list of all existing lots and make options for the select
    if (this.article.modeSuiviStock === ModeSuiviStock.SuiviLotsDLUO) {
      const lots = [
        ...new Set(
          data.article.stock
            .find((item) => item.depot.numero === this.departure.numeroDepot)
            ?.depot?.movement.filter((lot) => lot.lotEstEpuise === false)
            .map((item) => item.numeroLot)
        ),
      ];
      for (const lotNumber of lots) {
        this.lots.push({ value: lotNumber, label: lotNumber });
      }
      // Order by alphabeticla order
      this.lots.sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  ngOnDestroy(): void {
    this.subcription.unsubscribe();
  }

  async ngOnInit() {
    super.ngOnInit();

    // Subscription to form changes to detect selected article
    const subcriptionValueChanges = this.form.valueChanges.subscribe((item) => {
      if (this.form.invalid === false) {
        this.changeSubmitButtonState(true);
      } else {
        this.changeSubmitButtonState(false);
      }
    });
    this.subcription.add(subcriptionValueChanges);

    let remainingQuantity = 0;
    switch (this.article.modeSuiviStock) {
      case ModeSuiviStock.SuiviCMUP:
        remainingQuantity = this.departure?.codeEmplacement
          ? await this.stockService.getArticleRemainingQuantity(
              this.article,
              null,
              null,
              this.departure?.codeEmplacement
            )
          : await this.stockService.getArticleRemainingQuantity(
              this.article,
              null,
              this.departure.numeroDepot,
              null
            );
        break;
      case ModeSuiviStock.SuiviLotsDLUO:
        // Get all remaining quantity for all lots
        for (const lotNumber of this.lots) {
          const lotQuantity = this.departure?.codeEmplacement
            ? await this.stockService.getArticleRemainingQuantity(
                this.article,
                lotNumber.value,
                null,
                this.departure?.codeEmplacement
              )
            : await this.stockService.getArticleRemainingQuantity(
                this.article,
                lotNumber.value,
                this.departure.numeroDepot,
                null
              );
          if (lotQuantity <= 0) {
            // If the lot has no more stock, remove it from the available selection
            this.lots = this.lots.filter((item) => item !== lotNumber);
          } else {
            // Set the available quantity in the component
            this.lotsQuantity[lotNumber.value] = lotQuantity;
          }
        }
        break;
    }

    // Set data inside the form based on the selected article
    this.model = {
      _id: this.article.reference,
      reference: this.article.reference,
      description: this.article.intitule,
      quantiteEnStock: remainingQuantity,
      numeroLot: null,
      quantite: null,
    };

    // Init fields and buttons
    this.initFields();
    this.initButtons();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid === false) {
      this.store$.dispatch(
        upsertArticleMovement({ data: R.clone(this.model) })
      );
      this.dialogRef.close();
    }
  }

  changeSubmitButtonState(enable: boolean) {
    const element = this.document.querySelector(
      '.article-movement-popup-save-button'
    ) as HTMLElement;
    if (element) {
      switch (enable) {
        case true:
          element.classList.remove('btn-disabled');
          break;
        case false:
          element.classList.add('btn-disabled');
          break;
      }
    }
  }

  initFields() {
    this.formFields = [
      formlyColumn({
        fields: [
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'reference',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  label: this.translate.instant('reference'),
                  type: 'text',
                  appearance: 'outline',
                  disabled: true,
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'description',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  label: this.translate.instant('description'),
                  type: 'text',
                  appearance: 'outline',
                  disabled: true,
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'quantiteEnStock',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  label: this.translate.instant('quantiteEnStock'),
                  type: 'text',
                  appearance: 'outline',
                  disabled: true,
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'numeroLot',
                type: TcFormlyComponent.TcSmartSelect,
                templateOptions: {
                  label: this.translate.instant('numeroLot'),
                  change: (field, event) => {
                    const lotQuantity = this.lotsQuantity[event.value];
                    // Dynamic change of quantiteEnStock value in the form
                    if (field.form.controls?.quantiteEnStock) {
                      field.form.controls.quantiteEnStock.setValue(lotQuantity);
                      field.form.controls.quantiteEnStock.markAsTouched();
                    }
                    // Set the new reference for having a unique id in the grid based on the selected lot number
                    this.model['_id'] =
                      this.article.reference + '-' + event.value;
                  },
                  options: this.lots,
                  filter: true,
                  focusOnFilter: true,
                  appearance: 'outline',
                  required:
                    this.article.modeSuiviStock ===
                    ModeSuiviStock.SuiviLotsDLUO,
                  disabled:
                    this.article.modeSuiviStock !==
                    ModeSuiviStock.SuiviLotsDLUO,
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'quantite',
                type: TcFormlyComponent.NgxInput,
                templateOptions: {
                  required: true,
                  mask: '0*',
                  label: this.translate.instant('quantity'),
                  type: 'text',
                  appearance: 'outline',
                },
                validators: {
                  quantity: {
                    expression: (c: AbstractControl) =>
                      this.checkQuantity(c.value),
                    message: (error: any, field: FormlyFieldConfig) =>
                      this.translate.instant(
                        'quantity-exceed-quantity-in-stock'
                      ),
                  },
                  quantityNoZero: {
                    expression: (c: AbstractControl) =>
                      !(c.value === 0 || c.value === '0'),
                    message: (error: any, field: FormlyFieldConfig) =>
                      this.translate.instant('quantity-mandatory'),
                  },
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
        ],
        colSpan: 12,
      }),
    ];
  }

  /**
   * Custom check for quantities : cannot be more than the quantity in the depot / emplacement
   * @param value Quantity
   * @returns boolean
   */
  checkQuantity(value): boolean {
    const valueParsed =
      value && isNaN(parseInt(value, 10)) === false ? parseInt(value, 10) : 0;
    // Convert value to number in model if needed
    if (value && typeof value === 'string') {
      this.form.controls.quantite.setValue(valueParsed);
      this.form.controls.quantite.markAsTouched();
    }
    const formValue =
      this.model.quantiteEnStock &&
      isNaN(parseInt(this.model.quantiteEnStock as any, 10)) === false
        ? parseInt(this.model.quantiteEnStock as any, 10)
        : 0;
    return valueParsed <= formValue;
  }

  initButtons() {
    const buttons: TcSmartButton[] = [];

    buttons.push({
      label: 'cancel',
      action: this.onClose.bind(this),
      class: 'btn-secondary dialog-button',
    });
    buttons.push({
      label: 'confirm',
      action: this.onSubmit.bind(this),
      ionIcon: 'checkmark-outline',
      class:
        'btn-primary dialog-button article-movement-popup-save-button btn-disabled',
    });

    this.buttonsList = buttons;
  }
}
