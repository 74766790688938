import { Component, OnInit, Inject } from '@angular/core';
import {
  TcComponentLookup,
  TcTranslateService,
  formlyColumn,
  formlyControl,
  formlyRow,
  TcFormlyComponent,
} from '@tc/core';
import {
  closeDialogAndNavigateButtonClicked,
  submitAndCloseDialogButtonClicked,
  TcSmartDetailPopupComponent,
} from '@tc/advanced-components';
import { MaterialColor, TcConfigTypes } from '@tc/abstract';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormInvalidity,
} from '@tc/smart-form';
import { TcSmartButton } from '@tc/buttons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermissionAction } from '../../../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../../../typings/permission-subject.enum';
import { Store } from '@ngrx/store';
import { CustomRoutes } from '../../../../../typings/custom-routes.enum';

@TcComponentLookup('ElevageContactPopupComponent')
@Component({
  selector: 'app-elevage-contact-popup',
  templateUrl: './elevage-contact-popup.component.html',
  styleUrls: ['./elevage-contact-popup.component.scss'],
})
export class ElevageContactPopupComponent
  extends TcSmartDetailPopupComponent
  implements OnInit
{
  formStoreKey = 'elevage-contact-popup';

  constructor(
    private readonly translate: TcTranslateService,
    store$: Store<any>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(store$, data);

    this.formStoreKey = data.formStoreKey;
  }

  async setFormConfig() {
    this.dialogConfig.dialogStoreKey = this.formStoreKey;
    this.dialogConfig.hasFooter = false;

    this.formConfig = {
      permissionAction: PermissionAction.Update,
      permissionSubject: PermissionSubject.Elevage,
      configType: TcConfigTypes.TcForm,
      storeKey: this.formStoreKey,
      autoSubmit: false,
      fieldConfigs: [],
      titleConfig: {
        storeKey: this.formStoreKey,
        configType: TcConfigTypes.TcDetailTitle,
        titlePrefix: 'elevage-contact-popup.title',
        hasGenericSaveButton: false,
      },
      headerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
      },
      footerConfig: {
        configType: TcConfigTypes.TcDetailHeader,
        buttonsList: this.getHeaderButtons(),
      },
    };

    this.initFields();
  }

  initFields() {
    this.formConfig.fieldConfigs = [
      formlyColumn({
        fields: [
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'nom',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  required: true,
                  type: 'text',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'prenom',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  type: 'text',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'fonction',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  type: 'text',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'telportable',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  type: 'text',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'telephone',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  type: 'text',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
          formlyRow({
            className: 'dialog-input-group',
            fields: [
              formlyControl({
                key: 'email',
                type: TcFormlyComponent.FormlyInput,
                templateOptions: {
                  type: 'text',
                  appearance: 'outline',
                },
                modelOptions: {
                  updateOn: 'blur',
                },
                colSpan: 12,
              }),
            ],
          }),
        ],
        colSpan: 12,
      }),
    ];
  }

  getSaveButton(): TcSmartButton {
    return {
      label: 'save',
      action: submitAndCloseDialogButtonClicked,
      actionPayload: { storeKey: this.formStoreKey },
      class: 'btn-primary dialog-button',
      smartStateKey: DEFAULT_TC_SMART_FORM_STATE_KEY,
      disableStoreKey: this.formStoreKey,
      disableSelector: getTcSmartFormInvalidity,
    };
  }

  getHeaderButtons(): TcSmartButton[] {
    const headerButtons = [];

    headerButtons.push({
      label: 'cancel',
      action: closeDialogAndNavigateButtonClicked,
      actionPayload: {
        storeKey: this.formStoreKey,
        route: `/${CustomRoutes.ElevageDetail}`,
      },
      class: 'btn-secondary dialog-button',
    });

    headerButtons.push(this.getSaveButton());
    return headerButtons;
  }
}
