import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TcCoreModule } from '@tc/core';
import { SharedModule } from '../../../shared/shared.module';
import { CommandesGridComponent } from './components/smart/commandes-grid/commandes-grid.component';
import { CommandeDetailGridComponent } from './components/smart/commande-detail-grid/commande-detail-grid.component';
import { TcDataService, TcDataServiceFactory } from '@tc/data-store';
import { TcBreezeService } from '@tc/breeze';
import { TcButtonsModule } from '@tc/buttons';
import { TcSmartFormModule } from '@tc/smart-form';
import { EffectsModule } from '@ngrx/effects';
import { CommandeEffects } from './store/commande.effects';

const components = [CommandesGridComponent, CommandeDetailGridComponent];

const services = [TcDataServiceFactory, TcDataService, TcBreezeService];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcCoreModule,
    SharedModule,
    TcButtonsModule,
    TcSmartFormModule,
    EffectsModule.forFeature([CommandeEffects]),
  ],
  providers: [...services],
  exports: [...components],
})
export class CommandeModule {}
