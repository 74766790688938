import { selectValueByKey, TcSpinnerService } from '@tc/store';
import {
  DEFAULT_TC_DATA_STATE_KEY,
  deleteItemSuccess,
  getTcData,
  NgRxTcDataState,
  updateItem,
  updateItemSuccess,
} from '@tc/data-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { hasValue } from '@tc/utils';
import * as R from 'ramda';
import {
  recalculatePrice,
  removeDeliveryLine,
  resetLivraisonRowQuantity,
  saveDelivery,
  setExceededQuantitiesState,
  updateDeliveryDocument,
  updateDeliveryLine,
  verifyDeliveryWithoutOrder,
} from './livraison.actions';
import { navigate } from '@tc/advanced-components';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { getCurrentElevage } from '../../elevage/store/elevage.selectors';
import { DiscountType } from '../../../typings/discount-type.enum';
import {
  DEFAULT_TC_SMART_FORM_STATE_KEY,
  getTcSmartFormCurrentModel,
  NgRxTcSmartFormState,
} from '@tc/smart-form';
import { XpertService } from '../../../services/xpert.service';
import { ElevageService } from '../../../services/elevage.service';
import { DocumentService } from '../../../services/document.service';
import { ElevageDocument } from '../../elevage/interfaces/elevage-document.interface';
import { DocumentDomaine, DocumentType } from '../../../typings/document.enum';
import { TcLocalStorageService } from '@tc/local-storage';
import { LocalStorageKeys } from '../../../typings/local-storage-keys.enum';
import { TcNotificationService, TcTranslateService } from '@tc/core';
import { MatDialog } from '@angular/material/dialog';
import { TcPromptDialogComponent } from '@tc/dialog';
import { StockService } from './../../stock/services/stock.services';
import { ArticleLotMovement } from '../../../typings/article-lot-movement.interface';
import { MovementType } from '../../../typings/movement-type.enum';
import {
  getCurrentDocument,
  getCurrentDocumentArticleOrigin,
} from '../../article/store/catalog-article.selectors';
import { setCurrentDocument } from '../../article/store/catalog-article.actions';
import { PriceService } from '../../../services/price.service';
import { PaymentMode } from '../../../typings/payment-mode.enum';
import { DocumentLigne } from '../../elevage/interfaces/document-ligne.interface';
import { ArticleOrigin } from '../../commande/store/commande.payloads';
import { Encaissement } from '../../encaissement/store/encaissement.state';
import { getCurrentEncaissement } from '../../encaissement/store/encaissement.selectors';
import {
  saveCurrentEncaissement,
  setCurrentEncaissement,
} from '../../encaissement/store/encaissement.actions';

@Injectable()
export class LivraisonEffects {
  dataStore$: Observable<NgRxTcDataState>;
  formStore$: Observable<NgRxTcSmartFormState>;

  /**
   * Constructor
   */
  constructor(
    private readonly store$: Store<any>,
    private readonly actions$: Actions,
    private readonly xpertService: XpertService,
    private readonly elevageService: ElevageService,
    private readonly stockService: StockService,
    private readonly docService: DocumentService,
    private readonly spinner: TcSpinnerService,
    private readonly localStorage: TcLocalStorageService,
    private readonly notification: TcNotificationService,
    private readonly translateService: TcTranslateService,
    private readonly dialog: MatDialog,
    private readonly priceService: PriceService
  ) {
    this.dataStore$ = this.store$.pipe(
      select(DEFAULT_TC_DATA_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );

    this.formStore$ = this.store$.pipe(
      select(DEFAULT_TC_SMART_FORM_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  resetLivraisonRowQuantity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetLivraisonRowQuantity),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;

          const gridData = R.clone(
            await selectValueByKey(getTcData, this.dataStore$, storeKey)
          );

          const item = gridData.find((row) => row._id === rowData?._id);
          item.quantite = 0;

          this.store$.dispatch(updateItemSuccess({ storeKey: storeKey, item }));
          this.store$.dispatch(setExceededQuantitiesState({ exceeded: false }));
        })
      ),
    { dispatch: false }
  );

  verifyDeliveryWithoutOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyDeliveryWithoutOrder),
        tap(async (payload) => {
          const { storeKey } = payload;

          let gridData = R.clone(
            await selectValueByKey(getTcData, this.dataStore$, storeKey)
          );

          const elevage = await this.store$
            .select(getCurrentElevage)
            .pipe(take(1))
            .toPromise();

          gridData = gridData.filter((data) => +data.quantite > 0);

          const xpert = await this.xpertService.getConnectedXpert();

          const prescripteur = {
            _id: xpert.idXpert,
            nom: xpert.nom,
            prenom: xpert.prenom,
          };

          const siteLivraison = elevage.site?.find((site) => site.estPrincipal);

          const numXpertMobile =
            await this.docService.generateNewNumXpertMobile(xpert);
          const numeroPiece = (
            (await this.docService.getLastTempNumeroPiece()) + 1
          ).toString();

          const document: ElevageDocument = {
            numXpertMobile: numXpertMobile,
            domaine: DocumentDomaine.Vente,
            type: DocumentType.VenteBL,
            prescripteur: prescripteur,
            createdOn: new Date().toISOString(),
            date: new Date().toISOString().split('T')[0],
            dateLivraison: new Date().toISOString().split('T')[0],
            numeroPiece: numeroPiece.toString(),
            ligne: [],
            noteDeReglement: PaymentMode.Paiement1X,
            montant: null,
            totalTTC: null,
            dateCommande: null,
            echeance: null,
            siteLivraison: siteLivraison
              ? {
                  _id: siteLivraison.numeroInterne.toString(),
                  intitule: siteLivraison.intitule,
                }
              : null,
          };

          // Iterate on each row that have quantity and add it to existing document lines
          gridData.forEach((rowData: any) => {
            const ligne: DocumentLigne = {
              temporaryNumeroLot: rowData.numeroLot,
              numXpertMobile: numXpertMobile,
              domaine: DocumentDomaine.Vente,
              uniteVente: rowData.uniteVente,
              conditionnementXpert: rowData.conditionnementXpert,
              numeroPiece: numeroPiece,
              numeroInterne: null,
              type: DocumentType.VenteBL,
              quantite: rowData.quantite,
              taxe: rowData.taxe.taux,
              codeTaxe: rowData.taxe.code,
              article: { reference: rowData.reference },
              libelle: rowData.libelle,
              typeRemise: rowData.typeRemise
                ? (+rowData.typeRemise as DiscountType)
                : null,
              dateLivraison: null,
              datePieceDevisOrigine: new Date().toISOString().split('T')[0],
              montantRemise:
                rowData.tarif?.find(
                  (tarif) => tarif.elevage.numero === elevage.numero
                )?.remiseGenerale ?? 0,
              prixUnitaire:
                rowData.tarif?.find(
                  (tarif) => tarif.elevage.numero === elevage.numero
                )?.prixVente ?? 0,
            };

            // Add the article to the list if he was not already present
            document.ligne.push(ligne);
          });

          // Security checks
          if (
            this.docService.securityChecksForDocument(this.dialog, document) ===
            false
          ) {
            return;
          }

          this.store$.dispatch(
            setCurrentDocument({
              document: document,
              articleOrigin: ArticleOrigin.DeliveryWithoutOrder,
            })
          );
          this.store$.dispatch(setExceededQuantitiesState({ exceeded: false }));
          this.store$.dispatch(recalculatePrice());

          // XAS-84 - Hide features
          // this.store$.dispatch(
          //   navigate({
          //     route: `/${CustomRoutes.DeliveriesList}`,
          //     queryParams: [],
          //     storeKey: storeKey,
          //   })
          // );
        })
      ),
    { dispatch: false }
  );

  removeDeliveryLine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeDeliveryLine),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          const filteredLines = [];
          document.ligne.forEach((ligneObject) => {
            // Skip the lines that are matching the ref and the lot number or the ref without lot number
            if (rowData.numeroLot) {
              if (
                ligneObject.article.reference === rowData.article.reference &&
                ligneObject.temporaryNumeroLot === rowData.numeroLot
              ) {
                return;
              }
            } else {
              if (
                ligneObject.article.reference === rowData.article.reference &&
                !ligneObject.temporaryNumeroLot
              ) {
                return;
              }
            }
            filteredLines.push(ligneObject);
          });
          document.ligne = filteredLines;

          const origin = await this.store$
            .select(getCurrentDocumentArticleOrigin)
            .pipe(take(1))
            .toPromise();

          this.store$.dispatch(
            setCurrentDocument({ document: document, articleOrigin: origin })
          );
          setTimeout(() => {
            this.store$.dispatch(
              deleteItemSuccess({ storeKey, item: rowData })
            );
            this.store$.dispatch(recalculatePrice());
          });
        })
      ),
    { dispatch: false }
  );

  updateDeliveryDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateDeliveryDocument),
        tap(async (payload) => {
          const { storeKey } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );
          const headingFormValue = await selectValueByKey(
            getTcSmartFormCurrentModel,
            this.formStore$,
            storeKey
          );

          const elevage = R.clone(
            await this.store$
              .select(getCurrentElevage)
              .pipe(take(1))
              .toPromise()
          );

          const siteLivraison = headingFormValue?.adresseLivraison
            ? elevage.site?.find(
                (site) =>
                  site.numeroInterne === headingFormValue?.adresseLivraison
              )
            : undefined;

          if (document && headingFormValue) {
            // Update the fields from the form config
            document.date = headingFormValue.datePiece;
            document.dateLivraison = headingFormValue.dateLivraison;
            document.noteDeReglement = headingFormValue.noteDeReglement;
            document.siteLivraison = siteLivraison
              ? {
                  _id: siteLivraison.numeroInterne,
                  intitule: siteLivraison.intitule,
                }
              : null;

            const origin = await this.store$
              .select(getCurrentDocumentArticleOrigin)
              .pipe(take(1))
              .toPromise();

            this.store$.dispatch(
              setCurrentDocument({ document: document, articleOrigin: origin })
            );
          }
        })
      ),
    { dispatch: false }
  );

  updateDeliveryLine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateDeliveryLine),
        tap(async (payload) => {
          const { storeKey, rowData } = payload;
          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );

          // Select
          let line = null;
          if (rowData.numeroLot) {
            line = document.ligne.find(
              (item) =>
                item.article.reference === rowData.article.reference &&
                item.temporaryNumeroLot === rowData.numeroLot
            );
          } else {
            line = document.ligne.find(
              (item) =>
                item.article.reference === rowData.article.reference &&
                !item.temporaryNumeroLot
            );
          }
          if (line) {
            line.quantite = rowData.quantite;

            const origin = await this.store$
              .select(getCurrentDocumentArticleOrigin)
              .pipe(take(1))
              .toPromise();

            this.store$.dispatch(
              setCurrentDocument({ document: document, articleOrigin: origin })
            );
            this.store$.dispatch(recalculatePrice());
          }
        })
      ),
    { dispatch: false }
  );

  saveDelivery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveDelivery),
        tap(async (payload) => {
          const { storeKey } = payload;

          const action = `${storeKey} - spinner`;
          this.spinner.showSpinner(action);

          const elevage = R.clone(
            await this.store$
              .select(getCurrentElevage)
              .pipe(take(1))
              .toPromise()
          );

          const document: ElevageDocument = R.clone(
            await this.store$
              .select(getCurrentDocument)
              .pipe(take(1))
              .toPromise()
          );

          // Security checks
          if (
            this.docService.securityChecksForDocument(this.dialog, document) ===
            false
          ) {
            // Errors, stop the process.
            this.spinner.hideSpinner(action);
            return;
          }

          // Update what need to be updated before saving
          document.montant = this.docService.getMontantFromDocument(document);
          elevage.documents.push(document);

          // Get the encaissement from the store to check if he exists
          const encaissement: Encaissement = await this.store$
            .select(getCurrentEncaissement)
            .pipe(take(1))
            .toPromise();

          // Check if an encaissement exists and if he is really related to the current document
          if (encaissement.documents.length > 0) {
            const encaissementDocument = encaissement.documents[0];
            if (
              encaissementDocument.numXpertMobile !== document.numXpertMobile
            ) {
              this.store$.dispatch(
                setCurrentEncaissement({ encaissement: null })
              );
            }
          }

          this.store$.dispatch(
            updateItem({
              storeKey: 'elevage-grid',
              item: elevage,
              displaySuccessNotification: false,
            })
          );

          this.actions$
            .pipe(ofType(updateItemSuccess), take(1))
            .subscribe(() => {
              // Save the encaissement if it exists
              if (encaissement) {
                this.store$.dispatch(
                  saveCurrentEncaissement({ notification: false })
                );
              }

              this.elevageService.setStoreCurrentElevage(elevage.numero);
              this.spinner.hideSpinner(action);
            });

          await this.updateDeliveryArticles(
            document.ligne,
            document.numXpertMobile
          );
        })
      ),
    { dispatch: false }
  );

  recalculatePrice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recalculatePrice),
        tap(async () => {
          this.priceService.computePricesForDocument();
        })
      ),
    { dispatch: false }
  );

  private async updateDeliveryArticles(
    lignes: DocumentLigne[],
    numXpertMobile: string
  ) {
    const articles: ArticleLotMovement[] = lignes.map((row) => {
      return {
        reference: row.article.reference,
        quantite: row.quantite,
        numeroLot: row.temporaryNumeroLot,
      };
    });

    const currentVehicleCode = await this.localStorage.get(
      LocalStorageKeys.LastLoginVehicleKey
    );

    // Create movements and update stocks in articles
    await this.stockService.setArticleMovement(
      articles,
      MovementType.Release,
      numXpertMobile,
      null,
      currentVehicleCode
    );

    const translatedUpdateMessage =
      this.translateService.instant('update-successful');
    this.notification.success(translatedUpdateMessage);
    this.showSaveConfirmationDialog();
  }

  private showSaveConfirmationDialog(): void {
    this.dialog
      .open(TcPromptDialogComponent, {
        width: '37.5em',
        data: {
          title: this.translateService.instant('prompt.title'),
          disableTextTranslation: true,
          text: this.translateService.instant(
            'livraison-detail-page.saveConfirmation'
          ),
          confirm: this.translateService.instant('globalLabels.close'),
          displayCancelButton: false,
          displayConfirmButton: true,
          disableClose: true,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.store$.dispatch(
          navigate({
            route: `/${CustomRoutes.ElevageDetail}`,
            queryParams: {},
            storeKey: null,
          })
        );
      });
  }
}
