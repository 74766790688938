import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TcAppState } from '@tc/store';
import { TcSmartComponent } from '@tc/abstract';
import { take } from 'rxjs/operators';
import { LoginMfaInterface } from '../../../interfaces/login-mfa.interface';
import { clearMfaOptions, loginMfa } from '../../../store/auth.actions';
import { getAuthenticationMfaOptions } from '../../../store/auth.selectors';

/**
 * Represents the login MFA component.
 * @component
 * @selector app-login-mfa
 * @templateUrl ./login-mfa.component.html
 * @styleUrls ./login-mfa.component.scss
 */
@Component({
  selector: 'app-login-mfa',
  templateUrl: './login-mfa.component.html',
  styleUrls: ['./login-mfa.component.scss'],
})
/**
 * Represents the LoginMfaComponent class, which is responsible for handling the
 * multi-factor authentication (MFA) login process.
 * @extends TcSmartComponent
 * @implements OnInit, OnDestroy
 */
export class LoginMfaComponent
  extends TcSmartComponent
  implements OnInit, OnDestroy
{
  public plainTextCode: string;
  public src = null;

  /**
   * Constructs a new instance of the class.
   * @param {Store<TcAppState>} store$ - The store object for managing application state.
   * @param {TranslateService} translate - The translation service for handling language localization.
   * @returns None
   */
  constructor(
    store$: Store<TcAppState>,
    private readonly translate: TranslateService
  ) {
    super(store$);
  }

  /**
   * Initializes the component.
   * Retrieves the MFA options from the store and assigns them to the 'options' variable.
   * If the 'options' object contains a 'code' property, it sets the 'plainTextCode' property
   * to a formatted string that includes the translated 'plain-code' text and the code value.
   * Sets the 'src' property to the value of the 'image' property in the 'options' object.
   * @returns None
   */
  async ngOnInit() {
    const options: LoginMfaInterface = await this.store$
      .select(getAuthenticationMfaOptions)
      .pipe(take(1))
      .toPromise();

    if (options?.code) {
      this.plainTextCode = `${this.translate.instant(
        'login-mfa.plain-code'
      )}\n${options.code}`;
    }

    this.src = options?.image;
  }

  /**
   * Logs in the user with the provided data by dispatching the loginMfa action.
   * @param {any} data - The data needed for the login process.
   * @returns None
   */
  public login(data) {
    this.store$.dispatch(loginMfa(data));
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Dispatches an action to clear the MFA options in the store.
   * @returns None
   */
  ngOnDestroy() {
    this.store$.dispatch(clearMfaOptions());
  }
}
