import { createAction, props } from '@ngrx/store';
import { PasswordChange } from './xpert.state';

export const clearPasswordChange = createAction(
  '[Xpert] Clear Password Change'
);

export const setPasswordChange = createAction(
  '[Xpert] Set Password Change',
  props<{ data: PasswordChange }>()
);

export const saveNewPassword = createAction('[Xpert] Save New Password');
