import { createAction, props } from '@ngrx/store';
import { Permission, UserRights } from '../typings';

/**
 * the prefix for all the tc data actions
 */
export const TC_PERMISSIONS_ACTIONS_PREFIX: string = '[tc-permissions]';

/**
 * this action is triggered to load all the permissions for the current user
 */
export const loadTcPermissions = createAction(
  `${TC_PERMISSIONS_ACTIONS_PREFIX} Load Permissions`,
  props<{ userRoles: string[]; refresh: boolean }>()
);

/**
 * this action is triggered to load the permissions for the current user into the store
 */
export const loadTcPermissionsSuccess = createAction(
  `${TC_PERMISSIONS_ACTIONS_PREFIX} Load Permissions Success`,
  props<{ rights: UserRights; authenticatedUserPermissions: Permission[] }>()
);

/**
 * this action is triggered to refresh the permissions for the current user into the store
 */
export const refreshTcPermissionsSuccess = createAction(
  `${TC_PERMISSIONS_ACTIONS_PREFIX} Refresh Permissions Success`,
  props<{ rights: UserRights; authenticatedUserPermissions: Permission[] }>()
);
