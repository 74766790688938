import { LoginMfaInterface } from '../interfaces/login-mfa.interface';
import { UserModel } from '../models/user.model';

/**
 * Represents the authentication state of the application.
 * @interface AuthState
 * @property {boolean} isAuthenticated - Indicates whether the user is authenticated.
 * @property {UserModel} user - The user object containing user information.
 * @property {string | null} errorMessage - The error message associated with authentication.
 * @property {string} token - The authentication token.
 * @property {LoginMfaInterface} mfaOptions - The options for multi-factor authentication.
 * @property {string | null} loginAs - The user ID of the user being logged in as (if applicable).
 */
export interface AuthState {
  isAuthenticated: boolean;
  user: UserModel;
  errorMessage: string | null;
  token: string;
  mfaOptions: LoginMfaInterface;
  loginAs: string | null;
}

/**
 * The initial state for the authentication feature.
 * @type {AuthState}
 * @property {boolean} isAuthenticated - Indicates if the user is authenticated or not.
 * @property {User | null} user - The authenticated user object or null if not authenticated.
 * @property {string | null} errorMessage - The error message if authentication fails.
 * @property {string | null} token - The authentication token.
 * @property {MFAOptions | null} mfaOptions - The multi-factor authentication options.
 * @property {string | null} loginAs - The user to login as (for admin purposes).
 */
export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  token: null,
  mfaOptions: null,
  loginAs: null
};
